#expense-chart {
  padding-top: 25px !important;
}

#expense-chart .no-list {
  margin-left: -10px;
  padding-top: calc(20% - 7px);
}

#expense-chart .no-list .no-list-button {
  left: 0;
  margin-left: -10px;
}

#expense-chart .graph {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 500px;
  margin-bottom: 30px;
  overflow: hidden;
}

#expense-chart .graph.wide {
  width: 100%;
}

#expense-chart .graph.wide > div {
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0;
}

#expense-chart .graph > div {
  flex-basis: 50%;
  border-radius: 2px;
  background-color: #fff;
}

#expense-chart .graph .chart-left {
}

#expense-chart .graph .chart-right {
}

#expense-chart .graph-total {
  margin-bottom: 10px !important;
  padding: 0 20px;
  line-height: 50px;
  height: 50px;
}

#expense-chart .graph-total .left {
  float: left;
}

#expense-chart .graph-total .right {
  float: right;
  font-weight: bold;
  font-size: 20px;
}

#expense .display-type {
  width: 120px;
  float: left;
}

#expense #graph-type {
  width: 120px;
  height: 30px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #d5d5d5;
  display: inline-block;
}

#expense .graph-type li {
  display: inline-block;
  height: 26px;
  margin-top: 2px;
  width: 39px;
  text-align: center;
  border-right: 1px solid #d5d5d5;
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 10px;
  cursor: pointer;
}

#expense .graph-type li:last-child {
  border-right: none;
}

#expense .graph-type .pie-chart {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-chart/pie.png');
}

#expense .graph-type .pie-chart:hover,
#expense .graph-type .pie-chart-active {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-chart/pie-colored.png');
}

#expense .graph-type .area-chart {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/area-chart.png');
}

#expense .graph-type .area-chart-active {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/area-chart-active.png');
}

#expense .graph-type .bar-chart {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/bar-chart.png');
}

#expense .graph-type .bar-chart-active {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/bar-chart-active.png');
}

#expense .graph-type .column-chart {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-chart/bar.png');
}

#expense .graph-type .column-chart:hover,
#expense .graph-type .column-chart-active {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-chart/bar-colored.png');
}

#expense .graph-type .scatter-chart {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-chart/line.png');
}

#expense .graph-type .scatter-chart:hover,
#expense .graph-type .scatter-chart-active {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-chart/line-colored.png');
}

#expense-chart .chart-list {
  display: inline-block;
  width: 100%;
  padding-bottom: 50px;
}

#expense-chart .chart-list-clicked {
  width: 50%;
  padding-bottom: 50px;
}

#expense-chart .main-chart {
  width: 49px;
  float: left;
}

#expense-chart .sub-chart {
  width: 49px;
  float: right;
}

#expense-chart table {
  background-color: #fff;
}
