.list-selected {
  user-select: none;
}

#chart-select .list-body {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}

#chart-select div {
  outline: none;
}

#chart-select .list-selected:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#chart-select .list-selected {
  width: 88px;
  text-align: left;
  padding-left: 10px;
  height: 28px;
  font-weight: bold;
  border-radius: 2px;
  color: #657c97;
  background: #fff;
  border: 1px solid #657c97;
  line-height: 28px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 90%;
}

#chart-select #list {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  border-radius: 2px;
  border-top: solid 1px #657c97;
  border-bottom: solid 1px #657c97;
}

#chart-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  border-left: solid 1px #657c97;
  border-right: solid 1px #657c97;
}

#chart-select #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #657c97 !important;
  /*font-weight: bold;*/
}

#chart-select #list .child-body a {
  color: #657c97;
  display: block;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  font-size: 12px;
  text-decoration: none;
}

/* download */
#download {
  display: inline-block;
}

#download .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: 700;
  position: relative;
}

#download #list .icon-export {
  background-size: 25px 25px;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  margin: -7px 5px 0 5px;
}

#download div {
  outline: none;
}

#download .list-selected:hover {
  background-color: rgba(101, 124, 151, 0.1);
}

.language #chart-select .list-selected {
  width: 150px;
  background-position: 145px;
}

#download .list-selected {
  width: 85px;
  text-align: left;
  padding-left: 25px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #97a9c0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png'),
    url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down.png');
  background-size: 8px 6px, 16px;
  background-repeat: no-repeat, no-repeat;
  background-position: 95px, 7px;
}

.language #chart-select #list {
  width: 162px;
}

#download #list {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 190px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  font-weight: normal;
  border-top: 1px solid #97a9c0;
  margin-top: 1px;
}

#download #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  border-left: solid 1px #7e91a8;
  border-right: solid 1px #7e91a8;
}

#download #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
}

.language #download #list .child-body a {
  text-align: left;
  padding-left: 10px;
}

#download #list .child-body a {
  color: #333333 !important;
  display: block;
  padding: 10px 5px;
  font-size: 11px;
  text-decoration: none;
}

#download #list .child-body a.has-border {
  border-bottom: solid 1px #e8e8e8;
}

#download #list .child-body a.has-border.final {
  border-bottom: solid 1px #7e91a8;
}

/* attendee */
#attendee-filter-select {
  display: inline-block;
}

#attendee-filter-select .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  position: relative;
}

#attendee-filter-select div {
  outline: none;
}

#attendee-filter-select .list-selected:hover {
  background-color: rgba(101, 124, 151, 0.1);
}

#attendee-filter-select .list-selected {
  width: 150px;
  text-align: left;
  padding-left: 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #97a9c0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 145px;
}

#attendee-filter-select #list {
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  width: 162px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
}

#attendee-filter-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  border-left: solid 1px #97a9c0;
  border-right: solid 1px #97a9c0;
}

#attendee-filter-select #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
}

#attendee-filter-select #list .child-body a {
  color: #657c97 !important;
  display: block;
  text-align: left;
  border-bottom: solid 1px #97a9c0;
  padding: 12px 10px;
  font-size: 12px;
  text-decoration: none;
}

#attendee-filter-select #list .selected {
  background-color: #efefef;
}

#attendee .no-list {
  width: 100%;
  padding-top: calc(20% - 60px);
}

/* report */
#report-divide-select .list-body {
  text-decoration: none;
  color: #ffffff;
  height: 25px;
  cursor: pointer;
  position: relative;
}

#report-divide-select div {
  outline: none;
}

#report-divide-select .list-selected:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#report-divide-select .list-selected {
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 10px;
  height: 28px;
  border-radius: 2px;
  background: #fff;
  font-size: 13px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.22px;
  text-align: right;
  color: #419fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/tri-blue.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 90%;
}

#report-divide-select #list {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  border-radius: 2px;
  margin-top: 2px;
  border-top: solid 1px #657c97;
  border-bottom: solid 1px #657c97;
}

#report-divide-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  border-left: solid 1px #657c97;
  border-right: solid 1px #657c97;
}

#report-divide-select #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
}

#report-divide-select #list .child-body a {
  color: #047de0;
  display: block;
  text-align: left;
  padding: 12px 10px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}

#report-divide-select #list .selected {
  background-color: #efefef;
}

/* report-policy */

#report-policy-select .disabled .list-selected {
  background-color: #f9f9f9 !important;
  color: #aaa !important;
}

#report-policy-select .list-body {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}

#report-policy-select div {
  outline: none;
}

#report-policy-select .list-selected:hover {
  background-color: #f6f7f8;
}

#report-policy-select .list-selected {
  text-align: left;
  padding: 0 23px 0 10px;
  height: 28px;
  border-radius: 2px;
  color: #657c97;
  background: #fff;
  border: 1px solid #b4c0cc;
  line-height: 28px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-position: right 10px center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down-gray.png');
  background-repeat: no-repeat;
  background-size: 12px;
}

#report-policy-select #list {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  border-radius: 2px;
  border-top: solid 1px #657c97;
  border-bottom: solid 1px #657c97;
}

#report-policy-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  border-left: solid 1px #657c97;
  border-right: solid 1px #657c97;
}

#report-policy-select #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #657c97 !important;
}

#report-policy-select #list .child-body a {
  color: #657c97;
  display: block;
  width: 130px; /*padding left: 10px */
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 0 10px 12px;
  font-size: 12px;
  text-decoration: none;
}

#report-policy-select #list .selected {
  background-color: #efefef;
  font-weight: bold;
}

/* filter */
#filter-select li.label {
  font-size: 10px;
  color: #555555;
  padding-left: 10px;
  background-color: #f8f8f8;
}

#input-select div,
#filter-select div {
  outline: none;
  font-size: 11px;
  text-align: left;
}

#input-select .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#filter-select .disabled .list-body {
  color: #777;
  /*background-color: #cdcdcd;*/
  /*color: #aaa;*/
}

#input-select #list-clicked {
  border: solid 1px #047de0;
}

#input-select .list-body {
  background-color: #fff;
  color: #333333;
  border: 1px solid #cccccc;
}

#filter-select .list-body {
  background-color: rgb(64, 70, 78);
  color: #ffffff;
  border: 1px solid #71777d;
}

#input-select .list-body,
#filter-select .list-body {
  text-decoration: none;
  cursor: pointer;
  height: 36px;
  line-height: 35px;
  position: relative;
  border-radius: 2px;
}

#input-select .list-body *,
#filter-select .list-body * {
  transition: 0s !important;
}

#input-select .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 10px center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#filter-select .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/filter-dropdown-dark.png');
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: right 10px center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#input-select .disabled .list-selected,
#filter-select .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#filter-select #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 470px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#filter-select #list.search-list {
  margin-top: 5px;
}

#filter-select #list .search-area {
  position: fixed;
  z-index: 100;
  border-bottom: 1px solid #efefef;
}

/* input-select 스크롤바 스타일 */
#input-select ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 4px;
}

#input-select .selected-items-table::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 0px !important;
}

#input-select ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
}

#input-select ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

#input-select #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 120px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#input-select #list .child-body,
#filter-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#input-select #list .child-body:hover,
#filter-select #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#input-select #list .child-body:hover a,
#filter-select #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#filter-select #list .child-body.search {
  margin-top: 36px;
}

#filter-select #list .child-body a {
  line-height: 20px;
  padding: 10px;
}

#input-select #list .child-body a {
  padding: 12px 10px;
}

#input-select #list .child-body a,
#filter-select #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#input-select #list .selected,
#filter-select #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}

#filter-select #list input[type='text'] {
  background-color: white;
  border: 0px !important;
  width: 176px;
  color: #000;
}

/* date */
#date-select {
  float: left;
  margin-right: 10px;
}

#date-select .list-body {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

#date-select div {
  outline: none;
}

#date-select .list-selected:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#date-select .list-selected {
  width: 50px;
  text-align: left;
  padding-left: 10px;
  height: 28px;
  border-radius: 2px;
  color: #545454;
  background: #fff;
  border: 1px solid rgb(180, 192, 204);
  line-height: 28px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 90%;
}

#date-select #list {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 60px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  border-radius: 2px;
  margin-top: 2px;
  border-top: solid 1px rgb(180, 192, 204);
  border-bottom: solid 1px rgb(180, 192, 204);
}

#date-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  border-left: solid 1px rgb(180, 192, 204);
  border-right: solid 1px rgb(180, 192, 204);
}

#date-select #list .child-body:hover {
  background-color: #f6f7f8;
  color: #97a9c0;
  font-weight: bold;
}

#date-select #list .child-body a {
  color: #97a9c0;
  display: block;
  width: 100%;
  text-align: left;
  padding: 12px 10px;
  font-size: 12px;
  text-decoration: none;
}

/* user card scraping modal */

#card-policy-select div {
  outline: none;
  font-size: 13px;
}

#card-policy-select .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#card-policy-select .list-body {
  border: 1px solid rgb(180, 192, 204);
}

#card-policy-select .list-body {
  background-color: #fff;
  text-decoration: none;
  cursor: pointer;
  height: 36px;
  line-height: 35px;
  position: relative;
  color: #333333;
  border-radius: 2px;
}

#card-policy-select .list-body * {
  transition: 0s !important;
}

#card-policy-select .activated .list-selected {
  padding: 0 10px;
  height: 35px;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 10px center;
}

#card-policy-select .disabled .list-selected {
  padding: 0 10px;
  height: 35px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#card-policy-select #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#card-policy-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#card-policy-select #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#card-policy-select #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#card-policy-select #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  padding: 12px 10px;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#card-policy-select #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}

/*filter-transaction*/

#transaction {
  display: inline-block;
}

#transaction .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: 700;
  position: relative;
}

#transaction div {
  outline: none;
}

#transaction .list-selected:hover {
  background-color: rgba(101, 124, 151, 0.1);
}

#transaction .list-selected {
  width: 100px;
  text-align: left;
  padding-left: 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #657c97;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 95px;
  margin-right: 10px;
}

#transaction #list {
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  width: 112px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-right: 10px;
}

#transaction #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  border-left: solid 1px #657c97;
  border-right: solid 1px #657c97;
}

#transaction #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
}

#transaction #list .child-body a {
  color: #657c97 !important;
  display: block;
  text-align: center;
  border-bottom: solid 1px #657c97;
  padding: 12px 0;
  font-size: 12px;
  text-decoration: none;
}

/* filter-transaction-custom */
#transaction-custom {
  float: left;
  position: relative;
  display: inline-block;
}

#transaction-custom .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: normal;
  position: relative;
}

#transaction-custom div {
  outline: none;
}

#transaction-custom .list-selected:hover {
  width: 105px;
  text-align: left;
  padding: 0 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 105px;
  margin-right: 10px;
  margin-bottom: 1px;
  font-weight: bold;
  color: #047cde;
  background-color: rgba(101, 124, 151, 0.1);
}

#transaction-custom .list-selected {
  width: 105px;
  text-align: left;
  padding: 0 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  color: #002855;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 105px;
  margin-right: 10px;
  margin-bottom: 1px;
}

#transaction-custom #list-clicked #list {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#transaction-custom #list {
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  width: 122px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-right: 10px;
}

#transaction-custom #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  color: #002855 !important;
}

#transaction-custom #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
  font-weight: bold;
}

#transaction-custom #list .child-body a {
  color: #657c97 !important;
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
}

/* transaction-custom-active */
#transaction-custom-active {
  float: left;
  position: relative;
  display: inline-block;
}

#transaction-custom-active .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: normal;
  position: relative;
}

#transaction-custom-active div {
  outline: none;
}

#transaction-custom-active .list-selected:hover {
  width: 105px;
  text-align: left;
  padding: 0 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 105px;
  margin-right: 10px;
  margin-bottom: 1px;
  font-weight: bold;
  color: #047cde;
  background-color: rgba(101, 124, 151, 0.1);
}

#transaction-custom-active .list-selected {
  width: 105px;
  text-align: left;
  padding: 0 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  color: #047cde;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 105px;
  margin-right: 10px;
  margin-bottom: 1px;
}

#transaction-custom-active .list-selected span {
  width: calc(100% - 12px);
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#transaction-custom-active #list-clicked #list {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#transaction-custom-active #list {
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  width: 122px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-right: 10px;
}

#transaction-custom-active #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  color: #002855 !important;
}

#transaction-custom-active #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
  font-weight: bold;
}

#transaction-custom-active #list .child-body a {
  color: #657c97 !important;
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
}

/*select-company-size*/
#select-company-size div {
  width: 70%;
  outline: none;
  font-size: 13px;
  position: relative;
  float: left;
}

#select-company-size {
  width: 100%;
}

#select-company-size .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#select-company-size .list-body {
  height: 40px;
  border: solid 1px #d5d5d5;
}

#select-company-size .list-body {
  background-color: #fff;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  float: left;
  color: #333333;
  border-radius: 2px;
  padding: 2px 20px 2px 0px;
  line-height: 40px;
}

#select-company-size .list-body * {
  transition: 0s !important;
}

#select-company-size .activated .list-selected {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 9px 5.8px;
  width: 100%;
  text-align: left;
  margin: auto;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  padding: 0px 0px 0px 10px;
}

#select-company-size .disabled .list-selected {
  padding: 0 10px;
  height: 35px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  padding: 7px 10px;
}

#select-company-size #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 40px;
  border: solid 1px #d5d5d5;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#select-company-size #list .child-body {
  cursor: pointer;
  display: block;
  line-height: 10px;
  padding: 10px 20px 0px 0px;
}

#select-company-size .list-body ul {
  position: relative;
  float: left;
  padding: 0px;
}

#select-company-size #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#select-company-size #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#select-company-size #list .child-body a {
  color: #047de0;
  display: block;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 10px 20px 10px 10px;
}

#select-company-size #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}

#company_size {
  margin-top: 30px;
}

/* custom-select */
#custom-select div {
  outline: none;
  font-size: 11px;
  text-align: left;
  margin-top: 1px;
}

#custom-select .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#custom-select #list-clicked {
  z-index: 21;
  border: solid 1px #047de0;
}

#custom-select .list-body {
  background-color: #fff;
  color: #333333;
  border: 1px solid #b4c0cc;
}

#custom-select .list-body {
  text-decoration: none;
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  position: relative;
  border-radius: 2px;
}

#custom-select .list-body * {
  transition: 0s !important;
}

#custom-select .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 34px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down-gray.png');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: right 10px center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#custom-select .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#custom-select #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#custom-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#custom-select #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#custom-select #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#custom-select #list .child-body a {
  padding: 12px 10px;
}

#custom-select #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#custom-select #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}
/* custom-field */

/* company-users-select */
#card-types-select {
  float: left;
  position: relative;
}

#company-users-select {
  float: left;
  position: relative;
  top: -5px;
  margin-right: 1%;
}

#card-types-select div,
#company-users-select div {
  outline: none;
  font-size: 13px;
  text-align: left;
}

#card-types-select .disabled .list-body,
#company-users-select .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#card-types-select #list-clicked,
#company-users-select #list-clicked {
  background-color: #f4f6f8;
}

#card-types-select .list-body,
#company-users-select .list-body {
  background-color: #fff;
  color: #333333;
}

#card-types-select .list-body,
#company-users-select .list-body {
  text-decoration: none;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  width: 100px;
  position: relative;
  float: left;
  border-radius: 2px;
}

#card-types-select .list-body *,
#company-users-select .list-body * {
  transition: 0s !important;
}

#card-types-select .activated .list-selected,
#company-users-select .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 28px;
  line-height: 28px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 10px center;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: #002855;
}

#card-types-select .disabled .list-selected,
#company-users-select .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#card-types-select #list,
#company-users-select #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#card-types-select #list .child-body,
#company-users-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#card-types-select #list .child-body:hover,
#company-users-select #list .child-body:hover {
  /*.custom-tooltip .tooltip-text*/
  background-color: rgba(4, 125, 224, 0.05);
}

#card-types-select #list .child-body:hover a,
#company-users-select #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#card-types-select #list .child-body a,
#company-users-select #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  padding: 12px 10px;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#card-types-select #list .selected,
#company-users-select #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}
/* company-users-select end */

/* company-users-active */
#company-users-active {
  float: left;
  position: relative;
  top: -5px;
}

#company-users-active div {
  outline: none;
  font-size: 13px;
  text-align: left;
}

#company-users-active .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#company-users-active #list-clicked {
  background-color: #f4f6f8;
}

#company-users-active .list-body {
  background-color: #fff;
  color: #333333;
}

#company-users-active .list-body {
  text-decoration: none;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  width: 100px;
  position: relative;
  float: left;
  border-radius: 2px;
}

#company-users-active .list-body * {
  transition: 0s !important;
}

#company-users-active .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 28px;
  line-height: 28px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-blue.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 10px center;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: #047cde;
}

#company-users-active .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#company-users-active #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#company-users-active #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#company-users-active #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#company-users-active #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#company-users-active #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  padding: 12px 10px;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#company-users-active #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}
/* company-users-select end */

/* export-options */
#export-options {
  display: inline-block;
}

#export-options .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: 700;
  position: relative;
}

#export-options div {
  outline: none;
}

#export-options .list-selected:hover {
  background-color: rgba(101, 124, 151, 0.1);
}

#export-options .list-selected {
  width: 117px;
  text-align: left;
  padding-left: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #97a9c0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png'),
    url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/export/btn-export.png');
  background-size: 8px 6px, 13px 13px;
  background-repeat: no-repeat, no-repeat;
  background-position: 125px, 7px;
}

#export-options #list {
  width: 190px;
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  max-height: 300px;
  display: block;
  margin-right: 10px;
  margin-top: 2px;
  border-top: 1px solid #7e91a8;
}

#export-options #list .child-body {
  background-color: #ffffff !important;
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  border-left: solid 1px #7e91a8;
  border-right: solid 1px #7e91a8;
}

#export-options #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
}

#export-options #list .child-body a {
  color: #333333 !important;
  display: block;
  text-align: left;
  padding: 10px 0;
  font-size: 11px;
  font-weight: normal;
  text-decoration: none;
}

#export-options #list .child-body a.has-border {
  border-bottom: solid 1px #e8e8e8;
}

#export-options #list .child-body a.has-border.final {
  border-bottom: 1px solid #97a9c0;
}

#export-options .icon-report-download {
  background-size: 25px;
  width: 25px;
  height: 25px;
  background-position: center;
  margin: 0 5px;
  margin-right: 10px;
  background-repeat: no-repeat;
  margin-top: -7px;
}

/* company-fuel-select */
#company-fuel-select {
  float: left;
  position: relative;
  width: 100%;
}

#company-fuel-select div {
  outline: none;
  font-size: 13px;
  text-align: left;
}

#company-fuel-select .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#company-fuel-select #list-clicked {
  background-color: #f4f6f8;
}

#company-fuel-select .list-body {
  background-color: #fff;
  color: #333333;
}

#company-fuel-select .list-body {
  text-decoration: none;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  width: 100%;
  position: relative;
  float: left;
  border: 1px solid #b4c0cc;
  border-radius: 2px;
}

#company-fuel-select .list-body * {
  transition: 0s !important;
}

#company-fuel-select .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 10px center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#company-fuel-select .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#company-fuel-select #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#company-fuel-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#company-fuel-select #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#company-fuel-select #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#company-fuel-select #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  padding: 12px 10px;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#company-fuel-select #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}

/* fuel-options */
#input-select .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#filter-select .disabled .list-body {
  color: #777;
  /*background-color: #cdcdcd;*/
  /*color: #aaa;*/
}

/*fuel-select*/

#daily-wage-select div,
#fuel-select div {
  outline: none;
  font-size: 13px;
  text-align: left;
}

#daily-wage-select #list-clicked,
#fuel-select #list-clicked {
  border: solid 1px #047de0;
}

#fuel-select .list-body,
#daily-wage-select .disabled .list-body {
  background-color: #f9f9f9;
}

#daily-wage-select .list-body {
  background-color: #fff;
  color: #333333;
  border: 1px solid #99afcd;
  width: 150px;
  float: left;
  margin-right: 10px;
  text-decoration: none;
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  position: relative;
  border-radius: 2px;
}

#fuel-select .list-body {
  background-color: #fff;
  color: #333333;
  border: 1px solid #99afcd;
  width: 150px;
  margin-left: 20px;
  float: left;
  text-decoration: none;
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  position: relative;
  border-radius: 2px;
}

#daily-wage-select .list-body *,
#fuel-select .list-body *,
#filter-select .list-body * {
  transition: 0s !important;
}

#daily-wage-select .activated .list-selected,
#fuel-select .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 34px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 10px center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#daily-wage-select .disabled .list-selected,
#fuel-select .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#daily-wage-select #list,
#fuel-select #list {
  position: absolute;
  width: 150px;
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#daily-wage-select #list .child-body,
#fuel-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 150px;
  line-height: 10px;
}

#daily-wage-select #list .child-body:hover,
#fuel-select #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#daily-wage-select #list .child-body:hover a,
#fuel-select #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#daily-wage-select #list .child-body a,
#fuel-select #list .child-body a {
  padding: 12px 10px;
}

#daily-wage-select #list .child-body a,
#fuel-select #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#daily_wage_select #list .selected,
#fuel-select #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}
/* fuel-select end */

/* checkbox select */
#checkbox-select .list-body {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}

#checkbox-select div {
  outline: none;
}

#checkbox-select #list {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 2px;
  border-radius: 2px;
  border-top: solid 1px #97a9c0;
  border-bottom: solid 1px #97a9c0;
}

#checkbox-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  border-left: solid 1px #97a9c0;
  border-right: solid 1px #97a9c0;
}

#checkbox-select #list .child-body:hover a {
  color: #047de0 !important;
}

#checkbox-select #list .child-body:hover {
  background-color: rgba(5, 125, 224, 0.05);
  font-weight: bold;
}

#checkbox-select #list .child-body a {
  color: #657c97;
  display: block;
  text-align: left;
  padding: 12px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  width: 90%;
  margin: 0 auto;
  text-decoration: none;
}
/* checkbox select end */

/* page navigation */

#page-navigation div {
  outline: none;
  font-size: 11px;
  text-align: left;
}

#page-navigation .disabled .list-body {
  color: #777;
  /*background-color: #cdcdcd;*/
  /*color: #aaa;*/
}

#page-navigation .list-body {
  background-color: #ffffff;
  color: #000;
  border: 1px solid #efefef;
}

#page-navigation .list-body {
  text-decoration: none;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  position: relative;
  border-radius: 2px;
}

#page-navigation .list-body * {
  transition: 0s !important;
}

#page-navigation .activated .list-selected {
  padding: 0 10px 0 10px;
  height: 25px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 10px 6px;
  background-position: right 10px center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#page-navigation .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 25px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#page-navigation #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 150px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#page-navigation #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#page-navigation #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#page-navigation #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#page-navigation #list .child-body a {
  padding: 10px;
  font-size: 12px;
}

#page-navigation #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#page-navigation #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}

/* billing-transaction */
#billing-transaction {
  float: left;
  position: relative;
  display: inline-block;
}

#billing-transaction .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: normal;
  position: relative;
}

#billing-transaction div {
  outline: none;
}

#billing-transaction .list-selected:hover {
  width: 65px;
  text-align: center;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 1px;
  font-weight: bold;
  color: #047cde;
  background-color: rgba(101, 124, 151, 0.1);
}

#billing-transaction .list-selected {
  width: 65px;
  text-align: center;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  color: #002855;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 1px;
}

#billing-transaction #list-clicked #list {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#billing-transaction #list {
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  width: 65px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
}

#billing-transaction #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  color: #002855 !important;
}

#billing-transaction #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
  font-weight: bold;
}

#billing-transaction #list .child-body a {
  color: #657c97 !important;
  display: block;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
}

/* transaction-custom-active */
#billing-transaction-active {
  float: left;
  position: relative;
  display: inline-block;
}

#billing-transaction-active .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: normal;
  position: relative;
}

#billing-transaction-active div {
  outline: none;
}

#billing-transaction-active .list-selected:hover {
  width: 65px;
  text-align: center;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 1px;
  font-weight: bold;
  color: #047cde;
  background-color: rgba(101, 124, 151, 0.1);
}

#billing-transaction-active .list-selected {
  width: 65px;
  text-align: center;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  color: #047cde;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 1px;
}

#billing-transaction-active #list-clicked #list {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#billing-transaction-active #list {
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  width: 65px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
}

#billing-transaction-active #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  color: #002855 !important;
}

#billing-transaction-active #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
  font-weight: bold;
}

#billing-transaction-active #list .child-body a {
  color: #657c97 !important;
  display: block;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
}

/* transaction-upload */
#transaction-upload {
  margin-top: -8px;
}

#transaction-upload div {
  outline: none;
  font-size: 11px;
  text-align: left;
  float: left;
  width: 80px;
  height: 25px;
  color: #7d7d7d;
}

#transaction-upload .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#transaction-upload #list-clicked {
  border: solid 1px #047de0;
}

#transaction-upload .list-body {
  color: #333333;
  text-decoration: none;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  position: relative;
  transition: 0s !important;
  border-radius: 2px;
  border: solid 1px #cfcfcf;
}

#transaction-upload .activated .list-selected {
  width: 70%;
  padding: 0 15px 0 10px;
  height: 25px;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 5px center;
}

#transaction-upload .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 25px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#transaction-upload #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 100;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 27px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  color: #7d7d7d;
}

#transaction-upload #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#transaction-upload #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#transaction-upload #list .child-body:hover {
  transition: 0s !important;
  background-color: #f0f2f4 !important;
}

#transaction-upload #list .child-body a {
  padding: 12px 10px;
}

#transaction-upload #list .child-body a {
  color: #047de0;
  display: block;
  width: 85%;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#transaction-upload #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}

/* transaction-upload-active */
#transaction-upload-active {
  margin-top: -8px;
}

#transaction-upload-active div {
  outline: none;
  font-size: 11px;
  text-align: left;
  float: left;
  width: 80px;
  height: 25px;
  color: #333333;
}

#transaction-upload-active .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#transaction-upload-active #list-clicked {
  border: solid 1px #047de0;
}

#transaction-upload-active .list-body {
  color: #333333;
  text-decoration: none;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  position: relative;
  transition: 0s !important;
  border-radius: 2px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
}

#transaction-upload-active .activated .list-selected {
  width: 70%;
  padding: 0 15px 0 10px;
  height: 25px;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 5px center;
}

#transaction-upload-active .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 25px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#transaction-upload-active #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 100;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 27px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  color: #7d7d7d;
}

#transaction-upload-active #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#transaction-upload-active #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#transaction-upload-active #list .child-body:hover {
  transition: 0s !important;
  background-color: #f0f2f4 !important;
}

#transaction-upload-active #list .child-body a {
  padding: 12px 10px;
}

#transaction-upload-active #list .child-body a {
  color: #047de0;
  display: block;
  width: 85%;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#transaction-upload-active #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}

/* categoty-select*/
#category-select div {
  outline: none;
  font-size: 11px;
  text-align: left;
  margin-top: 1px;
}

#category-select .disabled .list-body {
  background-color: #f9f9f9;
  color: #aaa;
}

#category-select #list-clicked {
  border: solid 1px #047de0;
}

#category-select .list-body {
  background-color: #fff;
  color: #333333;
  border: 1px solid #b4c0cc;
}

#category-select .list-body {
  text-decoration: none;
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  position: relative;
  border-radius: 2px;
}

#category-select .list-body * {
  transition: 0s !important;
}

#category-select .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 34px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 10px center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#category-select .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 34px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 10px center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#category-select .disabled .list-selected {
  padding: 0 20px 0 10px;
  height: 35px;
  font-size: 12px;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#category-select #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#category-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#category-select #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#category-select #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#category-select #list .child-body a {
  padding: 12px 10px;
}

#category-select #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#category-select #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}
/* category-select */

/* plan-policy */
#plan-policy-select .disabled .list-selected {
  background-color: #f9f9f9 !important;
  color: #aaa !important;
}

#plan-policy-select .list-body {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}

#plan-policy-select div {
  outline: none;
}

#plan-policy-select {
  width: 450px;
  float: left;
}

#plan-policy-select .list-selected:hover {
  background-color: #f6f7f8;
}

#plan-policy-select .list-selected {
  text-align: left;
  padding-left: 10px;
  height: 34px;
  border-radius: 2px;
  color: #000000;
  background: #fff;
  border: 1px solid #b4c0cc;
  line-height: 34px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-position: right 10px center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 12px;
}

#plan-policy-select #list {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  border-radius: 2px;
  border-top: solid 1px #657c97;
  border-bottom: solid 1px #657c97;
}

#plan-policy-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  border-left: solid 1px #657c97;
  border-right: solid 1px #657c97;
}

#plan-policy-select #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #657c97 !important;
}

#plan-policy-select #list .child-body a {
  color: #657c97;
  display: block;
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 0 10px 12px;
  font-size: 12px;
  text-decoration: none;
}

#plan-policy-select #list .selected {
  background-color: #efefef;
  font-weight: bold;
}

/*policy-modal*/
#plan-item-select .disabled .list-selected {
}

#plan-item-select .list-body {
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

#plan-item-select div {
  outline: none;
}

#plan-item-select .list-selected:hover {
  background-color: #f6f7f8;
}

#plan-item-select .list-selected {
  text-align: left;
  padding: 0 13px;
  height: 34px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #b4c0cc;
  line-height: 34px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-position: right 6px center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 10px;
}

#plan-item-select #list {
  position: absolute;
  z-index: 1;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  border-radius: 2px;
  margin-top: 1px;
  border: solid 1px #b4c0cc !important;
}

#plan-item-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  color: #333 !important;
}

#plan-item-select .list-body #list li a {
  color: #333 !important;
}

#plan-item-select #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #333 !important;
}

#plan-item-select #list .child-body a {
  color: #657c97;
  display: block;
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 0 10px 12px;
  font-size: 12px;
  text-decoration: none;
}

#plan-item-select #list .selected {
  background-color: #efefef;
}

/*plan-item-td-1*/
#plan-td-1-selected .disabled .list-selected {
  height: 28px;
}

#plan-td-1-selected .list-body {
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

#plan-td-1-selected div {
  outline: none;
}

#plan-td-1-selected .list-selected:hover {
  background-color: #f6f7f8;
}

#plan-td-1-selected .list-selected {
  text-align: left;
  padding: 0 16px 0 13px;
  height: 28px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #b4c0cc;
  line-height: 28px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-position: right 6px center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 10px;
}

#plan-td-1-selected #list {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100px;
  display: block;
  border-radius: 2px;
  border-top: solid 1px #657c97;
  border-bottom: solid 1px #657c97;
}

#plan-td-1-selected #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  border-left: solid 1px #657c97;
  border-right: solid 1px #657c97;
}

#plan-td-1-selected #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #657c97 !important;
}

#plan-td-1-selected #list .child-body a {
  color: #657c97;
  display: block;
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 0 10px 12px;
  font-size: 12px;
  text-decoration: none;
}

#plan-td-1-selected #list .selected {
  background-color: #efefef;
  font-weight: bold;
}

/*plan-item-td-3*/
.plan-td-3-selected .disabled .list-selected {
  height: 28px;
}

.plan-td-3-selected .list-body {
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

#plan-td-3-selected div {
  outline: none;
}

#plan-td-3-selected .list-selected:hover {
  background-color: #f6f7f8;
}

#plan-td-3-selected .list-selected {
  text-align: left;
  padding: 0 13px;
  height: 28px;
  width: 35px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #b4c0cc;
  line-height: 28px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-position: right 3px center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 10px;
}

#plan-td-3-selected #list {
  position: absolute;
  width: 63px;
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100px;
  display: block;
  border-radius: 2px;
  border-top: solid 1px #657c97;
  border-bottom: solid 1px #657c97;
}

#plan-td-3-selected #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  border-left: solid 1px #657c97;
  border-right: solid 1px #657c97;
}

#plan-td-3-selected #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #657c97 !important;
}

#plan-td-3-selected #list .child-body a {
  color: #657c97;
  display: block;
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 0 10px 12px;
  font-size: 12px;
  text-decoration: none;
}

#plan-td-3-selected #list .selected {
  background-color: #efefef;
  font-weight: bold;
}

/*plan-item-td-4*/
#plan-td-4-selected .disabled .list-selected {
  height: 28px;
}

#plan-td-4-selected .list-body {
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

#plan-td-4-selected div {
  outline: none;
}

#plan-td-4-selected .list-selected:hover {
  background-color: #f6f7f8;
}

#plan-td-4-selected .list-selected {
  text-align: left;
  padding: 0 15px 0 13px;
  height: 28px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #b4c0cc;
  line-height: 28px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-position: right 6px center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 10px;
}

#plan-td-4-selected #list {
  position: absolute;
  width: 93px;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100px;
  display: block;
  z-index: 10;
  border-radius: 2px;
  border: solid 1px #b4c0cc !important;
  margin-top: 1px;
}

#plan-td-4-selected #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  color: #333 !important;
}

#plan-td-4-selected .list-body #list li a {
  color: #333 !important;
}

#plan-td-4-selected #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #333 !important;
}

#plan-td-4-selected #list .child-body a {
  color: #657c97;
  display: block;
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 0 10px 12px;
  font-size: 12px;
  text-decoration: none;
}

#plan-td-4-selected #list .selected {
  background-color: #efefef;
}

/* plan-days-select */
#plan-days-select .disabled .list-selected {
  background-color: #f9f9f9 !important;
  color: #aaa !important;
}

#plan-days-select .list-body {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}

#plan-days-select div {
  outline: none;
}

#plan-days-select {
  width: 120px;
  float: left;
}

#plan-days-select .list-selected:hover {
  background-color: #f6f7f8;
}

#plan-days-select .list-selected {
  text-align: left;
  padding-left: 10px;
  height: 34px;
  border-radius: 2px;
  color: black;
  background: #fff;
  border: 1px solid #b4c0cc;
  line-height: 34px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-position: right 10px center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 12px;
}

#plan-days-select #list {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  border-radius: 2px;
  border-top: solid 1px #657c97;
  border-bottom: solid 1px #657c97;
}

#plan-days-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 500;
  line-height: 10px;
  border-left: solid 1px #657c97;
  border-right: solid 1px #657c97;
}

#plan-days-select #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #657c97 !important;
}

#plan-days-select #list .child-body a {
  color: #657c97;
  display: block;
  width: 104px; /*padding left: 10px */
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 0 10px 12px;
  font-size: 12px;
  text-decoration: none;
}

#plan-days-select #list .selected {
  background-color: #efefef;
  font-weight: bold;
}

/* bulk-selected */
#bulk-selected {
  display: inline-block;
}

#bulk-selected .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: 700;
  position: relative;
}

#bulk-selected div {
  outline: none;
}

#bulk-selected .list-selected:hover {
  background-color: rgba(101, 124, 151, 0.1);
}

#bulk-selected .list-selected {
  width: 100px;
  text-align: left;
  padding-left: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #97a9c0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png'),
    url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expenses/check-all.png');
  background-size: 8px 6px, 16px;
  background-repeat: no-repeat, no-repeat;
  background-position: 110px, 7px;
}

#bulk-selected #list {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 130px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
}

#bulk-selected #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  border-left: solid 1px #97a9c0;
  border-right: solid 1px #97a9c0;
}

#bulk-selected #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
}

#bulk-selected #list .child-body a {
  text-align: left;
  color: #657c97 !important;
  display: block;
  border-bottom: solid 1px #97a9c0;
  padding: 12px 0 12px 10px;
  font-size: 12px;
  padding-left: 28px;
  text-decoration: none;
}

/* filter-transaction-custom */
#transaction-policy {
  float: left;
  position: relative;
  display: inline-block;
}

#transaction-policy .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: normal;
  position: relative;
}

#transaction-policy div {
  outline: none;
}

#transaction-policy .list-selected:hover {
  width: 105px;
  text-align: left;
  padding: 0 20px 0 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 115px;
  margin-right: 10px;
  margin-bottom: 1px;
  font-weight: bold;
  color: #047cde;
  background-color: rgba(101, 124, 151, 0.1);
}

#transaction-policy .list-selected {
  width: 105px;
  text-align: left;
  padding: 0 20px 0 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  color: #002855;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 115px;
  margin-right: 10px;
  margin-bottom: 1px;
}

#transaction-policy #list-clicked #list {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#transaction-policy #list {
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  width: 133px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-right: 10px;
}

#transaction-policy #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  color: #002855 !important;
}

#transaction-policy #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
  font-weight: bold;
}

#transaction-policy #list .child-body a {
  color: #657c97 !important;
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
}

/* transaction-custom-active */
#transaction-policy-active {
  float: left;
  position: relative;
  display: inline-block;
}

#transaction-policy-active .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: normal;
  position: relative;
}

#transaction-policy-active div {
  outline: none;
}

#transaction-policy-active .list-selected:hover {
  width: 105px;
  text-align: left;
  padding: 0 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 105px;
  margin-right: 10px;
  margin-bottom: 1px;
  font-weight: bold;
  color: #047cde;
  background-color: rgba(101, 124, 151, 0.1);
}

#transaction-policy-active .list-selected {
  width: 105px;
  text-align: left;
  padding: 0 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  color: #047cde;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 105px;
  margin-right: 10px;
  margin-bottom: 1px;
}

#transaction-policy-active .list-selected span {
  width: calc(100% - 12px);
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#transaction-policy-active #list-clicked #list {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#transaction-policy-active #list {
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  width: 122px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-right: 10px;
}

#transaction-policy-active #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  color: #002855 !important;
}

#transaction-policy-active #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
  font-weight: bold;
}

#transaction-policy-active #list .child-body a {
  color: #657c97 !important;
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
}

#add-approver-select {
  float: left;
  position: relative;
  display: inline-block;
}

#add-approver-select .list-body {
  text-decoration: none;
  color: #657c97;
  cursor: pointer;
  font-weight: normal;
  position: relative;
}

#add-approver-select div {
  outline: none;
}

#add-approver-select .list-selected {
  border: solid 1px #97a9c0;
  width: 65px;
  text-align: left;
  padding: 0 20px 0 20px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  color: #657c97;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png'),
    url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add.png');
  background-size: 8px, 15px;
  background-repeat: no-repeat;
  background-position: 88px, 4px;
  margin-right: 10px;
  margin-bottom: 1px;
  font-weight: 700;
}

#add-approver-select #list-clicked #list {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#add-approver-select #list {
  position: absolute;
  z-index: 100;
  background-color: #ffffff;
  width: 105px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
}

#add-approver-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 10px;
  color: #002855 !important;
}

#add-approver-select #list .child-body:hover * {
  background-color: #f6f7f8;
  color: #047de0 !important;
  font-weight: bold;
}

#add-approver-select #list .child-body a {
  color: #657c97 !important;
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
}

#add-approval-line-modal #input-select #list-clicked {
  border: solid 1px #b5d3ff;
}

#add-approval-line-modal #input-select .activated .list-selected {
  padding: 0 0 0 10px;
  margin-right: 34px;
  background-image: none;
  border-right: 1px solid #d3d3d3;
}

#add-approval-line-modal #input-select #list {
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  border: solid 1px #d4d4d4;
  margin-top: 4px;
  width: 100%;
}

#add-approval-line-modal #input-select .list-body {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  border-radius: 6px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 14px center;
}
