/* 새로운 로그인 적용 */
.sign-in-body {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 100% auto;
  background-color: #343a3c;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/sign-in-bg.png');
}

.sign-in-body input:-ms-input-placeholder {
  color: #b6c0c9;
  /*font-weight: normal;*/
}

.sign-in-body input::-webkit-input-placeholder {
  color: #b6c0c9;
  /*font-weight: normal;*/
}

.sign-in-body input::-moz-placeholder {
  color: #b6c0c9;
}

.sign-in-body input::-moz-placeholder {
  color: #b6c0c9;
}

.sign-in-body#member {
  width: 100%;
  height: 100%;
}

.sign-in-body#member .member-box {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -275px;
  margin-left: -275px;
  width: 550px;
  height: 550px;
  border-radius: 30px;
  background-color: #103868;
}

.sign-in-body#member .member-box #logo {
  position: relative;
  width: 190px;
  height: 60px;
  text-indent: -9999px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/web-logo.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 50px;
  padding-top: 50px;
  margin: 0 auto 105px auto;
}

.sign-in-body#member .member-box #form #terms {
  padding: 15px 0 20px 0;
}

.sign-in-body#member .member-box #form #terms input {
  margin-right: 10px;
}

.sign-in-body#member .member-box #form #terms input[type='checkbox'] {
  background-size: 12px;
}

.sign-in-body#member .member-box #form #terms div {
  color: #ffffff;
  font-size: 14px;
  width: 45%;
  float: left;
}

.sign-in-body#member .member-box #form input[type='checkbox'] {
  width: 13px !important;
  height: 13px !important;
}

.sign-in-body#member .member-box #form input#email {
  background-size: 20px;
  background-position: 12px center;
}

.sign-in-body#member .member-box #form input#username {
  background-size: 20px;
  background-position: 12px center;
}

.sign-in-body#member .member-box #form input {
  background-size: 14px;
  background-position: 97% center;
}

.sign-in-body#member .member-box #bottom button,
.sign-in-body#member .member-box #form button {
  font-weight: normal !important;
}

.sign-in-body#member .member-box {
  width: 500px;
  height: 600px;
  border-radius: 5px;
  background-color: #ffffff;
}

.sign-in-body#member .member-box #form {
  width: 402px;
  margin: 20px 0 0 50px;
}

.sign-in-body#member .member-box #logo {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/sign-in-logo.png');
  margin: 0 auto 0px auto;
}

.sign-in-body#member .member-box #form #terms {
  padding: 15px 0 5px 0;
}

.sign-in-body#member .member-box #form #terms div {
  color: #9f9f9f;
  font-size: 14px;
  width: 50%;
  float: left;
}

.sign-in-body#member .member-box #form #terms {
  padding: 15px 0 5px 0;
}

.sign-in-body#member .member-box #form #lost {
  color: #9f9f9f;
  text-decoration: none;
  font-size: 14px;
  float: right;
}

.sign-in-body#member .member-box #bottom {
  width: 100%;
  height: 60px;
}

.sign-in-body#member #register.member-box #logo {
  margin-bottom: 0px;
}

.sign-in-body#member .member-box #form input[type='text'],
.sign-in-body#member .member-box #form input[type='password'] {
  width: 338px;
  padding: 0 30px 0 32px;
  font-size: 14px;
  display: block;
  line-height: 20px;
  height: 50px;
  border-radius: 2px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: solid 1px #d1d7dd;
  color: #000000;
  outline: none;
  background-repeat: no-repeat;
}

.sign-in-body#member #register #form input.validated {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/blue-checked.png');
  background-size: 20px;
}

.sign-in-body#member #register #form input.error {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/x-red.png');
  background-size: 15px;
}

.sign-in-body #reset-password p {
  margin: 10px 0 20px 0;
}

.sign-in-body #reset-password input {
  width: 95%;
}

#reset-password .option input {
  margin: 10px 0;
  width: 95%;
}

#terms_of_service .description {
  height: 500px;
  overflow: auto;
  line-height: 20px;
  font-size: 13px;
}

.sign-in-body#member .member-box .desc_login {
  margin: 0 auto;
  position: relative;
  width: 190px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #9f9f9f;
  height: 85px;
}

.sign-in-body#member .member-box .desc_register {
  margin: 0 auto;
  position: relative;
  width: 190px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #9f9f9f;
  height: 40px;
}

@media (max-width: 999px) {
  .sign-in-body#member .member-box #logo {
    margin: 0 auto 0 auto;
  }

  .sign-in-body#member .member-box {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background-color: #ffffff;
  }

  .sign-in-body#member .member-box #bottom {
    position: relative;
    width: 100%;
  }

  .sign-in-body#member .member-box #bottom {
    width: 100%;
    height: 60px;
  }

  .sign-in-body#member .member-box #form {
    width: 80%;
    margin: 10% 10% 0;
  }

  .sign-in-body#member .member-box #bottom {
    height: 45px;
  }

  .sign-in-body#member .member-box #form input[type='text'],
  .sign-in-body#member .member-box #form input[type='password'] {
    width: 100%; /*(fallback) */
    width: -webkit-calc(100% - 18px); /* for Chrome, Safari */
    width: -moz-calc(100% - 18px); /* for Firefox */
    width: calc(100% - 18px); /* for IE */
    padding: 0px 0 0 20px;
    font-size: 14px;
    display: block;
    line-height: 20px;
    height: 50px;
    border-radius: 2px;
    margin: 0 0 10px 0;
    background-color: #ffffff;
    border: solid 1px #d1d7dd;
    color: #000000;
    outline: none;
    background-repeat: no-repeat;
  }
}

/* 새로운 로그인 적용 End */
