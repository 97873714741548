#company-groups {
  table-layout: fixed;
}

#company-groups .sort {
  width: 10px;
  height: 13px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/sort.png');
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 5px;
  cursor: pointer;
}

#company-groups .top-area,
#cards-view-custom .top-area {
  z-index: 90;
  right: 1px;
  height: 120px;
  position: fixed;
  width: 100%;
}

#company-groups .sort#up {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/up-blue.png');
}

#company-groups .sort#down {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down-blue.png');
}

#company-groups .group-td-check {
  width: 10%;
  padding: 0 10px;
  box-sizing: border-box;
}

#company-groups .group-td-1 {
  width: 25%;
  text-align: left;
  padding-right: 5px;
  box-sizing: border-box;
}

#company-groups .group-td-2 {
  width: 25%;
  text-align: left;
  font-size: 12px;
  padding-right: 10px;
  box-sizing: border-box;
}

#company-groups .group-td-3 {
  width: 25%;
  text-align: left;
  font-size: 12px;
  padding-right: 10px;
  box-sizing: border-box;
}

#company-groups .group-td-4 {
  width: 15%;
  text-align: left;
  font-size: 12px;
  padding-right: 10px;
  box-sizing: border-box;
}

#company-groups .group-td-4 .icon-cog-gray {
  float: right;
}

#company-groups .group-td-1 p,
#company-groups .group-td-2 p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#company-groups.wrap .content {
  padding-top: 120px;
  height: 100%;
  box-sizing: border-box;
}

#company-groups .top-area .button-area {
  padding: 20px 0 0 35px;
  float: left;
  background-color: #f7f8fa;
}

#company-groups .top-area .button-area span {
  color: #657c97;
}

#company-groups .search-area input[type='text'] {
  left: calc(50% - 15%);
}

#company-groups.wrap .content input::placeholder {
  color: #b7b7b7;
}

#company-groups-modal {
  width: 800px;
}

#company-groups-modal .Select-input {
  width: 100%;
}

#company-groups-modal .option .Select-placeholder {
  background-position: 560px !important;
}

#company-groups-modal .tax-policy-desc {
  color: #7d7d7d;
  font-size: 12px;
  display: inline;
}

#company-groups-modal .button-bottom button {
  width: 89.4px;
  height: 28px;
  border: 1px solid #047de0;
  border-radius: 2px;
  background-color: #047de0;
  color: #ffffff;
}

/*split view */
#company-group-members.wrap-split {
}

#company-group-members #split-view #group-list {
  display: block;
  width: 239px;
  height: 100%;
  position: fixed;
  padding: 0 0 20px 0;
  border-right: 1px solid #ddd;
  z-index: 1;
  overflow-y: auto;
}

#company-group-members #split-view #group-list .group-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #002855;
  width: 230px;
  margin: 0 5px;
  background: #ffffff;
  height: 75px;
  line-height: 75px;
  position: fixed;
  border-bottom: 2px solid #b6b6b6;
}

#company-group-members #split-view #group-list .list-ul {
  overflow-y: auto;
  margin: 75px 5px 0 5px;
  height: 80%; /*(fallback) */
  height: -webkit-calc(100% - 92px); /* for Chrome, Safari */
  height: -moz-calc(100% - 92px); /* for Firefox */
  height: calc(100% - 92px); /* for IE */
}

#company-group-members #split-view #group-list .list-ul .no-groups {
  color: #555555;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

#company-group-members #split-view #group-list .list-ul .list-li .group-info {
  float: left;
  width: 60%;
  line-height: 34px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 34px;
}

#company-group-members #split-view #group-list .list-ul .list-li .icons {
  float: right;
}

#company-group-members #split-view #group-list .list-ul .list-li .icons li:first-child {
  margin-right: 5px;
}

#company-group-members #split-view #group-list .list-ul .list-li .icons li {
  float: left;
}

#company-group-members #split-view #group-list .list-ul .list-li .icons {
  display: inline-block;
  margin-top: 3px;
  float: right;
}

#company-group-members #split-view #group-list .list-ul .list-li {
  border-bottom: 1px solid #e8e8e8;
  height: 34px;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
}

#company-group-members #split-view #group-list .list-ul .list-li:hover {
  background-color: rgba(190, 201, 216, 0.1);
}

#company-group-members #split-view #group-list .list-ul .selected {
  background-color: rgba(190, 201, 216, 0.1);
  font-weight: bold;
  font-size: 14px;
}

#company-group-members #split-view #group-list .list-ul .list-li:last-child {
  border-bottom: 2px solid #b6b6b6;
  padding: 10px 15px;
  height: 34px;
}

#company-group-members #split-view #group-list .list-ul li.no-tags {
  font-size: 12px;
  color: #333333;
  padding-top: 20px;
  text-align: center;
}

#company-group-members #split-view #members-view {
  width: -webkit-calc(100% - 240px); /* for Chrome, Safari */
  width: -moz-calc(100% - 240px); /* for Firefox */
  width: calc(100% - 240px); /* for IE */
  left: 240px;
  position: relative;
}

#company-group-members #split-view #members-view .content .dot {
  width: 6px;
  height: 6px;
  margin: 4px 20px 0 0;
  border-radius: 100%;
  background-color: #002855;
  float: left;
}

#company-group-members #split-view #members-view .content .group-members-body .no-list-text {
  margin-top: 50px;
}

#company-group-members #split-view #members-view .content {
  padding: 200px 35px 0 35px;
}

#company-group-members .top-area {
  position: fixed;
  float: left;
  height: 205px;
  z-index: 50;
  width: -webkit-calc(100% - 242px); /* for Chrome, Safari */
  width: -moz-calc(100% - 242px); /* for Firefox */
  width: calc(100% - 242px); /* for IE */
  background-color: #ffffff;
  margin: 0 auto;
  right: 1px;
}

#company-group-members #group .group-info {
  position: relative;
  float: left;
  width: -webkit-calc(100% - 70px);
  width: -moz-calc(100% - 70px);
  width: calc(100% - 70px);
  padding: 15px 35px;
}

#company-group-members #group .group-info .group-title {
  font-size: 16px;
  font-weight: bold;
  color: #002855;
}

#company-group-members #group .group-info .group-desc {
  font-size: 13px;
  font-weight: normal;
  color: #7d7d7d;
  margin-top: 10px;
  list-style-type: none;
}

#company-group-members #group .group-info .group-desc span {
  margin-left: 22px;
}

#company-group-members #group .button-search-area {
  width: 100%;
  height: 75px;
  float: left;
  position: relative;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
}

#company-group-members #group .button-area {
  position: relative;
  float: left;
  width: -webkit-calc(100% - 70px);
  width: -moz-calc(100% - 70px);
  width: calc(100% - 70px);
  height: 30px;
  border-bottom: 1px solid #efefef;
  padding: 10px 35px;
}

#company-group-members #group .button-area p {
  margin: 20px auto 0 auto;
  font-size: 12px;
  line-height: 15px;
  color: #333;
  background: rgba(250, 250, 250, 0.8);
}

#company-group-members .search-area {
  position: relative;
  float: left;
  width: 100%;
  height: 30px;
  margin-top: 35px;
}

#company-group-members .search-area input[type='text']:focus {
  background-color: transparent !important;
  border: 0 solid !important;
  color: #000000 !important;
}

#company-group-members .search-area input[type='text']:focus,
#company-group-members .search-area input[type='text'] {
  position: relative;
  float: left;
  font-size: 13px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #000000;
  left: calc(50% - 15%);
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 90%;
  width: 30%;
  height: 40px;
  border-bottom: solid 2px #002855 !important;
}

/* tag list */
#group-members .group-member-td-check {
  width: 30px;
  padding: 0 15px;
}

#group-members .group-members-row .group-member-td-check input {
  margin: 22.5px 0;
}

#group-members .group-member-td-1 {
  width: 20%;
  text-align: left;
  margin-right: 5px;
}

#group-members .group-member-td-2 {
  width: 20%;
  text-align: left;
  margin-right: 5px;
}

#group-members .group-member-td-profile img {
  float: left;
  width: 32px;
  height: 32px;
  margin: 10px 0;
  border-radius: 15px;
}

#group-members .group-member-td-profile {
  width: 34px;
  margin-right: 5px;
}

#group-members .div-table-body-wrapper {
  font-size: 13px;
}

#group-members .modal-desc dl {
  width: 100%;
  font-size: 14px;
  line-height: 45px;
  color: #545454;
}

#group-members .modal-desc dl dt {
  display: inline-block;
  vertical-align: middle;
  width: 20%;
  padding: 0 3px;
  margin: 11px 0;
  line-height: 15px;
  float: left;
}

#company-doc-center-policy dl dt {
  width: 15% !important;
}

#company-doc-center-policy dl dd input[type='checkbox'] {
  float: left;
  margin: 10px 10px 10px 0;
}

#company-doc-center-policy dl dd span {
  float: left;
  font-size: 14px;
  color: #555555;
}

#company-doc-center-policy .switch {
  float: left;
  margin-right: 10px;
}

/* company-taxes */

.swal2-container .with-checkbox .swal2-actions {
  margin: 0 auto;
}

.swal2-container .with-checkbox .swal2-header {
  height: 115px;
}

.swal2-container .with-checkbox .swal2-content {
  margin-top: 20px;
}

.swal2-container .with-checkbox .swal2-content .swal2-checkbox {
  margin: 20px 0 10px 0;
}

.swal2-container .with-checkbox .swal2-content .swal2-checkbox .swal2-label {
  display: flex;
}

.swal2-container .with-checkbox .swal2-content .swal2-checkbox input {
  margin: 0 10px;
}

#company-taxes .search-area input[type='text'] {
  left: calc(50% - 15%) !important;
}

#company-taxes .top-area {
  position: fixed;
  width: 100%;
  z-index: 100;
}

#company-taxes .sort {
  width: 10px;
  height: 13px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/sort.png');
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 5px;
  cursor: pointer;
}

#company-taxes .sort#up {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/up-blue.png');
}

#company-taxes .sort#down {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down-blue.png');
}

#company-taxes .group-td-check {
  width: 50px;
  padding: 0 10px;
}

#company-taxes .group-td-1 {
  width: 30%;
  text-align: left;
  margin-right: 5px;
}
#company-taxes.user-ca .group-td-1 {
  width: 40%;
}

#company-taxes .group-td-2 {
  width: 30%;
  text-align: left;
  font-size: 12px;
  margin-right: 10px;
}
#company-taxes.user-ca .group-td-2 {
  width: 25%;
}

#company-taxes .group-td-3 {
  width: 23%;
  text-align: left;
  font-size: 12px;
  margin-right: 10px;
}
#company-taxes.user-ca .group-td-3 {
  width: 18%;
}

#company-taxes .group-td-4 {
  width: 34px;
  text-align: left;
  font-size: 12px;
}

#company-taxes .group-td-5 {
  width: 34px;
  text-align: left;
  font-size: 12px;
}

#company-taxes .group-td-1 p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 11px;
}
#company-taxes .group-td-3 p,
#company-taxes .group-td-2 p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
}

#company-taxes .group-td-1 p {
  margin-left: 10px;
  width: 100%;
  float: left;
}

#company-taxes .group-td-1 .admin-info {
  float: left;
  margin: 7px 0;
}

#company-taxes .group-td-1 .admin-info .admin-name {
  font-weight: bold;
  font-size: 14px;
}

#company-taxes .group-td-1 .admin-info .admin-email {
  color: #7d7d7d;
}

#company-taxes.wrap .content {
  padding-top: 126px;
  height: 100%;
  box-sizing: border-box;
}

#company-taxes .top-area .button-area {
  width: calc(100% - 70px);
  height: 50px;
  background-color: #f7f8fa;
  position: relative;
  float: left;
  padding: 20px 35px 0;
}

#company-taxes .top-area .button-area .btn-icon {
  color: #657c97;
}

#company-taxes .search-area input[type='text'] {
  left: calc(50% - 15%);
}

#company-taxes.wrap .content input::placeholder {
  color: #b7b7b7;
}

#company-taxes-modal input[type='text']:disabled {
  color: #545454 !important;
}

#company-taxes #split-view #tax-list {
  /* display: block;
  width: 239px;
  height: 100%;
  position: fixed; */
  padding: 0 0 20px 0;
  background-color: #fff;
  /* z-index: 1;
  overflow-y: auto; */
}

#company-taxes #split-view #tax-list .tax-title {
  padding-top: 14px;
  height: 74px;
  box-sizing: border-box;
  color: #002855;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.333333px;
  text-align: center;
}

#company-taxes #split-view #tax-list .tax-title .en {
  line-height: 13px;
  font-size: 13px;
  float: left;
  margin: 30px 25px 25px 25px;
}

#company-taxes #split-view #tax-list .list-ul {
  overflow-y: auto;
  height: 80%;
  height: -webkit-calc(100% - 92px);
  height: -moz-calc(100% - 92px);
  height: calc(100% - 92px);
}

#company-taxes #split-view #tax-list .list-ul .no-taxes {
  color: #555555;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

#company-taxes #split-view #tax-list .list-ul .selected {
  background-color: rgba(190, 201, 216, 0.1);
  font-weight: bold;
  font-size: 14px;
}

#company-taxes #split-view #tax-list .list-ul .list-li {
  padding: 13px 10px;
  height: 40px;
  font-size: 14px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}

#company-taxes #split-view #tax-list .list-ul .list-li .tax-img {
  float: left;
  width: 25%;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-tax/hometax.png');
  background-size: 36px 36px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 5px;
  height: 40px;
}

#company-taxes #split-view #tax-list .list-ul .list-li .tax-info {
  float: left;
  width: 40%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 3px 0;
}

#company-taxes #split-view #tax-list .list-ul .list-li .tax-info .id {
  float: left;
  width: 100%;
  font-weight: bold;
  color: #000000;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#company-taxes #split-view #tax-list .list-ul .list-li .tax-info .number {
  float: left;
  width: 100%;
  margin-top: 12px;
  font-size: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#company-taxes #split-view #tax-list .list-ul .list-li .icons {
  display: inline-block;
  margin: 3px 0;
}

#company-taxes #split-view #tax-list .list-ul .list-li .icons {
  float: right;
}

#company-taxes #split-view #tax-list .list-ul .list-li .icons li:first-child {
}

#company-taxes #split-view #tax-list .list-ul .list-li .icons li {
  float: left;
}

#company-taxes #transaction-filter-custom {
  position: relative;
  margin-top: 0;
  width: 100%;
  box-sizing: border-box;
}

#company-taxes #billing-transaction-title span {
  float: left;
  border-bottom: none !important;
  margin-left: 40px;
  font-weight: 700;
}

#company-taxes .transaction-title {
  padding-top: 28px;
}

#mypage #split-view #spent-info .content p {
  font-size: 13px;
}

#mypage #split-view #spent-info .content .status {
  color: #7d7d7d;
}

#mypage #split-view #spent-info .content .tax-transaction-td-1 {
  width: 15px;
  padding: 0 10px;
}

#mypage #split-view #spent-info .content .tax-transaction-td-bill-type {
  width: 8%;
  padding-right: 20px;
  box-sizing: border-box;
}

#mypage #split-view #spent-info .content .tax-transaction-td-2 {
  width: 18%;
  box-sizing: border-box;
}

#mypage #split-view #spent-info .content .tax-transaction-td-3 {
  width: 8%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .tax-transaction-td-4 {
  width: 8%;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .tax-transaction-td-5 {
  width: 8%;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .tax-transaction-td-6 {
  width: 8%;
  text-align: right;
  padding-right: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
}

#mypage #split-view #spent-info .content .tax-transaction-td-7 {
  width: 8%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 10px;
  box-sizing: border-box;
}

#mypage #split-view #spent-info .content .tax-transaction-td-7 .div-table-body-row-wrapper span {
  float: left;
  width: 100%;
}

#mypage #split-view #spent-info .content .tax-transaction-td-8 {
  width: 8%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 15px;
  text-align: left;
  box-sizing: border-box;
}

#mypage #split-view #spent-info .content .tax-transaction-td-9 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  width: 15%;
}

#mypage #split-view #spent-info .content .tax-transaction-td-10 {
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  width: 6%;
  box-sizing: border-box;
}

#mypage #split-view #spent-info .content .tax-transaction-td-10 button {
  height: 25px;
  border-radius: 2px;
  border: solid 1px #047cde;
  background-color: #ffffff;
  color: #047cde;
  font-size: 13px;
}

#mypage #split-view #spent-info .content .tax-transaction-td-10 button:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #047cde;
  background-color: #047cde;
  color: #ffffff;
}

#mypage #split-view #spent-info .content .tax-transaction-td-10 .stage {
  color: #7d7d7d;
  font-size: 13px;
}

#mypage #split-view #spent-info .content .tax-transaction-td-img {
  width: 40px;
  padding-right: 10px;
}

#mypage #split-view #spent-info .content .tax-transaction-td-img img {
  margin-top: 5px;
  border: 1px solid #e5e8ea;
  border-radius: 2px;
}

#company-taxes #split-view #tax-list .icon-trash-gray {
  margin-right: 0px !important;
  background-position: center;
  width: 34px !important;
  height: 34px !important;
}

#company-taxes #split-view #tax-list .icon-trash-gray:hover {
  background-color: #edeff1;
  border-radius: 20px;
}

#company-taxes .div-table-header {
  border-bottom: 1px solid #687987;
}

#company-taxes #transaction-list {
  margin-top: 0;
}

#company-taxes .no-list-text {
  padding-top: 20px;
}

#company-taxes .icon-trash-gray {
  background-position: center;
  margin-right: 0;
}

#company-taxes .icon-trash-gray:hover {
  background-color: #edeff1;
  border-radius: 20px;
}

#company-taxes .group-td-1 img {
  float: left;
  width: 32px;
  height: 32px;
  margin: 10px 0;
  border-radius: 15px;
}

#company-taxes-modal {
  width: 800px;
  margin-left: -400px;
}

#company-taxes-modal .tax-policy-desc {
  color: #7d7d7d;
  font-size: 12px;
  display: inline-block;
  margin-top: -32px;
  line-height: 12px;
}

#company-taxes-modal dl dd {
  width: calc(78% - 100px);
}

#company-taxes-modal dl dt .mandatory {
  margin-left: 3px;
  color: #057ee1;
  font-size: 14px;
  display: inline-block;
}

#company-taxes-modal .option input[type='text'] {
  border: 1px solid #d5d5d5;
}

#company-taxes-modal input::placeholder {
  color: #b7b7b7;
}

#company-taxes-modal .button-bottom button {
  width: 91.4px;
  height: 30px;
  border-radius: 2px;
  background-color: #047de0;
  border: 1px solid #047de0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

#company-taxes-modal.modal-content .modal-desc button {
  margin-right: 0;
}

@media (max-width: 999px) {
  #mypage #split-view #tax-list {
    display: none;
  }

  #company-taxes #transaction-filter-custom {
    width: 100% !important;
  }

  #company-taxes .transaction-title {
    width: 100%;
  }
}
