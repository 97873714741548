#policy-members .settings {
  width: -webkit-calc(100% - 230px);
  width: -moz-calc(100% - 230px);
  width: calc(100% - 230px);
  margin: 150px auto;
}

#policy-members .div-table-body-wrapper p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#policy-members .mypage-member-font {
  font-size: 12px;
  text-align: left;
}

#policy-members .policy-member-td-icon {
  width: 5%;
}

#policy-members .policy-member-td-check {
  width: 3%;
  padding-left: 10px;
}

#policy-members .policy-member-td-1 {
  width: 20%;
  padding-left: 10px;
}

#policy-members .policy-member-td-2 {
  width: 15%;
  padding-left: 10px;
}

#policy-members .policy-member-td-3 {
  width: 20%;
  padding-left: 10px;
}

#policy-members .policy-member-td-4 {
  width: 10%;
  padding-left: 10px;
}

#policy-members .policy-member-td-5 {
  width: 10%;
  padding-left: 10px;
}

#policy-members .policy-member-td-6 {
  width: 15%;
  padding-left: 10px;
}

#policy-members .policy-member-td-delete {
  width: 5%;
  padding-left: 10px;
  text-align: center;
}

#policy-members .policy-member-td-delete span.header-title {
  margin-left: 0 !important;
}

#policy-members .main-field {
  float: left;
  width: 100%;
}

#policy-members .users-filter-area {
  z-index: 99;
  right: 1px;
  width: 100%;
  position: fixed !important;
  height: 150px;
  background-color: #f7f8fa;
}

#policy-members .users-filter-area .top-area {
  min-width: 1200px;
  height: 55px;
  width: 100%;
  padding: 0 150px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
}

#policy-members .users-filter-area .middle-area {
  width: calc(100% - 300px);
  margin: 2px auto 0 auto;
  height: 70px;
}

#policy-members .initial-field {
  position: fixed;
  -ms-overflow-style: none;
  width: 50px;
  height: -webkit-calc(100% - 280px);
  height: -moz-calc(100% - 280px);
  height: calc(100% - 280px);
  float: left;
  z-index: 10;
  margin-top: 150px;
  border-radius: 3px;
  background-color: #cad0d5;
  overflow: scroll;
}

#policy-members .initial-field .all-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#policy-members .initial-field .ko {
  height: 100%;
}

#policy-members .initial-field .ko-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#policy-members .initial-field .ko-field-active {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  cursor: pointer;
  color: #cad0d5;
  background-color: #ffffff;
  font-weight: bold;
}

#policy-members .initial-field .en-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#policy-members .initial-field .en-field-active {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  cursor: pointer;
  color: #cad0d5;
  background-color: #ffffff;
  font-weight: bold;
}

#policy-members .initial-field .all-field-active {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  cursor: pointer;
  color: #cad0d5;
  background-color: #ffffff;
  font-weight: bold;
}

#policy-members .initial-field .dot {
  margin: 15px 22px;
  width: 4px;
  height: 4px;
  opacity: 0.4;
  background-color: #ffffff;
  border-radius: 10px;
}

#policy-members .top-area .search-field {
  position: relative;
  float: left;
  width: -webkit-calc(100% - 300px);
  width: -moz-calc(100% - 300px);
  width: calc(100% - 300px);
  height: 30px;
  margin-top: 25px;
}

#policy-members .top-area .search-field input {
  font-weight: normal;
  font-style: normal;
  font-size: 13px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #000000;
  position: absolute;
  float: left;
  border-bottom: solid 2px #002855 !important;
  height: 28px;
  width: calc(30% - 50px);
  padding: 0 35px 0 15px;
  left: calc(50% - 15%);
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-color: transparent;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 96%;
}

#policy-members .top-area .search-field input[type='text']:focus {
  border: none !important;
  border-bottom: solid 2px #002855 !important;
}

#policy-members-select {
  float: left;
  position: relative;
  top: -5px;
  margin-right: 1%;
}

#policy-members-select div {
  outline: none;
  font-size: 13px;
  text-align: left;
}

#policy-members-select .list-body {
  text-decoration: none;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  width: 100px;
  position: relative;
  float: left;
  border-radius: 2px;
  background-color: #fff;
  color: #333333;
}

#policy-members-select .activated .list-selected {
  padding: 0 20px 0 10px;
  height: 30px;
  line-height: 28px;
  background-image: url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/triangle-navy.png);
  background-repeat: no-repeat;
  background-size: 7px 4px;
  background-position: right 10px center;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: #002855;
}

#policy-members-select #list {
  position: absolute;
  width: -webkit-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 1;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  display: block;
  margin-top: 2px;
  border: 1px solid #d8d8d8;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#policy-members-select #list .child-body {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  line-height: 10px;
}

#policy-members-select #list .child-body:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#policy-members-select #list .child-body:hover a {
  transition: 0s !important;
  color: #047de0 !important;
}

#policy-members-select #list .child-body a {
  color: #047de0;
  display: block;
  width: 90%;
  text-align: left;
  padding: 12px 10px;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#policy-members-select #list .selected {
  background-color: rgba(4, 125, 224, 0.05);
}

#policy-members .middle-area {
}

#policy-members .middle-area .button-area {
  width: 100%;
  margin-top: 20px;
}

#policy-members .button-area button {
  margin-right: 8px;
  margin-bottom: 5px;
}

#policy-members .button-area .add-policy-member {
  padding: 0 15px 0 25px;
  position: relative;
  float: left;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add.png');
  background-size: 16px 16px;
  background-position: 5px 6px;
}

#policy-members .button-area .add-group {
  padding: 0 15px 0 35px;
  position: relative;
  float: left;
  background-repeat: no-repeat;
  background-image: url('https://spendit-resource-staging.s3.ap-northeast-2.amazonaws.com/icons/svg/add-group-icon.svg');
  background-size: 20px 11px;
  background-position: 9px 9px;
}

#policy-members .button-area .linked-user-code {
  padding: 0 15px 0 25px;
  position: relative;
  float: left;
  background-repeat: no-repeat;
  background-image: url('https://spendit-resource-staging.s3.ap-northeast-2.amazonaws.com/icons/policy/user-code.svg');
  background-size: 16px 16px;
  background-position: 5px 6px;
}

#policy-members .button-area .download {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/export/download-icon.svg');
  background-size: 10px 12px;
  background-position: 12px 50%;
  background-repeat: no-repeat;
  padding: 0 12px 0 30px;
  position: relative;
  float: left;
  vertical-align: top;
  margin-left: 0 !important;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #97a9c0;
  color: #657c97;
  font-size: 12px;
  cursor: pointer;
}

#policy-members .button-area .upload {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/export/upload-icon.svg');
  background-size: 10px 12px;
  background-position: 12px 50%;
  background-repeat: no-repeat;
  padding: 0 12px 0 30px;
  position: relative;
  float: left;
  vertical-align: top;
  margin-left: 0 !important;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #97a9c0;
  color: #657c97;
  font-size: 12px;
  cursor: pointer;
}

#policy-members .button-area .remove {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/delete-red.png');
  background-size: 15px 15px;
  background-position: 12px;
  background-repeat: no-repeat;
  border: 1px solid #e65858;
  color: #e65858;
  padding: 0 12px 0 30px;
}

#policy-members .delete-icon-gray {
  background-image: url('https://spendit-resource-staging.s3.ap-northeast-2.amazonaws.com/icons/svg/delete-icon-grey.svg');
  background-size: 16px 16px;
  width: 100%;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
}

#policy-members .settings input[type='text'] {
  z-index: 0 !important;
  font-size: 12px;
  border: 1px solid #d4d4d3;
  border-radius: 2px;
  padding: 7px 10px;
  line-height: 20px;
  width: 100%;
  width: -webkit-calc(100% - 22px);
  width: -moz-calc(100% - 22px);
  width: calc(100% - 22px);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px !important;
}

#policy-members .checked-count {
  float: right;
  margin-right: 35px;
  font-stretch: normal;
  font-size: 12px;
  line-height: 27px;
  color: #037de0;
}

#policy-members .member-icon img {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 15px;
  margin: 8px 0;
}

#policy-members .member-icon {
  float: left;
}

#policy-members .member-info {
  float: left;
  width: calc(100% - 50px);
  margin: 5px 0 5px 5px;
}

#policy-members p.member-name {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  height: 20px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#policy-members p.member-email {
  font-size: 11px;
  font-weight: normal;
  color: #7d7d7d;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#policy-members .div-table-header {
  border-bottom: 1px solid #687987 !important;
}
