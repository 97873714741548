
.circle {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #037cde;
    margin-top: 10px;
    margin-left: 49%;
}

.about-wrap {
    width: 90% !important;
    margin-left: 5%;
    text-align: center;
    position: absolute;
    min-height: 1200px;
    margin-bottom: -35% !important;
}

.underline {
    text-decoration: underline;
}

.teal {
    color: #037cde;
}


#mobile-about {
    font-family: 'Nanum Square' !important;
    background: rgba(245,245,245,1);
    background: -moz-linear-gradient(top, rgba(245,245,245,1) 0%, rgba(213,213,213,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(245,245,245,1)), color-stop(100%, rgba(213,213,213,1)));
    background: -webkit-linear-gradient(top, rgba(245,245,245,1) 0%, rgba(213,213,213,1) 100%);
    background: -o-linear-gradient(top, rgba(245,245,245,1) 0%, rgba(213,213,213,1) 100%);
    background: -ms-linear-gradient(top, rgba(245,245,245,1) 0%, rgba(213,213,213,1) 100%);
    background: linear-gradient(to bottom, rgba(245,245,245,1) 0%, rgba(213,213,213,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#d5d5d5', GradientType=0 );
    word-break: keep-all;
}