@media (max-width: 999px) {

  nav > a {
    display: none;
  }

  nav #user {
    width: 10%;
    float: right;
  }

  nav #user #support {
    display: none;
  }

  nav #user #policy {
    display: none;
  }

  /* container*/
  .spendit-container {
    height: 100%; /*(fallback) */
    height: -webkit-calc(100% - 80px); /* for Chrome, Safari */
    height: -moz-calc(100% - 80px); /* for Firefox */
    height: calc(100% - 80px); /* for IE */
    padding-top: 80px;
  }

  .filter {
    display: none;
  }

  .main.no-filter .title {
    /*z-index: 10;*/
    margin-left: 0;
  }

  .main.filtered {
    left: 0;
    position: relative;
    width: 100%; /* fallback */
    height: 100%; /* fallback */
    height: -webkit-calc(100% - 36px);
    height: -moz-calc(100% - 36px);
    height: calc(100% - 36px);
    width: -webkit-calc(100% - 240px);
    width: -moz-calc(100% - 240px);
    width: calc(100%);
    overflow-x: auto;
    overflow-y: scroll;
  }

  .spendit-container .title .help {
    width: -webkit-calc(100vw - 60px);
    width: -moz-calc(100vw - 60px);
    width: calc(100vw - 60px);
    padding-top: 8px;
  }

  #report #split-view #report-list {
    display: none;
  }

  #report #split-view #report-view {
    width: 100%; /*(fallback) */
    width: -webkit-calc(100%); /* for Chrome, Safari */
    width: -moz-calc(100%); /* for Firefox */
    width: calc(100%); /* for IE */
    left: 0;
    position: relative;
  }

  #inspection {
    margin-top: 50px;
  }

  #mypage #split-view #card-info {
    display: none;
  }
  #mypage #split-view #spent-info {
    width: 100% !important; /*(fallback) */
    left: 0;
    position: relative;
  }
}
