#report-add-plan .desc .report_plan_check {
  margin: 10px 0;
  border-radius: 10px;
}

#report-add-plan .desc {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

#report-add-plan .desc .sub-title {
  margin: 10px;
}

#report-add-plan .desc dl dt {
  flex-basis: 30%;
  height: 37px;
  display: flex;
  align-items: center;
}

#report-add-plan .desc dl dd {
  width: 65%;
}

#report-add-plan .desc .custom-area {
  margin: 20px 0 30px 0;
  display: flex;
}

#report-add-plan .desc .select-area {
  height: 37px;
  margin-bottom: 20px;
  display: flex;
  flex: 1;
  align-items: center;
}

#report-add-plan .desc .custom-area .custom-row {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

#report-add-plan dl dd input[type='text'] {
  width: calc(100% - 22px);
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 8px 10px;
  border-radius: 6px;
}

#report-add-plan .desc .custom-area .custom-row .custom-title {
  width: 30%;
  color: #545454;
}

#report-add-plan .desc .custom-area .custom-row .custom-desc {
  width: 70%;
}

#report-add-plan .desc .custom-area .custom-row .custom-desc .Select {
  height: 38px;
}

#report-add-plan .desc .custom-area .custom-row .custom-desc input::placeholder {
  font-size: 12px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.3px;
  text-align: left;
  color: #c3cdd6;
}

#report-add-plan .bottom {
  width: 100%;
  padding: 5px 0;
  border-top: 1px solid #ededed;
}

#report-add-plan .necessary-star {
  color: #047de0;
}

#report-add-plan .Select-input {
  display: block !important;
}

#report-add-plan .Select-placeholder,
#report-add-plan .Select--single > .Select-control .Select-value {
  color: #c3cdd6;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 10px center;
  padding-right: 20px;
}
