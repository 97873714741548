@font-face {
  font-display: swap;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 700;
  src: local('Spoqa Han Sans Neo Bold'), url('assets/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
    url('assets/SpoqaHanSansNeo-Bold.woff') format('woff'), url('assets/SpoqaHanSansNeo-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  src: local('Spoqa Han Sans Neo Medium'), url('assets/SpoqaHanSansNeo-Medium.woff2') format('woff2'),
    url('assets/SpoqaHanSansNeo-Medium.woff') format('woff'),
    url('assets/SpoqaHanSansNeo-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 400;
  src: local('Spoqa Han Sans Neo Regular'), url('assets/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
    url('assets/SpoqaHanSansNeo-Regular.woff') format('woff'),
    url('assets/SpoqaHanSansNeo-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  src: local('Spoqa Han Sans Neo Light'), url('assets/SpoqaHanSansNeo-Light.woff2') format('woff2'),
    url('assets/SpoqaHanSansNeo-Light.woff') format('woff'), url('assets/SpoqaHanSansNeo-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 100;
  src: local('Spoqa Han Sans Neo Thin'), url('assets/SpoqaHanSansNeo-Thin.woff2') format('woff2'),
    url('assets/SpoqaHanSansNeo-Thin.woff') format('woff'), url('assets/SpoqaHanSansNeo-Thin.ttf') format('truetype');
}

* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  transition: 0s;
}

*::-webkit-input-placeholder {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
}

*::-webkit-scrollbar-corner {
  background: none;
}

input {
  font-size: 14px;
  transition: 0s !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  outline: none;
  user-select: none;
}

input[type='checkbox'] {
  width: 15px;
  height: 15px;
  cursor: pointer;
  border: 1px solid #c5c5c5;
  background: #fff;
  cursor: pointer;
  border-radius: 5px;
  -webkit-appearance: none;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.2);

  &:disabled {
    cursor: not-allowed;
  }
}

input[type='checkbox']:checked {
  background-color: #047de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/checked.png');
  background-size: 100%;
  border: solid 1px #0b5ab7;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px 0 var(--black-50);
}

p.ready {
  border: 1px solid #047de0;
  background-color: #047de0;
}

p.sent {
  border: 1px solid #95b941;
  background-color: #95b941;
}

p.approved {
  border: 1px solid #0b8672;
  background-color: #0b8672;
  color: #ffffff !important;
}

p.approved_completed.company_tax {
  background-color: #0b8672;
  color: #ffffff !important;
}

p.approved_completed {
  border: 1px solid #0b8672;
  background-color: #ffffff;
  color: #0b8672 !important;
}

p.refund_completed_completed {
  border: 1px solid #7b7b7b;
  background-color: #ffffff;
  color: #7b7b7b !important;
}

p.refund_completed {
  border: 1px solid #9c9c9c;
  background-color: #9c9c9c;
}

spen.bold {
  font-weight: bold;
}

button#btn-policy-action {
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #047de0;
  color: #047de0;
  font-size: 12px;
  transition: 0s;
}

button#btn-policy-action:hover {
  background-color: #047de0;
  border: solid 2px #047de0;
  color: #ffffff;
  transition: 0s;
}

button#btn-report-next {
  border-radius: 6px;
  background-color: #ffffff;
  border: solid 1px #047de0;
  color: #047de0;
  height: 40px;
  width: 180px;
  font-size: 15px;
  transition: 0s;
}

button#btn-policy-action:hover,
button#btn-report-next:hover {
  background-color: #047de0;
  border: solid 2px #047de0;
  color: #ffffff;
  transition: 0s;
}

button#btn-upload-member,
button#btn-upload-category,
button#btn-upload-tag {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/export/upload-icon.svg');
  background-size: 10px 12px;
  background-repeat: no-repeat;
  background-position: 10px;
}

button#btn-download-member,
button#btn-download-category,
button#btn-download-tag {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/export/download-icon.svg');
  background-size: 10px 12px;
  background-repeat: no-repeat;
  background-position: 12px 50%;
}

button#btn-report-next span {
  padding-left: 30px;
  background-size: 22px;
  background-repeat: no-repeat;
}

button#btn-report-next:hover span.submit {
  background-image: url('images/icons/icon-add-white.svg');
}

button#btn-report-next span.submit {
  padding-left: 24px;

  background-image: url('images/icons/icon-add-blue.svg');
  background-size: 14px 18px;
  background-position: center left;

  font-size: 18px;
}

button#btn-report-next:disabled {
  background-color: #fff;
  border: 1px solid #9a9a9a;
  cursor: not-allowed;
}

button#btn-report-next:disabled:hover {
  border: 1px solid #9a9a9a;
  box-shadow: none;
}

button#btn-report-next:disabled span.submit,
button#btn-report-next:disabled:hover span.submit {
  color: #9a9a9a;
  background-color: #fff;
  background-image: url('images/icons/icon-add-grey.svg');
}

button#btn-report-next:hover span.cancel,
button#btn-report-next:hover span.cancel_approve {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-actions/report-cancel-hover.png');
}

button#btn-report-next span.cancel,
button#btn-report-next span.cancel_approve {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-actions/report-cancel.png');
}

button#btn-report-next:hover span.approve,
button#btn-report-next:hover span.complete_refund {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-actions/report-approve-hover.png');
}

button#btn-report-next span.approve,
button#btn-report-next span.complete_refund {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-actions/report-approve.png');
}

button#btn-report-next:hover span.deny {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-actions/report-deny-hover.png');
}

button#btn-report-next span.deny {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-actions/report-deny.png');
}

button#btn-report-next:hover span.refund {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-actions/report-refund-hover.png');
}

button#btn-report-next span.refund {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-actions/report-refund.png');
}

.draggable {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  float: none !important;
  display: inline-block;
}

.dragged {
  opacity: 0.7;
  cursor: pointer;
}

.placeholder {
  opacity: 0.5;
  cursor: pointer;
}

.spendit-container .filtered .button-list {
  width: calc(100% - 310px);
}

.spendit-container .button-list {
  margin-bottom: 15px;
  padding: 22px 35px;
  position: fixed;
  background-color: #fff;
  width: calc(100% - 70px);
  z-index: 300;
  border-bottom: 1px solid #efefef;
}

.spendit-container .button-list button,
.spendit-container .button-area button,
.spendit-container .button-list > ul {
  vertical-align: top;
  margin-right: 8px;
  margin-left: 0 !important;
}

#company-users .main-field {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

#company-users .initial-field {
  position: fixed;
  -ms-overflow-style: none;
  width: 50px;
  height: -webkit-calc(100% - 280px);
  height: -moz-calc(100% - 280px);
  height: calc(100% - 280px);
  float: left;
  z-index: 301;
  margin-top: 120px;
  border-radius: 3px;
  background-color: #cad0d5;
  overflow: scroll;
}

#company-users .initial-field #ko {
  height: 100%;
}

#company-users .initial-field #en {
  height: 100%;
}

#company-users .initial-field .dot {
  margin: 15px 22px;
  width: 4px;
  height: 4px;
  opacity: 0.4;
  background-color: #ffffff;
  border-radius: 10px;
}

#company-users .initial-field .ko-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#company-users .initial-field .en-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#company-users .initial-field .all-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#company-users .initial-field .all-field-active,
#company-users .initial-field .en-field-active,
#company-users .initial-field .ko-field-active,
#company-users .initial-field .all-field:hover,
#company-users .initial-field .ko-field:hover,
#company-users .initial-field .en-field:hover {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  cursor: pointer;
  color: #cad0d5;
  background-color: #ffffff;
  font-weight: bold;
}

#company-users .settings {
  position: relative;
  flex: 1 1 0%;
  width: 100%;
  padding: 0px 67px;
  box-sizing: border-box;
  overflow-y: auto;
}

#company-users .settings > .content {
  height: 100%;
}

#company-users .users-filter-area {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px 0px;
}

#company-users .users-filter-area .top-area {
  min-width: 1200px;
  height: 55px;
  width: 100%;
  padding: 0 92px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #efefef;
}

#company-users .middle-area {
  display: flex;
  width: 100%;
  padding: 20px 67px;
  box-sizing: border-box;
  height: 100px;
}

#company-users .users-filter-area .user-status {
  position: relative;
  float: right;
  padding: 3px 0;
}

#company-users .users-filter-area .user-status .live-active,
#company-users .users-filter-area .user-status .removed-active,
#company-users .users-filter-area .user-status .live:hover,
#company-users .users-filter-area .user-status .removed:hover {
  color: #047cde;
  font-weight: bold;
  position: relative;
  float: left;
  text-align: left;
  cursor: pointer;
}

#company-users .users-filter-area .user-status {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #637c96;
}

#company-users .users-filter-area .user-status .live-active,
#company-users .users-filter-area .user-status .live {
  padding: 0 10px;
  position: relative;
  float: left;
  text-align: left;
  cursor: pointer;
}

#company-users .users-filter-area .user-status .removed-active,
#company-users .users-filter-area .user-status .removed {
  padding-left: 10px;
  position: relative;
  float: left;
  text-align: left;
  color: #637c96;
  cursor: pointer;
}

#company-users .users-filter-area .user-status .division {
  position: relative;
  float: left;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: left;
  color: #637c96;
}

#company-users .users-filter-area .search-area {
  display: flex;
  align-items: center;
  position: relative;
  float: left;
  width: -webkit-calc(100% - 300px);
  width: -moz-calc(100% - 300px);
  width: calc(100% - 300px);
  height: 30px;
  margin-top: 25px;
}

#company-users .users-filter-area .search-area .input-search:focus {
  background-color: transparent !important;
  border: 0 solid !important;
  color: #000000 !important;
}

#company-users .users-filter-area .search-area input[type='text']:focus,
#company-users .users-filter-area .search-area input[type='text'] {
  font-weight: normal;
  font-style: normal;
  font-size: 13px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #000000;
  position: absolute;
  float: left;
  border-bottom: solid 2px #002855 !important;
  height: 28px;
  width: 30%;
  left: calc(50% - 15%);
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-color: transparent;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 95%;
}

#company-users .users-filter-area .search-area .search-field {
  position: relative;
  width: 400px;
  margin-left: 10%;
  float: left;
  border-bottom: solid 2px #002855;
  height: 28px;
}

#company-users .users-filter-area .search-area .search-button {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  position: relative;
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  cursor: pointer;
}

#company-users .users-filter-area .button-area {
  position: relative;
  float: left;
  padding: 10px 0;
}

.spendit-container .button-area {
  position: relative;
  padding: 5px 0 22px 0;
  width: 100%;
}

.spendit-container .button-area .select-expenses {
  margin-right: 10px;
  float: left;
}

.spendit-container #report-document .action-buttons #download .list-selected {
  overflow: auto !important;
  white-space: normal !important;
}

.spendit-container #report-document .action-buttons {
  width: -webkit-calc(100% - 18px); /* for Chrome, Safari */
  width: -moz-calc(100% - 18px); /* for Firefox */
  width: calc(100% - 18px); /* for IE */
  padding: 10px;
  background-color: #e2e6ea;
  display: inline-block;
}

.spendit-container #report-document .action-buttons > li {
  display: inline-block;
}

.spendit-container #report-document .action-buttons > li.left {
  width: 70%;
}

/*.spendit-container #report-document .action-buttons > li.center {*/
/*width: 30%;*/
/*text-align: center;*/
/*}*/

.spendit-container #report-document .action-buttons > li.right {
  width: 30%;
  text-align: right;
}

.spendit-container #report-document .action-buttons > li.left > ul > li {
  margin-right: 5px;
  float: left;
}

#application > nav {
  width: 100%;
  background-color: #022855;
  height: 54px;
  z-index: 500;
  top: 0;
  position: fixed;
}

button {
  background-color: #fff;
  cursor: pointer;
}

a {
  cursor: pointer;
}

nav > a {
  width: 100%;
  height: 100%;
}

nav #logo {
  display: inline-block;
  width: 240px;
  cursor: pointer;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/web-logo.png');
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: 20px center;
  text-indent: -9999px;
  height: 54px;
}

nav #user {
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  right: 20px;
}

nav #user li {
  outline: none;
  height: 100%;
  display: flex;
  align-items: center;
}

nav #user > li > ul {
  display: none;
}

nav #user #policy .policy-selected > p {
  font-size: 7px;
}
#user-menu-policy-dropdown .selected {
  background-color: #f2f6fa;
}

#user-menu-policy-dropdown,
#user-menu-profile-dropdown {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

nav #user #profile {
  padding-left: 10px;
}

nav #user #profile .profile-image {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center center;
  border-radius: 15px;
  margin-left: 10px;
  cursor: pointer;
}

#user-menu-profile-dropdown {
  position: absolute;
  top: 7px;
  right: -5px;
  background-color: #ffffff;
  width: 298px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  display: block;
}

#user-menu-support-dropdown:before,
#user-menu-policy-dropdown:before,
#user-menu-profile-dropdown:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #efefef;
  right: 15px;
  top: -12px;
}

#user-menu-support-dropdown:after,
#user-menu-policy-dropdown:after,
#user-menu-profile-dropdown:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#user-menu-profile-dropdown li {
  position: relative;
  display: block;
}

#user-menu-profile-dropdown li.menu-list {
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 22px center;
  height: 50px;
  cursor: pointer;
}

#user-menu-profile-dropdown li.user-detail p {
  margin-left: 80px;
}

#user-menu-profile-dropdown li.user-detail .profile-image {
  float: left;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 55px;
}

#user-menu-profile-dropdown li.user-detail .company-name {
  font-size: 12px;
  line-height: 18px;
  color: #8b8b8b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#user-menu-profile-dropdown li.user-detail .email {
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#user-menu-profile-dropdown li.user-detail .name {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

#user-menu-profile-dropdown li.user-detail {
  height: 55px;
  padding: 20px 10px;
  color: #000000;
  overflow: hidden;
}

#user-menu-profile-dropdown li.personal-info {
  border-top: 2px solid #e8e8e8;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/top-right-menu/menu-personal.png');
}

#user-menu-profile-dropdown li.company-info {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/top-right-menu/menu-company.png');
}

#user-menu-profile-dropdown li.log-out {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/top-right-menu/menu-logout.png');
  border-top: 2px solid #e8e8e8;
}

#user-menu-profile-dropdown li a {
  color: #111111;
  display: block;
  width: 100%;
  margin-left: 65px;
  text-align: left;
  font-size: 13px;
  line-height: 50px;
  text-decoration: none;
}

#user-menu-profile-dropdown li.menu-list:hover {
  background-color: #f2f6fa;
}

#user-menu-policy-dropdown div {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
  display: block;
}

#user-menu-policy-dropdown {
  position: absolute;
  top: 8px;
  right: 0px;
  background-color: #ffffff;
  width: 250px;
  max-height: 300px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  display: block;
}

#user-menu-policy-dropdown li:first-child {
  border-top: none;
}

#user-menu-policy-dropdown li {
  position: relative;
  border-top: 2px solid #e8e8e8;
  display: block;
}

#user-menu-policy-dropdown li ul {
  position: relative;
}

#user-menu-policy-dropdown li button {
  color: #111111;
  position: relative;
  display: block;
  text-align: left;
  padding: 15px 10px;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  border: 0;
  width: 100%;
  height: auto;
  font-weight: normal;
  background-color: transparent;
}

#user-menu-policy-dropdown li button:hover {
  background-color: #f2f6fa;
}

#user-menu-support-dropdown div {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}

#user-menu-support-dropdown {
  position: absolute;
  top: 9px;
  right: -12px;
  font-size: 12px;
  letter-spacing: -0.2px;
  max-height: 300px;
  width: 220px;
  border-radius: 1px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #c9c9c9;
  display: block;
  padding: 5px 5px 20px 5px;
}

#user-menu-support-dropdown #support-title {
  color: #022855;
  font-weight: bold;
  padding: 10px;
  font-size: 12px;
  letter-spacing: -0.2px;
  border-bottom: solid 1px #c9c9c9;
}

#user-menu-support-dropdown li {
  position: relative;
  display: block;
}

#user-menu-support-dropdown li button {
  position: relative;
}

#user-menu-support-dropdown li a {
  color: #037de0;
  display: block;
  width: 200px;
  font-weight: 700;
  text-align: left;
  padding: 10px;
  font-size: 11px;
  text-decoration: none;
}

#user-menu-support-dropdown li a:hover {
  text-decoration: underline;
}

#user-menu-support-dropdown #support-bottom {
  border-top: 1px solid #c9c9c9;
  padding: 10px;
}

#user-menu-support-dropdown input {
  width: 160px;
  height: 30px;
  position: relative;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  background-color: #ffffff;
  border: solid 1px #d9dfe6;
  float: left;
  font-size: 12px;
  padding-left: 5px;
}

/* container*/
.spendit-container {
  height: 100%; /*(fallback) */
  position: relative;
}

/* filter */
.filter {
  width: 240px;
  height: 100%;
  background-color: #2b323a;
  position: fixed;
  left: 0;
  top: 50px;
  overflow-y: auto;
  z-index: 700 !important;
}

.filter.closed {
  height: 36px;
  cursor: pointer;
  overflow-y: hidden;
  background-color: rgba(2, 40, 85, 0.6);
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/filter-white.png');
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 20px center;
}

.filter .wrap {
  width: 200px;
  padding: 60px 20px 20px 20px;
}

.filter .title {
  background-color: #40464d;
  color: #fff;
  padding: 10px 20px 12px 40px;
  font-size: 14px;
  font-weight: 700;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/filter-white.png');
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 20px center;
  z-index: 1;
  overflow: hidden;
  position: fixed;
  width: 180px;
}

.filter .title .close {
  width: 12px;
  height: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/filter-close.png');
  background-repeat: no-repeat;
  background-size: 12px;
  position: absolute;
  top: 11px;
  right: 12px;
  cursor: pointer;
}

.filter .title .open {
  width: 12px;
  height: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/filter-open.png');
  background-repeat: no-repeat;
  background-size: 12px 12px;
  position: absolute;
  top: 11px;
  right: 12px;
  cursor: pointer;
}

.filter .option {
  margin-bottom: 15px;
}

.filter .option input[type='text'] {
  width: 180px;
  height: 21.5px;
  color: #ffffff;
  background-color: rgb(64, 70, 78);
  border-radius: 2px;
  border: 1px solid rgb(96, 100, 105) !important;
}

.filter .filter-picker-div {
  border: none !important;
}

.filter .option .radio li {
  font-size: 13px;
  line-height: 24px;
  color: #333;
}

.filter .option .multi-select li {
  font-size: 13px;
  color: #333;
  padding: 5px;
  cursor: pointer;
  text-align: left;
}

.filter .option .multi-select input {
  margin-right: 10px;
  cursor: pointer;
}

.filter .option .multi-select label {
  color: #ffffff;
  cursor: pointer;
}

.filter .option .multi-select .selected {
  border: 1px solid #657c97;
  padding: 5px;
  border-radius: 2px;
}

.filter .option li input[type='checkbox'] {
  margin-right: 7px;
  margin-left: 2px;
  margin-top: 3px;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.filter .option .radio li div {
  display: inline-block;
}

.filter .option .toggle {
  border: 1px solid rgb(96, 100, 105);
  border-radius: 2px;
  height: 35px;
  color: #fff;
}

.filter .option .toggle li {
  display: inline-block;
  font-size: 12px;
  line-height: 31px;
  margin: 2px;
  cursor: pointer;
}

.filter .option .toggle li:hover {
  background-color: rgba(216, 218, 219, 0.7);
  color: #000;
}

.filter .option .toggle.disabled li.selected {
  background-color: rgba(216, 218, 219, 0.4);
}

.filter .option .toggle li.selected {
  background-color: rgb(216, 218, 219);
  border-radius: 2px;
  color: #000;
}

.filter .option .toggle.toggle-3 li {
  width: 31.3%;
  text-align: center;
}

.filter .option .toggle.toggle-2 li {
  width: 47.9%;
  text-align: center;
}

.filter .option .reset button {
  background-color: #657c97;
  border: none;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  height: 40px;
  width: 200px;
  margin-top: 30px;
}

.filter .option th.rdtSwitch {
  line-height: 24px;
}

.filter .Select-control {
  border: 1px solid #b4c0cc !important;
}

#report .filter .option.report-type {
  margin: 20px 0 30px 0;
}

/* report-filter */
#expense .filter .option .multi-select li,
#report .filter .option .multi-select li {
  width: 190px;
  height: 18px;
  border-radius: 16px;
  line-height: 18px;
  margin: 10px 0;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

#expense .filter .option .multi-select .stage_0,
#report .filter .option .multi-select .stage_0 {
  background-color: #3769a2;
}

#expense .filter .option .multi-select .stage_1,
#report .filter .option .multi-select .stage_1 {
  background-color: #7f9846;
}

#expense .filter .option .multi-select .stage_2,
#report .filter .option .multi-select .stage_2 {
  background-color: #2c5b53;
}

#expense .filter .option .multi-select .stage_3,
#report .filter .option .multi-select .stage_3 {
  background-color: #9c9c9c;
}

#expense .filter .option .multi-select input[type='checkbox'],
#report .filter .option .multi-select input[type='checkbox'] {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/checkbox/filter-check.svg');
  float: right;
  border-radius: 10px;
  width: 16px;
  height: 16px;
  background-position: center;
  background-size: 18px;
  background-repeat: no-repeat;
  border: none;
  margin: 0;
}

#expense .filter .option .multi-select input[type='checkbox']:checked,
#report .filter .option .multi-select input[type='checkbox']:checked {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/checkbox/filter-checked.svg');
  float: right;
  border-radius: 10px;
  width: 16px;
  height: 16px;
  background-position: center;
  background-size: 19px;
  background-repeat: no-repeat;
  border: none;
  margin: 0;
  background-color: #4c5054;
}

/* main-filter */
.main {
  font-size: 14px;
}

.main.filtered {
  position: relative;
  width: 100%; /* fallback */
  height: 100%; /* fallback */
  overflow-x: auto;
  overflow-y: auto;
}

.main.no-filter .image-header {
  width: -webkit-calc(100% - 70px);
  width: -moz-calc(100% - 70px);
  width: calc(100% - 70px);
}

.main.filtered .image-header {
  width: -webkit-calc(100% - 310px);
  width: -moz-calc(100% - 310px);
  width: calc(100% - 310px);
}

.main.no-filter {
  left: 0;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

#filtered-custom {
  top: 0px;
}

#no-filter-custom {
  top: 0px;
}

.main .title.center {
  text-align: center;
  padding-left: 0 !important;
}

.main .title {
  background-color: #e6eaee;
  font-size: 14px;
  font-weight: 700;
  color: #022855;
  z-index: 300;
  overflow-x: hidden;
  position: fixed;
  width: 100%;
  top: 50px;
  height: 36px;
  line-height: 36px;
  padding-left: 35px;
}

.main .title p {
  display: inline-block;
  width: 85px;
}

.main ul.sub-title {
  display: inline-block;
}

.main ul.sub-title li {
  display: inline-block;
  /*border-bottom: 2px solid rgba(232, 235, 238, 0.5);*/
  cursor: pointer;
  font-weight: 500;
  margin-right: 15px;
  height: 34px;
  padding: 0 5px;
}

.main ul.sub-title li:hover {
  /*border-bottom: 2px solid #047CDE;*/
  color: #047cde;
}

.main.no-filter .title {
  /*z-index: 10;*/
  margin-left: 240px;
}

.main .wrap.padding {
  padding: 100px 35px;
}

.main .wrap {
  padding: 18px 35px;
}

/* global-table */
table {
  width: 100%;
  font-size: 13px;
  color: #333;
}

table thead {
  border-bottom: 1px solid #b6b6b6;
  font-size: 12px;
  color: #7d7d7d;
}

table thead th {
  text-align: left;
}

.relative {
  position: relative !important;
}

#expense-chart .sort,
#admin-inspection .sort {
  position: relative;
}

.sort {
  position: absolute;
  width: 10px;
  height: 11px;
  cursor: pointer;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/sort.png');
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 5px;
  background-position-y: 1px;
}

.sort#up {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/up-blue.png');
}

.sort#down {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down-blue.png');
  background-position-y: 7px;
}

table tbody tr.unselectable {
  cursor: auto;
}

table tbody tr:last-child {
  border-bottom: 1px solid #b6b6b6;
}

table tbody tr.unselectable:hover {
  background-color: transparent;
}

table tbody tr:hover {
  background-color: rgba(2, 40, 85, 0.04);
}

table tbody .selected {
  background-color: rgba(2, 40, 85, 0.04);
}

table tbody tr td {
  vertical-align: middle;
}

/*
	detail
*/
.detail > .title {
  background-color: #e6eaee;
  padding: 11px 0;
  font-size: 14px;
  font-weight: 700;
  color: #022855;
  z-index: 100;
  width: 100%;
  top: 50px;
  text-align: center;
  position: fixed;
  transition: 0s !important;
}

.detail .menu {
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}

.detail .menu li {
  display: inline-block;
  padding: 11px 15px;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}

.detail .menu li:hover {
  color: #047cde;
  border-bottom: 2px solid #047cde;
}

.detail .menu .selected {
  color: #047cde;
  border-bottom: 2px solid #047cde;
}

.detail .wrap {
  margin: 0 auto;
  padding: 0 35px;
  height: 100%;
  box-sizing: border-box;
  transition: 0s;
}

.detail .wrap-top {
  margin: 35px 0;
}

.detail > .wrap > .settings {
  margin-top: 35px;
}

.detail > .wrap .content {
  font-size: 14px;
}

.detail .content.half {
  width: 49.3%;
  float: left;
}

.detail > .wrap .content > .title {
  background-color: rgba(0, 39, 85, 0.05);
  width: 100%;
  width: -webkit-calc(100% - 20px);
  width: -moz-calc(100% - 20px);
  width: calc(100% - 20px);
  font-weight: 700;
  padding: 13px 0 13px 20px;
  color: #555;
}

.detail > .wrap .content > .title a {
  margin-left: 5px;
  display: inline-block;
  height: 14px;
}

.detail .content > .desc {
  background-color: rgba(2, 40, 85, 0.02);
  padding: 20px;
}

.detail .content > .desc.language > div {
  width: 150px;
}

.detail .content .desc dl {
  line-height: 48px;
  color: #333;
}

.detail .content .desc .edit-distance {
  margin: 30px 0 10px 0;
  color: #7d7d7d;
}

.detail .content .desc .edit-distance .title {
  width: 10%;
  margin: 5px 0;
  color: #333;
}

.detail .content .desc .edit-distance .title span {
  margin-left: 10px;
}

.detail .content .desc .edit-distance .detail {
  width: 6%;
  margin: 5px 0;
  padding-left: 15px;
}

.detail .content .desc .edit-distance .distance-desc {
  width: 50%;
  margin: 5px 0;
}

.detail .content .desc .distance_deductible {
  margin: 0;
  color: #7d7d7d;
}

.detail .content .desc .distance_deductible .title {
  width: 10%;
  margin: 5px 0;
  color: #333;
}

.detail .content .desc .distance_deductible .title span {
  margin-left: 10px;
}

.detail .content .desc .distance_deductible .detail {
  width: 6%;
  margin: 5px 0;
  padding-left: 15px;
}

.detail .content .desc .distance_deductible .distance-desc {
  width: 50%;
  margin: 5px 0;
}

.detail .content .desc .deduction_distance .title {
  width: 10%;
  margin: 0;
  color: #333;
}

.detail .content .desc .deduction_distance .title span {
  margin-left: 10px;
}

.detail .content .desc .deduction_distance .detail {
  width: 6%;
  margin: 5px 0;
  padding-left: 15px;
}

.detail .content .desc .deduction_distance .distance-desc {
  width: 50%;
}

.detail .content .desc .deduction_distance .distance-desc span {
  margin-right: 10px;
  color: #555555;
  font-size: 12px;
}

.detail .content .desc .deduction_distance .distance-desc input::placeholder {
  color: #7d7d7d;
  text-align: right;
}

.detail .content .desc .deduction_distance .distance-desc input {
  z-index: 0 !important;
  font-size: 12px;
  border: 1px solid #b4c0cc;
  border-radius: 2px;
  padding: 7px 10px;
  line-height: 20px;
  width: 200px;
  margin-right: 5px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px !important;
}

#company-options dl dd .switch {
  float: left;
  margin-right: 10px;
}

#company-options #spendit-csv-dd {
  margin: 0 auto 24px 0;
}

#company-options #spendit-csv-dt {
  padding-top: 5px;
}

/* company-options scrollbar */
#company-options ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 4px;
}

#company-options .list::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
}

#company-options ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
}

#company-options ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #707070 !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

#company-options .company-csv-right-options:hover {
  background-color: #e9eff5;
}

#company-options .company-csv-right-options {
  padding: 0 14px;
  color: #333333;
  float: left;
  width: calc(100% - 28px);
  cursor: pointer;
}

#company-options .company-csv-right-options .option {
  float: left;
  height: 22px;
  line-height: 22px;
  font-size: 12px !important;
  color: #333333;
  margin: 3px 0;
  width: 100%;
}

#company-options .company-csv-left-options {
  padding: 0 10px;
  color: #333333;
  float: left;
  width: calc(100% - 20px);
}

#company-options .company-csv-left-options .option {
  border-radius: 11px;
  background-color: #e1f2ff;
  float: left;
  height: 22px;
  padding: 0 10px;
  line-height: 22px;
  font-size: 12px !important;
  color: #333333;
  margin: 3px 0;
}

#company-options .draggable {
  float: left !important;
}

#company-options .csv-options {
  float: left;
  width: 340px;
  margin: 0 15px;
}

#company-options .csv-options .draggable {
  width: 100%;
  float: left;
}

#company-options #spendit-csv-dd .moving-area {
  float: left;
  width: 38px;
  height: 65px;
  margin-top: 35px;
  padding: 337px 0 0 0;
}

#company-options #spendit-csv-dd .moving-area .moving-button {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  border: solid 1px rgba(151, 169, 192, 0.5);
  background-color: #ffffff;
  float: left;
  margin: 2px 0;
  cursor: pointer;
}

#company-options #spendit-csv-dd .moving-area .moving-button.top {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/double-top-arrow.svg');
  background-size: 12px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

#company-options #spendit-csv-dd .moving-area .moving-button.bottom {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/double-bottom-arrow.svg');
  background-size: 12px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

#company-options .setting-area {
  float: left;
  width: 38px;
  height: 100px;
  margin-top: 35px;
  padding: 152px 0;
}

#company-options .setting-area .setting-selected {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px rgba(2, 40, 85, 0.5);
  background-color: #e6eaee;
  cursor: pointer;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/right-arrow.svg');
  background-size: 10px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

#company-options .setting-area .setting-available {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px rgba(2, 40, 85, 0.5);
  background-color: #e6eaee;
  margin-bottom: 6px;
  cursor: pointer;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/left-arrow.svg');
  background-size: 10px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

#company-options .list p {
  width: 100%;
  text-align: center;
  line-height: 20px;
  margin-top: 50px;
  color: #a9a9a9 !important;
}

#company-options .list {
  overflow-y: auto;
  height: 400px;
  border: 1px solid #b4c0cc;
  border-radius: 3px;
  background-color: #ffffff;
  margin-top: 5px;
}

#company-options .list .empty-option-desc {
  margin: 180px auto;
  width: 240px;
  height: 40px;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: -0.2px;
  text-align: center;
  color: #a9a9a9 !important;
}

#company-options .list .empty-option-desc span {
  color: #a9a9a9 !important;
}

#company-options .edit-card-expense-desc {
  float: left;
  font-size: 14px;
  color: #555555;
}

#company-info {
  max-width: 1400px;
}

#company-info dl dd {
  max-width: 900px;
}

#company-info .content .desc dl dd.logo {
  padding-top: 10px;
  height: 100px;
}

#company-info input::placeholder {
  color: #c3cdd6;
}

#company-info .geosuggest.company-address {
  flex: 1;
}

#company-info .geosuggest.company-address .geosuggest__input-wrapper {
  display: flex;
  flex: 1;
}

#company-info .geosuggest.company-address input {
  flex: 1;
  display: inline-block;
  padding: 6.5px 15px !important;
  border: solid 1px #d4d4d4;
  border-radius: 6px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  vertical-align: middle;
  color: #1e1e1e !important;
  font-size: 13px;
}

#company-info .geosuggest.company-address input:focus {
  border: 1px solid #d4d4d4 !important;
  box-shadow: 0 0 0 1px rgb(181, 211, 255);
}

.detail .content .subject {
  width: 100%;
  height: 50px;
  position: relative;
  float: left;
}

.detail .content .subject p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 50px;
  color: #bdbdbd;
}

.detail .content .desc dl dt {
  width: 30%;
  line-height: 35px;
  display: inline-block;
  vertical-align: top;
}

.detail .content .desc dl dd .switch,
.detail .content .desc dl dt .switch {
  margin-top: 6px;
}

.detail .content .desc dl dd {
  width: 70%;
  display: inline-block;
  line-height: 35px;
  vertical-align: top;
}

.detail .content .desc .Select {
  width: 100%;
}

.detail .content .desc table {
  width: 100%;
}

.detail .content .desc table thead {
  border-bottom: 1px solid #b6b6b6;
  font-size: 12px;
  color: #7d7d7d;
}

.detail .content .desc table:not(.sticy-table) thead th {
  padding-bottom: 7px;
  text-align: left;
}

.detail .content .desc table tbody tr {
  height: 54px;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}

.detail .content .desc table tbody tr:hover {
  background-color: rgba(2, 40, 85, 0.04);
}

.detail .content .desc table tbody tr td {
  vertical-align: middle;
}

.detail .content .desc .description {
  padding: 20px;
  font-size: 12px;
  color: #1e1e1e;
}

.detail .submit {
  background-color: #047cde;
  color: #fff;
  font-size: 12px;
  padding: 0 30px;
  border: none;
}

.detail .cancel {
  background-color: #e35b57;
  color: #fff;
  font-size: 12px;
  padding: 7px 30px;
}

.detail .config-buttons button {
  background-color: #047de0;
  border: 1px solid #047de0;
  color: #fff;
}

.detail .config-buttons button:disabled {
  color: #fff;
}

.detail .config-buttons {
  border-top: 1px solid #efefef;
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  text-align: right;
}

.detail .config-custom-buttons {
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  text-align: right;
}

.detail .config-custom-buttons .custom-desc {
  font-size: 12px;
  line-height: 30px;
  float: left;
  color: #9f9f9f;
  text-align: left;
  width: 70%;
}

.detail hr {
  width: 100%;
  height: 0px;
  border-top: 1px solid red;
  margin-top: 35px;
  background-color: #fff;
}

.input-red {
  color: #de3c3c !important;
}

/* modal */
.spendit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 800;
  overflow-y: auto;
  overflow-x: hidden;
}

.spendit-modal .bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 800;
  top: 0;
  left: 0;
}

.spendit-modal.large .modal-content {
  position: absolute;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 900px;
  margin-left: -450px; /* 900 / 2 */
  z-index: 900;
  border-radius: 6px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.spendit-modal.expense .modal-content {
  position: absolute;
  background-color: #fff;
  top: 100px;
  left: 50%;
  width: 850px;
  margin-left: -450px; /* 900 / 2 */
  z-index: 900;
  border-radius: 6px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

#expense-modal-split-right button {
  margin-right: 0 !important;
}

.spendit-modal#user-card-modal .modal-content {
  width: 600px;
}

.spendit-modal.medium .modal-content {
  position: absolute;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 700px;
  margin-left: -350px; /* 700 / 2 */
  z-index: 900;
  border-radius: 6px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.spendit-modal.xsmall .modal-content {
  position: absolute;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 300px;
  margin-left: -150px; /* 600 / 2 */
  z-index: 900;
  border-radius: 2px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}

.spendit-modal.small .modal-content {
  position: absolute;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 600px;
  margin-left: -300px; /* 600 / 2 */
  z-index: 900;
  border-radius: 6px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}

.spendit-modal.xsmall .modal-content {
  position: absolute;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 320px;
  margin-left: -160px; /* 900 / 2 */
  z-index: 900;
  border-radius: 2px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}

.spendit-modal .modal-content .modal-title {
  height: 40px;
  font-size: 18px;
  top: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  background-color: #f0f2f4;
}

.spendit-modal .modal-content .exit {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/x.png');
  width: 15px;
  height: 15px;
  position: absolute;
  right: 20px;
  top: 13px;
  z-index: 10;
  background-size: 100%;
  cursor: pointer;
}

.spendit-modal .modal-content .modal-desc .invited_complete .title-area .icon {
  width: 30px;
  float: left;
}

.spendit-modal .modal-content .modal-desc .invited_complete .title-area {
  width: 240px;
  margin: 0 auto;
}

.spendit-modal .modal-content .modal-desc .invited_complete .title-area .title {
  float: left;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #333333;
  padding: 3px 0 0 3px !important;
  margin-bottom: 20px;
}

.spendit-modal .modal-content .modal-desc .invited_complete .desc {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.2px;
  text-align: center;
  float: left;
  color: #7d7d7d;
}

.spendit-modal .modal-content .modal-desc .invited_complete .desc span {
  float: left;
  width: 100%;
}

.spendit-modal .modal-content #expense-modal-split-right .modal-desc {
  padding: 0 0 30px 0 !important;
  margin-bottom: 60px;
}

.spendit-modal .modal-content .modal-desc {
  padding: 25px;
}

.spendit-modal .modal-content .modal-desc dl {
  width: 100%;
  font-size: 14px;
  line-height: 45px;
  color: #545454;
  /*margin-top: 10px*/
}

.spendit-modal .modal-content .modal-desc dl dt {
  display: inline-block;
  vertical-align: middle;
  width: 20%;
  padding: 0 3px;
  line-height: 15px;
}

.spendit-modal .modal-content .modal-desc dl dd {
  display: inline-block;
  vertical-align: middle;
  width: 78%;
}

.spendit-modal .modal-content .modal-desc .option-desc {
  color: #9f9f9f;
  font-size: 12px;
  padding-left: 3px;
  line-height: 14px;
  border-bottom: solid 1px rgba(154, 150, 156, 0.15);
  padding-bottom: 10px;
}

.spendit-modal .modal-content .modal-desc #personal {
  border-bottom: solid 1px #fff;
}

.spendit-modal .modal-content .modal-desc dl dd input[type='number'],
.spendit-modal .modal-content .modal-desc dl dd input[type='text'] {
  width: -webkit-calc(100% - 22px); /* for Chrome, Safari */
  width: -moz-calc(100% - 22px); /* for Firefox */
  width: calc(100% - 22px); /* for IE */
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 8px 10px;
  border-radius: 6px;
}

.spendit-modal .modal-content .button-bottom {
  margin-top: 20px;
  text-align: right;
}

.spendit-modal .modal-content .button-bottom-custom {
  position: relative;
  float: left;
  width: 100%;
  text-align: right;
}

.button-bottom-custom {
  padding: 20px 0;
  border-top: 1px solid #ccc;
}

.spendit-modal .modal-content button.confirm {
  height: 33px;
  border-radius: 2px;
  border: 1px solid #047de0;
  background-color: #047de0;
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  float: right;
  padding: 0 15px;
}

.spendit-modal .modal-content button.cancel {
  height: 33px;
  border-radius: 2px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  font-size: 14px;
  color: #047de0;
  font-weight: normal;
  float: right;
  padding: 0 15px;
}

.spendit-modal .modal-content button.overview {
  height: 33px;
  border-radius: 2px;
  border: 1px solid #047de0;
  background-color: #ffffff;
  font-size: 14px;
  color: #047de0;
  font-weight: normal;
  float: left;
  padding: 0 15px;
}

.spendit-modal .modal-content button.submit {
  position: relative;
  display: inline-block;
  background-color: #047de0;
  height: 30px;
  font-size: 12px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #047de0;
}

button.blue-border {
  position: relative;
  display: inline-block;
  background-color: #ffffff;
  height: 30px;
  font-size: 12px;
  border-radius: 2px;
  color: #047de0;
  border: 1px solid #047de0;
}

.spendit-modal .modal-content button.disabled {
  position: relative;
  display: inline-block;
  background-color: #d5d5d5;
  height: 30px;
  font-size: 12px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #d5d5d5;
}

/*modal-custom*/
#modal-content-custom {
  position: absolute;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 700px;
  margin-left: -350px; /* 700 / 2 */
  z-index: 900;
  box-shadow: 0 2px 4px 0 var(--black-50);
}

.spendit-modal .modal-content .modal-title p {
  color: #022855;
  font-weight: normal;
  padding: 13px 25px;
}

.spendit-modal .modal-content .modal-title #p-custom {
  color: #5a5c5f;
  font-weight: 700;
  padding: 13px 25px;
}

.spendit-modal .modal-content .modal-title-custom {
  height: 40px;
  top: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 100%;
  background-color: #f0f2f4;
}

.spendit-modal .modal-content .modal-title-custom p {
  color: #333333;
  font-weight: 700;
  padding: 10px 25px;
}

.spendit-modal .modal-content .modal-desc-custom {
  padding: 25px;
  /*margin-top: 30px;*/
}

.spendit-modal .modal-content .modal-main-desc {
  width: 100%;
  height: 100px;
  position: relative;
  float: left;
  margin: 0 auto;
}

.spendit-modal .modal-content .modal-desc-custom .modal-desc-comment {
  width: 100%;
}

.spendit-modal .modal-content .modal-desc-custom dl {
  width: 100%;
  font-size: 14px;
  color: #545454;
  margin-top: 4px;
}

.spendit-modal .modal-content .modal-desc-custom dl#border {
  width: calc(100% - 20px);
  color: #545454;
  border-top: solid 1px #f0f0f0;
  padding: 10px;
  left: 0;
  position: absolute;
  background-color: #ffffff;
  margin-top: 20px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.spendit-modal .modal-content .modal-desc-custom dl dt {
  display: inline-block;
  vertical-align: middle;
  width: 20%;
  padding-left: 3px;
  line-height: 15px;
}

.spendit-modal .modal-content .modal-desc-custom dl dd {
  display: inline-block;
  vertical-align: middle;
  width: 78%;
}

.spendit-modal .modal-content .modal-desc-custom .modal-desc-comment dd {
  display: inline-block;
  vertical-align: middle;
  width: 78%;
  margin-left: 21%;
}

.spendit-modal .modal-content .modal-desc-custom .modal-desc-comment dd p {
  width: 400px;
  height: 18px;
  font-family: SpoqaHanSans;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: left;
  color: #a3a3a3;
}

.spendit-modal .modal-content .modal-desc-custom button.submit {
  width: 140px;
  height: 38px;
  border-radius: 2px;
  background-color: #047de0;
  margin-left: 77%;
}

.modal-submit-button {
  position: relative;
  float: left;
  width: 100%;
  height: 80px;
  border-top: solid 1px #cccccc;
  margin-top: 40px;
}

.spendit-modal .modal-content .modal-download-button {
  position: relative;
  float: left;
  width: 124px;
  height: 38px;
  border-radius: 2px;
}

.spendit-modal .modal-content .modal-download-button .download {
  width: 100%;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down.png');
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 95px;
  padding-right: 30px;
}

.spendit-modal .modal-content button.submit-custom {
  position: relative;
  display: inline-block;
  background-color: #fff;
  float: right;
  height: 38px;
  font-size: 16px;
  color: #047de0;
  border-radius: 2px;
  border: solid 1px #047de0;
  margin-top: 10px;
}

.csv-select-button {
  position: relative;
  float: left;
  text-align: center;
  width: 76px;
  height: 28px;
  line-height: 28px;
  border-radius: 3px;
  border: solid 1px #047de0;
  font-size: 11px;
  background-color: #fff;
  color: #047de0;
  margin: 16px;
}

.csv_upload span {
  position: relative;
  float: left;
  height: 28px;
  line-height: 28px;
  border-radius: 3px;
  font-size: 12px;
  color: #7d7d7d;
  margin-left: 5px;
}

/* switch */
.switch {
  position: relative;
  width: 36px;
  cursor: pointer;
  height: 20px;
  border-radius: 100px;
  background-color: #1d4578;
  border: solid 1px #1d4578;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.switch .text {
  position: absolute;
  color: #ffffff;
  font-size: 9px;
  margin-top: 4px;
}

.switch .text.on {
  margin-left: 8px;
}

.switch .text.off {
  margin-left: 21px;
}

.switch .button {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 14px;
  margin-left: 17px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
  border: solid 0.5px rgba(0, 0, 0, 0.1);
}

.switch.off {
  background-color: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.switch.off .button {
  margin-left: 0;
}

.option textarea {
  width: calc(100% - 22px);
  width: -webkit-calc(100% - 22px);
  width: -moz-calc(100% - 22px);
}

.option:not(.card-login) input[type='text'],
.option textarea,
.option:not(.card-login) input[type='password'],
.option input[type='number'] {
  font-size: 12px;
  border: 1px solid #b4c0cc;
  border-radius: 2px;
  padding: 7px 10px;
  line-height: 20px;
}

.option input[type='text']:active,
.option textarea:focus,
.option input[type='password']:active,
.option input[type='number']:active {
  outline: none;
}

.option .select {
  position: relative;
  border: 1px solid #b4c0cc;
  border-radius: 2px;
  background-color: #fff;
  padding: 11px 10px 12px 10px;
  font-size: 12px;
  cursor: pointer;
  line-height: 10px;
}

.option .select .arrow {
  position: absolute;
  top: 15px;
  right: -10px;
  text-indent: -9999px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/filter-dropdown.png');
  background-repeat: no-repeat;
  background-size: 13px;
}

.option .select .list {
  position: absolute;
  top: 36px;
  left: -1px;
  width: 100%;
  border: 1px solid #b4c0cc;
  border-radius: 0 0 3px 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  overflow: auto;
  max-height: 200px;
}

.option .select .list li {
  padding: 10px;
  line-height: 15px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.option .select .list .selected {
  background-color: #fafafa;
}

.option .select .list li:hover {
  background-color: #fafafa;
}

.option .select .list li:last-child {
  border: none;
}

.option .span-filter-date {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  margin: 11px 0 0 5px;
  color: #909090;
}

.option .Select-placeholder {
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/filter-dropdown-dark.png');
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 173px center;
}

.option .Select-value {
  font-size: 12px;
}

.option#calendar {
  margin-bottom: 8px;
}

.option#calendar-last {
  margin-bottom: 10px !important;
}

.fixed {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 100;
}

#loading {
  text-align: center;
  padding-top: 50px;
}

/* custom tooltip */
.custom-tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.custom-tooltip .tooltip-text {
  visibility: hidden;
  line-height: 15px;
  width: 120px;
  text-align: right;
  font-size: 12px;
  background-color: #fff;
  padding: 5px;
  border-radius: 6px;
  z-index: 400;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  /* Position the tooltip text */
  position: absolute;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  border: solid 1px #dedede;
  /* Fade in tooltip */
  opacity: 0;
  /*transition: opacity 1s;*/
}

.custom-tooltip .tooltip-text .tooltip-title {
  color: #bbb;
}

.custom-tooltip .tooltip-text .tooltip-price {
  color: #333;
}

/* Tooltip arrow */
.custom-tooltip .tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #dedede transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* card transaction */
.spendit-modal.card-transaction .modal-content .transaction-title {
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 100%;
}

.spendit-modal.card-transaction .modal-content .transaction-title p {
  color: #047de0;
  font-weight: 700;
  padding: 30px 25px;
}

.spendit-modal.card-transaction .modal-content {
  position: absolute;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 400px;
  margin-left: -200px; /* 900 / 2 */
  z-index: 900;
  border-radius: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 10px dotted #f6f6f6;
  border-right: 10px dotted #f6f6f6;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.spendit-modal.card-transaction .modal-content .transaction-desc {
  padding: 10px 25px;
  font-size: 12px;
}

.spendit-modal.card-transaction .modal-content .transaction-desc dl.first {
  border-top: 1px solid #83a3bd;
}

.spendit-modal.card-transaction .modal-content .transaction-desc dl {
  border-bottom: 1px solid #83a3bd;
  line-height: 35px;
}

.spendit-modal.card-transaction .modal-content .transaction-desc dl dt {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  padding-left: 10px;
  color: #7f7f7f;
  border-bottom: solid 1px #eaeaea;
  height: 35px;
}

.spendit-modal.card-transaction .modal-content .transaction-desc dl dd.last,
.spendit-modal.card-transaction .modal-content .transaction-desc dl dt.last {
  border-bottom: none !important;
}

.spendit-modal.card-transaction .modal-content .transaction-bottom {
  text-align: right;
  width: 350px;
  margin: 0 0 40px 25px;
}

.spendit-modal.card-transaction .modal-content .transaction-desc dl dd {
  display: inline-block;
  vertical-align: middle;
  width: 270px;
  height: 35px;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: solid 1px #eaeaea;
}

/* card transaction custom start */
.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-cancel {
  position: absolute;
  top: 320px;
  left: 146px;
  width: 128px;
  height: 83px;
  object-fit: contain;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-title {
  margin-top: 10px;
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 100%;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-title {
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 100%;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-title p {
  color: #787878;
  font-weight: 700;
  padding: 10px 25px;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-title #store-name {
  height: 17px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: #000000;
  padding-bottom: 0px;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-title #price {
  height: 17px;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: right;
  color: #000000;
}

.spendit-modal.card-transaction .bg-custom {
  position: absolute;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 370px;
  margin-left: -200px; /* 900 / 2 */
  z-index: 900;
  border-radius: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom {
  border: 10px solid #000000;
  height: 634.66px;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-desc {
  padding: 10px 10px;
  font-size: 13px;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-desc dl.first {
  border-top: solid 1px #d3d3d3;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-desc dl {
  border-bottom: dotted 1px #d3d3d3;
  line-height: 33px;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-desc dl dt {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  padding-left: 10px;
  color: #7f7f7f;
  height: 35px;
  background-color: #f6f6f6;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-desc dl dd.last,
.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-desc dl dt.last {
  border-bottom: none !important;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-bottom {
  text-align: center;
  width: 350px;
  margin: 0 0 20px 25px;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-desc dl dd {
  display: inline-block;
  vertical-align: middle;
  width: 285px;
  height: 35px;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
}

.spendit-modal.card-transaction .bg-custom .modal-content-custom .transaction-desc dl .dd-custom {
  line-height: 100%;
  white-space: pre-line;
}

.spendit-modal.card-transaction .bg-custom .receipt-button {
  height: 40px;
  background-color: #373737;
  position: relative;
  float: left;
  width: 100%;
}

.spendit-modal.card-transaction .bg-custom .receipt-button .exit {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-close.png');
  width: 40px;
  height: 40px;
  position: relative;
  background-color: #292929;
  float: right;
  background-size: 15px 15px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.spendit-modal.card-transaction .bg-custom .receipt-button .download-receipt {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-download-image.png');
  background-size: 100%;
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 20px;
  float: left;
  top: 10px;
  cursor: pointer;
}

.spendit-modal.card-transaction .bg-custom .receipt-button .download-receipt a {
  width: 20px;
  height: 20px;
  position: relative;
  float: left;
  cursor: pointer;
}

.spendit-modal.card-transaction .bg-custom .receipt-button .send-receipt {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-create-image.png');
  background-size: 100%;
  width: 20px;
  height: 20px;
  position: relative;
  float: left;
  margin-left: 40px;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.spendit-modal.card-transaction .bg-custom .receipt-button .exit:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-close-hover.png');
}

.spendit-modal.card-transaction .bg-custom .receipt-button .download-receipt:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-download-image-hover.png');
}

.spendit-modal.card-transaction .bg-custom .receipt-button .send-receipt:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-create-image-hover.png');
}

/* card transaction custom end */

/* bank transaction custom start */
.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-title {
  margin-top: 10px;
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 100%;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-title {
  text-align: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 100%;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-title p {
  color: #787878;
  font-weight: normal;
  padding: 10px 25px;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-title #store-name {
  height: 17px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: #000000;
  padding-bottom: 0px;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-title #price {
  height: 17px;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: right;
  color: #000000;
}

.spendit-modal.bank-transaction .bg-custom {
  position: absolute;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 370px;
  margin-left: -200px; /* 900 / 2 */
  z-index: 900;
  border-radius: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom {
  border: 10px solid #000000;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-desc {
  padding: 10px 10px;
  font-size: 12px;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-desc dl.first {
  border-top: solid 1px #000000;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-desc dl {
  border-bottom: dashed 1px #d3d3d3;
  line-height: 33px;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-desc #bottom {
  border-bottom: solid 1px #d3d3d3;
  line-height: 33px;
  margin-bottom: 30px;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .description {
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .description {
  margin-bottom: 150px;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .description p {
  line-height: 14px;
  margin-bottom: 4px;
  padding-left: 10px;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  color: #787878;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-desc dl dt {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  padding-left: 10px;
  color: #7f7f7f;
  height: 40px;
  background-color: #f6f6f6;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-desc dl dd.last,
.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-desc dl dt.last {
  border-bottom: none !important;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-bottom {
  text-align: center;
  width: 350px;
  margin: 0 0 20px 25px;
}

.spendit-modal.bank-transaction .bg-custom .modal-content-custom .transaction-desc dl dd {
  display: inline-block;
  vertical-align: middle;
  width: 285px;
  height: 35px;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
}

.spendit-modal.bank-transaction .bg-custom .receipt-button {
  height: 40px;
  background-color: #373737;
  position: relative;
  float: left;
  width: 100%;
}

.spendit-modal.bank-transaction .bg-custom .receipt-button .exit {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-close.png');
  width: 40px;
  height: 40px;
  position: relative;
  background-color: #292929;
  float: right;
  background-size: 15px 15px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.spendit-modal.bank-transaction .bg-custom .receipt-button .download-receipt {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-download-image.png');
  background-size: 100%;
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 20px;
  float: left;
  top: 10px;
  cursor: pointer;
}

.spendit-modal.bank-transaction .bg-custom .receipt-button .download-receipt a {
  width: 20px;
  height: 20px;
  position: relative;
  float: left;
  cursor: pointer;
}

.spendit-modal.bank-transaction .bg-custom .receipt-button .send-receipt {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-create-image.png');
  background-size: 100%;
  width: 20px;
  height: 20px;
  position: relative;
  float: left;
  margin-left: 40px;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.spendit-modal.bank-transaction .bg-custom .receipt-button .exit:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-close-hover.png');
}

.spendit-modal.bank-transaction .bg-custom .receipt-button .download-receipt:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-download-image-hover.png');
}

.spendit-modal.bank-transaction .bg-custom .receipt-button .send-receipt:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/transaction-create-image-hover.png');
}

/* bank transaction custom end */

@media (max-width: 999px) {
  nav {
    height: 54px !important;
  }

  nav #logo {
    width: 20%;
  }

  nav #user {
    width: 20%;
    text-align: right;
  }

  nav #user #intercom-alarm-new,
  nav #user #intercom-alarm {
    display: none;
  }

  .spendit-container {
    padding-top: 50px !important;
  }

  .expense-td-1 {
    text-align: center;
  }
}

@media (max-width: 640px) {
  nav #logo {
    width: 100%;
    background-position: center center;
  }

  nav #user #intercom-alarm-new,
  nav #user #intercom-alarm {
    display: none;
  }

  .main .wrap.padding {
    padding: 100px 0;
  }

  .spendit-container .button-list {
    height: 50px;
  }
}

/* 클릭시 placeholder 제거 */
#company-taxes .search-area input:focus::-webkit-input-placeholder,
#policy-approval-flow .search-area input:focus::-webkit-input-placeholder,
#company-groups .search-area input:focus::-webkit-input-placeholder,
#policy-project .search-area input:focus::-webkit-input-placeholder,
#company-users .users-filter-area .search-area input:focus::-webkit-input-placeholder,
#cards-view-custom .search-area input:focus::-webkit-input-placeholder,
#policy .top-area .search-area input:focus::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}

#policy-approval-flow .search-area input:focus:-moz-placeholder,
#company .search-area input:focus:-moz-placeholder,
#policy-project .search-area input:focus:-moz-placeholder,
#company-users .users-filter-area .search-area input:focus:-moz-placeholder,
#cards-view-custom .search-area input:focus:-moz-placeholder,
#policy .top-area .search-area input:focus:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent;
}

#policy-approval-flow .search-area input:focus::-moz-placeholder,
#company .search-area input:focus::-moz-placeholder,
#policy-project .search-area input:focus::-moz-placeholder,
#company-users .users-filter-area .search-area input:focus::-moz-placeholder,
#cards-view-custom .search-area input:focus::-moz-placeholder,
#policy .top-area .search-area input:focus::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
}

#policy-approval-flow .search-area input:focus:-ms-input-placeholder,
#company .search-area input:focus:-ms-input-placeholder,
#policy-project .search-area input:focus:-ms-input-placeholder,
#company-users .users-filter-area .search-area input:focus:-ms-input-placeholder,
#cards-view-custom .search-area input:focus:-ms-input-placeholder,
#policy .top-area .search-area input:focus:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
}

#expenses-bulk {
  width: 700px;
}

#expenses-bulk .modal-desc-custom .input-area {
  width: 100%;
  font-size: 14px;
  color: #545454;
  margin-top: 15px;
  height: 60px;
}

#expenses-bulk dl {
  margin-bottom: 39px;
}

#expenses-bulk dl dd {
  width: 450px;
  margin-right: 5px;
  float: right;
}

#expenses-bulk .submit-btn {
  width: 80px;
  height: 30px;
  border-radius: 2px;
  background-color: #047de0;
  border: 1px solid #047de0;
  color: #ffffff;
}

#expenses-bulk input::placeholder {
  color: #c3cdd6;
}

.spendit-modal #tax-transaction {
  width: 820px;
  margin-left: -400px;
}

*:focus {
  outline: none;
}
