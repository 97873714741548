#policy-setting .modal-desc {
  height: 380px;
}

#policy-setting .modal-desc .selected-policy {
  width: 650px;
  display: flex;
  flex-direction: column;
}

#policy-setting .modal-desc .selected-policy .detail {
  width: 650px;
  height: 20px;
  position: relative;
  float: left;
}

#policy-setting .modal-desc .selected-policy .detail .head {
  position: relative;
  float: left;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #7d7d7d;
}

#policy-setting .modal-desc .selected-policy .detail .count {
  position: relative;
  float: right;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #7d7d7d;
}

#policy-setting .modal-desc .selected-policy .detail .count .checked-count {
  font-weight: bold;
  color: #047de0;
}

#policy-setting .modal-desc .selected-policy .policy-icon-field {
  position: relative;
  float: left;
  width: 650px;
  height: 38px;
  border-radius: 2px;
  border: solid 1px #d5d5d5;
  overflow-x: scroll;
}

#policy-setting .modal-desc .selected-policy .policy-icon-field .policy-icon-scroll {
  position: relative;
  float: left;
  height: 38px;
  overflow-x: scroll;
}

#policy-setting .modal-desc .selected-policy .policy-icon-field .policy-icon-scroll .policy-icon {
  position: relative;
  float: left;
  width: 107px;
  height: 28px;
  border-radius: 100px;
  background-color: #eaf0f5;
  margin: 5px;
}

#policy-setting .modal-desc .selected-policy .policy-icon-field .policy-icon-scroll .policy-icon .policy-name {
  width: 77px;
  height: 20px;
  position: relative;
  float: left;
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: #000000;
  overflow: hidden;
  margin: 4px 0 4px 10px;
}

#policy-setting .modal-desc .selected-policy .policy-icon-field .policy-icon-scroll .policy-icon .delete-policy {
  width: 10px;
  height: 10px;
  line-height: 20px;
  text-align: center;
  position: relative;
  font-weight: 700;
  float: left;
  font-size: 12px;
  color: #657c97;
  margin: 4px 0;
  cursor: pointer;
}

#policy-setting .modal-desc .policy-list {
  width: 650px;
  height: 250px;
}

#policy-setting .modal-desc .policy-list .detail {
  width: 650px;
  height: 30px;
  position: relative;
  float: left;
  margin-top: 10px;
}

#policy-setting .modal-desc .policy-list .detail .head {
  position: relative;
  float: left;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #7d7d7d;
}

#policy-setting .modal-desc .policy-list .detail .all-check {
  position: relative;
  float: right;
  width: 80px;
  height: 24px;
  border-radius: 2px;
  border: solid 1px #047de0;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #047de0;
  cursor: pointer;
}

#policy-setting .modal-desc .policy-list .detail .all-check-active {
  position: relative;
  float: right;
  width: 80px;
  height: 24px;
  border-radius: 2px;
  border: solid 1px #047de0;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #ffffff;
  cursor: pointer;
  background-color: #047de0;
}

#policy-setting .modal-desc .policy-list .search-field {
  position: relative;
  width: 630px;
  height: 20px;
}

#policy-setting .modal-desc .policy-list .search-field .search-row {
  float: left;
  width: 650px;
  height: 42px;
}

#policy-setting .modal-desc .policy-list .search-field input {
  width: 578px;
  height: 20px;
  position: relative;
  float: left;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #000000;
  border-radius: 2px;
  border: solid 1px #d5d5d5;
  padding: 10px;
}

#policy-setting .modal-desc .policy-list .search-field input::placeholder {
  font-size: 13px !important;
  text-align: left;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.3px;
  color: #b5b5b5;
}

#policy-setting .modal-desc .policy-list .search-field .search-button {
  float: left;
  width: 50px;
  height: 42px;
  margin: 0 !important;
  border-radius: 2px;
  border: solid 1px #d5d5d5;
  background-color: #eaeaea;
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
}

#policy-setting .modal-desc .policy-list .list-field {
  position: relative;
  padding: 10px;
  float: left;
  margin-top: 10px;
  width: 630px;
  height: 150px;
  border-radius: 2px;
  border: solid 1px #d5d5d5;
  overflow: scroll;
}

#policy-setting .modal-desc .policy-list .list-field .list {
  width: 630px;
  float: left;
  position: relative;
  height: 20px;
  margin-bottom: 15px;
}

#policy-setting .modal-desc .policy-list .list-field .list input {
  float: left;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

#policy-setting .modal-desc .policy-list .list-field .list .list-name {
  float: left;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  width: 90%;
  line-height: 15px;
  letter-spacing: -0.2px;
  color: #545454;
  height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#policy-setting .config-buttons button {
  background-color: #047de0;
  border: 1px solid #047de0;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  width: 70px;
  height: 36px;
}

#policy-setting .config-buttons button:disabled {
  color: #fff;
}

#policy-setting .config-buttons {
  border-top: 1px solid #efefef;
  margin-top: 10px;
  padding-top: 10px;
  text-align: right;
  float: left;
}

#policy-duplication-modal .bold {
  font-weight: bold;
  float: left;
  margin-right: 10px;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#policy-duplication-modal dl {
  width: 100%;
  font-size: 14px;
  line-height: 45px;
  color: #545454;
}

#policy-duplication-modal .select-area {
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px #d5d5d5;
}

#policy-duplication-modal .select-area .header {
  height: 15px;
  padding: 10px 0;
  align-items: center;
  flex: 1;
  display: flex;
  border-bottom: 1px solid #edeff1;
}

#policy-duplication-modal .select-area .header input[type='checkbox'] {
  border: 1px solid #7f7f7f;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.41);
}

#policy-duplication-modal .select-area .header .title {
  font-size: 12px;
  color: #7d7d7d;
}

#policy-duplication-modal .select-area input[type='checkbox'] {
  margin: 0 10px;
}

#policy-duplication-modal .select-area .body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#policy-duplication-modal .select-area .body .body-row {
  height: 15px;
  padding: 10px 0;
  flex: 1;
  align-items: center;
  display: flex;
}

#policy-duplication-modal .select-area .body .body-row.disabled .element {
  color: #7d7d7d;
}

#policy-duplication-modal .select-area .body .body-row.disabled .element-desc {
  color: #7d7d7d;
}

#policy-duplication-modal .select-area .body .body-row.disabled input[type='checkbox'] {
  background-color: #d9dfe6;
}

#policy-duplication-modal .select-area .body .body-row .element {
  width: 15%;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
}

#policy-duplication-modal .select-area .body .body-row .element-desc {
  width: 70%;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  letter-spacing: -0.2px;
}

#policy-overlay {
  float: left;
}

#policy-overlay .icon-visible {
  cursor: pointer;
  float: left;
  width: 100%;
}

#policy-overlay .icon-visible .overlay-custom {
  padding: 5px 5px;
  display: none;
  min-height: 20px;
  border-radius: 5px;
  background-color: #000000;
  z-index: 200;
  width: 80px;
  margin-left: -70px;
  margin-top: 40px;
  position: absolute;
  color: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  font-size: 10px;
  line-height: 1.8;
  text-align: center;
}

#policy-overlay .icon-visible .overlay-custom:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #000000;
  right: 45%;
  top: -12px;
}

#policy-overlay .icon-visible .overlay-custom:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#policy-overlay .icon-visible:hover .overlay-custom {
  display: inline-block !important;
}
