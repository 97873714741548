.bold {
  font-weight: 700;
}

.required-blue {
  color: #047de0;
}

.red {
  color: #e65858;
}

.blue {
  color: #047de0;
}

.align-right {
  text-align: right;
}

/* expense */
#expense.filter .option {
  margin-bottom: 10px;
}

#expense.filter .option.expense-type {
  margin-top: 25px;
}

#expense.filter .option.expense-violation {
  margin-bottom: 25px;
}

#expense .sub-title li:hover {
  color: #047de0;
}

#expense .sub-title .selected {
  color: #047de0;
  font-weight: 700;
  border-bottom: 2px solid #047de0;
}

#report.filter .title,
#expense.filter .title {
  background-position: 20px 11px;
}

#report.filter .title p,
#expense.filter .title p {
  height: 14px;
  float: left;
}

#report.filter .title p.reset-title,
#expense.filter .title p.reset-title {
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  color: #ffe27c;
  margin-left: 3px;
  line-height: 15px;
}

#report.filter .title .reset-area,
#expense.filter .title .reset-area {
  float: left;
  cursor: pointer;
}

#report.filter .title .icon-filter-reset,
#expense.filter .title .icon-filter-reset {
  float: left;
  height: 15px;
  margin-left: 8px;
  background-position: center;
}

#expense .unreadable {
  color: #de3c3c;
}

#expense .no-list {
  width: 100%;
  padding-top: 20%;
}

#expense .button-group > button,
#expense .button-group > ul,
#report .button-group > button,
#report .button-group > ul,
#attendee .button-group > ul {
  vertical-align: top;
}

#expense .filter-area,
#report .filter-area {
  padding-top: 29px;
  height: 22px;
}

#report .filter-area .filter-data,
#expense .filter-area .filter-data {
  width: calc(100% - 40px);
  overflow-x: scroll;
  float: left;
}

#report .filter-area .filter-data .filter-scroll,
#expense .filter-area .filter-data .filter-scroll {
  white-space: nowrap;
}

#report .filter-area .filter-data .filter-scroll div,
#expense .filter-area .filter-data .filter-scroll div {
  display: inline-block;
  padding: 0 15px;
  height: 22px;
  line-height: 22px;
  margin-right: 4px;
  border-radius: 12.5px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
}

#report .filter-area .filter-data .filter-scroll .delete-icon,
#expense .filter-area .filter-data .filter-scroll .delete-icon {
  float: right;
  padding: 0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/delete-x.svg');
  width: 8px;
  height: 22.5px;
  background-size: 8px;
  background-repeat: no-repeat;
  margin: 0;
  margin-left: 5px;
  cursor: pointer;
  background-position: center;
}

#report .filter-area p,
#expense .filter-area p {
  font-size: 10px;
  color: #7d7d7d;
  line-height: 22px;
  float: left;
  margin-right: 10px;
}

#report .filter-area div span,
#expense .filter-area div span {
  padding: 5px 3px;
  margin-left: 3px;
  line-height: 10px;
  float: right;
  cursor: pointer;
  opacity: 0.5;
  font-size: 15px !important;
}

#report .filter-area .policy,
#expense .filter-area .policy {
  border: 1px solid #554799;
  background-color: #554799;
}

#expense .filter-area .report {
  border: 1px solid #d48538;
  background-color: #d48538;
}

#expense .filter-area .payment {
  border: 1px solid #0e9fc3;
  background-color: #0e9fc3;
}

#expense .filter-area .tag {
  border: 1px solid #9a1f5c;
  background-color: #9a1f5c;
}

#expense .filter-area .sub-tag {
  border: 1px solid #515151;
  background-color: #515151;
}

#expense .filter-area .category {
  border: 1px solid #057de0;
  background-color: #057de0;
}

#expense .filter-area .user {
  border: 1px solid #2eaa80;
  background-color: #2eaa80;
}

.no-list {
  width: 100%;
  padding-top: 20%;
}

#expense-rules .no-list-text,
#cards-view .no-list-text,
#cards-view-custom .no-list-text,
#card-list-div .no-list-text {
  margin-top: 100px;
  line-height: 18px;
  padding-top: 30px;
}

.no-list-text {
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: -0.2px;
  color: #b0b0b0;
}

.no-list-subject {
  font-size: 14px;
  color: #2143af;
  margin-top: 8px;
}

.no-list-desc {
  font-size: 12px;
  color: #7d7d7d;
  margin-top: 8px;
}

.no-list-button {
  position: relative;
  left: 50%;
  margin-left: -126px;
  width: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 92px;
}

#expense #list .content.expense-list {
  font-size: 13px !important;
}

#expense #list .content.expense-list .expense-row.unread {
  background-color: rgba(4, 125, 224, 0.07);
}

#policy-info .icon-edit,
#report .icon-edit {
  background-image: url('https://spendit-resource-staging.s3.ap-northeast-2.amazonaws.com/icons/report/new-pencil-icon.svg');
  background-size: 16px;
  background-position: center;
  float: left;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-repeat: no-repeat;
}

#report #plan-table tr.public,
#report #report-table tr.public,
#expense #list .content .expense-row.public {
  background-color: rgba(255, 255, 102, 0.2) !important;
}

#expense .no-filter .div-map-header {
  width: -webkit-calc(100% - 600px); /* for Chrome, Safari */
  width: -moz-calc(100% - 600px); /* for Firefox */
  width: calc(100% - 600px); /* for IE */
}

#expense .div-map-header {
  width: -webkit-calc(100% - 840px); /* for Chrome, Safari */
  width: -moz-calc(100% - 840px); /* for Firefox */
  width: calc(100% - 840px); /* for IE */
  position: fixed;
  font-size: 10px;
  border-bottom: 1px solid #efefef;
  z-index: 200;
  background-color: #ffffff;
}

.div-map-header > div,
.div-table-header > div {
  float: left;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
.spendit-modal,
#feed .main ul#feedlist,
.detail > .wrap .content {
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
  animation-name: fadeIn;
  animation-duration: 0.3s;
}

.detail > .wrap .content {
  border-radius: 6px;
}

.detail > .wrap .settings .content {
  margin-bottom: 10px;
}

.div-table-body .div-table-body-row-wrapper {
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.6s;
  animation-name: fadeIn;
  animation-duration: 0.6s;
}

.div-table-header input[type='checkbox'] {
  border: 1px solid #7f7f7f;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.div-table-header input[type='checkbox']:checked {
  border: 1px solid #0b5ab7;
}

#transaction-list .div-table-header {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

@media (max-width: 549px) {
  .policy-refund .div-table-header {
    width: -webkit-calc(100% - 330px);
    width: -moz-calc(100% - 330px);
    width: calc(100% - 330px);
  }

  #company-taxes .div-table-header,
  #company-groups .div-table-header,
  #policy-project .div-table-header,
  #personal-refund .div-table-header,
  #expense-rules .div-table-header,
  #transaction-list .div-table-header,
  #cards-view .div-table-header,
  #cards-view-custom .div-table-header,
  #policy-refund #single-view .div-table-header,
  #policy-members .div-table-header,
  #company-users .div-table-header {
    width: -webkit-calc(100% - 70px);
    width: -moz-calc(100% - 70px);
    width: calc(100% - 70px);
  }

  #policy .div-table-header {
    color: #7d7d7d;
  }

  #policy-approval-flow,
  div-table-header,
  #policy-members .div-table-header,
  #company-users .div-table-header {
    width: -webkit-calc(100% - 300px) !important;
    width: -moz-calc(100% - 300px) !important;
    width: calc(100% - 300px) !important;
    color: #7d7d7d;
  }

  #group-members .div-table-header,
  #project-tags .div-table-header {
    width: -webkit-calc(100% - 310px); /* for Chrome, Safari */
    width: -moz-calc(100% - 310px); /* for Firefox */
    width: calc(100% - 310px); /* for IE */
    padding: 0 35px;
  }

  #policy .div-table-header,
  #attendee .div-table-header,
  #report .div-table-header,
  #expense .div-table-header {
    width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
    width: -moz-calc(100% - 70px); /* for Firefox */
    width: calc(100% - 70px); /* for IE */
  }

  #policy .div-table-header {
    padding: 0 !important;
  }

  #policy-approval-flow .div-table-header,
  #company-taxes .div-table-header,
  #company-groups .div-table-header,
  #policy-refund .div-table-header,
  #policy-members .div-table-header,
  #company-users .div-table-header,
  #transaction-list .div-table-header,
  .policy-refund .div-table-header,
  #personal-refund .div-table-header,
  #cards-view .div-table-header,
  #cards-view-custom .div-table-header,
  #attendee .div-table-header,
  #expense-rules .div-table-header,
  #policy .div-table-header,
  #report .div-table-header,
  #expense .div-table-header {
    position: fixed;
    font-size: 10px;
    z-index: 12;
    background-color: #ffffff;
  }

  #transaction-list .bank-transaction-td-policy,
  #mypage #split-view #spent-info .content .card-transaction-td-8,
  #mypage #split-view #spent-info .content .card-transaction-td-9,
  #mypage #split-view #spent-info .content .card-transaction-td-number,
  #mypage #split-view #spent-info .content .card-transaction-td-11,
  #expense .moving-table,
  #expense #list .expense-td-5,
  #expense #list .expense-td-7,
  #expense #list .expense-td-violation,
  #expense #list .expense-td-policy,
  #expense #list .expense-td-project {
    display: none;
  }

  #expense #map .fixed-map-custom {
    left: 35px !important;
    padding-top: 80px !important;
  }
}

@media (min-width: 550px) and (max-width: 999px) {
  .policy-refund .div-table-header {
    width: -webkit-calc(100% - 330px);
    width: -moz-calc(100% - 330px);
    width: calc(100% - 330px);
  }

  #company-taxes .div-table-header,
  #company-groups .div-table-header,
  #personal-refund .div-table-header,
  #expense-rules .div-table-header,
  #cards-view .div-table-header,
  #cards-view-custom .div-table-header,
  #transaction-list .div-table-header,
  #policy-refund #single-view .div-table-header,
  #policy-members .div-table-header,
  #company-users .div-table-header,
  #policy-project .div-table-header {
    width: -webkit-calc(100% - 70px);
    width: -moz-calc(100% - 70px);
    width: calc(100% - 70px);
  }

  #policy .div-table-header {
    color: #7d7d7d;
  }

  #policy-members .div-table-header {
    color: #7d7d7d;
    width: -webkit-calc(100% - 300px) !important;
    width: -moz-calc(100% - 300px) !important;
    width: calc(100% - 300px) !important;
  }

  @media (min-width: 1000px) {
    #policy .div-table-header {
      color: #7d7d7d;
    }

    #policy-members .div-table-header,
    #company-users .div-table-header {
      color: #7d7d7d;
      width: -webkit-calc(100% - 300px) !important;
      width: -moz-calc(100% - 300px) !important;
      width: calc(100% - 300px) !important;
    }
  }

  #company-taxes .div-table-header,
  #company-groups .div-table-header,
  #attendee .div-table-header,
  #policy .div-table-header,
  #report .div-table-header,
  #expense .div-table-header {
    width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
    width: -moz-calc(100% - 70px); /* for Firefox */
    width: calc(100% - 70px); /* for IE */
  }

  #policy .div-table-header {
    padding: 0 !important;
  }

  #transaction-list .div-table-header {
    position: fixed;
    font-size: 10px;
    z-index: 12;
    background-color: #ffffff;
  }

  #company-taxes .div-table-header,
  #company-groups .div-table-header,
  #group-members .div-table-header,
  #project-tags .div-table-header,
  #policy-refund .div-table-header,
  #policy-approval-flow .div-table-header,
  #policy-members .div-table-header,
  #company-users .div-table-header,
  .policy-refund .div-table-header,
  #cards-view .div-table-header,
  #cards-view-custom .div-table-header,
  #attendee .div-table-header,
  #expense-rules .div-table-header,
  #policy-project .div-table-header,
  #policy .div-table-header,
  #report .div-table-header,
  #expense .div-table-header {
    position: fixed;
    font-size: 10px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    z-index: 12;
    background-color: #ffffff;
  }

  #expense #map .fixed-map-custom {
    left: 35px !important;
    padding-top: 80px !important;
  }

  #expense .moving-table .div-map-header {
    width: -webkit-calc(100% - 600px) !important; /* for Chrome, Safari */
    width: -moz-calc(100% - 600px) !important; /* for Firefox */
    width: calc(100% - 600px) !important; /* for IE */
  }

  #transaction-list .bank-transaction-td-policy,
  #mypage #split-view #spent-info .content .card-transaction-td-number,
  #mypage #split-view #spent-info .content .card-transaction-td-11,
  #expense .moving-table .expense-map-td-1,
  #expense .moving-table .expense-map-td-3,
  #expense .moving-table .expense-map-td-4,
  #expense .moving-table .expense-map-td-5 {
    display: none;
  }

  #expense .moving-table .expense-map-td-2 {
    width: 75% !important;
  }

  #expense .moving-table .expense-map-td-6 {
    width: 20% !important;
  }
}

@media (min-width: 550px) and (max-width: 1200px) {
  #expense .moving-table .expense-map-td-1,
  #expense .moving-table .expense-map-td-3,
  #expense .moving-table .expense-map-td-4,
  #expense .moving-table .expense-map-td-5 {
    display: none;
  }

  #expense .moving-table .expense-map-td-2 {
    width: 75% !important;
  }

  #expense .moving-table .expense-map-td-6 {
    width: 20% !important;
  }
}

@media (min-width: 1000px) {
  #group-members .div-table-header,
  #project-tags .div-table-header {
    width: -webkit-calc(100% - 310px); /* for Chrome, Safari */
    width: -moz-calc(100% - 310px); /* for Firefox */
    width: calc(100% - 310px); /* for IE */
    position: fixed;
    font-size: 10px;
    border-bottom: 1px solid #efefef;
    z-index: 10;
    background-color: #ffffff;
  }

  #company-taxes .div-table-header,
  #company-groups .div-table-header,
  #policy-project .div-table-header,
  #personal-refund .div-table-header,
  #expense-rules .div-table-header,
  #cards-view-custom .div-table-header,
  #cards-view .div-table-header {
    width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
    width: -moz-calc(100% - 70px); /* for Firefox */
    width: calc(100% - 70px); /* for IE */
    position: fixed;
    font-size: 10px;
    border-bottom: 1px solid #efefef;
    z-index: 50;
    background-color: #ffffff;
  }

  #attendee .no-filter .div-table-header,
  #report .no-filter .div-table-header,
  #expense .no-filter .div-table-header {
    width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
    width: -moz-calc(100% - 70px); /* for Firefox */
    width: calc(100% - 70px); /* for IE */
  }

  #transaction-list .div-table-header {
    position: fixed;
    font-size: 10px;
    z-index: 1;
    background-color: #ffffff;
  }

  .policy-refund .div-table-header {
    position: fixed;
    font-size: 10px;
    border-bottom: 1px solid #efefef;
    z-index: 200;
    background-color: #ffffff;
  }

  .policy-refund .div-table-header,
  #transaction-list .div-table-header {
    width: -webkit-calc(100% - 330px); /* for Chrome, Safari */
    width: -moz-calc(100% - 330px); /* for Firefox */
    width: calc(100% - 330px); /* for IE */
  }

  #policy-refund .div-table-header {
    z-index: 0 !important;
  }

  #policy-refund #single-view .div-table-header,
  #policy-members .div-table-header,
  #company-users .div-table-header,
  #policy .div-table-header {
    width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
    width: -moz-calc(100% - 70px); /* for Firefox */
    width: calc(100% - 70px); /* for IE */
    position: fixed;
    border-bottom: 1px solid #efefef;
    z-index: 200;
    background-color: #ffffff;
  }

  #policy .div-table-header {
    color: #7d7d7d;
  }

  #policy-members .div-table-header {
    width: -webkit-calc(100% - 300px) !important; /* for Chrome, Safari */
    width: -moz-calc(100% - 300px) !important; /* for Firefox */
    width: calc(100% - 300px) !important; /* for IE */
    z-index: 50 !important;
    color: #7d7d7d;
  }

  #policy .div-table-header {
    padding: 0 !important;
  }

  #attendee .div-table-header,
  #report .div-table-header,
  #expense .div-table-header {
    width: -webkit-calc(100% - 310px); /* for Chrome, Safari */
    width: -moz-calc(100% - 310px); /* for Firefox */
    width: calc(100% - 310px); /* for IE */
    position: fixed;
    font-size: 10px;
    border-bottom: 1px solid #efefef;
    z-index: 200;
    background-color: #ffffff;
  }
}

#expense .div-map-body {
  padding-top: 35px;
}

#expense .div-table-body {
  padding: 35px 0 0 0;
}

#company-taxes .div-table-body,
#company-groups .div-table-body,
#group-members .div-table-body,
#project-tags .div-table-body,
#policy-refund .div-table-body,
#policy-approval-flow .div-table-body,
#policy-members .div-table-body,
#company-users .div-table-body,
#transaction-list .div-table-body,
#policy-project .div-table-body,
.policy-refund .div-table-body,
#personal-refund .div-table-body,
#expense-rules .div-table-body,
#cards-view-custom .div-table-body,
#attendee .div-table-body,
#policy .div-table-body,
#cards-view .div-table-body {
  padding: 30px 0 0 0;
}

#policy-refund .policy-refund-row:hover,
#policy-approval-flow .user-row:hover,
#policy-members .user-row:hover,
#transaction-list .transaction-row:hover,
.policy-refund .refund-row:hover,
#personal-refund .refund-row:hover,
#cards-view .payment-row:hover,
#cards-view-custom .payment-row:hover,
#policy-project .project-row:hover,
#company-groups .group-row:hover,
#company-taxes .group-row:hover,
#policy .policy-row:hover,
#report .report-row:hover,
#expense-rules .expense-rule-row:hover {
  background-color: rgba(2, 40, 85, 0.04);
}

#expense .expense-row.wide {
  height: 130px;
  font-size: 14px !important;
}

#company-users .user-row .mypage-member-td-4 p {
  text-overflow: unset;
  overflow: visible;
  white-space: pre;
}

#company-users .user-row .mypage-member-td-4 button {
  padding: 0 10px;
}

#project-tags .tags-row,
#policy-refund .policy-refund-row,
#policy-approval-flow .user-row,
#policy-members .user-row,
#transaction-list .transaction-row,
.policy-refund .refund-row,
#personal-refund .refund-row,
#expense-rules .expense-rule-row,
#cards-view .payment-row,
#cards-view-custom .payment-row,
#policy-project .project-row,
#company-groups .group-row,
#company-taxes .group-row,
#report .report-row,
#expense .expense-map-row {
  width: 100%;
  height: 60px;
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  display: inline-block;
}

#group-members .group-members-row {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: inline-block;
}

#transaction-upload-modal .transaction-update-row {
  display: flex;
  width: 100%;
  min-height: 29px;
  cursor: pointer;
  border-bottom: 1px solid #efefef;
}

#group-members .group-members-row > div,
#project-tags .tags-row > div,
#policy-refund .policy-refund-row > div,
#policy-approval-flow .user-row > div,
#policy-members .user-row > div,
#transaction-upload-modal .transaction-update-row > div,
#transaction-list .transaction-row > div,
.policy-refund .refund-row > div,
#personal-refund .refund-row > div,
#expense-rules .expense-rule-row > div,
#cards-view .payment-row > div,
#cards-view-custom .payment-row > div,
#policy-project .project-row > div,
#company-groups .group-row > div,
#company-taxes .group-row > div,
#policy .policy-row > div,
#expense .expense-map-row > div,
#report .report-row > div {
  float: left;
}

#policy .no-list {
  padding-top: calc(20% - 110px);
}

#policy .no-list-button {
  position: relative;
  left: 50%;
  margin-left: -115px;
  width: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 92px;
}

#expense .expense-row .canceled,
#report-document .canceled {
  color: #9f9f9f;
}

#expense #list .content .div-table-header expense-td-1 {
  vertical-align: bottom;
}

#expense #list .content .expense-td-1 {
  width: 10px;
  padding: 0 10px;
}

#expense #list .content .expense-row.wide .unreadable {
  width: -webkit-calc(100% - 300px); /* for Chrome, Safari */
  width: -moz-calc(100% - 300px); /* for Firefox */
  width: calc(100% - 300px); /* for IE */
}

#expense #list .content .expense-row .unreadable {
  width: -webkit-calc(100% - 100px); /* for Chrome, Safari */
  width: -moz-calc(100% - 100px); /* for Firefox */
  width: calc(100% - 100px); /* for IE */
}

#expense #list .content .wide .expense-td-2 {
  width: 120px;
}

.expense-border {
  box-sizing: border-box;
  width: 38px;
  height: 38px;
  margin: 0 auto;
  background-size: 38px 38px;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  border: 1px solid #e5e8ea;
  border-radius: 2px;
}

.expense-border-wide {
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  margin: 10px 10px;
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  border: 1px solid #e5e8ea;
  border-radius: 7px;
}

span.header-title {
  margin-left: 10px;
  color: #7d7d7d;
  font-size: 11px;
}

.div-table-header-row-wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.div-table-header-wrapper {
  display: table-cell;
  height: 30px;
  vertical-align: middle;
}

.div-table-body-row-wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.div-table-body-wrapper {
  display: table-cell;
  vertical-align: middle;
}

#expense #list .content .expense-td-wide-3 {
  width: 10%;
}

#expense #list .content .expense-td-wide-3 p {
  font-size: 14px;
}

#expense #list .content .expense-td-3 .report {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

#expense #list .content .expense-td-3 .canceled {
  border: solid 1px #e65858;
  border-radius: 100px;
  color: #e65858;
  font-size: 9px;
  float: left;
  line-height: 18px;
  padding: 0 5px;
  margin-right: 7px;
}

#expense #list .content .expense-td-wide-4 p {
  font-size: 14px;
}

#expense #list .content .expense-td-5 .category {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#expense #list .content .expense-td-6 .date {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#expense #list .content .expense-td-7 .method {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#expense #list .content .expense-td-8 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#expense #list .content .report span:hover {
  color: #047de0;
}

#expense #list .content .price {
  color: #047de0;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#expense #image .image-header {
  position: fixed;
  height: 30px;
  padding: 0 35px 0 10px;
  z-index: 100;
  font-size: 13px;
  border-bottom: 1px solid #efefef;
  background-color: #ffffff;
  box-sizing: border-box;
}

#expense #image .no-list {
  padding-top: calc(20% - 16px);
}

#expense #image .image-header .hand {
  margin-left: 10px;
  font-size: 11px;
}

#expense #image .image-header .check {
  position: relative;
  float: left;
  top: 25%;
}

#expense #image .content ul.expense-images {
  padding: 55px 0;
}

/* attendee */
#expense .expense-td-7 #percentage-bar-wrap {
  margin: 5px 0;
}

#percentage-bar-wrap .price {
  font-size: 11px !important;
  clear: both;
}

#percentage-bar.limit-status-div {
  float: right;
}

#percentage-bar .limit-status {
  width: 50px;
  height: 6px;
  float: left;
  border-radius: 100px;
}

#percentage-bar .limit-status p {
  clear: both;
  height: 6px;
}

#percentage-bar .label {
  color: #333;
  margin-left: 3px;
  line-height: 7px;
  float: right;
  font-size: 9px;
}

#attendee-filter .mine-only {
  font-size: 12px;
  cursor: pointer;
  padding: 10px;
}

#attendee-filter .mine-only span.inactive {
  color: #a9a9a9;
}

#attendee-filter .mine-only input {
  margin-right: 10px;
}

#attendee-filter ul.attendee-mine-only {
  line-height: 35px;
  cursor: pointer;
  border: 1px solid #b4c0cc;
  background-color: #ffffff;
}

#attendee-filter .title {
  background-position: 20px 11px;
}

#attendee-filter .title p {
  float: left;
}

/* policy */
#policy .wrap-no-contents .no-list-text {
  padding-top: 125px;
}

#policy .wrap-no-contents {
  padding: 125px 0 0 0;
}

#policy .wrap {
  padding: 67px 0;
}

#policy .content {
  position: relative;
}

#policy .button-list button {
  float: left;
}

#policy .button-list {
  position: fixed;
  float: left;
  margin-bottom: 5px;
  padding: 15px 0;
  width: calc(100% - 230px);
  border-bottom: 0;
}

#policy .search-area {
  position: relative;
  width: 100%;
  height: 32px;
  text-align: center;
  z-index: 250;
}

#policy .search-area input {
  position: relative;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #000000;
  border-bottom: solid 2px #002855 !important;
  width: 100%;
  height: 30px;
  background-color: transparent;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 95%;
}

#policy .search-area input::placeholder {
  font-size: 14px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #6d6666;
}

#policy .search-area input[type='text']:focus {
  background-color: transparent !important;
  border-top: 0px solid !important;
  border-left: 0px solid !important;
  border-right: 0px solid !important;
  color: #000000 !important;
}

#policy .initial-field {
  position: fixed;
  -ms-overflow-style: none;
  width: 50px;
  height: calc(100% - 220px);
  float: left;
  z-index: 301;
  margin-top: 125px;
  border-radius: 3px;
  background-color: #cad0d5;
  overflow: scroll;
  left: 30px;
}

#policy .initial-field #ko {
  height: 100%;
}

#policy .initial-field #en {
  height: 100%;
}

#policy .initial-field .dot {
  margin: 15px 22px;
  width: 4px;
  height: 4px;
  opacity: 0.4;
  background-color: #ffffff;
  border-radius: 10px;
}

#policy .initial-field .ko-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#policy .initial-field .en-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#policy .initial-field .all-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#policy .initial-field .all-field-active,
#policy .initial-field .en-field-active,
#policy .initial-field .ko-field-active,
#policy .initial-field .all-field:hover,
#policy .initial-field .ko-field:hover,
#policy .initial-field .en-field:hover {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  cursor: pointer;
  color: #cad0d5;
  background-color: #ffffff;
  font-weight: bold;
}

#policy .settings {
  width: calc(100% - 230px);
  margin: 0 auto;
}

#policy .policy-td-1 {
  padding-left: 10px;
  width: 30%;
}

#policy .policy-td-1 .policy-name {
  padding-right: 10px;
  margin-left: 8px;
}

#policy .policy-td-2 {
  width: 25%;
}

#policy .policy-td-3 {
  width: 15%;
}

#policy .policy-td-4 {
  width: 23%;
  font-weight: bold;
  text-align: right;
  font-size: 12px;
  letter-spacing: -0.2px;
  color: #022855;
}

#policy .policy-td-4 .icon-row .icon-policy-copy,
#policy .policy-td-4 .icon-row .icon-report-copy {
  float: left;
  width: 34px;
  height: 34px;
  margin-right: 10px;
}

#policy .policy-td-4 .bar {
  margin: 10px 10px 7px 0;
  height: 16px;
  border: solid 0.5px #bbbbbb;
  float: right;
}

#policy .policy-td-5 {
  width: 5%;
  float: left !important;
}

#policy .policy-td-5 .icon-trash-gray {
  float: none;
  background-size: 34px 35px;
}

#policy .policy-td-5 .icon-trash-gray:hover {
  background-size: 34px 35px;
}

/* mypage */
#mypage table .mypage-policy-td-1 {
  padding-left: 10px;
  width: 75%;
}

#mypage table .mypage-policy-td-2 {
  width: 15%;
}

#mypage table .mypage-policy-td-3 {
  width: 10%;
}

#mypage {
  color: #333;
}

#mypage .detail .menu li {
  max-width: 150px;
}

#mypage .title {
  text-align: center;
  padding: 11px 0 11px 0;
}

#mypage .content .title {
  text-align: left !important;
  padding: 13px 0 13px 20px !important;
}

#mypage .content .title .help {
  float: right;
  width: 15px;
  margin-right: 20px;
}

#mypage .content #auto-scan-desc {
  padding-bottom: 20px;
  font-size: 12px;
  color: #1e1e1e;
  line-height: 20px;
}

#mypage .sub-title {
  color: #022855;
  font-weight: 700;
  padding-top: 60px;
  text-align: center;
}

#mypage .sub-title span {
  border-bottom: 2px solid #022855;
  padding: 3px;
}

#mypage .notify-policy span.policy-name {
  font-weight: bold;
}

#policy-info #input-select .list-body,
#policy-info .Select-control {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 6px;
}

#policy-info .Select-menu-outer {
  border-radius: 6px;
  margin-top: 1px;
  /*border: 1px solid #ccc;*/
}

#policy-info .Select-menu-outer .Select-menu {
  border-radius: 6px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
}

#policy-info .detail .title p {
  width: 50%;
  margin: 0 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 16px;
}

#policy-info .sub-title {
  color: #022855;
  font-weight: 700;
  padding-top: 35px;
  text-align: center;
}

#policy-info .option input[type='number'],
#report-document .option input[type='text'],
#policy-info .option input[type='text'] {
  z-index: 0 !important;
  font-size: 12px;
  border: 1px solid #d4d4d3;
  border-radius: 2px;
  padding: 7px 10px;
  line-height: 20px;
  width: 100%;
  width: -webkit-calc(100% - 22px); /* for Chrome, Safari */
  width: -moz-calc(100% - 22px); /* for Firefox */
  width: calc(100% - 22px); /* for IE */
}

#policy-info .option input[type='number'],
#policy-info .option input[type='text'] {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px !important;
}

#erp-option-modal input {
  border-radius: 6px !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #d4d4d4 !important;
  z-index: 0 !important;
  font-size: 12px;
  padding: 7px 10px;
  line-height: 20px;
  width: 100%;
  width: -webkit-calc(100% - 22px); /* for Chrome, Safari */
  width: -moz-calc(100% - 22px); /* for Firefox */
  width: calc(100% - 22px); /* for IE */
}

#policy-info .option #report-field-title {
  z-index: 0 !important;
  font-size: 12px;
  border: 1px solid #b4c0cc;
  border-radius: 2px;
  padding: 8px 0px 8px 10px;
  line-height: 20px;
  color: #555555;
  font-weight: bold;
  font-size: 13px;
  width: 100%;
  width: -webkit-calc(100% - 100px); /* for Chrome, Safari */
  width: -moz-calc(100% - 100px); /* for Firefox */
  width: calc(100% - 100px); /* for IE */
}

#policy-info .option #report-custom-field-title {
  z-index: 0 !important;
  font-size: 12px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  padding: 8px 10px;
  line-height: 20px;
  width: 100%;
  width: -webkit-calc(100% - 30px); /* for Chrome, Safari */
  width: -moz-calc(100% - 30px); /* for Firefox */
  width: calc(100% - 30px); /* for IE */
}

#policy-info .sub-title span {
  border-bottom: 2px solid #022855;
  padding: 3px;
}

#policy-info .content .title .help {
  float: right;
  margin-right: 20px;
  width: 15px;
}

#policy-info #split-view #spent-info {
  width: 100%; /*(fallback) */
  width: -webkit-calc(100% - 260px); /* for Chrome, Safari */
  width: -moz-calc(100% - 260px); /* for Firefox */
  width: calc(100% - 260px); /* for IE */
  left: 260px;
  height: 100%;
  position: relative;
  border: none;
}

#policy-info #split-view #spent-info .sub-title {
  position: fixed;
  height: 100px;
  width: -webkit-calc(100% - 260px); /* for Chrome, Safari */
  width: -moz-calc(100% - 260px); /* for Firefox */
  width: calc(100% - 260px); /* for IE */
}

#policy-info #split-view #spent-info .sub-title#right {
  position: fixed;
  width: -webkit-calc(100% - 260px); /* for Chrome, Safari */
  width: -moz-calc(100% - 260px); /* for Firefox */
  width: calc(100% - 260px); /* for IE */
  background-color: #fff;
  height: 50px;
  box-shadow: 0px 0px 30px white;
}

#policy-info #split-view #spent-info-container {
  padding: 0 35px;
  background-color: #fff;
}

#policy-info .desc.approver-list {
  background-color: rgba(0, 0, 0, 0);
  display: flow-root;
}

#policy-info .desc.approver-list .inner {
  text-align: center;
}

#policy-info .policy-approve-types {
  position: relative;
  display: inline-block;
  width: -webkit-calc(100% - 20px); /* for Chrome, Safari */
  width: -moz-calc(100% - 20px); /* for Firefox */
  width: calc(100% - 20px); /* for IE */
  overflow-x: auto;
  margin: 10px auto;
  white-space: nowrap;
}

#policy-info .policy-approve-types > div:hover {
  border: 1px solid #047de0;
}

#policy-info .policy-approve-types > div {
  width: 188px;
  height: 98px;
  text-align: center;
  display: inline-block;
  background-color: #fafafa;
  font-size: 12px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #e6eaee;
}

#policy-info .policy-approve-types .default-active .approve-type-desc,
#policy-info .policy-approve-types .default-active .approve-type-title,
#policy-info .policy-approve-types .step-active .approve-type-desc,
#policy-info .policy-approve-types .step-active .approve-type-title,
#policy-info .policy-approve-types .submit-approve-active .approve-type-desc,
#policy-info .policy-approve-types .submit-approve-active .approve-type-title,
#policy-info .policy-approve-types .single-active .approve-type-desc,
#policy-info .policy-approve-types .single-active .approve-type-title,
#policy-info .policy-approve-types .default:hover .approve-type-desc,
#policy-info .policy-approve-types .default:hover .approve-type-title,
#policy-info .policy-approve-types .submit-approve:hover .approve-type-desc,
#policy-info .policy-approve-types .submit-approve:hover .approve-type-title,
#policy-info .policy-approve-types .step:hover .approve-type-desc,
#policy-info .policy-approve-types .step:hover .approve-type-title,
#policy-info .policy-approve-types .single:hover .approve-type-desc,
#policy-info .policy-approve-types .single:hover .approve-type-title {
  color: #047de0;
}

#policy-info .policy-approve-types .approve-type-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
  line-height: 24px;
}

#policy-info .policy-approve-types .approve-type-desc {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #7d7d7d;
}

#policy-info .policy-approve-types .default {
  margin-right: 10px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-approve-default.png');
  background-repeat: no-repeat;
  background-size: 86px 26px;
  background-position: center 70px;
}

#policy-info .policy-approve-types .default-active {
  background-color: #ffffff;
}

#policy-info .policy-approve-types .default-active,
#policy-info .policy-approve-types .default:hover {
  border: 1px solid #047de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-approve-default-active.png');
}

#policy-info .policy-approve-types .submit-approve {
  margin-right: 10px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-approve-single.png');
  background-repeat: no-repeat;
  background-size: 36px 26px;
  background-position: center 70px;
}

#policy-info .policy-approve-types .single {
  margin-right: 10px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-approve-single.png');
  background-repeat: no-repeat;
  background-size: 36px 26px;
  background-position: center 70px;
}

#policy-info .policy-approve-types .submit-approve-active,
#policy-info .policy-approve-types .single-active {
  background-color: #ffffff;
}

#policy-info .policy-approve-types .submit-approve-active,
#policy-info .policy-approve-types .submit-approve:hover {
  border: 1px solid #047de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-approve-single-active.png');
}

#policy-info .policy-approve-types .single-active,
#policy-info .policy-approve-types .single:hover {
  border: 1px solid #047de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-approve-single-active.png');
}

#policy-info .policy-approve-types .step {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-approve-step.png');
  background-repeat: no-repeat;
  background-size: 101px 26px;
  background-position: center 70px;
}

#policy-info .policy-approve-types .step-active,
#policy-info .policy-approve-types .step:hover {
  border: 1px solid #047de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-approve-step-active.png');
}

#policy-info .policy-approve-types .step-active {
  background-color: #ffffff;
}

#policy-info .policy-approver-list {
  background-color: #f3f5f7;
  padding: 24px 10px;
  display: inline-block;
  width: 98%;
  border-radius: 2px;
  text-align: center;
}

#policy-info .toggle-area {
  display: flex;
  flex: 1;
  align-items: center;
}

#policy-info .toggle-area input {
  margin-left: 10px;
}

.draggable_desc .skip-approve-desc {
  line-height: 25px;
  color: #9f9f9f;
  margin: 30px 0;
}

.draggable_desc .skip-approve-title {
  float: left;
  color: #555555;
  font-size: 14px;
  line-height: 20px;
  width: 200px;
}

.draggable_desc .skip-approve-toggle {
  float: left;
  width: calc(100% - 200px);
}

.draggable_desc {
  position: relative;
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: left;
  color: #7d7d7d;
  padding: 23px 0 0 0;
}

#policy-info .desc.approver_list .inner,
#policy-info .policy-approver-list .inner {
  display: inline-block;
  width: -webkit-calc(100% - 20px); /* for Chrome, Safari */
  width: -moz-calc(100% - 20px); /* for Firefox */
  width: calc(100% - 20px); /* for IE */
  height: auto;
  overflow-x: auto;
  white-space: nowrap;
}

#policy-info .policy-approver-list .inner {
  overflow-x: inherit !important;
}

#policy-info .policy-approver-list .inner .List {
  display: inline-block;
}

#policy-info .policy-approver-list div.final-bar {
  height: 140px;
  text-align: center;
  margin-bottom: -14px;
  display: inline-block;
  margin-right: 10px;
  border-left: solid 1px #dce2e7;
}

#policy-info .policy-approver-list div.approver-info {
  width: 108px;
  height: 118px;
  padding: 10px 5px;
  text-align: center;
  border-radius: 3px;
  display: inline-block;
  letter-spacing: -0.2px;
  margin-right: 10px;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #c6c6c6;
}

#policy-info .policy-approver-list .List div.approver-info {
  border: solid 1px #b4d5ff;
}

#policy-info .policy-approver-list .List div.approver-info .email {
  border-bottom: solid 1px #b4d5ff;
}

#policy-info .policy-approver-list div.approver-info .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  color: #000000;
  font-weight: bold;
  letter-spacing: -0.2px;
  margin-bottom: 5px;
  height: 20px;
}

#policy-info .policy-approver-list div.approver-info .email {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 12px;
  text-align: center;
  font-size: 10px;
  color: #7d7d7d;
  height: 30px;
  margin-bottom: 4px;
  border-bottom: 1px solid #c6c6c6;
}

#policy-info .policy-approver-list div.approver-info .limitation {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #000000;
  letter-spacing: -0.3px;
  padding-top: 8px;
  margin-left: 5px;
}

#policy-info .policy-approver-list div.approver-info .limitation-sub {
  font-size: 10px;
  position: relative;
  float: right;
}

#policy-info .policy-approver-list div.approver-info .limitation-admin {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  letter-spacing: -0.3px;
  padding-top: 8px;
}

#policy-info .policy-approver-list div.approver-info .role {
  line-height: 18px;
  font-size: 12px;
  margin-bottom: 12px;
  text-align: center;
  color: #000000;
}

#policy-info .policy-approver-list .plan-icon {
  width: 36px;
  height: 16px;
  line-height: 12px;
  border-radius: 10px;
  position: absolute;
  border: 1px solid #aaaaaa;
  margin: -19px 36px;
  background-color: #aaaaaa;
}

#policy-info .policy-approver-list .plan-icon span {
  color: #ffffff;
  font-size: 10px;
}

#policy-info .policy-member-final {
  text-align: center;
  width: 5%;
}

#policy-info .policy-member-td-1 {
  width: 15%;
  padding-left: 10px;
}

#policy-info .policy-member-td-2 {
  width: 10%;
}

#policy-info .policy-member-td-3 {
  width: 10%;
}

#policy-info .policy-member-td-approval-line {
  width: 15%;
  padding-left: 10px;
}
#policy-info .div-table-body .policy-member-td-approval-line {
  color: #101010;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.32px;
}

#policy-info .policy-member-td-erp-code {
  padding-left: 10px;
  width: 10%;
  text-align: right;
  cursor: text;
}

#policy-info .policy-member-td-dept-code {
  padding-left: 10px;
  width: 10%;
  text-align: right;
  cursor: text;
}

#policy-info .policy-member-td-limitation {
  width: 12%;
  text-align: right;
  padding: 0 0 0 15px;
  cursor: text;
}

#policy-info .policy-member-td-4 {
  width: 8%;
  text-align: center;
}

#policy-info .policy-member-td-4 .icon-trash-gray {
  float: none;
}

#policy-info .policy-member-td-4 div {
  margin: 0 auto;
}

#report-custom-fields #custom-fields,
#policy-info #custom-fields {
  padding: 15px;
  display: inline-block;
  width: 100%;
}

#report-custom-fields #custom-fields .label,
#policy-info #custom-fields .label {
  margin: 10px 0;
  font-size: 12px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #555555;
}

#report-custom-fields #custom-fields .title,
#policy-info #custom-fields .title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: left;
  color: #555555;
}

#custom-field-desc {
  padding: 0 20px 0 10px;
  font-size: 12px;
  overflow: hidden;
  display: block;
  border: 1px solid #d5d5d5;
  height: 36px;
  line-height: 35px;
  position: relative;
  border-radius: 2px;
}

#report-custom-fields #custom-fields .field-name {
  float: left;
  margin-top: 10px;
  width: 30%;
}

#policy-info #custom-fields .field-name {
  float: left;
  margin-top: 10px;
  width: 27%;
}

#report-custom-fields #custom-fields .field-type {
  width: calc(70% - 150px);
  float: left;
  margin-top: 10px;
  margin-left: 30px;
}

#policy-info #custom-fields .field-type {
  width: calc(60% - 150px);
  float: left;
  margin-top: 10px;
  margin-left: 30px;
}

#report-custom-fields #custom-fields .field-button-area {
  float: left;
  width: 10%;
  padding: 55px 30px 0 30px;
}

#policy-info #custom-fields .field-button-area {
  float: left;
  text-align: right;
  margin: 43px 40px 0px 15px;
}

#report-custom-fields .report-policy-custom-fields input.no-title,
#mypage .option input[type='text'].no-title,
#policy-info .option input[type='text'].no-title,
#policy-info .report-policy-custom-fields input.no-title,
#policy-info #custom-fields input#report-field-title.no-title {
  border: 1px solid #e65858;
}

#report-custom-fields .report-policy-custom-fields,
#policy-info .report-policy-custom-fields {
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border: solid 1px #e8e8e8;
}

#report-custom-fields .report-policy-custom-fields button,
#policy-info .report-policy-custom-fields button {
  margin-left: 10px;
}

#custom-fields .option-title {
  color: #555555;
  font-size: 13px;
  font-weight: bold;
}

#policy-info .report-policy-custom-fields .option-title {
  float: left;
  font-weight: bold;
  font-size: 14px;
  width: 20%;
  padding: 10px 10px 0 0;
  word-wrap: break-word;
  color: #555555;
}

#report-custom-fields .report-policy-custom-fields .option-title {
  float: left;
  font-weight: bold;
  font-size: 14px;
  width: 20%;
  height: 48px;
  line-height: 48px;
  word-wrap: break-word;
  color: #555555;
}

#report-custom-fields .report-policy-custom-fields .custom-body {
  display: inline-block;
  width: 70%;
}

#policy-info .report-policy-custom-fields .custom-body {
  display: inline-block;
  width: 72%;
}

#policy-info .report-policy-custom-fields .custom-body dt {
  width: 20%;
  font-size: 14px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #555555;
}

#report-custom-fields .report-policy-custom-fields .custom-body dt {
  width: 18%;
  margin-left: 2%;
  font-size: 14px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #555555;
  height: 48px;
  line-height: 48px;
}

#policy-info .report-policy-custom-fields .custom-body dt {
  line-height: 50px;
}

#report-custom-fields .report-policy-custom-fields .custom-body dd,
#policy-info .report-policy-custom-fields .custom-body dd {
  width: 80%;
}

#report-custom-fields .report-policy-custom-fields .remove,
#policy-info .report-policy-custom-fields .remove {
  float: right;
}

#report-custom-fields .report-policy-custom-fields .field-options,
#policy-info .report-policy-custom-fields .field-options {
  height: 22px;
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #e8e8e8;
}

#report-custom-fields .report-policy-custom-fields .field-options .switch,
#policy-info .report-policy-custom-fields .field-options .switch {
  float: left;
}

#report-custom-fields .report-policy-custom-fields .field-options .label {
  float: left;
  line-height: 20px;
  margin-left: 10px;
}

#policy-info .report-policy-custom-fields .field-options .label {
  float: left;
  line-height: 20px;
  margin-left: 20px;
}

#project-modal dl.project-desc {
  border-top: 1px solid #e7ecef;
  margin-top: 20px;
  padding-top: 20px;
}

#project-modal dd.option input[type='text'] {
  width: 90%;
  float: left;
  margin-right: 10px;
}

#project-modal dd div.option-list {
  height: 45px;
}

#project-modal dd div.option-list div.icon-x {
  float: left;
  margin-top: 12px;
}

#mypage .option:not(.card-login) input[type='text'],
#mypage .option:not(.card-login) input[type='password'] {
  font-size: 12px;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  padding: 7px 10px;
  line-height: 20px;
  width: 100%;
  width: -webkit-calc(100% - 22px); /* for Chrome, Safari */
  width: -moz-calc(100% - 22px); /* for Firefox */
  width: calc(100% - 22px); /* for IE */
}

#mypage #split-view #person-info .attendee-name p {
  padding: 5px;
  font-weight: 700;
  font-size: 18px;
  margin-top: 50px;
}

#mypage #split-view #spent-info {
  width: 100%; /*(fallback) */
  width: -webkit-calc(100% - 260px); /* for Chrome, Safari */
  width: -moz-calc(100% - 260px); /* for Firefox */
  width: calc(100% - 260px); /* for IE */
  left: 260px;
  min-width: 700px;
  position: relative;
}

#mypage #split-view #spent-info-container {
  padding: 27px 35px;
  background-color: #f7f8fa;
}

#mypage #split-view #spent-info .content tr.removed {
  background-color: rgba(237, 237, 237, 0.3);
  color: #9d9d9d !important;
}

#mypage #split-view #spent-info .content .card-transaction-td-2 .cancel_type_part {
  border: solid 1px #e65858;
  border-radius: 100px;
  color: #e65858;
  line-height: 18px;
  font-size: 9px;
  float: left;
  padding: 0 3px;
  margin-right: 5px;
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#mypage #split-view #spent-info .content .card-transaction-td-2 .cancel_type_all {
  border: solid 1px #e65858;
  border-radius: 100px;
  color: #ffffff;
  line-height: 18px;
  font-size: 9px;
  float: left;
  padding: 0 3px;
  margin-right: 5px;
  background-color: #e65858;
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#mypage #split-view #spent-info .content .transaction-row .card-transaction-td-2 p.report-title {
  font-size: 11px;
  letter-spacing: -0.2px;
  text-align: left;
  line-height: 20px;
  color: #9d9d9d;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .card-transaction-td-2 p.store {
  font-weight: 700;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  margin-right: 5px;
}

#mypage #split-view #spent-info .content .card-transaction-td-2 img {
  vertical-align: middle;
  margin-right: 3px;
}

#mypage #split-view #spent-info .content .card-transaction-td-3 {
  width: 8%;
}

#mypage #split-view #spent-info .content .card-transaction-td-3 p.address {
  font-size: 12px;
  color: #7d7d7d;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
}

#mypage #split-view #spent-info .content .card-transaction-td-4 {
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .transaction-row .card-transaction-td-4 {
  font-size: 13px;
}

#mypage #split-view #spent-info .content .card-transaction-td-5 {
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .transaction-row .card-transaction-td-5 {
  font-size: 14px;
}

#mypage #split-view #spent-info .content .card-transaction-td-6 {
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .transaction-row .card-transaction-td-6 {
  font-size: 14px;
}

#mypage #split-view #spent-info .content .card-transaction-td-6 p.subtotal {
  font-weight: 700;
}

#mypage #split-view #spent-info .content .card-transaction-td-7 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .card-transaction-td-7 p.time {
  color: #7d7d7d;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .card-transaction-td-8 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

#mypage #split-view #spent-info .content .card-transaction-td-9 span,
#mypage #split-view #spent-info .content .card-transaction-td-8 span {
  color: #7d7d7d;
  font-size: 11px;
}

#mypage #split-view #spent-info .content .transaction-row .price,
#mypage #split-view #spent-info .content .transaction-row .policy_name,
#mypage #split-view #spent-info .content .transaction-row .name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .transaction-row .price.cancel-all {
  color: #e65858;
  text-decoration-line: line-through;
}

#mypage #split-view #spent-info .content .transaction-row .card-transaction-td-8 {
  font-size: 12px;
}

#mypage #split-view #spent-info .content .card-transaction-td-9 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
}

#mypage #split-view #spent-info .content .transaction-row .card-transaction-td-9 {
  font-size: 12px;
}

#mypage #split-view #spent-info .content .bank-transaction-td-thumb img,
#mypage #split-view #spent-info .content .card-transaction-td-10 img {
  border: 1px solid #e5e8ea;
  border-radius: 2px;
}

#mypage #split-view #spent-info .content .card-transaction-td-10 {
  padding-right: 10px;
}

#mypage #split-view #spent-info .content .card-transaction-td-number {
  font-size: 11px;
  text-align: right;
  padding-right: 10px;
  color: #619cd8;
}

#mypage #split-view #spent-info .content .card-transaction-td-11 {
  white-space: nowrap;
  float: right;
}

#mypage #split-view #spent-info .content .transaction-row .card-transaction-td-11 {
  font-size: 14px;
}

#split-view #spent-info .content thead th {
  font-size: 11px !important;
}

#mypage #split-view #spent-info .content .refund-td-1 {
  width: 15%;
  padding-left: 13px;
}

#mypage #split-view #spent-info .content .refund-td-2 {
  width: 12%;
}

#mypage #split-view #spent-info .content .refund-td-3 {
  width: 12%;
}

#mypage #split-view #spent-info .content .refund-td-canceled {
  width: 10%;
}
#mypage #split-view #spent-info .content .refund-td-4 {
  width: 10%;
  text-align: right;
}

#mypage #split-view #spent-info .content .refund-td-5 {
  width: 15%;
  text-align: right;
  padding-right: 10px;
}

#mypage #split-view #spent-info .content .refund-td-6 {
  width: 15%;
  text-align: right;
  padding-right: 20px;
}

#mypage #categories * {
  transition: 0s !important;
}

#mypage #categories .toggle-area {
  display: flex;
  align-items: center;
  overflow: hidden;
}

#mypage #categories .toggle-area input {
  margin-left: 10px;
}

#mypage #categories {
  flex: 1;
}

#mypage #categories .option input[type='text'] {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px !important;
}

#mypage #split-view .group-title-div button {
  margin-top: 20px;
}

#mypage #split-view #group-simple-list {
  overflow-y: auto;
  height: -webkit-calc(100% - 250px);
  height: -moz-calc(100% - 250px);
  height: calc(100% - 250px);
  overflow-x: hidden;
}

#mypage #split-view .mypage-group-td-1 {
  width: 30%;
  font-size: 14px;
}

#mypage #split-view .mypage-group-td-2 {
  font-size: 14px;
}

#mypage #split-view .mypage-group-td-3 {
  width: 5%;
}

#mypage #split-view #group-simple-table {
  table-layout: fixed;
  border-top: 1px solid #ccc;
}

#mypage #split-view #group-simple-table {
  margin-top: 10px !important;
}

#mypage #split-view #group-simple-table .mypage-group-simple-td-1 {
  width: 80%;
  font-size: 13px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #group-simple-table .mypage-group-simple-td-2 {
  width: 5%;
}

#mypage #split-view #group-simple-table .mypage-group-simple-td-3 {
  width: 15%;
}

#mypage #split-view .set-group {
  padding: 10px 30px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
}

#mypage #split-view .set-group .select-box {
  width: 85%;
  float: left;
}

#mypage #split-view .set-group button {
  width: 10%;
  margin-left: 2%;
}

#personal-refund {
  display: flex;
  flex-direction: column;
}

#personal-refund .content {
  flex: 1;
  overflow: auto;
}

#split-view #spent-info .refund-row p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#personal-refund .content .refund-td-check,
#split-view #spent-info .content .refund-td-check {
  width: 15px;
  padding: 0 10px;
}

#personal-refund .content .refund-td-1,
#split-view #spent-info .content .refund-td-1 {
  width: calc(35% - 35px);
  width: -webkit-calc(35% - 35px); /* for Chrome, Safari */
  width: -moz-calc(35% - 35px); /* for Firefox */
  padding-left: 13px;
}

#personal-refund .content .refund-td-2,
#split-view #spent-info .content .refund-td-2 {
  width: 12%;
  text-align: center;
}

#personal-refund .content .refund-td-3,
#split-view #spent-info .content .refund-td-3 {
  width: 12%;
}

#personal-refund .content .refund-td-canceled,
#split-view #spent-info .content .refund-td-canceled {
  width: 10%;
  text-align: right;
}

#personal-refund .content .refund-td-4,
#split-view #spent-info .content .refund-td-4 {
  width: 10%;
  text-align: right;
}

#personal-refund .content .refund-td-5,
#split-view #spent-info .content .refund-td-5 {
  width: 15%;
  float: right;
  text-align: center;
  font-size: 11px;
  padding-right: 10px;
}

#personal-refund .content .refund-td-5 .btn-status,
#split-view #spent-info .content .refund-td-5 .btn-status {
  border-radius: 2px;
  background-color: #047de0;
  border: solid 1px #047de0;
  padding: 5px 8px;
  font-size: 11px;
  color: #fff;
  cursor: pointer;
}

#personal-refund .content .div-table-body-wrapper .refund-td-5 .refund_confirmed,
#split-view #spent-info .content .div-table-body-wrapper .refund-td-5 .refund_confirmed {
  color: #7d7d7d;
}

#personal-refund .content .refund-td-5 .refund-ready,
#split-view #spent-info .content .refund-td-5 .refund-ready {
  color: #047de0;
}

#personal-refund .content .refund-td-6,
#split-view #spent-info .content .refund-td-6 {
  width: 15%;
  text-align: right;
  padding-right: 20px;
}

#expense-add #menu-expense {
  width: calc(100% - 40px);
  background-color: rgb(243, 246, 250);
  border-radius: 2px;
  margin: 0 20px;
}

#expense-add #menu-expense li#navigation {
  text-align: right;
  position: absolute;
  right: 60px;
  height: 25px;
  top: 16px;
  background-color: transparent;
}

#expense-add #menu-expense li#navigation .pages {
  height: 25px;
  line-height: 25px;
  float: left;
  vertical-align: middle;
  padding: 0 10px;
  font-size: 13px;
  color: #022855;
}

#expense-add #menu-expense li {
  display: inline-block;
  background: #f0f2f4;
}

#expense-add .modal-title p {
  padding: 11px 32px;
}

#expense-add #expense-modal-split-left .dropzone {
  height: 420px;
}

#expense-add #expense-modal-split-left .left-button button {
  width: 100%;
  height: 35px;
  line-height: 33px;
  justify-content: center;
}

#expense-add #expense-modal-split-left {
  max-height: 550px;
}

#expense-add #expense-modal-split-left .bottom {
  border-top: 1px solid #ddd;
  width: 240px;
  margin-left: 10px;
  position: absolute;
  bottom: 0;
  padding: 25px 10px 50px 10px;
}

#expense-add #expense-modal-split-left .remove {
  border-top: 1px solid #ddd;
  width: 240px;
  margin-left: 140px;
  position: absolute;
  bottom: 0;
  padding: 25px 10px 50px 10px;
}

#expense-add #expense-modal-split-left .dropzone img {
  width: 50px;
  display: inline-block;
  margin-bottom: 10px;
}

#expense-add #expense-modal-split-left .dropzone.in-expense .text {
  text-align: center;
  height: 90%;
}

#expense-add #expense-modal-split-left .dropzone .text {
  text-align: center;
}

#expense-add #expense-modal-split-left .dropzone .text span {
  display: block;
  font-size: 10px;
  margin-top: -170px;
  color: #000;
  line-height: 1.5;
}

#expense-add #expense-modal-split-left .dropzone .text span.bold {
  font-weight: 700;
}

#expense-add #expense-modal-split-left .dropzone.true {
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  height: 400px;
  width: 250px;
  border-radius: 2px;
  border: 1px solid #f0f2f4;
}

#expense-add #expense-modal-split-left .doc-info {
  height: 36px;
  width: 36px;
  border-radius: 2px;
  position: absolute;
  bottom: 180px;
  line-height: 16px;
  padding: 0 5px;
  margin: 5px;
  cursor: pointer;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/doc-center/timestamp-blue.png ');
  background-size: 36px;
  background-repeat: no-repeat;
}

#expense-add .selected-items-table::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 0px !important;
}

/* expense-distance-modal */

#expense-distance-modal .select-type .title {
  font-weight: 700;
}

#expense-distance-modal input {
  width: 101px;
  cursor: pointer;
  font-size: 11px;
  left: 0;
  top: 0;
}

#expense-distance-modal .left {
  width: 300px;
  float: left;

  overflow-y: scroll;
  height: 540px;
}

#expense-distance-modal .route {
  line-height: 16px;
}

#expense-distance-modal .route dt {
  width: 21%;
  display: inline-block;
  font-size: 12px;
  color: #2058a2;
}

#expense-distance-modal .route dd {
  width: 79%;
  display: inline-block;
  font-size: 13px;
  text-overflow: ellipsis;
  margin-bottom: 6px;
}

/*#expense-add .expense-image-upload input {             !*input tag 공통 스타일*!*/
/*width: 100px;*/
/*height: 30px;*/
/*border-radius: 3px;*/
/*font-size: 13px;*/
/*color: #333;*/
/*border: 1px solid #333;*/
/*cursor: pointer;*/
/*}*/
#expense-add .left-button {
  padding: 10px 0;
}

#expense-add .left-button input.upload {
  opacity: 0; /*input type="file" tag 투명하게 처리*/
  left: 30px;
  height: 35px;
  filter: alpha(opacity=0);
  -ms-filter: 'alpha(opacity=0)';
  -khtml-opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  cursor: pointer;
}

#expense-add .left-button button.replace {
  /*button tag 에 원하는 스타일 적용*/
  position: absolute;
  /*width: 100px;*/
  /*height: 30px;*/
  /*border-radius: 3px;*/
  /*font-size: 13px;*/
  /*color: #333;*/
  /*border: 1px solid #333;*/
  cursor: pointer;
}

#expense-add .left-button button.delete {
  /*button tag 에 원하는 스타일 적용*/
  position: absolute;
  color: #e65858 !important;
  border: 1px solid #e65858 !important;
  cursor: pointer;
  padding-left: 25px;
}

spendit-modal#expense-add .left-button button.delete {
  position: absolute;
  cursor: pointer;
}

#expense-add .left-button button.modify {
  position: absolute;
  color: #047de0 !important;
  border: 1px solid #047de0 !important;
  cursor: pointer;
  padding-left: 25px;
}

spendit-modal#expense-add .left-button button.modify {
  position: absolute;
  cursor: pointer;
}

#expense-add .left-button button.modify input[type='file'] {
  display: none;
}

#expense-distance-modal .route input {
  width: 100% !important;
}

#expense-add #expense-modal-split-right .potential-match button {
  margin-left: 20px;
}

#expense-add #expense-modal-split-right .desc {
  padding: 11px 45px 60px 45px;
}

#expense-add .expense-button-bottom .expense-rule-check input[type='checkbox'] {
  width: 12px !important;
  height: 12px !important;
  margin-right: 8px;
}

#expense-add .expense-button-bottom {
  display: flex;
  width: 100%;
}

#expense-add .expense-button-bottom .expense-rule-check {
  text-align: left;
  color: #545454;
  font-size: 14px;
  height: 25px;
  padding-left: 5px;
}

#expense-add .expense-button-bottom #right span {
  font-size: 13px;
  margin: 0px;
}

#expense-add .expense-button-bottom #left,
#expense-add .expense-button-bottom #center,
#expense-add .expense-button-bottom #right {
  display: inline-block;
}

#expense-add .expense-button-bottom #left {
  display: flex;
  flex-basis: 25%;
}

#expense-add .expense-button-bottom #center span {
  margin-left: 3px;
}

#expense-add .expense-button-bottom #center {
  font-size: 13px;
  flex-basis: 25%;
  color: #545454;
}

#expense-add .expense-button-bottom #right {
  display: flex;
  flex-basis: 50%;
  justify-content: flex-end;
}

#expense-add .modal-content .modal-desc {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 0;
}

#expense-add #expense-modal-split-right dl {
  width: -webkit-calc(100% - 40px); /* for Chrome, Safari */
  width: -moz-calc(100% - 40px); /* for Firefox */
  width: calc(100% - 40px);
  margin: 0 auto;
  font-size: 14px;
  color: #1e1e1e;
  margin-bottom: 50px;
}

#expense-add #expense-modal-split-right dl dd,
#expense-add #expense-modal-split-right dl dt {
  display: inline-block;
  white-space: nowrap;
}

#expense-add #expense-modal-split-right dl dt {
  width: 18%;
  color: #545454;
  vertical-align: middle;
}

#expense-add #expense-modal-split-right dl dd {
  width: 380px;
  padding: 0 4px;
  vertical-align: middle;
  line-height: 0;
  transition: 0s;
}

#expense-add #expense-modal-split-right .spacer {
  width: 100%;
  height: 25px;
  display: block;
}

#expense-add #expense-modal-split-right dl input {
  z-index: 0 !important;
  background-color: transparent;
  outline: none;
}

#expense-add #expense-modal-split-right dl input::placeholder {
  color: #b7b7b7;
}

#expense-add #expense-modal-split-right dt.top-info .mandatory {
  margin-left: 3px;
  color: #057ee1;
  font-size: 14px;
}

#expense-add #expense-modal-split-right dt.top-info {
  color: #545454;
  font-size: 13px;
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#expense-add #expense-modal-split-right #edit-distance {
  display: inline-block;
  vertical-align: middle;
}

#expense-add #expense-modal-split-right dt.top-info span {
  font-size: 11px;
}

#expense-add #expense-modal-split-right dd.top-info input:disabled {
  background-color: #f9f9f9;
  color: #3f3f3f;
  padding: 6.5px 15px !important;
}
#expense-add #expense-modal-split-right dd.top-info input#vat,
#expense-add #expense-modal-split-right dd.top-info input#price {
  width: 150px;
  display: inline-block;
  vertical-align: middle;
}

#expense-add #expense-modal-split-right dd.top-info.round_trip {
  width: 10%;
  display: inline-block;
}

#expense-add #expense-modal-split-right dd.top-info .wage-per-day {
  line-height: 35px;
  font-size: 12px;
  color: #333333;
  display: inline-block;
  vertical-align: middle;
}

#expense-add #expense-modal-split-right dd.top-info span {
  font-size: 11px;
}

#expense-add #expense-modal-split-right dd.top-info .currency .Select-control {
  height: 37px;
}

#expense-add #expense-modal-split-right dd.top-info .currency .Select.is-open {
  z-index: 10000;
}

#expense-add #expense-modal-split-right dd.top-info.price-missing #input-select .list-body,
#expense-add #expense-modal-split-right dd.top-info.price-missing input {
  border: 1px solid #de3c3c;
}

#expense-add #expense-modal-split-right p.price-missing {
  font-size: 12px;
  color: #de3c3c;
  line-height: 20px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-policy.png');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: left;
  padding-left: 15px;
}

#expense-add #expense-modal-split-right dd.top-info .original {
  font-size: 12px;
  display: inline-block;
  color: #b5b5b5;
  line-height: 16px;
  margin-left: 15px;
  vertical-align: middle;
}

#expense-add #expense-modal-split-right dd.top-info .original span {
  font-weight: 700;
  margin-left: 5px;
  color: #7d7d7d;
}

#expense-add #expense-modal-split-right p.original.round_trip {
  display: inline-block;
  width: 60%;
  display: inline-block;
  width: 60%;
  font-size: 11px;
  line-height: 22px;
  height: 22px;
  margin-left: 20px;
}

#expense-add #expense-modal-split-right dd.top-info #store {
  width: 270px;
}

#expense-add #expense-modal-split-right dd.top-info #store-name input {
  font-size: 12px;
}

#expense-add #expense-modal-split-right dd.top-info#date {
  position: relative;
  height: 38px;
  padding-right: 10px;
}

#expense-add #expense-modal-split-right dd.top-info#date .with-btn {
  width: 400px;
}

#expense-add #expense-modal-split-right dd.top-info#date .picker-div {
  height: 36px;
  line-height: 30px;
}

#expense-add #expense-modal-split-right dd.top-info#date .picker-div-period .rdt,
#expense-add #expense-modal-split-right dd.top-info#date .picker-div .rdt {
  width: inherit !important;
  float: none !important;
}

#expense-add #expense-modal-split-right dd.top-info#date .picker-div input {
  position: absolute;
  top: 0;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/calendar-active.png');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: -webkit-calc(100% - 10px); /* for Chrome, Safari */
  background-position: -moz-calc(100% - 10px); /* for Firefox */
  background-position: calc(100% - 10px); /* for IE */
}

#expense-add #expense-modal-split-right dd.top-info#date .picker-div-period input {
  position: absolute;
  top: 0;
  font-size: 13px;
  padding: 7px 10px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/calendar-active.png');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: -webkit-calc(100% - 10px); /* for Chrome, Safari */
  background-position: -moz-calc(100% - 10px); /* for Firefox */
  background-position: calc(100% - 10px); /* for IE */
}

#expense-add #expense-modal-split-right dd.top-info#date .picker-div .rdt .rdtPicker,
#expense-add #expense-modal-split-right dd.top-info#date .picker-div-period .rdt .rdtPicker {
  top: 40px;
  position: absolute !important;
}

#expense-add #expense-modal-split-right dd.top-info#date .picker-div .icon-calendar-inactive,
#expense-add #expense-modal-split-right dd.top-info#date .picker-div .icon-calendar-active {
  display: none;
}

#expense-add #expense-modal-split-right dd.top-info#date .picker-div-period {
  display: inline-block;
  margin-right: 8px;
  width: 100%;
  border-radius: 6px;
  border: none;
}

#expense-add #expense-modal-split-right dd.top-info#date .icon-x-period {
  position: absolute;
  display: block;
  width: 12px;
  top: 12px;
  right: 13px;
  cursor: pointer;
  height: 12px;
  background-size: 12px;
}

#expense-add #expense-modal-split-right dd.top-info#date .icon-add-blue {
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  background-size: 100%;
  position: absolute;
  cursor: pointer;
  margin-left: 5px;
  top: 12px;
  right: 14px;
}

#expense-add #expense-modal-split-right #store-address {
  line-height: 20px;
  padding: 0 12px 0 0;
  margin-left: 115px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #999;
}

#expense-add #expense-modal-split-right .payment-list-icon {
  float: left;
  margin: 0 3px;
}

#expense-add #expense-modal-split-right .payment-list-div {
  line-height: 18px;
}

#expense-add #expense-modal-split-right .list-selected .payment-list-div {
  padding-top: 9px;
}

#policy-info .report-policy-custom-fields dd .dropdown-menu,
#report #report-custom-fields .custom-field .dropdown-menu,
#attendee-modal .dropdown-menu,
#expense-distance-modal .dropdown-menu,
#expense-add .dropdown-menu {
  position: absolute;
  z-index: 500;
  background-color: #fff;
  border: 1px solid #ddd;
  /*padding: 3px 15px 5px 15px;*/
  width: 100%;
  width: -webkit-calc(100% - 2px); /* for Chrome, Safari */
  width: -moz-calc(100% - 2px); /* for Firefox */
  width: calc(100% - 2px); /* for IE */
  border-radius: 2px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

#policy-info .report-policy-custom-fields dd .dropdown-menu > li:hover,
#report #report-custom-fields .custom-field .dropdown-menu > li:hover,
#attendee-modal .dropdown-menu > li:hover,
#expense-distance-modal .dropdown-menu > li:hover,
#expense-add .dropdown-menu > li:hover {
  background-color: rgba(4, 125, 224, 0.05);
}

#policy-info .report-policy-custom-fields dd .dropdown-menu > li,
#report #report-custom-fields .custom-field .dropdown-menu > li,
#attendee-modal .dropdown-menu > li,
#expense-distance-modal .dropdown-menu > li,
#expense-add .dropdown-menu > li {
  padding: 3px 10px;
}

#policy-info .report-policy-custom-fields dd .dropdown-menu > li > a > div > img,
#policy-info .report-policy-custom-fields dd .dropdown-menu > li > a > div > span,
#report #report-custom-fields .custom-field .dropdown-menu > li > a > div > img,
#report #report-custom-fields .custom-field .dropdown-menu > li > a > div > span,
#attendee-modal .dropdown-menu > li > a > div > img,
#attendee-modal .dropdown-menu > li > a > div > span,
#expense-distance-modal .dropdown-menu > li > a > div > img,
#expense-distance-modal .dropdown-menu > li > a > div > span,
#expense-add .dropdown-menu > li > a > div > img,
#expense-add .dropdown-menu > li > a > div > span {
  vertical-align: middle;
}

#policy-info .report-policy-custom-fields dd .dropdown-menu,
#report #report-custom-fields .custom-field .dropdown-menu > li > a,
#attendee-modal .dropdown-menu > li > a,
#expense-distance-modal .dropdown-menu > li > a,
#expense-add .dropdown-menu > li > a {
  text-decoration: none;
}

#policy-info .report-policy-custom-fields dd .dropdown-menu > li > a > div,
#report #report-custom-fields .custom-field .dropdown-menu > li > a > div,
#attendee-modal .dropdown-menu > li > a > div,
#expense-distance-modal .dropdown-menu > li > a > div,
#expense-add .dropdown-menu > li > a > div {
  line-height: 30px;
}

#expense-add #expense-modal-split-right .option {
  width: 100%; /*(fallback) */
  width: -webkit-calc(100% - 20px); /* for Chrome, Safari */
  width: -moz-calc(100% - 20px); /* for Firefox */
  width: calc(100% - 20px); /* for IE */
  height: 38px;
}

#expense-add #expense-modal-split-right dd.top-info#memo .option {
  width: 100% !important;
}

#expense-add #expense-modal-split-right #report .option {
  width: 282px;
  display: inline-block;
}

#expense-add #expense-modal-split-right #report .new-report {
  width: 110px;
  height: 38px;
  border-radius: 2px;
  background-color: #f7f7f7;
  border: solid 1px #d5d5d5;
  text-align: center;
  line-height: 38px;
  display: inline-block;
  margin: 0;
  margin-left: 4px;
}

#expense-auto .modal-body {
  height: 365px;
  display: inline-block;
}

.spendit-modal#user-card-assign-modal .save button:hover {
  background-color: #0f58ae;
  border: 1px solid #0f58ae;
}

.spendit-modal#user-card-assign-modal .save button {
  background-color: #047de0;
  border: 1px solid #047de0;
  color: #ffffff;
  width: 140px;
  height: 38px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}

.spendit-modal#user-card-assign-modal .save,
#expense-auto .save {
  background-color: #fff;
  margin-left: 5%;
  margin-right: 5%;
  text-align: right;
  padding: 20px 0;
  border-top: solid 1px #ededed;
  width: 90%;
  bottom: 0;
  display: inline-block;
}

#expense-auto #dropzone-only .dropzone,
#expense-auto #split-left .dropzone {
  border-radius: 2px;
  background-color: rgba(240, 242, 243, 0.3);
  border: 1px dashed #c9c9c9;
}

#expense-auto #dropzone-only .dropzone {
  width: 490px !important;
  height: 300px !important;
  margin: auto !important;
}

#expense-auto #dropzone-only .dropzone img {
  width: 490px;
  display: inline-block;
  margin-bottom: 10px;
}

#expense-auto #dropzone-only .dropzone .text > div {
  margin: 0 auto;
}

#expense-auto #dropzone-only .dropzone .text {
  text-align: center;
}

#expense-auto #dropzone-only .dropzone .text span,
#expense-auto #split-left .text span {
  display: block;
  font-size: 12px;
  color: #777;
  line-height: 20px;
}

#expense-auto #split-left {
  float: left;
}

#expense-auto #split-left .dropzone {
  width: 160px;
  height: 300px;
  margin: 30px;
}

#expense-auto #split-left .dropzone img {
  width: 160px;
  height: 300px;
  display: inline-block;
  margin-bottom: 10px;
}

#expense-auto #split-left .dropzone .text > div {
  margin: 0 auto;
}

#expense-auto #split-left .dropzone .text {
  text-align: center;
}

#expense-auto #split-right .modal-desc {
  padding: 0 25px;
  height: 305px;
  margin-top: 30px;
  overflow-y: auto;
}

#expense-auto #split-right {
  overflow-y: auto;
}

#expense-auto #split-right ul li:first-child {
  border-top: 1px solid #ededed;
}

#expense-auto #split-right ul li {
  font-size: 13px;
  width: 255px;
  border-bottom: 1px solid #ededed;
  padding: 10px 10px 10px 35px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/image-list.png');
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 10px;
}

#expense-auto #split-right ul li p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 230px;
  line-height: 20px;
}

#expense-auto #split-right ul li div.icon-x {
  float: right;
  margin-top: -15px;
}

#report-share ul {
  margin: 20px 0;
}

#report-share input[type='text'] {
  z-index: 0 !important;
  font-size: 12px;
  border: 1px solid #d4d4d3;
  border-radius: 2px;
  padding: 7px 10px;
  line-height: 20px;
  width: calc(100% - 22px);
}

#report-share ul li {
  line-height: 28px;
  display: flex;
  align-items: center;
}

#report-share ul li p {
  font-weight: 700;
}

#report-share ul li input {
  margin-right: 5px;
}

#report-share dl {
  width: 100%;
  font-size: 14px;
  line-height: 45px;
  color: #545454;
}

#report-share dt {
  display: inline-block;
  vertical-align: middle;
  width: 20%;
  padding: 0 3px;
  line-height: 15px;
}

#report-share dd {
  display: inline-block;
  vertical-align: middle;
  width: 78%;
}

.spendit-modal#add-policy .modal-desc p {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.spendit-modal#add-policy #policy-field p {
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.spendit-modal#add-policy .modal-desc .policy-type {
  display: inline-block;
  height: 100%;
  width: 49%;
  margin: 2px;
  cursor: pointer;
}

.spendit-modal#add-policy .modal-desc .policy-type img {
  width: 100%;
}

.spendit-modal#add-policy .modal-desc .policy-type:hover {
  background-color: #f8f8f8;
}

.spendit-modal#invite-member #invitation-error {
  font-size: 14px;
  margin-top: 10px;
  color: #de3c3c;
}

.spendit-modal#invite-member .modal-desc p {
  font-size: 14px;
  margin-bottom: 20px;
}

.spendit-modal#invite-member .modal-desc .modal-main-desc .text-style-1 {
  font-size: 14px;
  margin-bottom: 10px;
}

.spendit-modal#invite-member .modal-desc .modal-main-desc .text-style-2 {
  font-size: 12px;
  margin-bottom: 10px;
  color: #7d7d7d;
}

.spendit-modal#invite-member .modal-desc .modal-main-desc .text-style-3 {
  font-size: 12px;
  margin-bottom: 5px;
  color: #de3c3c;
}

.spendit-modal#invite-member .modal-desc .Select-arrow-zone {
  display: none;
}

.spendit-modal#invite-member .modal-desc .csv_upload {
  display: flex;
  width: calc(100% - 30px);
  padding: 15px;
  position: relative;
  float: left;
  margin: 10px 0;
  border: solid 1px #cecece;
  border-radius: 2px;
  background-color: #fafafa;
}

.spendit-modal#invite-member .modal-desc .csv_upload input {
  display: none;
}

.spendit-modal#invite-member .Select .Select-control {
  border: 1px solid rgb(212, 212, 212);
  border-radius: 6px;
}

.spendit-modal#invite-member .is-searchable.is-open > .Select-control {
  box-shadow: rgb(181, 211, 255) 0px 0px 0px 1px;
}

#attendee-modal dl dd input[type='text'] {
  width: calc(100% - 22px);
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 8px 10px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 20px;
}

#attendee-modal .no-addable-attendee {
  color: #de3c3c;
  font-size: 12px;
}

#attendee-modal dl {
  width: 100%;
  font-size: 14px;
  line-height: 45px;
  color: #545454;
}

#attendee-modal dl dt {
  width: 100px;
  display: inline-block;
  vertical-align: middle;
  width: 20%;
  padding: 0 3px;
  line-height: 15px;
}

#attendee-modal dl dd {
  width: 440px;
  display: inline-block;
  vertical-align: middle;
}

#attendee-modal .edit-area dl dd {
  width: 340px;
}

#attendee-modal .edit-area .right-area {
  width: 450px;
  float: left;
}

#attendee-modal .edit-area .left-area {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 250px;
  margin-right: 50px;
  float: left;
  margin-top: 12px;
}

#attendee-modal .edit-area .left-area .image {
  width: 100%;
  height: 250px;
  border-radius: 2px;
  border: solid 1px #cecece;
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#attendee-modal .edit-area .left-area .no-image {
  width: 100%;
  height: 250px;
  border-radius: 2px;
  border: solid 1px #cecece;
  float: left;
  background-size: 49.9px 33.2px;
  background-repeat: no-repeat;
  background-position: 100px 80px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/attendees/no-image.png');
}

#attendee-modal .edit-area .left-area .image .layer {
  width: 250px;
  height: 250px;
  position: absolute;
  background-color: #000000;
  opacity: 0.4;
}

#attendee-modal .edit-area .left-area .no-image p {
  font-size: 12px;
  color: #b5b5b5;
  text-align: center;
  margin: 120px 0;
}

#attendee-modal .edit-area .left-area .image .top-area {
  width: 250px;
  height: 26px;
  float: right;
  cursor: pointer;
  position: absolute;
}

#attendee-modal .edit-area .left-area .image .top-area .delete-area,
#attendee-modal .edit-area .left-area .image .top-area .edit-area {
  position: absolute;
  width: 14px;
  height: 14px;
  padding: 6px;
  border-radius: 2px;
  opacity: 0.3;
  background-color: #000000;
}

#attendee-modal .edit-area .left-area .image .top-area .delete-area {
  right: 0;
}

#attendee-modal .edit-area .left-area .image .top-area .edit-area {
  right: 24px;
}

#attendee-modal .edit-area .left-area .image .top-area .delete,
#attendee-modal .edit-area .left-area .image .top-area .edit {
  width: 14px;
  height: 14px;
  position: absolute;
  padding: 5px;
  z-index: 100;
}

#attendee-modal .edit-area .left-area .image .top-area .delete {
  right: 0;
}

#attendee-modal .edit-area .left-area .image .top-area .edit {
  right: 24px;
}

#attendee-modal .edit-area .left-area .image .top-area .edit:hover {
  cursor: pointer;
}

#attendee-modal .edit-area .left-area .image .top-area .delete:hover {
  cursor: pointer;
}

#attendee-modal .edit-area .left-area #upload {
  display: none;
}

#attendee-modal .edit-area .left-area .upload-button {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 30px;
  height: 18px;
  line-height: 14px;
  border-radius: 2px;
  border: solid 1px #97a9c0;
  float: left;
  margin: 11px 0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/btn-transaction-upload.png');
  background-size: 18px 12px;
  background-position: 8px;
  background-repeat: no-repeat;
  cursor: pointer;
  width: fit-content;
}

#attendee-modal .edit-area .left-area .upload-button span {
  font-size: 12px;
  color: #657c97;
}

#attendee-modal .button-bottom {
  float: left;
  width: 100%;
  height: 40px;
  padding-top: 10px;
}

#attendee-modal .edit-area {
  width: 750px;
  display: inline-block;
}

#attendee-modal .button-area {
  margin-bottom: 20px;
}

#attendee-modal .attendee-limit-left {
  float: left;
  width: 100px;
}

#attendee-modal .attendee-limit-right input {
  text-align: right;
  width: 315px;
}

#attendee-modal .attendee-limit-right {
  float: left;
  width: 300px;
}

#period-select #period-body {
  width: 100%;
  text-align: center;
}

#period-select p.period-label {
  line-height: 30px;
  font-size: 12px;
  text-align: left;
  width: 170px;
  font-weight: bold;
}

#report-history-modal .report-history-td-1 {
  padding: 0 10px;
}

#report-history-modal .report-history-td-2 {
  width: 70%;
}

#report-history-modal .report-history-td-3 {
  text-align: left;
  width: 20%;
  padding: 0 10px;
}

#categories {
  height: 100%;
}

#categories .sub-category-name {
  line-height: 22px;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
}

#categories .category-td-1 {
  width: 7%;
  padding-left: 2%;
}

#categories .double.category-td-1 {
  width: 3%;
}

#categories .double.category-td-2 {
  width: 20%;
}

#categories .category-td-2 {
  width: 35%;
  padding-left: 5px;
}

#categories .category-td-2 > div {
  flex: 1;
  overflow-x: visible;
}

/* category */
#categories .category-td-2 .option.name.unclicked {
  display: flex;
  flex: 1;
  align-items: center;
}

#categories .category-td-2 .name #categories .category-td-2 p {
  display: inline-block;
}

#categories .category-td-2 input {
  width: 60% !important;
  height: 30px !important;
  padding: 0 10px !important;
}

#categories .double.category-td-3 {
  width: 15%;
}

#categories .category-td-3 > div {
  flex: 1;
}

#categories .category-td-3 {
  width: 35%;
}

#categories .category-td-3 input {
  height: 30px !important;
  padding: 0 10px !important;
}

#categories .category-td-3 button {
  float: left;
  margin: 0 1% !important;
}

#categories .double.category-td-4,
#categories .double.category-td-5 {
  width: 5%;
}

#categories .category-td-4 {
  width: 3%;
  padding-right: 5px;
}

#categories .category-td-5 {
  width: 3%;
  padding-right: 5px;
}

#categories .sub-category-td-2 .unclicked {
  width: 60%;
}

#categories .sub-category-td-3 .unclicked {
  width: 80%;
}

#categories .sub-category-td-2 .switch {
  float: left;
  margin-right: 10px;
}

#categories .sub-category-td-2 .clicked {
  float: left;
  width: 60%;
}

#categories .sub-category-td-3 .clicked {
  float: left;
  width: 80%;
}

#categories .sub-category-td-2 span div {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#categories .sub-category-td-2 {
  color: #333333;
  cursor: text;
}

#categories .sub-category-td-3 {
  width: 20%;
  cursor: text;
}

#categories .category-td-4 div,
#categories .sub-category-td-4 div,
#categories .sub-category-td-5 div {
  margin: 0 auto;
}

#categories .sub-category-td-4,
#categories .sub-category-td-5 {
  text-align: center;
}

#categories .sub-category-td-5 {
  padding-right: 5px;
}

#categories .sub-category-td-4 button {
  width: 60px;
}

#expense .wrap#map {
  flex: 1;
  padding: 0 35px;
  overflow: auto;
}

#expense #map .fixed-map {
  position: fixed;
  width: 450px;
  top: 128px;
}

#expense .no-filter #map .fixed-map-custom {
  left: 35px;
  padding-top: 25px;
}

#expense #map .fixed-map-custom {
  position: fixed;
  float: left;
  width: 500px;
  padding-top: 25px;
  left: 270px;
}

#expense #map #moving-table {
  margin-left: 530px;
}

#expense #map #moving-table .no-list {
  padding-top: calc(20% - 45px);
}

#expense #map table > th {
  font-size: 12px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #7d7d7d;
}

#expense #map .name,
#expense #map .price,
#expense #map .spent-at {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#expense #map .expense-map-td-1 {
  width: 5%;
  text-align: center;
  font-size: 13px;
  letter-spacing: -0.3px;
  color: #6a6a6a;
  padding-left: 10px;
}

#expense #map .expense-map-td-2 {
  width: 30%;
  letter-spacing: -0.3px;
  color: #1e1e1e;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#expense #map .expense-map-td-3 {
  width: 17%;
  text-align: right;
  padding-right: 15px;
}

#expense #map .div-map-body .expense-map-td-3 {
  color: #047de0;
}

#expense #map .expense-map-td-4 {
  width: 17%;
}

#expense #map .expense-map-td-5 {
  width: 12%;
  text-align: center;
}

#expense #map .expense-map-td-6 button {
  padding: 0 10px;
  white-space: nowrap;
}

#expense #map .expense-map-td-6 {
  width: 12%;
  text-align: center;
}

.daum-info-div {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 150px;
  height: 80px;
  background: #fff;
  border-radius: 5px;
  border-bottom: 2px solid #ccc;
  border-right: 1px solid #ccc;
  overflow-wrap: break-word;
  display: inline-block;
}

.daum-info-div .hr {
  display: block;
  width: 180%;
}

.daum-info-div .merchant-name {
  padding: 8px 5px;
  text-align: left;
  width: 140px;
  font-size: 12px;
  font-weight: bold;
  border-bottom: solid 0.5px #e8e8e8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.daum-info-div .location-info {
  font-size: 12px;
  padding: 8px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #101010;
}

.daum-info-div .location-info span {
  color: #e35b57;
  margin-left: 10px;
}

#road-view {
  margin: 0 auto;
}

#road-view .left {
  width: 415px;
  height: 415px;
  margin-bottom: 20px;
  float: left;
}

#road-view .right {
  width: 415px;
  height: 415px;
  margin-bottom: 20px;
  margin-left: 20px;
  float: left;
}

.list-body #list {
  /*left: -1px;*/
}

.list-body #list li a {
  color: #777 !important;
}

#filter-select .disabled .list-body {
  /*background-color: #eee !important;*/
}

#expense-split .image-table {
  width: 230px;
  height: 400px;
  margin: 0 auto;
  text-align: center;
  float: left;
}

#expense-split .image-table img {
  width: 200px;
  height: 350px;
  float: left;
}

#expense-split .split-table {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 350px;
  max-height: 400px;
  padding: 0 20px;
}

#expense-split table thead tr {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: #7d7d7d;
  height: 20px;
}

#expense-split table tr {
  height: 60px;
  font-size: 14px;
}

#expense-split table .split-td-1 {
  width: 2%;
}

#expense-split table .split-td-2 {
  padding: 5px;
  width: 35%;
}

#expense-split table .split-td-3 {
  padding: 5px;
  width: 35%;
}

#expense-split table .split-td-4 input {
  text-align: right;
}

#expense-split table .split-td-4 {
  padding: 5px 10px 5px 5px;
  text-align: right;
  width: 20%;
}

#expense-split table .split-td-5 {
  width: 5%;
  padding-left: 5px;
}

#expense-split table tr.original td {
  height: 60px;
  background-color: #f9f9f9;
}

#expense-split table tr.original:hover td {
  background-color: #f9f9f9;
}

#expense-split table tr:hover {
  background-color: #ffffff;
}

#expense-split table tr.bottom {
  border-bottom: none;
}

#expense-split .bottom-left {
  float: left;
  width: 20%;
  padding: 10px;
}

#expense-split .bottom-left.add div {
  background-size: 10px;
  width: 10px;
  height: 10px;
}

#expense-split .bottom-left.add {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  border: solid 1.5px #047de0;
  margin-top: 5px;
}

#expense-split .bottom-right {
  float: right;
  width: 70%;
  padding: 10px;
}

#expense-split p#label {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #7d7d7d;
  text-align: right;
}

#expense-split p#total-amount {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.07;
  letter-spacing: -0.2px;
  color: #1e1e1e;
  text-align: right;
}

#report-submit-modal #submit-approve > ul {
  display: flex;
}

#report-submit-modal #submit-approve > ul #filter-select .list-selected {
  height: 27px !important;
}

#report-submit-modal #submit-approve > ul #filter-select .list-body {
  height: 28px !important;
  line-height: 28px !important;
}

#report-submit-modal #submit-approve > ul .select-div {
  width: 180px;
  float: left;
  margin-right: 7px;
}

#report-submit-modal #submit-approve > ul li.left {
  flex: 1;
  padding: 10px;
  border-right: 1px solid #e8e8e8;
  vertical-align: top;
}

#report-submit-modal #submit-approve > ul li.left.policy-approver {
  border-right: 0;
}

#report-submit-modal #submit-approve > ul li.right {
  padding: 10px 20px;
  width: 184px;
}

#report-submit-modal #submit-approve .sub-title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: #7d7d7d;
  margin-bottom: 10px;
}

.inspection-dropdown-menu {
  position: absolute;
  z-index: 500;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 3px 15px 5px 15px;
  width: 385px;
  border-radius: 2px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.inspection-dropdown-hide {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
  padding: 0;
}

.spendit-modal#report-add-expense #search-area {
  border-bottom: 1px solid #ddd;
}

.spendit-modal#report-add-expense #search-area ul li {
  margin-right: 30px;
}

.spendit-modal#report-add-expense #search-area ul li:last-child {
  margin-right: 0;
}

.spendit-modal#report-add-expense #search-area ul li,
.spendit-modal#report-add-expense #search-area ul li .option {
  display: inline-block;
  vertical-align: top;
}

.spendit-modal#report-add-expense #search-area ul li .option input {
  margin-bottom: 25px;
  border: 1px solid #cfcfcf;
}

.spendit-modal#report-add-expense .bottom {
  text-align: right;
}

.spendit-modal#report-add-expense #search-area ul li .option#calendar {
  margin-right: 10px;
}

.spendit-modal#report-add-expense #search-area ul li .option .filter-picker-div .rdt input {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/calendar-active.png');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 130px center;
}

.spendit-modal#report-add-expense #search-area ul li .option .filter-picker-div .rdt .rdtPicker {
  height: 300px;
}

.spendit-modal#report-add-expense .expenses-body {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20px;
}

.spendit-modal#report-add-expense .expenses-body .no-expense {
  color: #7d7d7d;
  font-size: 13px;
  text-align: center;
  padding: 3px;
  float: left;
  width: 100%;
}

.spendit-modal#report-add-expense .expenses-body table thead {
  background-color: #ffffff;
  position: absolute;
  width: 850px;
  top: 118px;
  color: #7d7d7d;
  font-size: 11px;
  border-bottom: 1px solid #b6b6b6;
}

.spendit-modal#report-add-expense .expenses-body .div-pagination {
  margin-top: 10px;
}

.spendit-modal#report-add-expense .expenses-body table tbody {
  margin-top: 100px;
}

.spendit-modal#report-add-expense .expenses-body table thead .report-add-expense-td-3 {
  font-weight: normal !important;
  padding: 5px 0;
}

.spendit-modal#report-add-expense .expenses-body table thead .report-add-expense-td-4 {
  padding: 5px 0;
}

.spendit-modal#report-add-expense .expenses-body table thead .report-add-expense-td-5 {
  padding: 5px 0;
}

.spendit-modal#report-add-expense .expenses-body table thead tr {
  display: flex;
}

.spendit-modal#report-add-expense .expenses-body table .report-add-expense-td-1 {
  width: 5%;
  padding-left: 10px;
}

.spendit-modal#report-add-expense .expenses-body table .report-add-expense-td-2 {
  width: 5%;
}

.spendit-modal#report-add-expense .expenses-body table .report-add-expense-td-3 {
  width: 40%;
  font-weight: bold;
}

.spendit-modal#report-add-expense .expenses-body table .report-add-expense-td-4 {
  width: 25%;
  text-align: right;
  padding-right: 10px;
}

.spendit-modal#report-add-expense .expenses-body table .report-add-expense-td-5 {
  width: 15%;
  text-align: right;
}

.spendit-modal#report-add-expense .expenses-body table .report-add-expense-td-6 {
  width: 5%;
}

.spendit-modal#report-add-expense .expenses-body table .report-add-expense-td-6 div {
  margin: 0 auto;
}

.spendit-modal#report-add-expense .expenses-body table .tr-selected {
  background-color: rgba(255, 255, 255, 0.8);
}

#potential-match table {
  font-size: 14px;
}

#potential-match table .potential-match-td-1 {
  padding-left: 10px;
}

#potential-match table td.potential-match-td-3 {
  color: #047de0;
}

.spent-info-container .refund-summary {
  width: -webkit-calc(100% - 400px);
  width: -moz-calc(100% - 400px);
  width: calc(100% - 400px);
}

#card-transaction-title .filter-box {
  float: left;
  position: absolute;
  padding-left: 50px;
  width: 700px;
}

#card-transaction-title .left {
  float: left;
  position: absolute;
  padding-left: 50px;
}

#card-transaction-title .filter {
  position: absolute;
  padding-left: 50px;
}

#expense-rule {
  overflow-y: auto;
  overflow-x: hidden;
  /*height: 350px;*/
}

#expense-rule dl dt {
  font-size: 12px;
  line-height: 13px;
  font-weight: bold;
  width: 25%;
}

#expense-rule dl dd {
  font-size: 12px;
  line-height: 13px;
  font-weight: bold;
  width: 70%;
}

#expense-rule .expense-rule-table {
  margin-top: 20px;
}

#expense-rule .expense-rule-table tr td {
  font-size: 14px;
}

#expense-rule .expense-rule-table .expense-rule-modal-td-1 {
  width: 20%;
}

#expense-rule .expense-rule-table .expense-rule-modal-td-2 {
  width: 10%;
}

#expense-rule .expense-rule-table .expense-rule-modal-td-3 {
  width: 10%;
}

#expense-rule .expense-rule-table .expense-rule-modal-td-4 {
  width: 15%;
}

#expense-rule .expense-rule-table .expense-rule-modal-td-5 {
  width: 20%;
}

#expense-rule .expense-rule-table .expense-rule-modal-td-6 {
  width: 10%;
  text-align: center;
}

.spendit-modal .modal-content.expense-rule {
  top: 100px;
  transform: none;
}

#expense-rules .spendit-modal .Select-control {
  border-radius: 6px !important;
  border: 1px solid #cccccc !important;
}

#expense-rules .spendit-modal .Select-value .Select-value-label {
  color: #545454 !important;
}

#user-modal .user-description input {
  width: 150px;
}

#user-modal .user-description.modal-desc button {
  margin-right: 0px;
}

#user-modal .user-description dl {
  width: 100%;
  font-size: 14px;
  line-height: 45px;
  color: #545454;
}

#card-modal .card-numbers span {
  margin: 12px;
  font-size: 15px;
}

#card-modal .message {
  color: #de3c3c;
}

#card-modal .card-numbers input {
  width: 52px !important;
  text-align: center;
}

#scraping-card-modal {
}

#scraping-card-modal:before,
#scraping-card-modal:after {
  clear: both;
  content: '';
  display: table;
}

#scraping-card-modal .left-area {
  width: 296px;
  height: 100%;
  background-color: #edf3f7;
  float: left;
}

#scraping-card-modal .left-border {
  width: 260px;
  float: left;
  border: solid 1px #ffffff;
  margin: 0 16px 23px 16px;
}

#scraping-card-modal .left {
  width: 250px;
  float: left;
  text-align: left;
  margin: 23px;
  height: 140px;
  border-radius: 5px;
  background-image: linear-gradient(to left, #5a7eac, #4a6c99);
  box-shadow: 0 8px 8px 0 #cad0d4;
}

#scraping-card-modal .left .card-detail {
  padding: 2px 20px 20px 20px;
}

#scraping-card-modal .left .card-detail div {
  width: 70%;
  float: left;
}

#scraping-card-modal .left .top-section {
  float: left;
  height: 50px;
}

#scraping-card-modal .left .top-section .card-info {
  padding: 0 20px 30px 20px;
  font-size: 11px;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  width: 210px;
}

#scraping-card-modal .left .top-section .card-info p {
  font-weight: bold;
  float: left;
}

#scraping-card-modal .left .top-section .card-info .card-type {
  float: left;
}

#scraping-card-modal .left .top-section .card-info .divide {
  font-size: 11px;
  padding: 0 5px;
  float: left;
}

#scraping-card-modal .left .top-section .card-logo {
  float: right;
}

#scraping-card-modal .left .middle-section {
  float: left;
  height: 28.4px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 33.1px 28.4px;
  background-position: 20px;
  margin-bottom: 5px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/user-card/card-chip.png');
}

#scraping-card-modal .left .bottom-section {
  float: left;
  padding-left: 20px;
  color: #ffffff;
}

#scraping-card-modal .left .bottom-section .number {
  height: 24px;
  font-size: 16px;
  line-height: 24px;
}

#scraping-card-modal .left .bottom-section .card-type {
  height: 15px;
  font-size: 10px;
  line-height: 15px;
}

#scraping-card-modal .left .card-name {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: left;
  color: #222222;
}

#scraping-card-modal .left .card-type .card-name {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: #222222;
}

#scraping-card-modal .left .card-type .card-logo {
  float: right;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

#scraping-card-modal .left .card-detail .user-card-number {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #292929;
}

#scraping-card-modal .left .card-detail .user-card-name {
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: #292929;
}

#scraping-card-modal .left .hologram {
  padding-left: 20px;
  width: 35px;
  height: 30.1px;
}

#scraping-card-modal dl {
  width: 350px;
}

#scraping-card-modal dl dt {
  letter-spacing: -0.2px;
  color: #545454;
  width: 150px;
}

#scraping-card-modal dl dd {
  text-align: left;
  width: 190px;
}

#scraping-card-modal .card-setting {
  font-weight: bold;
  font-size: 12px;
  padding-left: 19px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/user-card/card-set.png');
  background-size: 12px 12px;
  background-position: left;
  background-repeat: no-repeat;
  height: 18px;
  float: left;
  line-height: 18px;
  width: 100%;
  margin-bottom: 10px;
}

#scraping-card-modal .card-limit-left {
  float: left;
  width: 50px;
  padding: 12px 0;
}

#scraping-card-modal .card-limit-right {
  float: left;
  line-height: 45px;
}

#scraping-card-modal #left {
  width: 254px;
  height: 281px;
  margin: 0 23px;
  float: none;
  margin-top: 219px;
}

#scraping-card-modal #left dd input {
  border: 1px solid #99afcd;
}

#scraping-card-modal #left dd {
  text-align: left;
}

#scraping-card-modal #left dt {
  font-size: 12px;
  color: var(--greyish-brown);
}

#scraping-card-modal #left .info-desc input {
  border: 1px solid #b4c0cc;
  float: left;
}

#scraping-card-modal #left .info-desc {
  text-align: left;
  float: left;
  width: 100%;
}

#scraping-card-modal #left .info-title {
  font-size: 12px;
  color: var(--greyish-brown);
  line-height: 18px;
  height: 18px;
  float: left;
  width: 100%;
  padding: 5px 0px;
}

#scraping-card-modal #left .card-info {
  font-weight: bold;
  font-size: 12px;
  padding-left: 19px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/user-card/card-info.png');
  background-repeat: no-repeat;
  background-size: 13px 11px;
  background-position: left;
  height: 18px;
  float: left;
  line-height: 18px;
  width: 100%;
  margin-bottom: 17px;
}

#scraping-card-modal .switch-area {
  float: right;
}

/* price info */
#price-info .price-info-td-1 {
  width: 50%;
  padding-left: 10px;
}

#price-info .price-info-td-2 {
  width: 25%;
}

#price-info .price-info-td-3 {
  text-align: right;
  padding-right: 10px;
  width: 25%;
}

#price-info #auto-scan-history .history-td-1 {
  width: 25%;
  padding-left: 10px;
}

#price-info #auto-scan-history .history-td-2 {
  text-align: right;
  width: 25%;
}

#price-info #auto-scan-history .history-td-3 {
  text-align: right;
  width: 25%;
}

#price-info #auto-scan-history .history-td-4 {
  text-align: right;
  padding-right: 10px;
  width: 25%;
}

#expense-rules .content {
  padding-top: 80px;
  height: 100%;
  box-sizing: border-box;
}

#policy-project .top-area {
  height: 120px;
  z-index: 60;
  width: 100%;
  margin: 0 auto;
}

#policy-project .button-search-area {
  width: 100%;
  height: 55px;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

#policy-project .button-area p {
  margin: 20px auto 0 auto;
  font-size: 12px;
  line-height: 15px;
  color: #333;
  background: rgba(250, 250, 250, 0.8);
}

#policy-project .search-area {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 25px;
  height: 30px;
  line-height: 90px;
}

#policy-project .search-area input[type='text']:focus {
  background-color: transparent !important;
  border: 0 solid !important;
  color: #000000 !important;
}

#policy-project .search-area input[type='text']:focus,
#policy-project .search-area input[type='text'] {
  position: relative;
  float: left;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #000000;
  left: calc(50% - 15%);
  left: -moz-calc(50% - 15%);
  left: -webkit-calc(50% - 15%);
  width: 30%;
  height: 30px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-repeat: no-repeat;
  background-position: 95%;
  background-size: 20px;
  border-bottom: solid 2px #002855 !important;
}

#policy-project .btn-icon#btn-trash {
  border: 1px solid #d32f2f;
  color: #d32f2f;
}

#policy-project .search-area .imported-at {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/refresh-transaction.png');
  background-position: left center;
  background-size: 14px 18px;
  background-repeat: no-repeat;
  float: right;
  margin-right: 30px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
  padding-left: 20px;
  color: #657c97;
}

#expense-rules .button-area {
  width: -webkit-calc(100% - 70px);
  width: -moz-calc(100% - 70px);
  width: calc(100% - 70px);
  padding: 25px 0;
  background-color: #f7f8fa;
  z-index: 100;
  position: fixed;
  border-bottom: 1px solid #efefef;
}

#expense-rules .button-area p {
  margin: 20px auto 0 auto;
  font-size: 12px;
  line-height: 15px;
  color: #333;
  background: rgba(250, 250, 250, 0.8);
}

#expense-rules .expense-rule-td-1 {
  width: 15px;
  padding: 0 10px;
}

#expense-rules .div-table-body-wrapper .expense-rule-td-2 {
  font-weight: bold;
}

#expense-rules .expense-rule-td-2 p,
#expense-rules .expense-rule-td-3 p,
#expense-rules .expense-rule-td-4 p,
#expense-rules .expense-rule-td-5 p,
#expense-rules .expense-rule-td-6 p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#expense-rules .expense-rule-td-2,
#expense-rules .expense-rule-td-3,
#expense-rules .expense-rule-td-4,
#expense-rules .expense-rule-td-5,
#expense-rules .expense-rule-td-6 {
  margin-right: 10px;
  width: 15%;
}

#expense-rules .expense-rule-td-7 {
  width: -webkit-calc(25% - 100px);
  width: -moz-calc(25% - 100px);
  width: calc(25% - 100px);
  padding-right: 10px;
  text-align: right;
}

#personal-accounts th.account-td-1 {
  vertical-align: bottom;
}

#personal-accounts .account-td-1 {
  width: 5%;
  padding-left: 10px;
  line-height: 24px;
}

#personal-accounts .account-td-2 {
  width: 45%;
  padding-left: 10px;
}

#personal-accounts td.account-td-2 {
  font-weight: bold;
}

#personal-accounts .account-td-3,
#personal-accounts .account-td-4 {
  width: 15%;
}

#personal-accounts .account-td-5 {
  width: 15%;
  text-align: center;
}

#personal-accounts .account-td-6 {
  width: 5%;
}

/* help bar */
/*.spendit-container .title p {*/
/*float: left;*/
/*}*/

/*.spendit-container .title .help div {*/
/*float: right;*/
/*}*/

/*.spendit-container .title .help {*/
/*width: -webkit-calc(100vw - 300px);*/
/*width: -moz-calc(100vw - 300px);*/
/*width: calc(100vw - 300px);*/
/*padding-top: 8px;*/
/*}*/

.install-flash {
  text-align: center;
  line-height: 22px;
  padding-top: 140px;
}

#category-modal .modal-desc {
  padding: 5px 25px 25px 25px;
}

#category-modal dd.with-info {
  line-height: 20px;
}

#category-modal dd.with-info > .summary {
  margin-left: 50px;
}

#category-modal dd.with-info .summary {
  font-size: 12px;
  color: #898989;
  margin-bottom: 30px;
}

#category-modal .option input[type='text'] {
  padding: 8px 10px;
}

#category-modal dd.with-info .switch {
  float: left;
  margin-right: 15px;
}

#category-modal dd .icon-help {
  display: inline-block;
}

#category-modal * {
  transition: 0s !important;
}

#category-modal dt.required {
  color: #2058a2;
}

#category-modal dt span.required {
  color: #2058a2;
}

#category-modal dl {
  overflow: scroll;
  padding-right: 20px;
}

#category-modal .category-rule-area {
  margin-bottom: 25px;
}

#category-modal span {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: left;
  color: #7d7d7d;
}

#category-modal input::placeholder {
  color: #b7b7b7;
  font-size: 14px;
}

#category-modal ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 4px;
}

#category-modal dl ::-webkit-scrollbar {
  display: none !important;
}

#category-modal ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
}

#category-modal ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #707070 !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

#category-modal ::-webkit-scrollbar-corner {
  border: 1px;
}

#category-modal #policy-setting ::-webkit-scrollbar {
  display: none !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 4px;
}

#category-modal #policy-setting dl ::-webkit-scrollbar {
  display: none !important;
  -webkit-appearance: none !important;
  height: 5px;
}

#category-modal #policy-setting ::-webkit-scrollbar {
  display: none !important;
  -webkit-appearance: none !important;
  height: 5px;
}

#category-modal #policy-setting ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #707070 !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

#category-modal .top-area {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #555555;
  padding: 0 3px 15px 3px;
}

#category-modal dl dt {
  width: 30%;
}

#category-modal dl dd {
  width: 68%;
}

#category-modal .dash {
  width: 20px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}

#category-modal .option .toggle {
  background-color: #fff;
  height: 40px;
  width: 380px;
  border-radius: 6px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d4d4d4;
}

#category-modal .option .toggle li {
  display: inline-block;
  font-size: 12px;
  line-height: 38px;
  cursor: pointer;
}

#category-modal .option .toggle li:hover {
  background-color: rgba(63, 69, 76, 0.1);
  border-radius: 4px;
}

#category-modal .option .toggle li.selected {
  color: #fff !important;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #0c3f7c, #002d62);
}

#category-modal .option .toggle.toggle-3 li {
  width: 120px;
  height: 34px;
  line-height: 34px;
  margin: 3px;
  text-align: center;
  color: #7d7d7d;
  float: left;
}

#category-modal #input-select .activated .list-selected {
  padding: 0 0 0 10px;
  margin-right: 34px;
  background-image: none;
  border-right: 1px solid #d3d3d3;
}

#category-modal #input-select #list {
  border-radius: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d4d4d4;
}

#category-modal #input-select #list-clicked {
  border: 1px solid #d3d3d3;
}

#category-modal #input-select .list-body {
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border-radius: 6px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down.png');
  background-repeat: no-repeat;
  background-size: 9px 6px;
  background-position: right 14px center;
}

#category-modal .memo-button-area {
  width: 252px;
  height: 40px;
  border-radius: 6px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d4d4d4;
  float: left;
}

#category-modal .memo-button-area div {
  text-align: center;
  font-size: 12px;
  line-height: 32px;
  float: left;
  cursor: pointer;
}

#category-modal .memo-button-area div.checked {
  width: 120px;
  height: 34px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #0c407c, #012e63);
  margin: 3px;
  color: #ffffff;
}

#category-modal .memo-button-area div.unchecked {
  width: 120px;
  height: 34px;
  border-radius: 4px;
  margin: 3px;
  color: #7d7d7d;
}

#category-modal .memo-button-area div.unchecked:hover {
  background-color: rgba(63, 69, 76, 0.1);
  border-radius: 4px;
}

#company-admin .Select {
  display: flex;
  flex: 1;
}

#company-admin .Select-control {
  border: solid 1px #d4d4d4;
  border-radius: 6px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

#company-admin .is-searchable.is-open > .Select-control {
  box-shadow: rgb(181, 211, 255) 0px 0px 0px 1px;
}

#company-admin .mypage-member-td-1 {
  padding-left: 10px;
  width: 5%;
}

#company-admin table tbody tr {
  cursor: initial;
  font-size: 14px;
}

#company-admin table tbody tr:hover {
  background-color: unset;
}

#company-admin .mypage-member-td-2 {
  width: 45%;
}

#company-admin td.mypage-member-td-2 {
  font-weight: bold;
}

#company-admin .mypage-member-td-3 {
  width: 45%;
}

#company-admin .mypage-member-td-4 {
  width: 5%;
  text-align: right;
  padding-right: 20px;
}

#company-users .div-table-body-wrapper p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#company-users .user-row {
  min-height: 60px;
}

#company-users .user-row p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

#company-users .mypage-member-font {
  font-size: 12px;
  text-align: left;
}

#company-users .mypage-member-policy {
  font-size: 12px;
  text-align: left;
  color: #7d7d7d;
  font-weight: normal;
}

#company-users .mypage-member-td-1 {
  width: 15%;
  padding-left: 10px;
}

#company-users td.mypage-member-td-1 {
  font-weight: bold;
}

#company-users .mypage-member-td-2 {
  width: 20%;
}

#company-users .mypage-member-td-3 {
  width: 15%;
}

#company-users .mypage-member-td-4 {
  width: 15%;
}

#company-users .mypage-member-td-5 {
  width: 15%;
}

#company-users .mypage-member-td-6 {
  width: 7%;
  /* text-align: right; */
}

#company-users .mypage-member-td-7 {
  width: 13%;
  text-align: center;
}

#company-users .mypage-member-td-7 p {
  overflow: visible;
}

#company-info .remove {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/delete-red.png');
  background-size: 15px 15px;
  background-position: 1px;
  background-repeat: no-repeat;
  position: relative;
  float: left;
  height: 19px;
  width: 12px;
}

.option .remove {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/delete-red.png');
  background-size: 15px 15px;
  background-position: 1px;
  background-repeat: no-repeat;
  position: relative;
  float: left;
  height: 19px;
  width: 12%;
}

.option .upload-logo {
  position: relative;
  float: left;
  font-size: 12px;
  width: 150px;
  text-align: center;
  vertical-align: middle;
}

#bills .content {
  margin-top: 30px;
}

#bills .bill-td-index {
  text-align: center;
  width: 5%;
}

#bills .bill-td-1 {
  padding-left: 10px;
  width: 50%;
}

#bills .bill-td-2 {
  width: 17%;
  text-align: center;
}

#bills .bill-td-3 {
  width: 17%;
}

#bills .bill-td-3 .invoiced {
  color: #047cde;
}

#bills .bill-td-3 .not_paid {
  color: #de3c3c;
}

#bills .bill-td-3 .canceled {
  color: #de3c3c;
}

#bills .bill-td-4 {
  width: 8%;
}

#bills .bill-td-5 {
  width: 8%;
  padding-right: 15px;
}

#bills .content .desc {
  display: inline-block;
  width: 96%;
}

#bills .payment-info.no-payment.modify {
  background-color: #0c5f75;
}

#bills .payment-info.no-payment.add {
  background-color: #506e92;
}

#bills .payment-info {
  cursor: pointer;
  width: 30%;
  float: left;
  height: 130px;
  border-radius: 5px;
  background-color: #0d8bad;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  color: #ffffff;
  padding: 10px;
}

#bills .payments {
  padding: 10px;
}

#bills .payments div {
  margin: 5px;
}

#bills .table {
  width: 100%;
  float: right;
}

#bills .payment-info .bank-name {
  padding: 10px;
  height: 50px;
}

#bills .payment-info .number {
  padding: 5px 10px;
}

#bills .payment-info .name {
  padding: 5px 10px;
  font-weight: bold;
}

#bills .payment-info.no-payment #set-payment {
  border: 1px solid #ffffff;
  line-height: 30px;
  width: 100px;
  font-size: 12px;
  border-radius: 2px;
  text-align: center;
  margin: 50px auto 0 auto;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 8px;
  padding-left: 20px;
}

#bills .payment-info.no-payment #set-payment.add {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/card-add.png');
}

#bills .payment-info.no-payment #set-payment.modify {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/card-edit.png');
}

#bill-modal .details span {
  font-size: 13px;
  font-weight: bold;
}

#bill-modal dd {
  vertical-align: top;
}

#bill-modal dt {
  font-size: 15px;
  font-weight: bold;
  vertical-align: top;
}

#bill-modal dd .value.total-price {
  color: #047cde;
  font-weight: bold;
}

#bill-modal dd .value {
  float: right;
  text-align: right;
}

#expense-matching .modal-desc .image {
  display: block;
  width: 248px;
  height: 387px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 2px;
}

#expense-matching .modal-desc .button-div {
  width: 100%;
  text-align: center;
  height: 387px;
  background-color: rgba(255, 255, 255, 0.8);
}

.erp-code-save-button {
  height: 25px;
  width: 55px;
}

#expense-merge .select-body {
  width: 100%;
  height: 400px;
  display: flex;
}

#expense-merge .left {
  width: 48%;
}

#expense-merge .right .image {
  border: 1px solid #cdcdcd;
  width: 250px;
  height: 300px;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#expense-merge .right .image img {
  width: 100%;
}

#expense-merge .right {
  width: 48%;
}

.spendit-modal#user-modal .button-bottom {
  background-color: #fff;
  margin-left: 4%;
  margin-right: 5%;
  margin-top: 50px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  width: 91%;
  bottom: 0;
  display: inline-block;
}

.spendit-modal#user-modal .button-bottom button {
  height: 30px;
  margin-left: 5px;
}

#daum-map-div,
#daum-map-div *,
#daum-map-div a:hover,
#daum-map-div a:visited,
#daum-map-div a:active {
  transition: 0s !important;
}

.button-bottom {
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

#in-front {
  z-index: 123123123123;
  transition: 0s !important;
}

.spendit-modal#user-card-assign-modal .public-on-off .switch {
  float: right;
}

.spendit-modal#user-card-assign-modal .public-on-off {
  width: 610px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  background-color: #f2f2f2;
  padding: 17px 20px;
  display: inline-block;
}

.spendit-modal#user-card-assign-modal #split-left {
  width: 250px;
  height: 320px;
  margin: 20px;
  border-right: solid 1px #ededed;
  float: left;
  overflow-y: auto;
}

.spendit-modal#user-card-assign-modal #split-left .policy {
  height: 14px;
  font-size: 14px;
  padding: 20px 0;
  border-bottom: 1px solid #b0b0b0;
  width: 230px;
  cursor: pointer;
  display: inline-block;
}

.spendit-modal#user-card-assign-modal #split-left .policy-users {
  padding: 20px 0;
  border-bottom: 1px solid #b0b0b0;
  width: 230px;
  display: inline-block;
}

.spendit-modal#user-card-assign-modal #split-left .add:hover {
  background-color: #047de0;
  color: #fff;
}

.spendit-modal#user-card-assign-modal #split-left .policy:hover {
  color: #047de0;
}

.spendit-modal#user-card-assign-modal #split-left .add {
  font-size: 10px;
  border: 1px solid #047de0;
  color: #047de0;
  border-radius: 50px;
  float: right;
  padding: 3px 5px;
  cursor: pointer;
}

.spendit-modal#user-card-assign-modal #split-right {
  margin: 20px;
  overflow-y: auto;
  max-height: 280px;
  border: solid 1px #b0b0b0;
}

.spendit-modal#user-card-assign-modal #split-right .box {
  width: 335px;
  min-height: 100px;
  display: inline-block;
  border-radius: 2px;
}

.spendit-modal#user-card-assign-modal #split-left .policy-users .user .icon-add,
.spendit-modal#user-card-assign-modal #split-right .box .user .icon-x {
  float: right;
  margin-top: 2px;
  margin-left: 5px;
}

.spendit-modal#user-card-assign-modal #split-left .policy-users .user p,
.spendit-modal#user-card-assign-modal #split-right .box .user p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
  float: left;
}

.spendit-modal#user-card-assign-modal #split-left .policy-users .user {
  margin: 5px;
  line-height: 14px;
  padding: 7px 10px;
  border-radius: 100px;
  background-color: #f2f9ff;
  float: left;
  color: #000000;
  font-size: 11px;
  border: solid 1px #6aa6e9;
}

.spendit-modal#user-card-assign-modal .selected-member {
  font-size: 14px;
  margin-top: 20px;
  background-color: #fff;
  letter-spacing: -0.2px;
  color: #545454;
}

.spendit-modal#user-card-assign-modal #split-right .box .user {
  margin: 10px 5px;
  line-height: 14px;
  padding: 7px 10px;
  border-radius: 100px;
  background-color: #f2f9ff;
  float: left;
  color: #000000;
  font-size: 11px;
  border: solid 1px #6aa6e9;
}

/*스크롤 제거*/
.modal-content ::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

#invite-member .modal-content .modal-desc .result .subtitle.success {
  float: left;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #000000;
  padding-left: 20px;
  background-size: 15px;
  background-position: 1px 3px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/csv-invitation-success.png');
}

#invite-member .modal-content .modal-desc .result .subtitle.not-name {
  color: #888888;
}

#invite-member .modal-content .modal-desc .result .subtitle.fail {
  float: left;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #000000;
  padding-left: 20px;
  background-size: 15px;
  background-position: 1px 3px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-policy.png');
}

#invite-member .modal-content .modal-desc .result .csv-result {
  float: left;
  font-size: 13px;
  margin-left: 5px;
}

#invite-member .modal-content .modal-desc .result .subtitle {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
}

#invite-member .modal-content .modal-desc .result {
  font-size: 13px;
  padding: 10px;
  line-height: 20px;
  margin: 5px 0;
}

#invite-member .modal-content .modal-desc .result .reason {
  padding-bottom: 5px;
}

#invite-member .modal-content .modal-desc .result .reason .detail {
  color: #e65858;
  /*display: inline-block;*/
}

#invite-member .modal-content .modal-desc .result .upload {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

#invite-member #user-upload-table td.td-1 .expense-policy {
  width: 15px;
  margin: 0 auto;
  background-size: 15px;
  background-repeat: no-repeat;
}

/* user upload table */
#invite-member #user-upload-table .csv-td-1 {
  width: 25%;
  padding-left: 16px;
}

#invite-member #user-upload-table .csv-td-2 {
  width: 25%;
}

#invite-member #user-upload-table .csv-td-3 {
  width: 25%;
}

#invite-member #user-upload-table .csv-td-4 {
  width: 25%;
}

#invite-member #user-upload-table {
  width: 100%;
  margin-top: 20px;
  font-size: 13px;
  color: #333;
  border: solid 1px #cecece;
}

#invite-member #user-upload-table thead th {
  text-align: left;
  line-height: 25px;
}

#invite-member #user-upload-table tbody tr:first-child {
  border-top: solid 1px #d0d0d0;
}

#invite-member #user-upload-table tbody tr {
  height: 39px;
  line-height: 16px;
  border-bottom: solid 1px #e8e8e8;
}

#invite-member #user-upload-table tbody tr:last-child {
  border-bottom: solid 1px #d0d0d0;
}

#invite-member #user-upload-table tbody tr:hover {
  background-color: transparent;
}

#invite-member #user-upload-table tbody .selected {
  background-color: transparent;
}

#invite-member #user-upload-table tbody tr td {
  vertical-align: middle;
}

#invite-member #user-upload-table td.td-1 {
  width: 5%;
}

#invite-member #user-upload-table td.td-2 {
  width: 35%;
}

#invite-member #user-upload-table td.td-3 {
  line-height: 25px;
  width: 60%;
}

#report-collector .collected-at {
  display: inline-block;
  padding-left: 10px;
  line-height: 36px;
  font-size: 12px;
  vertical-align: top;
}

#attendee-modal .attendee-add {
  margin-bottom: 5px;
}

#attendee-modal .attendee-add .table-area {
  border: solid 1px #d5d5d5;
  overflow-y: scroll;
  height: 150px;
}

#attendee-modal .attendee-add-subtitle {
  font-size: 12px;
  color: #7d7d7d;
  line-height: 18px;
  margin-bottom: 10px;
}

#attendee-modal .attendee-add tbody tr.blank-area {
  height: 25px;
}

#attendee-modal .attendee-add tbody tr:last-child {
  border-bottom: 1px solid #e8e8e8 !important;
}

#attendee-modal .attendee-add tbody tr {
  text-align: center;
  height: 39px;
  line-height: 39px;
}

#attendee-modal .attendee-add .tr-head {
  text-align: center;
  height: 25px;
  line-height: 25px;
}

#attendee-modal .attendee-add td p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}

#attendee-modal .attendee-add td {
  font-size: 12px;
  padding-left: 5px;
  text-align: left;
}

#attendee-modal .attendee-add .tr-head td {
  font-size: 12px;
  padding-left: 5px;
  text-align: left;
}

#attendee-modal .attendee-add thead {
  border-bottom: none !important;
}

#attendee-modal .attendee-add th {
  vertical-align: middle;
  text-align: left;
  background-color: #f0f2f4;
  height: 25px;
}

#attendee-modal .attendee-add table tbody tr {
  height: 39px;
  cursor: pointer;
  line-height: 16px;
}

#attendee-modal .attendee-add table .td-new {
  width: 4%;
  text-align: center;
}

#attendee-modal .attendee-add table .td-0 {
  width: 1%;
  border-bottom: 1px solid white;
}

#attendee-modal .attendee-add table .td-1 {
  width: 18%;
}

#attendee-modal .attendee-add table .td-2 {
  width: 18%;
}

#attendee-modal .attendee-add table .td-3 {
  width: 18%;
}

#attendee-modal .attendee-add table .td-4 {
  width: 18%;
}

#attendee-modal .attendee-add table .td-5 {
  width: 18%;
}

#attendee-modal .attendee-add table .td-6 {
  width: 4%;
  padding: 5px 5px;
}

#attendee-modal .attendee-add table .td-7 {
  width: 1%;
  border-bottom: 1px solid white;
}

#attendee-modal .attendee-add table .tr-head th {
  padding-left: 5px;
}

#attendee-modal .attendee-add table .td-new-h {
  width: 4%;
  text-align: center;
  padding-left: 5px;
}

#attendee-modal .attendee-add table .td-0-h {
  border-bottom: 1px solid white;
  width: 1%;
}

#attendee-modal .attendee-add table .td-1-h {
  width: 18%;
}

#attendee-modal .attendee-add table .td-2-h {
  width: 18%;
}

#attendee-modal .attendee-add table .td-3-h {
  width: 18%;
}

#attendee-modal .attendee-add table .td-4-h {
  width: 18%;
}

#attendee-modal .attendee-add table .td-5-h {
  width: 18%;
}

#attendee-modal .attendee-add table .td-6-h {
  width: 4%;
}

#attendee-modal .attendee-add table .td-7-h {
  border-bottom: 1px solid white;
  width: 1%;
}

.typeahead-add-new {
  color: #022855;
}

.typeahead-add {
  background-color: #fff;
  padding: 0 10px;
  text-decoration: none;
  line-height: 34px;
  height: 34px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  text-align: left;
  color: #8e8e8e;
}

.typeahead-add:hover {
  background-color: #f4f9fd;
}

#transaction-list .transaction-row {
  font-size: 14px;
}

#transaction-list .store {
  font-weight: 700;
  font-size: 14px;
}

#transaction-list .spent-at,
#transaction-list .store,
#transaction-list .transaction-subtitle,
#transaction-list .price,
#transaction-list .policy-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#transaction-list .bank-transaction-td-main-title {
  width: 12%;
}

#transaction-list .bank-transaction-td-sub-title {
  width: 10%;
}

#transaction-list .bank-transaction-td-policy span,
#transaction-list .bank-transaction-td-saving span,
#transaction-list .bank-transaction-td-withdrawal span,
#transaction-list .bank-transaction-td-main-title span,
#transaction-list .bank-transaction-td-sub-title span {
  color: #7d7d7d;
  font-size: 11px;
}

#transaction-list .bank-transaction-td-spent-at {
  width: 11%;
}

#transaction-list .bank-transaction-td-select {
  padding-left: 10px;
  width: 3%;
}

#transaction-list .bank-transaction-td-thumb,
#transaction-list .bank-transaction-td-type {
  width: 35px;
  padding-right: 10px;
}

#transaction-list .bank-transaction-td-policy {
  width: 11%;
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
}

#transaction-list .transaction-row .bank-transaction-td-policy {
  font-size: 12px;
}

#transaction-list .bank-transaction-td-recover {
  width: 8%;
  float: right !important;
  text-align: center;
}

#transaction-list .bank-transaction-td-saving,
#transaction-list .bank-transaction-td-withdrawal,
#transaction-list .bank-transaction-td-balance {
  width: 10%;
  text-align: left;
}

#transaction-list .bank-transaction-td-main-title p.report-title {
  font-size: 12px;
  letter-spacing: -0.2px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #9d9d9d;
}

#personal-refund #spent-info-container {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
}

#bank_transactions_filter {
  margin-bottom: 10px;
}

/* policy-member-custom start */
#policy-info .policy-approve-types-custom {
  position: relative;
  display: inline-block;
  margin: 10px auto;
  white-space: nowrap;
}

#policy-info .policy-approve-types-custom div {
  cursor: pointer;
}

#policy-info .policy-approve-types-custom .approve-type-title {
  position: relative;
  float: left;
  margin: 20px auto 0 auto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: center;
  color: #97a9c0;
  width: 100%;
  line-height: 24px;
}

#policy-info .policy-approve-types-custom .approve-type-desc {
  position: relative;
  float: left;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #97a9c0;
  width: 100%;
  margin-top: 19px;
  margin-bottom: 70px;
  max-height: 40px;
}

#policy-info .policy-approve-types-custom .approve-type-desc-sub {
  position: relative;
  float: left;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #97a9c0;
  width: 100%;
  margin-top: 19px;
}

/* 승인모드타이틀 */
#policy-info .policy-approve-types-custom .multi-type .multi-type-title-active,
#policy-info .policy-approve-types-custom .submit-approve-type-active .submit-approve-title,
#policy-info .policy-approve-types-custom .single-type-active .single-title,
#policy-info .policy-approve-types-custom .multi-type .multi-type-title:hover,
#policy-info .policy-approve-types-custom .submit-approve-type:hover .submit-approve-title,
#policy-info .policy-approve-types-custom .single-type:hover .single-title {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #047de0;
  border: 1px solid #047de0;
  border-radius: 2px;
  position: relative;
  float: left;
  width: 100%;
  height: 25px;
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

/* hover */
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-step-desc:hover .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-step-desc:hover .approve-type-desc,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-step-desc:hover .approve-type-title,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-default-desc:hover .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-default-desc:hover .approve-type-desc,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-default-desc:hover .approve-type-title,
#policy-info .policy-approve-types-custom .multi-type .multi-type-title:hover ~ .multi-type-desc .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .multi-type .multi-type-title:hover ~ .multi-type-desc .approve-type-desc,
#policy-info .policy-approve-types-custom .multi-type .multi-type-title:hover ~ .multi-type-desc .approve-type-title,
#policy-info .policy-approve-types-custom .multi-type .multi-type-title:hover ~ .multi-type-desc .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .multi-type .multi-type-title:hover ~ .multi-type-desc .approve-type-desc,
#policy-info .policy-approve-types-custom .multi-type .multi-type-title:hover ~ .multi-type-desc .approve-type-title,
#policy-info .policy-approve-types-custom .submit-approve-type:hover .submit-approve-desc .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .submit-approve-type:hover .submit-approve-desc .approve-type-desc,
#policy-info .policy-approve-types-custom .submit-approve-type:hover .submit-approve-desc .approve-type-title,
#policy-info .policy-approve-types-custom .single-type:hover .single-desc .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .single-type:hover .single-desc .approve-type-desc,
#policy-info .policy-approve-types-custom .single-type:hover .single-desc .approve-type-title {
  color: #047de0;
}

#policy-info .policy-approve-types-custom .multi-type:hover .multi-type-title {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #047de0;
  border: 1px solid #047de0;
  border-radius: 2px;
}

/* active */
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-step-desc-active .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-step-desc-active .approve-type-desc,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-step-desc-active .approve-type-title,
#policy-info
  .policy-approve-types-custom
  .multi-type
  .multi-type-desc
  .multi-default-desc-active
  .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-default-desc-active .approve-type-desc,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-default-desc-active .approve-type-title,
#policy-info .policy-approve-types-custom .submit-approve-type-active .submit-approve-desc .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .submit-approve-type-active .submit-approve-desc .approve-type-desc,
#policy-info .policy-approve-types-custom .submit-approve-type-active .submit-approve-desc .approve-type-title,
#policy-info .policy-approve-types-custom .single-type-active .single-desc .approve-type-desc-sub,
#policy-info .policy-approve-types-custom .single-type-active .single-desc .approve-type-desc,
#policy-info .policy-approve-types-custom .single-type-active .single-desc .approve-type-title {
  color: #047de0;
}

/* 단일승인모드 start */
#policy-info .policy-approve-types-custom .single-type-active,
#policy-info .policy-approve-types-custom .single-type {
  position: relative;
  float: left;
  width: 210px;
  height: 300px;
  margin-right: 16px;
  margin-left: 20px;
}

/* hover, active body */
#policy-info .policy-approve-types-custom .single-type-active .single-desc,
#policy-info .policy-approve-types-custom .single-type:hover .single-desc {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 100%;
  height: 260px;
  border: 1px solid #047de0;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: 100px 28px;
  background-position: center 140px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-mode/submit-approve-hover.png');
}

#policy-info .policy-approve-types-custom .single-type .single-title {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 100%;
  height: 25px;
  border: 1px solid #97a9c0;
  border-radius: 2px;
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: #97a9c0;
  color: #ffffff;
}

#policy-info .policy-approve-types-custom .single-type .single-desc {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 100%;
  height: 260px;
  border: 1px solid #e6eaee;
  border-radius: 2px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-mode/submit-approve.png');
  background-repeat: no-repeat;
  background-size: 100px 28px;
  background-position: center 140px;
}

#policy-info .policy-approve-types .single-type .single-desc .approve-type-desc {
  position: relative;
  float: left;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #7d7d7d;
}

/* 단일승인모드 end */

/* 단일제출모드 start */
#policy-info .policy-approve-types-custom .submit-approve-type-active,
#policy-info .policy-approve-types-custom .submit-approve-type {
  position: relative;
  float: left;
  width: 210px;
  height: 300px;
}

#policy-info .policy-approve-types-custom .submit-approve-type .submit-approve-title {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 100%;
  height: 25px;
  border: 1px solid #97a9c0;
  border-radius: 2px;
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: #97a9c0;
  color: #ffffff;
}

/* hover, active body */
#policy-info .policy-approve-types-custom .submit-approve-type-active .submit-approve-desc,
#policy-info .policy-approve-types-custom .submit-approve-type:hover .submit-approve-desc {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 100%;
  height: 260px;
  border: 1px solid #047de0;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: 100px 28px;
  background-position: center 140px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-mode/single-hover.png');
}

#policy-info .policy-approve-types-custom .submit-approve-type .submit-approve-desc {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 100%;
  height: 260px;
  border: 1px solid #e6eaee;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: 100px 28px;
  background-position: center 140px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-mode/single.png');
}

#policy-info .policy-approve-types-custom .submit-approve-type .submit-approve-desc .submit-approve-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
  line-height: 24px;
}

#policy-info .policy-approve-types .submit_approve-type .submit-approve-desc {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #7d7d7d;
}

/* 단일제출모드 end */

/* 멀티승인모드 start */
#policy-info .policy-approve-types-custom .multi-type {
  position: relative;
  float: left;
  width: 440px;
  height: 300px;
  margin-left: 20px;
}

#policy-info .policy-approve-types-custom .multi-type .multi-type-title {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 100%;
  height: 25px;
  border: 1px solid #97a9c0;
  border-radius: 2px;
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: #97a9c0;
  color: #ffffff;
}

#policy-info .policy-approve-types-custom .multi-type .multi-type-desc {
  position: relative;
  float: left;
  width: 444px;
  min-width: 444px;
  height: 260px;
}

/* 멀티승인모드 end */

/*멀티선택모드*/
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-default-desc {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 210px;
  height: 260px;
  border: 1px solid #e6eaee;
  border-radius: 2px;
  margin-right: 16px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-mode/advanced-default.png');
  background-repeat: no-repeat;
  background-size: 100px 28px;
  background-position: center 140px;
}

/* hover, active body */
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-default-desc-active,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-default-desc:hover,
#policy-info .policy-approve-types-custom .multi-type .multi-type-title:hover ~ .multi-type-desc .multi-default-desc {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 210px;
  height: 260px;
  border: 1px solid #047de0;
  border-radius: 2px;
  margin-right: 16px;
  background-repeat: no-repeat;
  background-size: 100px 28px;
  background-position: center 140px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-mode/advanced-default-hover.png');
}

/* 멀티선택모드 end */

/* 멀티지정승인모드 start */
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-step-desc {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 210px;
  height: 260px;
  border: 1px solid #e6eaee;
  border-radius: 2px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-mode/advanced-step.png');
  background-repeat: no-repeat;
  background-size: 100px 28px;
  background-position: center 140px;
}

/* hover, active body */
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-step-desc-active,
#policy-info .policy-approve-types-custom .multi-type .multi-type-desc .multi-step-desc:hover,
#policy-info .policy-approve-types-custom .multi-type .multi-type-title:hover ~ .multi-type-desc .multi-step-desc {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  width: 210px;
  height: 260px;
  border: 1px solid #047de0;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: 100px 28px;
  background-position: center 140px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-mode/advanced-step-hover.png');
}

/* 멀티지정승인모드 end */
/* policy-member-custom end */

#expense .no-filter .icon-list {
  left: calc(50% - 90px);
  left: -moz-calc(50% - 90px);
  left: -webkit-calc(50% - 90px);
}

@media (max-width: 700px) {
  #expense .checked-count {
    display: none;
  }
}

@media (max-width: 999px) {
  #expense .icon-list {
    left: -moz-calc(50% - 90px);
    left: -webkit-calc(50% - 90px);
    left: calc(50% - 90px);
  }
}
@media (max-width: 1200px) {
  .page-navigation .page-info {
    display: none;
  }
}

@media (min-width: 1000px) {
  #expense .icon-list {
    left: calc(240px + (((100% - 240px) / 2) - 90px));
    left: -moz-calc(240px + (((100% - 240px) / 2) - 90px));
    left: -webkit-calc(240px + (((100% - 240px) / 2) - 90px));
  }
}

#company-taxes .checked-count,
#company-group-members .checked-count,
#group-members .checked-count,
#project-tags .checked-count,
#spent-info .checked-count,
#expense-rules .checked-count,
#cards-view-custom .checked-count,
#attendee .checked-count,
#policy-project .checked-count,
#report .checked-count {
  font-size: 12px;
  margin-right: 30px;
  float: right;
  line-height: 27px;
  color: #037de0;
}

#expense .checked-count {
  margin-right: 35px;
  font-stretch: normal;
  font-size: 12px;
  line-height: 27px;
  color: #037de0;
}

/* report-policy-custom-field start */
.custom-body .custom-field-box {
  position: relative;
  float: left;
  width: 100%;
  font-size: 12px;
}

.custom-body .custom-field-address {
  padding-top: 2px;
  position: relative;
  float: left;
  width: 100%;
}

.custom-body .custom-field-address .custom-field-value {
  position: relative;
  float: left;
  width: 15%;
  margin: 12px 0px 14px 5px;
  color: #555555;
  font-size: 12px;
}

.custom-body .custom-field-address .custom-field-input {
  position: relative;
  float: left;
  width: 75%;
}

.custom-body .custom-field-box .custom-field-value {
  position: relative;
  float: left;
  width: 15%;
  margin: 14px 0px 14px 5px;
  color: #555555;
}

.custom-body .custom-field-box .custom-field-toggle {
  position: relative;
  float: left;
  width: 800%;
  margin: 14px 0px;
}

.custom-body .custom-field-box .custom-field-detail {
  position: relative;
  float: left;
  width: 75%;
  margin: 14px 0px;
}

.custom-body .custom-field-box .detail-sub > .input-div {
  width: -webkit-calc(100% - 80px);
  width: -moz-calc(100% - 80px);
  width: calc(100% - 80px);
  float: left;
}

.custom-body .custom-field-box .detail-sub {
  position: relative;
  float: left;
  width: 75%;
}

#custom-field-add {
  padding: 17px 20px;
  line-height: 0px;
  border: 1px solid #047de0;
  color: #047de0;
}

.custom-body .custom-field-box .custom-field-text {
  position: relative;
  float: left;
  width: 75%;
}

.custom-body .custom-field-box .custom-field-input .field-options:last-child {
  border-bottom: none !important;
}

.custom-body .custom-field-box .custom-field-input {
  position: relative;
  float: left;
  width: 75%;
}

.custom-body .custom-field-box #report-field-title {
  font-size: 12px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  padding: 7px 10px;
  line-height: 20px;
  margin-top: 3px;
  width: 100%;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px !important;
  width: -webkit-calc(100% - 22px);
  width: -moz-calc(100% - 22px);
  width: calc(100% - 22px);
}

#policy-info .report-policy-custom-fields .option-title-custom {
  float: left;
  font-size: 14px;
  width: 20%;
  margin: 14px 0px;
  word-wrap: break-word;
}

#policy-info .report-policy-custom-fields .option-title-custom .option-custom-title {
  float: left;
  color: #555555;
  font-weight: bold;
  font-size: 13px;
  padding-left: 10px;
}
/* report-policy-custom-field end */

#scraping-alert-modal .modal-content {
  width: 500px;
  margin-left: -250px; /* 700 / 2 */
}

#scraping-alert-modal .login-subtitle span.text {
  padding-left: 20px;
}

#scraping-alert-modal .login-description {
  font-size: 13px;
  text-align: center;
  color: #333333;
  margin: 10px auto;
  line-height: 20px;
}

#scraping-alert-modal .login-buttons {
  text-align: center;
}
#scraping-alert-modal .buttons {
  position: relative;
  display: inline-block;
  overflow-x: hidden;
  margin: 10px auto;
  white-space: nowrap;
}

#scraping-alert-modal .login-bank:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/bank_login_hover.png');
}

#scraping-alert-modal .login-bank {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/bank_login.png');
}

#scraping-alert-modal .login-card:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/card_login_hover.png');
}

#scraping-alert-modal .login-card {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/card_login.png');
}

#scraping-alert-modal .scraping-btn-login {
  position: relative;
  float: left;
  width: 160px;
  opacity: 0.79;
  border-radius: 2px;
  margin: 10px;
  border: solid 1px #999999;
  color: #999999;
  vertical-align: bottom;
  font-size: 13px;
  padding: 50px 0 14px 0;
  font-weight: bold;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center 17px;
  background-size: 34px 24px;
  cursor: pointer;
}

#scraping-alert-modal .scraping-btn-login:hover {
  border: solid 1px #3999e7;
  color: #047de0;
}

#scraping-alert-modal .bottom .close {
  cursor: pointer;
  float: right;
}

#scraping-alert-modal .bottom .do-not-showing {
  cursor: pointer;
  float: left;
}

#scraping-alert-modal .bottom {
  line-height: 20px;
  font-size: 13px;
  padding-bottom: 20px;
  color: #7d7d7d;
}

#scraping-alert-modal .bottom input[type='checkbox'] {
  float: left;
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-right: 10px;
}

#scraping-alert-modal .login-subtitle {
  text-align: center;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  font-size: 15px;
}

/* attendee custom start */
#attendee-modal .attendee-custom {
  margin-bottom: 30px;
  height: 200px;
}

#attendee-modal .attendee-custom-subtitle {
  font-size: 12px;
  color: #7d7d7d;
  line-height: 18px;
  margin-bottom: 10px;
}

#attendee-modal .attendee-custom table tbody tr.blank-area {
  height: 1px;
}

#attendee-modal .attendee-custom table tbody tr:last-child {
  border-bottom: 1px solid #e8e8e8 !important;
}

#attendee-modal .attendee-custom table tbody tr {
  text-align: center;
  height: 39px;
  line-height: 39px;
}

#attendee-modal .attendee-custom table th {
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-bottom: solid 1px #d0d0d0;
}

#attendee-modal .attendee-custom table td > p {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
  white-space: nowrap;
}

#attendee-modal .attendee-custom .external .td-name,
#attendee-modal .attendee-custom .internal .td-name {
  width: 20%;
  padding-left: 10px;
  text-align: left;
}

#attendee-modal .attendee-custom .internal.unaccessible {
  color: #9f9f9f;
}

#attendee-modal .attendee-custom .external .td-company-name,
#attendee-modal .attendee-custom .external .td-position,
#attendee-modal .attendee-custom .external .td-dept,
#attendee-modal .attendee-custom .internal .td-company-name {
  width: 20%;
  text-align: left;
}

#attendee-modal .attendee-custom .internal .td-email {
  width: 30%;
  text-align: left;
}

#attendee-modal .attendee-custom table td {
  font-size: 12px;
}

#attendee-modal .attendee-custom table th td {
  font-size: 12px;
  padding-left: 5px;
  text-align: left;
}

#attendee-modal .attendee-custom thead {
  border-bottom: none !important;
  position: relative;
}

#attendee-modal .attendee-custom th {
  vertical-align: middle;
  text-align: left;
  height: 25px;
}

#attendee-modal .attendee-custom table tbody tr {
  height: 39px;
  cursor: pointer;
  line-height: 16px;
}

#attendee-modal .attendee-custom table .td {
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}

#attendee-modal .attendee-custom table .td-h {
  text-align: center;
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
}

.sort-custom {
  position: relative;
  width: 10px;
  height: 13px;
  top: 1px;
  background-position-y: center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/sort.png');
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 5px;
  cursor: pointer;
}

.sort-custom#up {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/up-blue.png');
}

.sort-custom#down {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down-blue.png');
  background-position-y: 7px;
}

.td #internal-attendee-name {
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #545454;
}

.td #internal-attendee-etc {
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #545454;
}

#display-more {
  white-space: normal;
  line-height: 20px;
  padding: 2px 5px;
  margin: 5px 5px 5px 0;
  border-radius: 100px;
  background-color: #ebf1f5;
  float: left;
  font-size: 12px;
  cursor: pointer;
}

#display-attendees {
  color: #fff !important;
  /* white-space: normal; */
  line-height: 20px;
  padding: 2px 5px;
  text-align: center;
  width: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 5px 5px 5px 0;
  border-radius: 100px;
  background-color: #047de0;
  float: left;
  font-size: 12px;
  cursor: pointer;
}
/* attendee custom end */

.overlay-reports-custom p {
  line-height: 26px;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
/* overlay-reports-custom end */

/*#policy-refund.content {*/
/*margin-top: 180px;*/
/*}*/

.auto-report-complete {
  padding-top: 40px;
  width: calc(100% - 70px);
  position: fixed;
  background: white;
  height: 140px;
  object-fit: contain;
  border-radius: 2px;
  z-index: 3;
}

.auto-report-complete .title {
  width: 100%;
  width: -webkit-calc(100% - 20px);
  width: -moz-calc(100% - 20px);
  width: calc(100% - 20px);
  font-weight: 700;
  font-size: 14px;
  padding: 13px 0 13px 20px;
  object-fit: contain;
  background-color: rgba(0, 39, 85, 0.05);
  color: #555;
}

.auto-report-complete .content {
  width: 100%;
  height: 70px;
  object-fit: contain;
  background-color: rgba(2, 40, 85, 0.02);
}

.auto-report-complete .content .select-box {
  width: 200px;
  padding: 20px;
}

/* user-card-assign-modal-custom */

.spendit-modal#user-card-assign-modal-custom .public-on-off .switch {
  float: right;
}

.spendit-modal#user-card-assign-modal-custom .public-on-off {
  width: 610px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  background-color: #f2f2f2;
  padding: 17px 20px;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  text-align: left;
  color: #000000;
}

.spendit-modal#user-card-assign-modal-custom #split-left {
  width: 250px;
  height: 320px;
  margin: 20px;
  border-right: solid 1px #ededed;
  float: left;
  overflow-y: auto;
}

.spendit-modal#user-card-assign-modal-custom #split-left .policy {
  height: 14px;
  font-size: 14px;
  padding: 20px 0;
  border-bottom: 1px solid #b0b0b0;
  width: 230px;
  cursor: pointer;
  display: inline-block;
}

.spendit-modal#user-card-assign-modal-custom #split-left .policy-users {
  padding: 20px 0;
  border-bottom: 1px solid #b0b0b0;
  width: 230px;
  display: inline-block;
}

.spendit-modal#user-card-assign-modal-custom #split-left .add:hover {
  background-color: #047de0;
  color: #fff;
}

.spendit-modal#user-card-assign-modal-custom #split-left .policy:hover {
  color: #047de0;
}

.spendit-modal#user-card-assign-modal-custom #split-left .add {
  font-size: 10px;
  border: 1px solid #047de0;
  color: #047de0;
  border-radius: 50px;
  float: right;
  padding: 3px 5px;
  cursor: pointer;
}

.spendit-modal#user-card-assign-modal-custom #split-right {
  margin: 20px;
  overflow-y: auto;
  max-height: 280px;
  border: solid 1px #b0b0b0;
}

.spendit-modal#user-card-assign-modal-custom #split-right .box {
  width: 335px;
  min-height: 100px;
  display: inline-block;
  border-radius: 2px;
}

.spendit-modal#user-card-assign-modal #split-left .policy-users .user .icon-add,
.spendit-modal#user-card-assign-modal #split-right .box .user .icon-x {
  float: right;
  margin-top: 2px;
  margin-left: 5px;
}

.spendit-modal#user-card-assign-modal-custom #split-left .policy-users .user p,
.spendit-modal#user-card-assign-modal-custom #split-right .box .user p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
  float: left;
}

.spendit-modal#user-card-assign-modal-custom #split-left .policy-users .user {
  margin: 5px;
  line-height: 14px;
  padding: 7px 10px;
  border-radius: 100px;
  background-color: #f2f9ff;
  float: left;
  color: #000000;
  font-size: 11px;
  border: solid 1px #6aa6e9;
}

.spendit-modal#user-card-assign-modal-custom .selected-member {
  font-size: 14px;
  margin-top: 20px;
  background-color: #fff;
  letter-spacing: -0.2px;
  color: #545454;
}

.spendit-modal#user-card-assign-modal-custom #split-right .box .user {
  margin: 10px 5px;
  line-height: 14px;
  padding: 7px 10px;
  border-radius: 100px;
  background-color: #f2f9ff;
  float: left;
  color: #000000;
  font-size: 11px;
  border: solid 1px #6aa6e9;
}

.spendit-modal#user-card-assign-modal-custom .save button:hover {
  background-color: #0f58ae;
  border: 1px solid #0f58ae;
}

.spendit-modal#user-card-assign-modal-custom .save button {
  background-color: #047de0;
  border: 1px solid #047de0;
  color: #ffffff;
  width: 140px;
  height: 38px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}

.spendit-modal#user-card-assign-modal-custom .save {
  background-color: #fff;
  margin-left: 5%;
  margin-right: 5%;
  text-align: right;
  padding: 20px 0;
  border-top: solid 1px #ededed;
  width: 90%;
  bottom: 0;
  display: inline-block;
}

.spendit-modal#user-card-assign-modal-custom .user-add {
  width: 650px;
  padding: 25px 0;
}

.spendit-modal#user-card-assign-modal-custom .user-add .user-add-add-subtitle {
  width: 120px;
  height: 18px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: left;
  color: #7d7d7d;
}

#user-card-assign-modal-custom .modal-desc {
  height: 300px;
}

#user-card-assign-modal-custom .user-add .blank-area {
  height: 25px;
  cursor: pointer;
  line-height: 25px;
}

#user-card-assign-modal-custom .user-add tbody tr:last-child {
  border-bottom: 1px solid #e8e8e8 !important;
}

#user-card-assign-modal-custom .user-add tbody tr {
  text-align: center;
  height: 39px;
  line-height: 39px;
}

#user-card-assign-modal-custom .user-add .tr-head {
  text-align: center;
  background-color: #f0f2f4;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: #7d7d7d;
}

#user-card-assign-modal-custom .user-add td p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: left;
  color: #545454;
}

#user-card-assign-modal-custom .user-add .name {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #545454;
}

#user-card-assign-modal-custom .user-add td {
  font-size: 12px;
  text-align: left;
}

#user-card-assign-modal-custom .user-add .tr-head td {
  font-size: 12px;
  padding-left: 5px;
  text-align: left;
}

#user-card-assign-modal-custom .user-add thead {
  border-bottom: none !important;
  position: fixed;
  width: 648px;
  height: 25px;
  line-height: 25px;
}

#attendee-modal .user-add th {
  vertical-align: middle;
  text-align: left;
  background-color: #f0f2f4;
  height: 25px;
}

#user-card-assign-modal-custom .user-add table tbody tr {
  height: 40px;
  cursor: pointer;
  line-height: 40px;
}

#user-card-assign-modal-custom .user-add table .td-new {
  width: 4%;
  text-align: center;
}

#user-card-assign-modal-custom .user-add .table-area {
  border: solid 1px #d5d5d5;
  overflow-y: scroll;
  height: 200px;
  margin-top: 10px;
}

#user-card-assign-modal-custom .user-add table .td-0 {
  width: 1%;
  border-bottom: 1px solid white;
}

#user-card-assign-modal-custom .user-add table .td-1 {
  padding-left: 20px;
  width: 280px;
}

#user-card-assign-modal-custom .user-add table .td-2 {
  padding-left: 20px;
  width: 280px;
}

#user-card-assign-modal-custom .user-add table .td-3 {
}

#user-card-assign-modal-custom .user-add table .td-4 {
}

#user-card-assign-modal-custom .user-add table .td-5 {
}

#user-card-assign-modal-custom .user-add table .td-6 {
  padding: 0 10px;
}

#user-card-assign-modal-custom .user-add table .td-7 {
}

#user-card-assign-modal-custom .user-add table .tr-head th {
  height: 25px;
  line-height: 25px !important;
}

#user-card-assign-modal-custom .user-add table .td-new-h {
  width: 22px;
  text-align: center;
}

#user-card-assign-modal-custom .user-add table .td-0-h {
  border-bottom: 1px solid white;
}

#user-card-assign-modal-custom .user-add table .td-1-h {
  padding-left: 20px;
  width: 280px;
}

#user-card-assign-modal-custom .user-add table .td-2-h {
  padding-left: 20px;
  width: 280px;
}

#user-card-assign-modal-custom .user-add table .td-3-h {
  width: 91px;
}

#user-card-assign-modal-custom .user-add table .td-4-h {
  width: 91px;
}

#user-card-assign-modal-custom .user-add table .td-5-h {
  width: 92px;
}

#user-card-assign-modal-custom .user-add table .td-6-h {
  width: 50px;
}

#user-card-assign-modal-custom .user-add table .td-7-h {
  border-bottom: 1px solid white;
}

#user-card-assign-modal-custom .sort {
  position: relative;
  width: 10px;
  height: 13px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/sort.png');
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 5px;
  cursor: pointer;
  background-position: 1px 4px;
}

#user-card-assign-modal-custom .sort#up {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/up-blue.png');
}

#user-card-assign-modal-custom .sort#down {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down-blue.png');
  background-position-y: 7px;
}

#user-card-assign-modal-custom .search-field {
  width: 100%;
  margin-top: 10px;
}

#user-card-assign-modal-custom .search-field .subject {
  width: 54px;
  height: 29px;
  line-height: 29px;
  position: relative;
  float: left;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: #545454;
  margin-right: 58px;
  padding: 5px 10px;
}

#user-card-assign-modal-custom .search-field .search-users {
  position: relative;
  float: left;
  width: 500px;
  height: 48px;
}

#user-card-assign-modal-custom .icon-report-delete-expense:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-delete-expense-hover.png');
}

#user-card-assign-modal-custom .icon-report-delete-expense {
  float: right;
  cursor: pointer;
  width: 20px;
  margin: 0 auto;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-delete-expense.png');
  background-size: 20px;
}

.detail .content .desc .fuel-header {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #7d7d7d;
  text-align: left;
  border-bottom: solid 1px #b6b6b6;
}

.detail .content .desc .fuel-header .fuel-dt-0 {
  width: 10%;
  padding-left: 10px;
}

.detail .content .desc .fuel-header .fuel-dt-1 {
  width: 40%;
}

.detail .content .desc .fuel-header .fuel-dt-2 {
  width: 40%;
}

.detail .content .desc .wage-header {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #7d7d7d;
  text-align: left;
  border-bottom: solid 1px #b6b6b6;
}

.detail .content .desc .wage-header .wage-dt-0 {
  width: 10%;
  padding-left: 10px;
}

.detail .content .desc .wage-header .wage-dt-1 {
  width: calc(35% - 30px);
  margin-right: 20px;
}

.detail .content .desc .wage-header .wage-dt-2 {
  width: calc(35% - 30px);
  margin-right: 20px;
}

.detail .content .desc .wage-header .wage-dt-4 {
  width: 10%;
}

.detail .content .desc .wage-header .wage-dt-3 {
  width: 10%;
}

.detail .content .desc .wage-row,
.detail .content .desc .fuel-row {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #333333;
  padding: 10px 0;
  line-height: 37px;
  border-bottom: solid 1px #e8e8e8;
}

.detail .content .desc .wage-row:hover {
  background-color: #e9eff5;
}

.detail .content .desc .wage-row .wage-status {
  width: 10%;
  padding-left: 10px;
}

.detail .content .desc .wage-row .wage-label {
  width: calc(35% - 30px);
  margin-right: 20px;
}

.detail .content .desc .wage-row .wage-price {
  width: calc(35% - 30px);
  margin-right: 20px;
}

.detail .content .desc .wage-row .wage-currency {
  width: 10%;
}

.detail .content .desc .fuel-row:hover {
  background-color: #e9eff5;
}

.detail .content .desc .fuel-row .fuel-status {
  width: 10%;
  padding-left: 10px;
}

.detail .content .desc .fuel-row .fuel-label {
  width: 40%;
}

.detail .content .desc .fuel-row .fuel-value {
  width: 30%;
}

.detail .content .desc .wage-row .wage-delete-area,
.detail .content .desc .fuel-row .fuel-delete-area {
  width: 10%;
  float: right;
  height: 37px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.detail .content .desc .wage-row .wage-delete-area .icon-trash-gray,
.detail .content .desc .fuel-row .fuel-delete-area .icon-trash-gray {
  margin-right: 20px;
  background-image: url('../../images/icons/icon-trash-gray.svg');
  background-size: 18px;
  width: 18px;
  height: 18px;
}

.detail .content .desc .wage-row .wage-delete-area .icon-trash-gray:hover,
.detail .content .desc .fuel-row .fuel-delete-area .icon-trash-gray:hover {
  background-image: url('../../images/icons/icon-trash-red.svg');
}

.detail .content .desc .fuel-row .fuel-label input[type='text'] {
  width: 90%;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.detail .content .desc .wage-row .wage-delete-area .wage-delete-button,
.detail .content .desc .fuel-row .fuel-delete-area .fuel-delete-button {
  float: right;
  width: 100%;
  height: 100%;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-delete-expense.png');
  background-repeat: no-repeat;
  background-size: 20px;
  cursor: pointer;
  background-position: 90% 10px;
}

.detail .content .desc .wage-row .wage-delete-area .wage-delete-button:hover,
.detail .content .desc .fuel-row .fuel-delete-area .fuel-delete-button:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-delete-expense-hover.png');
}

.detail .content .desc .button-area .add-fuel {
  height: 30px;
  border-radius: 2px;
  border: solid 1px #7e91a8;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add.png');
  background-size: 16px 16px;
  background-position: 12px;
  background-repeat: no-repeat;
  padding: 0 15px 0 30px;
}

.detail .content .desc .button-area .add-fuel span {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #7e91a8;
}

.detail .content .desc .check-title {
  float: left;
  width: 100px;
  text-align: left;
  object-fit: contain;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: #333333;
  line-height: 35px;
}

.detail .content .desc .check-desc {
  float: left;
  width: 14px;
  height: 14px;
  line-height: 40px;
  margin-right: 10px;
}

.spendit-modal.small .modal-content #fuel-modal {
  height: 200px;
}

.spendit-modal.small .modal-content #fuel-modal .add_button_area {
  width: 550px;
  margin-top: 50px;
  float: right;
  border-top: 1px solid #cccccc;
}

.spendit-modal.small .modal-content #fuel-modal .add_button_area .add_button {
  width: 100px;
  height: 38px;
  border-radius: 2px;
  background-color: #047de0;
  margin: 10px;
  float: right;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid #047de0;
}

.spendit-modal.small .modal-content #fuel-modal .fuel-desc {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: #333333;
}

#attendees-overlay .internal-attendees {
  width: 100%;
  height: 20px;
  position: relative;
}

#attendees-overlay .external-attendees {
  width: 100%;
  height: 20px;
  position: relative;
}

#note-overlay .note-visible {
  cursor: pointer;
  float: left;
}

#note-overlay .note-visible .overlay-custom {
  padding: 5px 5px;
  display: none;
  min-height: 40px;
  border-radius: 5px;
  background-color: #000000;
  border: solid 1px #ffffff;
  z-index: 200;
  width: 151px;
  margin-left: -135px;
  margin-top: 35px;
  position: absolute;
  color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
}

#note-overlay .note-visible .overlay-custom:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #000000;
  right: 20%;
  top: -12px;
}

#note-overlay .note-visible .overlay-custom:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#note-overlay .note-visible .overlay-custom p {
  line-height: 26px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  float: left;
  margin-left: 2px;
  height: 20px;
}

#note-overlay .note-visible:hover .overlay-custom {
  display: inline-block !important;
}

#note-overlay .note-visible .overlay-custom .overlay_title {
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-left: 2px;
}

#note-overlay .note-visible .overlay-custom .sub-title {
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-left: 2px;
}

#replace-approver .setting-approver {
  width: 10%;
}

#replace-approver .has-mandators span {
  color: #555555;
}

#replace-approver .has-mandators span.mandators {
  font-size: 12px;
}

#replace-approver .has-mandators .mandator-list p {
  line-height: 25px;
  font-size: 13px;
}

#replace-approver .has-mandators .mandator-list .image {
  background-size: 25px;
  float: left;
  margin-right: 5px;
  width: 25px;
  background-position: center;
  height: 25px;
  border-radius: 100%;
  background-repeat: no-repeat;
}

#replace-approver .has-mandators .mandator-list {
  height: 154px;
  max-height: 154px;
  padding: 3px 5px;
  margin: 5px 0;
  color: #555555;
  overflow-y: auto;
  border: 1px solid #d5d5d5;
  background-color: #fff !important;
}

#replace-approver .setting-approver-desc {
  width: 70%;
  font-size: 13px;
  color: #555555;
}

#replace-approver dl dd.select-approver {
  width: 80%;
}

#replace-approver dt {
  font-size: 13px;
  width: 20% !important;
  color: #555555;
}

.detail .config-buttons #approver-save {
  width: 78px;
  height: 33px;
  border-radius: 2px;
  background-color: #97a9c0;
  border: 1px solid #97a9c0;
}

.detail .config-buttons #approver-save-disabled {
  width: 78px;
  height: 33px;
  border-radius: 2px;
  background-color: rgba(2, 40, 85, 0.08);
  border: 1px solid rgba(2, 40, 85, 0.08);
}

#row-overlay .row-visible {
  cursor: pointer;
  float: left;
  width: 100%;
}

#row-overlay .row-visible .overlay-custom {
  padding: 5px 5px;
  min-height: 20px;
  border-radius: 5px;
  background-color: #000000;
  border: solid 1px #ffffff;
  z-index: 100;
  width: 100px;
  text-align: center;
  margin-left: 10px;
  margin-top: 20px;
  position: absolute;
  color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 11px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 20px;
  display: none;
}

#row-overlay .row-visible .overlay-custom:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #000000;
  left: 20%;
  top: -12px;
}

#row-overlay .row-visible .overlay-custom:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#row-overlay .row-visible:hover .overlay-custom {
  display: inline-block !important;
}

#row-overlay .row-visible .overlay-custom .overlay_title {
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-left: 2px;
}

#row-overlay .row-visible .overlay-custom .sub-title {
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-left: 2px;
}

#plan-overlay .plan-visible {
  cursor: pointer;
  float: left;
  width: 100%;
}

#plan-overlay .plan-visible .overlay-custom {
  padding: 5px 5px;
  display: none;
  min-height: 20px;
  border-radius: 5px;
  background-color: #000000;
  border: solid 1px #ffffff;
  z-index: 200;
  width: 120px;
  margin-left: -110px;
  margin-top: 35px;
  position: absolute;
  color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  font-size: 10px;
  line-height: 1.8;
  text-align: center;
}

#plan-overlay .plan-visible .overlay-custom:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #000000;
  right: 20%;
  top: -12px;
}

#plan-overlay .plan-visible .overlay-custom:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#plan-overlay .plan-visible:hover .overlay-custom {
  display: inline-block !important;
}

#expense-add #edit-distance::placeholder {
  color: #7d7d7d;
  font-size: 12px;
  padding-left: 128px;
}

#expense-add #price::placeholder {
  color: #7d7d7d;
  font-size: 12px;
  padding-left: 120px;
}

#admin-settings .detail .menu li {
  max-width: 200px;
}

#pwd-tooltip {
  z-index: 10000;
  position: absolute;
  width: 310px;
  height: 200px;
  background-color: #ffff;
  border: solid 1px rgba(4, 125, 224, 0.5);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  left: calc(50% - 190px);
}

#pwd-tooltip:after {
  content: '';
  position: absolute;
  border-top: 10px solid #ffffff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  top: -10px;
  left: 145px;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

#pwd-tooltip:before {
  content: '';
  position: absolute;
  border-top: 10px solid rgba(4, 125, 224, 0.5);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  left: 145px;
  top: -11px;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

#pwd-tooltip .pwd-necessary {
  float: left;
}

#pwd-tooltip .check-pwd {
  margin: 5px 0 5px 30px;
  float: left;
  width: calc(100% - 30px);
  font-size: 12px;
}

#pwd-tooltip .check-pwd .check-value {
  float: left;
  line-height: 12px;
}

#pwd-tooltip .check-pwd .checked-icon {
  background-color: #047de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/checked.png');
  background-size: 100%;
  border-radius: 10px;
  box-shadow: inset 0 1px 3px 0 var(--black-50);
  width: 12px;
  height: 12px;
  float: left;
  margin-right: 9px;
}

#pwd-tooltip .check-pwd .unchecked-icon {
  background-color: #047de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/unchecked.png');
  background-size: 100%;
  border-radius: 10px;
  box-shadow: inset 0 1px 3px 0 var(--black-50);
  width: 12px;
  height: 12px;
  float: left;
  margin-right: 9px;
}

#pwd-tooltip .pwd-necessary p {
  margin: 20px 0 10px 30px;
}

#pwd-tooltip p {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #1e1e1e;
  float: left;
  margin: 5px 0 5px 30px;
}

#pwd-tooltip .safety-bar {
  float: left;
  width: calc(100% - 30px);
  margin: 10px 0 0 30px;
}

#pwd-tooltip .safety-bar .normal {
  width: 80px;
  height: 5px;
  margin-left: 2px;
  float: left;
  background-color: #ebebeb;
}

#pwd-tooltip .safety-bar .unfit {
  width: 80px;
  height: 5px;
  margin-left: 2px;
  float: left;
  background-color: #e65858 !important;
}

#pwd-tooltip .safety-bar .unfit-icon {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/pwd-unfit-icon.svg');
  background-repeat: no-repeat;
  float: left;
  position: absolute;
  right: 191px;
  top: 157px;
  width: 14px;
  height: 20px;
}

#pwd-tooltip .safety-bar .suitable {
  width: 80px;
  height: 5px;
  margin-left: 2px;
  float: left;
  background-color: #ffdd6b !important;
}

#pwd-tooltip .safety-bar .suitable-icon {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/pwd-suitable-icon.svg');
  background-repeat: no-repeat;
  float: left;
  position: absolute;
  right: 108px;
  top: 157px;
  width: 14px;
  height: 20px;
}

#pwd-tooltip .safety-bar .strong {
  width: 80px;
  height: 5px;
  margin-left: 2px;
  float: left;
  background-color: #49c487 !important;
}

#pwd-tooltip .safety-bar .strong-icon {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/pwd-strong-icon.svg');
  background-repeat: no-repeat;
  float: left;
  position: absolute;
  right: 28px;
  top: 157px;
  width: 14px;
  height: 20px;
}

.foreign-price,
.origin-price {
  font-size: 10px;
}
