.spendit-modal#policy-upload-modal #invitation-error {
  font-size: 14px;
  margin-top: 10px;
  color: #de3c3c;
}

.spendit-modal#policy-upload-modal .modal-content .modal-main-desc {
  height: 60px;
}

.spendit-modal#policy-upload-modal .modal-desc p {
  font-size: 14px;
  margin-bottom: 20px;
}

.spendit-modal#policy-upload-modal .modal-desc .modal-main-desc .text-style-1 {
  font-size: 14px;
  margin-bottom: 10px;
}

.spendit-modal#policy-upload-modal .modal-desc .modal-main-desc .text-style-2 {
  font-size: 12px;
  margin-bottom: 10px;
  color: #7d7d7d;
}

.spendit-modal#policy-upload-modal .modal-desc .modal-main-desc .text-style-3 {
  font-size: 12px;
  margin-bottom: 5px;
  color: #de3c3c;
}
border-radius .spendit-modal#policy-upload-modal .modal-desc .Select-control {
  height: auto !important;
}

.spendit-modal#policy-upload-modal .modal-desc .Select-arrow-zone {
  display: none;
}

.spendit-modal#policy-upload-modal .modal-desc input {
  /*width: 100%; !*(fallback) *!*/
  /*width: -webkit-calc(100% - 30px); !* for Chrome, Safari *!*/
  /*width: -moz-calc(100% - 30px); !* for Firefox *!*/
  /*width: calc(100% - 30px); !* for IE *!*/
  /*border: 1px solid #ddd;*/
  /*border-radius: 2px;*/
  /*height: 40px;*/
  /*margin-top: 20px;*/
  /*padding: 0 15px;*/
  outline: none;
  height: 25px !important;
  width: 100px;
}

.spendit-modal#policy-upload-modal .modal-desc .csv_upload {
  display: flex;
  width: calc(100% - 30px);
  padding: 15px;
  position: relative;
  float: left;
  margin: 10px 0;
  border: solid 1px #cecece;
  border-radius: 2px;
  background-color: #fafafa;
}

.spendit-modal#policy-upload-modal .modal-desc .csv_upload input {
  display: none;
}

#policy-upload-modal .modal-content .modal-desc .result .subtitle.success {
  float: left;
  font-weight: normal;
  color: #555555;
  padding-left: 20px;
  margin-bottom: 14px;
  font-size: 14px;
  background-size: 15px;
  background-position: 1px 3px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/csv-invitation-success.png');
}

#policy-upload-modal .modal-content .modal-desc .result .subtitle.not-name {
  color: #888888;
}

#policy-upload-modal .modal-submit-button {
  height: 60px;
}

#policy-upload-modal .modal-submit-button .button-field {
  width: 100%;
  margin-top: 10px;
  float: left;
}

#policy-upload-modal .modal-content .modal-desc .result .subtitle.fail {
  float: left;
  font-weight: normal;
  color: #555555;
  padding-left: 20px;
  background-size: 15px;
  margin-bottom: 14px;
  font-size: 14px;
  height: 15px;
  background-position: 1px 3px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-policy.png');
}

#policy-upload-modal .modal-content .modal-desc .result .csv-result {
  float: left;
  font-size: 14px;
  margin-left: 5px;
  color: #555555;
}

#policy-upload-modal .modal-content .modal-desc .result .subtitle {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
}

#policy-upload-modal .modal-content .modal-desc .result {
  font-size: 13px;
  padding: 10px;
  line-height: 20px;
  margin: 5px 0;
  float: left;
}

#policy-upload-modal .modal-content .modal-desc .result .reason {
  padding-bottom: 5px;
}

#policy-upload-modal .modal-content .modal-desc .result .reason .detail {
  color: #e65858;
  /*display: inline-block;*/
}

#policy-upload-modal .modal-content .modal-desc .result .upload {
  height: 200px;
  overflow-y: auto;
  width: 1800px;
}

#policy-upload-modal #user-upload-table td.td-1 .expense-policy {
  width: 15px;
  margin: 0 auto;
  background-size: 15px;
  background-repeat: no-repeat;
}

/* user upload table */
#policy-upload-modal #user-upload-table .csv-td-1 {
  padding-left: 16px;
}

#policy-upload-modal #user-upload-table .csv-td-2 {
}

#policy-upload-modal #user-upload-table .csv-td-3 {
}

#policy-upload-modal #user-upload-table {
  width: 100%;
  margin-top: 20px;
  font-size: 13px;
  color: #333;
  border: solid 1px #cecece;
}

#policy-upload-modal #user-upload-table thead th {
  text-align: left;
  line-height: 25px;
}

#policy-upload-modal #user-upload-table tbody tr:first-child {
  border-top: solid 1px #d0d0d0;
}

#policy-upload-modal #user-upload-table tbody tr {
  height: 39px;
  line-height: 16px;
  border-bottom: solid 1px #e8e8e8;
}

#policy-upload-modal #user-upload-table tbody tr:last-child {
  border-bottom: solid 1px #d0d0d0;
}

#policy-upload-modal #user-upload-table tbody tr:hover {
  background-color: transparent;
}

#policy-upload-modal #user-upload-table tbody .selected {
  background-color: transparent;
}

#policy-upload-modal #user-upload-table tbody tr td {
  vertical-align: middle;
}

#policy-upload-modal #user-upload-table td.td-1 {
  width: 5%;
}

#policy-upload-modal #user-upload-table td.td-2 {
  width: 35%;
}

#policy-upload-modal #user-upload-table td.td-3 {
  line-height: 25px;
  width: 60%;
}

#policy-upload-modal .invited_complete {
  float: left;
  width: 100%;
}

#policy-upload-modal .div-table-header {
  background-color: #f0f2f4;
  line-height: 36px;
  background-color: #fafafa;
  outline: none;
  font-size: 12px;
  text-align: left;
  color: #7d7d7d;
  float: left;
  width: 100%;
}

#policy-upload-modal .policy-upload-td {
  width: 100px;
  overflow-wrap: break-word;
  margin: 0 10px;
  float: left;
}

#policy-upload-modal .policy-upload-td.tag {
  width: 110px;
  overflow-wrap: break-word;
  float: left;
  margin: 0 0 0 10px;
}

#policy-upload-modal .policy-upload-td.policy-member {
  width: 83px;
  overflow-wrap: break-word;
  margin: 0 10px;
  float: left;
}

#policy-upload-modal .div-table-header.tag {
  outline: none;
  font-size: 12px;
  text-align: left;
  color: #7d7d7d;
  float: left;
  border-bottom: none;
  width: calc(100% - 70px);
}

#policy-upload-modal .div-table-header .policy-upload-td {
  width: 100px;
  overflow-wrap: break-word;
  margin: 0 10px;
}

#policy-upload-modal .div-table-header .policy-upload-td.tag {
  width: 110px;
  overflow-wrap: break-word;
  margin: 0 0 0 10px;
}

#policy-upload-modal .div-table-header .policy-upload-td.policy-member {
  width: 83px;
  overflow-wrap: break-word;
  margin: 0 10px;
}

#policy-upload-modal .div-table-header .policy-upload-td.policy-member:nth-child(4),
#policy-upload-modal .policy-upload-td.policy-member:nth-child(4) {
  width: 70px;
}

#policy-upload-modal .div-table-header .policy-upload-td.policy-member:nth-child(5),
#policy-upload-modal .policy-upload-td.policy-member:nth-child(5) {
  width: 109px;
}

#policy-upload-modal .div-table-header .policy-upload-td.policy-member:nth-child(6),
#policy-upload-modal .policy-upload-td.policy-member:nth-child(6) {
  width: 70px;
}

#policy-upload-modal .div-table-header.policy-member {
  outline: none;
  font-size: 12px;
  text-align: left;
  color: #7d7d7d;
  float: left;
  box-sizing: border-box;
}

#policy-upload-modal .div-table-body {
  float: left;
  width: 100%;
  font-size: 11px;
  border-top: 1px solid #edeff1;
}

#policy-upload-modal .div-table-body.tag {
  margin: 36px 10px 0 10px;
  padding-top: 0;
  width: calc(100% - 20px);
}

#policy-upload-modal .div-table-body.tag .policy-upload-row {
  width: 100%;
}

#policy-upload-modal .div-table-body.policy-member {
  margin: 0 10px 0 10px;
  width: calc(100% - 20px);
}

#policy-upload-modal .div-table-body span {
  font-weight: bold;
}

#policy-upload-modal .div-table-body .policy-upload-row {
  border-bottom: 1px solid #efefef;
  display: inline-block;
  float: left;
  font-size: 12px;
  width: 100%;
}

#policy-upload-modal .div-table-body-wrapper {
  line-height: 12px;
  height: 29px;
}

#policy-upload-modal .result-field {
  overflow-x: scroll;
  margin-top: 10px;
  width: 640px;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
}

#policy-upload-modal .div-table-header-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#policy-upload-modal .policy-upload-row p {
  margin-bottom: 0px !important;
  font-size: 12px !important;
  font-weight: normal !important;
  float: left;
  width: 100px;
  margin: 2px 2px 2px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 15px;
}

#policy-upload-modal .policy-upload-row p.bold-name {
  font-weight: bold !important;
}

#policy-upload-modal .policy-upload-row .error {
  color: #de3c3c;
  float: left;
}

#policy-upload-modal .invited_complete .desc {
  width: calc(100% - 40px);
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.2px;
  text-align: center;
  float: left;
  color: #7d7d7d;
}

#policy-upload-modal .result-field::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 0px !important;
}

#policy-upload-modal ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #d8d8d8 !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

#policy-upload-overlay {
  float: left;
}

#policy-upload-overlay .icon-visible {
  cursor: pointer;
  float: left;
  width: 100%;
  position: relative;
}

#policy-upload-overlay .icon-visible .overlay-custom {
  padding: 5px 5px;
  display: none;
  min-height: 20px;
  border-radius: 5px;
  background-color: #000000;
  z-index: 200;
  width: 120px;
  margin-left: -100px;
  margin-top: 25px;
  position: absolute;
  color: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  font-size: 10px;
  line-height: 1.8;
  text-align: center;
}

#policy-upload-overlay .icon-visible .overlay-custom:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #000000;
  right: 45%;
  top: -12px;
}

#policy-upload-overlay .icon-visible .overlay-custom:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#policy-upload-overlay .icon-visible:hover .overlay-custom {
  display: inline-block !important;
}

#policy-upload-modal .icon {
  width: 30px;
  float: left;
  margin-right: 10px;
}

#policy-upload-modal .result-area {
  width: 650px;
}

#policy-upload-modal .result-area .result-section {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}

#policy-upload-modal .icon p {
  float: left;
  width: 30px;
  margin-bottom: 0px !important;
}

#policy-upload-modal .result-area p.result-title {
  color: #333333;
  font-size: 16px !important;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 0px !important;
}

#policy-upload-modal .result-area p.result-desc {
  color: #7d7d7d;
  font-size: 14px;
  margin: 10px 0 0 0;
  /* margin: auto; */
  text-align: center;
  float: left;
  width: 100%;
}

#policy-upload-modal .result-area p.result-desc-sub {
  color: #7d7d7d;
  font-size: 14px;
  margin: 10px 0 0 0;
  float: left;
  width: 100%;
  text-align: center;
}
