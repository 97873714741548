.sidebar-div {
    background-color: #022855;
    height: 100%;
    width: 80%;
    max-width: 300px;
}

.sidebar-div li {
    color: #F2F2F2;
    font-size: 18px !important;
    text-align: left;
    margin-left: 50%;
    transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    line-height: 50px;
    border-bottom: solid 1px #8093aa;
    font-weight: 200;
    width: 80%;
}

.selected_menu {
    border-bottom: solid 2px #5bb5ff !important;
}

.selected_menu .triangle{
    transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
}

.current-menu {
    font-weight: 600 !important;
}

.triangle {
    background-image: url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/triangle.png);
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 5px;
 }

.key_features_submenu {
    font-size: 10px !important;
    letter-spacing: -1px;
    line-height: 40px !important;
}
#landing-nav-wrapper {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
}
#mobile-landing-nav-wrapper {
    position: absolute;
    top: -40px;
    left: 0;
    z-index: 3;
    width: 100%;
}
#mobile-landing-nav {
    position: fixed;
    width: 100%;
}

.mobile-nav-open {
    height: 100%;
}

#mobile-landing-top-bar {
    height: 40px;
    background-color: #022855;
    width: 100%;
    background-image: url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/spendit-small-logo.png);
    background-size: 100px;
    background-position: center center;
    background-repeat: no-repeat;
}

#hamburger {
    background-image: url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/hamburger.png);
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    width: 20px;
    height: 18px;
    left: 12px;
    top: 12px;
    position: absolute;
}

.nav-logo {
    height: 25px;
    color: #F2F2F2;
    text-align: center;
    margin-top: 15px;
}

.close-nav {
    height: 25px;
    width: 25px;
    background-image: url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/ex.png);
    background-size: 15px;
    background-position: center center;
    background-repeat: no-repeat;
    position: fixed;
    left: 10px;
    top: 8px;
}