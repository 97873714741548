/* 카드, 계좌, 환급 관리 페이지 css */
#cards-view .content {
  margin-top: 83px;
}

#cards-view .card-corp-td-1 {
  padding-left: 10px;
  width: 3%;
}

#cards-view .card-corp-td-2 p {
  margin: 0 10px;
}

#cards-view .card-corp-td-2 {
  text-align: left;
  width: 4%;
}

#cards-view .card-corp-td-3 p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view .card-corp-td-3 {
  width: 9%;
  text-align: left;
}

#cards-view .card-corp-td-4 {
  width: 20%;
}

#cards-view .card-corp-td-4 img {
  float: left;
  margin-right: 10px;
}

#cards-view .card-corp-td-4 p {
  font-weight: bold;
  color: #333333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view .div-table-body-wrapper .card-corp-td-5 {
  color: #333333;
  max-width: 100px;
}

#cards-view .div-table-body-wrapper .card-corp-td-5 div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view .card-corp-td-5 {
  width: 25%;
  padding-left: 10px;
}

#cards-view .card-corp-td-5 {
  color: #7d7d7d;
}

#cards-view .div-table-body-wrapper .card-corp-td-5 {
  font-size: 12px !important;
}

#cards-view .div-table-body-wrapper .card-corp-td-6 .add_code {
  color: #047de0;
}

#cards-view .card-corp-td-6 {
  width: 10%;
}

#cards-view .card-corp-td-7 {
  width: 2%;
}

#cards-view .card-corp-td-8 {
  width: 2%;
}

#cards-view .card-td-select {
  padding-left: 10px;
  width: 5%;
}

#cards-view .card-td-1 {
  padding-left: 10px;
  width: 5%;
}

#cards-view .card-td-2 {
  padding-left: 10px;
  width: 25%;
}

#cards-view .card-td-2 img {
  float: left;
  margin-right: 10px;
}

#cards-view .card-td-usage {
  text-align: right;
  width: 10%;
  padding-right: 10px;
}

#cards-view .card-td-usage-company {
  text-align: left;
  width: 10%;
  padding-right: 10px;
}

#cards-view .card-corp-td-4 .card-info,
#cards-view .card-td-2 .card-info,
#cards-view .card-detail .card-info {
  height: 25px;
  margin-bottom: 3px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view .card-corp-td-4 .card-info span,
#cards-view .card-td-2 .card-info span,
#cards-view .card-detail .card-info span {
  font-weight: bold;
  color: #333333;
  font-size: 14px;
  line-height: 25px;
}

#cards-view .card-number {
  font-size: 12px;
  color: #5b5b5b;
}

#mypage #cards-view .card-td-icons-header {
  width: 12%;
}

#mypage #cards-view .card-td-icons .div-table-body-wrapper > div {
  margin: 0 auto;
}

#mypage #cards-view .card-td-icons {
  width: 3%;
  float: right;
}

#mypage #cards-view .card-td-3 {
  width: 3%;
}

#cards-view .card-td-4,
#cards-view .card-td-5,
#cards-view .card-td-6 {
  width: 3%;
}

#cards-view > #card-type.company-card.types {
  width: 70%;
  float: left;
  border: none !important;
}

#cards-view .bank-td-select {
  width: 5%;
  padding-left: 10px;
}

#cards-view .bank-td-logo .bank-type {
  width: 50px;
  float: left;
  margin: 0 20px;
  text-align: center;
  line-height: 25px;
  height: 25px;
  border-radius: 100px;
}

#cards-view .bank-td-logo .personal {
  color: #1ca9c0;
  border: solid 1px #1ca9c0;
}

#cards-view .bank-td-logo .corporation {
  color: #037dde;
  border: solid 1px #037dde;
}

#cards-view .bank-td-logo {
  width: 5%;
}

#cards-view .bank-td-info {
  width: 12%;
}

#cards-view .bank-td-info img {
  float: left;
}

#cards-view .div-table-body-wrapper p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view .div-table-body-wrapper .bank-td-info span {
  margin-left: 10px;
  line-height: 25px;
}

#cards-view .bank-td-owner {
  width: 10%;
}
#cards-view .bank-td-name {
  width: 15%;
}

#cards-view .bank-td-user {
  width: 20%;
}

#cards-view .bank-td-number {
  width: 25%;
}

#cards-view .bank-td-setting-header {
  width: 9%;
}

#cards-view .bank-td-setting .div-table-body-wrapper > div {
  margin: 0 auto;
}

#cards-view .bank-td-setting {
  width: 3%;
  float: right !important;
}

#cards-view .download-btn-area {
  width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
  width: -moz-calc(100% - 70px); /* for Firefox */
  width: calc(100% - 70px);
  padding: 25px 0 0 0;
  height: 57px;
  background-color: #fff;
  z-index: 100;
  position: fixed;
  border-bottom: 1px solid #efefef;
}

#card-type.company-card.sort {
  text-align: right;
  padding-bottom: 0 !important;
}

.company-card #card-type.company-card {
  border: none !important;
}

.company-card #card-info > #card-type {
  padding-bottom: 0 !important;
}

#cards-view #card-type {
  padding-bottom: 10px;
  float: right;
}

#card-type li {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 12px;
  margin-top: 10px;
  border-right: 1px solid #ddd;
  padding-right: 10px;
  cursor: pointer;
}

#card-type li:last-child {
  border: none;
}

#card-type li.selected {
  font-weight: 700;
  color: #047de0;
}

#split-view #card-info {
  display: block;
  width: 250px;
  height: 100%;
  position: fixed;
  padding: 0 5px 20px 5px;
  border-right: 1px solid #ddd;
  z-index: 5;
  background-color: #fff;
}

#split-view .simple-button-area {
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #e8e8e8;
}

#split-view #card-info table {
  margin-top: 30px;
}

#split-view #card-info #card-list-div {
  overflow-y: auto;
  height: 80%; /*(fallback) */
  height: -webkit-calc(100% - 280px); /* for Chrome, Safari */
  height: -moz-calc(100% - 280px); /* for Firefox */
  height: calc(100% - 280px); /* for IE */
}

#split-view #card-info #card-list .div-pagination {
  border-top: 2px solid #e8e8e8;
}

#split-view #card-info .card-detail .card-info div {
  float: right;
  width: 80%;
  line-height: 18px;
  font-weight: normal;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#split-view #card-info .card-buttons {
  width: 30px;
  text-align: right;
}

#split-view #card-info .card-detail {
  width: 155px;
  height: 50px;
  padding-top: 10px;
}

#split-view #card-info #card-list .card-li {
  border-bottom: 1px solid #e8e8e8;
}

#split-view #card-info #card-list .selectable {
  cursor: pointer;
}

#split-view #card-info #card-list ul li {
  display: inline-block;
  margin: 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

#split-view #card-info #card-list li.icons {
  width: 60px;
  text-align: right;
}

#split-view #card-info #card-list li:last-child {
  border: none;
}

#split-view #card-info #card-list .selectable:hover {
  background-color: #e9f0f5;
}

#split-view #card-info #card-list .selected {
  background-color: #e9f0f5;
}

#split-view #card-info #card-list li .card-company {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.2px;
  color: #333333;
}

#split-view #card-info #card-list li .card-number {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  padding-top: 5px;
  letter-spacing: -0.2px;
  color: #5b5b5b;
  font-weight: normal;
}

#split-view #card-info #card-list .icon li {
  display: inline-block;
}

#split-view #card-info #card-list li .card-number span {
  font-size: 7px;
  vertical-align: middle;
}

/* cards-view-custom */
#cards-view-custom .content {
  padding-top: 120px;
  height: 100%;
  box-sizing: border-box;
}

#cards-view-custom .card-corp-td-1 {
  padding-left: 10px;
  width: 3%;
}

#cards-view-custom #btn-export-download {
  margin-right: 5px;
}

#cards-view-custom .card-corp-td-2 p {
  margin: 0 10px;
}

#cards-view-custom .card-corp-td-2 {
  text-align: left;
  width: 4%;
}

#cards-view-custom .card-corp-td-3 p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view-custom .card-corp-td-3 {
  width: 9%;
  text-align: left;
}

#cards-view-custom .card-corp-td-4 {
  width: 20%;
}

#cards-view-custom .card-corp-td-4 img {
  float: left;
  margin-right: 10px;
}

#cards-view-custom .card-corp-td-4 p {
  font-weight: bold;
  color: #333333;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view-custom .div-table-body-wrapper .card-corp-td-5 {
  color: #333333;
  max-width: 100px;
}

#cards-view-custom .div-table-body-wrapper .card-corp-td-5 div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view-custom .card-corp-td-5 {
  width: 23%;
  padding-left: 10px;
}

#cards-view-custom .card-corp-td-5 span {
  color: #7d7d7d;
}

#cards-view-custom .div-table-body-wrapper .card-corp-td-5 {
  font-size: 12px !important;
}

#cards-view-custom .div-table-body-wrapper .card-corp-td-6 .add_code {
  color: #047de0;
}

#cards-view-custom .card-corp-td-6 {
  width: 10%;
}

#cards-view-custom .card-corp-td-6 .erp-code {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view-custom .card-corp-td-7 {
  width: 34px;
  padding-right: 5px;
}

#cards-view-custom .card-corp-td-8 {
  width: 26px;
}

#cards-view-custom .card-td-select {
  padding-left: 10px;
  width: 5%;
}

#cards-view-custom .card-td-1 {
  padding-left: 10px;
  width: 5%;
}

#cards-view-custom .card-td-2 {
  padding-left: 10px;
  width: 25%;
}

#cards-view-custom .card-td-2 img {
  float: left;
  margin-right: 10px;
}

#cards-view-custom .card-td-usage {
  text-align: right;
  width: 10%;
  padding-right: 10px;
}

#cards-view-custom .card-td-usage-company {
  text-align: left;
  width: 10%;
  padding-right: 10px;
}

#cards-view-custom #btn-export-transaction,
#cards-view-custom #btn-export-csv,
#cards-view-custom #btn-remove,
#cards-view-custom #btn-download,
#cards-view-custom #btn-remove {
  margin-right: 5px;
}

#swal2-content .info-area {
  height: 34px;
  float: left;
  margin-left: 75px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin-top: 10px;
}

#swal2-content .info-area .duzon-icon {
  float: left;
  height: 34px;
  width: 90px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/erp/duzon.png');
  background-size: 75px;
  background-repeat: no-repeat;
  background-position: 15px 11px;
}

#swal2-content .info-area .duzon-info {
  float: left;
  font-size: 13px;
  padding: 8px 15px;
  text-align: left;
}

#cards-view-custom .card-corp-td-4 .card-info,
#cards-view-custom .card-td-2 .card-info,
#cards-view-custom .card-detail .card-info {
  height: 25px;
  margin-bottom: 3px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view-custom .card-corp-td-4 .card-info span,
#cards-view-custom .card-td-2 .card-info span,
#cards-view-custom .card-detail .card-info span {
  font-weight: bold;
  color: #333333;
  font-size: 14px;
  line-height: 25px;
}

#cards-view-custom .card-number {
  font-size: 12px;
  color: #5b5b5b;
}

#mypage #cards-view-custom .card-td-icons-header {
  width: 12%;
}

#mypage #cards-view-custom .card-td-icons .div-table-body-wrapper > div {
  margin: 0 auto;
}

#mypage #cards-view-custom .card-td-icons {
  width: 3%;
  float: right;
}

#mypage #cards-view-custom .card-td-3 {
  width: 3%;
}

#cards-view-custom .card-td-4,
#cards-view-custom .card-td-5,
#cards-view-custom .card-td-6 {
  width: 3%;
}

#cards-view-custom > #card-type.company-card.types {
  width: 70%;
  float: left;
  border: none !important;
}

#cards-view-custom .bank-td-select {
  width: 5%;
  padding-left: 10px;
}

#cards-view-custom .bank-td-logo .bank-type {
  width: 50px;
  float: left;
  margin: 0 20px;
  text-align: center;
  line-height: 25px;
  height: 25px;
  border-radius: 100px;
}

#cards-view-custom .bank-td-logo .personal {
  color: #1ca9c0;
  border: solid 1px #1ca9c0;
}

#cards-view-custom .bank-td-logo .corporation {
  color: #037dde;
  border: solid 1px #037dde;
}

#cards-view-custom .bank-td-logo {
  width: 5%;
}

#cards-view-custom .bank-td-info {
  width: 12%;
}

#cards-view-custom .bank-td-info img {
  float: left;
}

#cards-view-custom .div-table-body-wrapper p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#cards-view-custom .div-table-body-wrapper .bank-td-info span {
  margin-left: 10px;
  line-height: 25px;
}

#cards-view-custom .bank-td-owner {
  width: 10%;
}
#cards-view-custom .bank-td-name {
  width: 15%;
}

#cards-view-custom .bank-td-user {
  width: 20%;
}

#cards-view-custom .bank-td-number {
  width: 25%;
}

#cards-view-custom .bank-td-setting-header {
  width: 9%;
}

#cards-view-custom .bank-td-setting .div-table-body-wrapper > div {
  margin: 0 auto;
}

#cards-view-custom .bank-td-setting {
  width: 3%;
  float: right !important;
  padding-right: 10px;
}

#company-groups .download-btn-area,
#cards-view-custom .download-btn-area {
  width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
  width: -moz-calc(100% - 70px); /* for Firefox */
  width: calc(100% - 70px);
  height: 30px;
  background-color: #f7f8fa;
  position: relative;
  float: left;
  padding: 19px 35px 0;
}

#company-taxes .top-area,
#company-groups .top-area,
#cards-view-custom .top-area {
  z-index: 90;
  right: 1px;
  height: 120px;
  position: fixed;
  width: 100%;
}

#company-taxes .button-search-area,
#company-groups .button-search-area,
#cards-view-custom .button-search-area {
  position: relative;
  float: left;
  width: 100%;
  height: 55px;
  background-color: #ffffff;
  z-index: 1;
}

#company-taxes .search-area,
#company-groups .search-area {
  position: relative;
  float: left;
  width: 100%;
  height: 30px;
  margin-top: 25px;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
}

#cards-view-custom .search-area {
  display: flex;
  flex: 1;
  height: 30px;
  margin-top: 25px;
  padding: 0 35px;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
}

#company-taxes .search-area input:focus,
#company-groups .search-area input:focus,
#cards-view-custom .search-area input:focus {
  background-color: transparent !important;
  border: 0 solid !important;
  color: #000000 !important;
}

#company-taxes .search-area input[type='text'],
#company-groups .search-area input[type='text'],
#cards-view-custom .search-area input[type='text'] {
  position: relative;
  float: left;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.3px;
  font-size: 13px;
  text-align: center;
  color: #000000;
  border-bottom: solid 2px #002855 !important;
  left: calc(50% - 15% - 100px);
  width: 30%;
  height: 30px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 95%;
}

#cards-view-custom #card-type {
  padding-bottom: 10px;
  float: right;
}
