#overlay-trigger {
  position: relative;
  text-align: left;
  cursor: pointer;
  margin: 0 auto;
  outline: none;
  z-index: 1;
}
#overlay-trigger.open {
  z-index: 2;
}
#overlay-trigger .report-attendee-overlay {
  width: 200px !important;
}

#overlay-trigger .overlay {
  width: 300px;
  position: absolute;
  border-radius: 4px;
  padding: 20px;
  z-index: 65;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #ebebeb;
}

#overlay-trigger .overlay .overlay-title {
  font-weight: bold;
  padding-bottom: 5px;
  margin: 5px 0;
  border-bottom: 1px solid #cccccc;
}

#overlay-trigger .overlay .overlay-content.link:hover {
  color: #057de0;
  text-decoration: underline;
}

#overlay-trigger .overlay {
  line-height: 20px;
}

#overlay-trigger .report-expenses-attendee-overlay {
  font-size: 12px;
  width: 200px;
}

#overlay-trigger .report-expenses-attendee-overlay .attendee-overlay-title {
  font-weight: bold;
  margin-bottom: 5px;
}

#overlay-trigger .report-expenses-attendee-overlay .external-attendee-title {
  color: #0f4486;
}

#overlay-trigger .report-expenses-attendee-overlay .internal-attendee-title {
  color: #67abf0;
}
#overlay-trigger .report-expenses-attendee-overlay .overlay-bar {
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
  margin: 15px auto;
}
.with-overlay {
  float: left;
}

div#overlay-trigger.icon-config-info {
  float: left;
  margin: auto 10px;
  width: 12px;
}

div#overlay-trigger.icon-config-info .overlay {
  padding: 15px !important;
}

div#overlay-trigger.icon-config-info .overlay .overlay-content {
  line-height: 20px;
}
