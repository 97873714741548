#new-user-bank-modal .modal-content {
  width: 700px;
}

#new-user-card-modal .modal-content {
  width: 800px;
}

#new-user-bank-modal .modal-desc .type-wrapper,
#new-user-card-modal .modal-desc .type-wrapper {
  padding: 5px 35px 10px;
  text-align: center;
}

#new-user-bank-modal .select-bank-type,
#new-user-card-modal .select-card-type {
  display: inline-block;
}

#new-user-bank-modal .select-bank-type .bank-type:hover {
  background-color: #047de0;
  border: solid 1px #005baa;
  color: #ffffff;
}

#new-user-card-modal .select-card-type .card-type {
  position: relative;
  padding-top: 30px;
  width: 180px;
  height: 300px;
  box-sizing: border-box;
  float: left;
  background-color: #f8f8f8;
  border-radius: 10px;
  border: solid 2px #d3dae0;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.33px;
  cursor: pointer;
}

#new-user-card-modal .select-card-type .card-type + .card-type {
  margin-left: 20px;
}

#new-user-card-modal .select-card-type .card-type:before {
  position: absolute;
  content: '';
  z-index: 1;
}
#new-user-card-modal .select-card-type .card-type:after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 55px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  content: '';
}

#new-user-card-modal .select-card-type .card-type.corporation:hover {
  border-color: #2e59af;
}
#new-user-card-modal .select-card-type .card-type.corporation:before {
  right: 20px;
  bottom: 43px;
  width: 143px;
  height: 135px;
  background-image: url('images/card-type-corporation-background.png');
  background-size: 143px 135px;
}
#new-user-card-modal .select-card-type .card-type.corporation:after {
  background-color: #2e59af;
}

#new-user-card-modal .select-card-type .card-type.personal:hover {
  border-color: #1a4c49;
}
#new-user-card-modal .select-card-type .card-type.personal:before {
  right: 21px;
  bottom: 32px;
  width: 137px;
  height: 119px;
  background-image: url('images/card-type-personal-background.png');
  background-size: 137px 119px;
}
#new-user-card-modal .select-card-type .card-type.personal:after {
  background-color: #1a4c49;
}

#new-user-card-modal .select-card-type .card-type.etc:hover {
  border-color: #555189;
}
#new-user-card-modal .select-card-type .card-type.etc:before {
  right: 23px;
  bottom: 40px;
  width: 125px;
  height: 79px;
  background-image: url('images/card-type-etc-background.png');
  background-size: 125px 79px;
}
#new-user-card-modal .select-card-type .card-type.etc:after {
  background-color: #555189;
}

#new-user-bank-modal .select-bank-type .bank-type {
  width: 190px;
  line-height: 110px;
  text-align: center;
  float: left;
  margin: 10px;
  border-radius: 2px;
  cursor: pointer;
  color: #535353;
  font-weight: bold;
  background-color: #f9fafb;
  border: solid 1px #d3dae0;
}

#new-user-card-modal .card-numbers span {
  margin: 9.5px;
  font-size: 15px;
}

#new-user-card-modal .card-numbers input {
  width: 75px !important;
  text-align: center;
}

#new-user-bank-modal .bank-companies .bank:hover,
#new-user-card-modal .card-companies .card:hover {
  box-shadow: 0 0 0 2px #047de0;
  border: solid 1px #f9fafb;
}

#new-user-bank-modal .bank-companies .bank,
#new-user-card-modal .card-companies .card {
  float: left;
  display: flex;
  margin: 0 10px 15px 0;
  width: 130px;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 2px;
  background-color: #f9fafb;
  border: solid 1px #d3dae0;
}

#new-user-bank-modal .bank-companies .bank .bank-inner,
#new-user-card-modal .card-companies .card .card-inner {
  line-height: 50px;
  display: inline-flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

#new-user-bank-modal .bank-companies .bank .bank-inner img,
#new-user-card-modal .card-companies .card .card-inner img {
  float: left;
  margin-right: 6px;
}

#new-user-bank-modal .bank-companies .bank .bank-inner p,
#new-user-card-modal .card-companies .card .card-inner p {
  float: left;
}

#new-user-bank-modal .bank-companies,
#new-user-card-modal .card-companies {
  display: inline-block;
  text-align: center;
}

#new-user-bank-modal .scraping-type-label {
  text-align: left;
  color: #6a6a6a;
}

#new-user-card-modal .card-info-wrapper,
#new-user-bank-modal .bank-info-wrapper {
  text-align: center;
  width: 100%;
}

#new-user-card-modal .card-info-wrapper .card-info,
#new-user-bank-modal .bank-info-wrapper .bank-info {
  display: flex;
  justify-content: center;
  margin-bottom: 27px;
}

#new-user-card-modal .card-info-wrapper .card-info img,
#new-user-bank-modal .bank-info-wrapper .bank-info img {
  float: left;
}

#new-user-card-modal .card-info-wrapper .card-info p,
#new-user-bank-modal .bank-info-wrapper .bank-info p {
  color: var(--grey-700);
  font-size: 13px;
  margin-left: 6px;
  float: left;
  height: 35px;
  line-height: 35px;
}

#new-user-card-modal .scraping-select-personal-card .card-registered {
  height: 40px;
  display: table-cell;
  vertical-align: middle;
}

#new-user-card-modal .scraping-select-personal-card .new-card-number {
  width: 30%;
  height: 40px;
  display: table-cell;
  vertical-align: middle;
}

#new-user-card-modal .scraping-select-personal-card .card-select {
  width: 36px;
  height: 40px;
  display: table-cell;
  vertical-align: middle;
}

#new-user-card-modal .scraping-select-personal-card .new-card-name {
  font-weight: bold;
  height: 40px;
  width: 50%;
  display: table-cell;
  vertical-align: middle;
}

#new-user-card-modal .scraping-select-personal-card {
  display: table;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  cursor: pointer;
}

#new-user-card-modal .button-bottom {
  margin-top: 32px;
  padding-top: 10px;
}

#new-user-card-modal .modal-content .modal-desc {
  padding-bottom: 19px;
}

#process-bar {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 70px;
}

#process-bar .process-bar-wrapper {
  display: inline-block;
}

#process-bar .step div {
  float: left;
}

#process-bar .step.active {
  border-bottom-color: #047de0;
  color: #1e1e1e;
}

#process-bar .step.active .number {
  background-color: #047de0;
}

#process-bar .step {
  text-align: left;
  line-height: 15px;
  float: left;
  padding: 10px 0;
  font-size: 10px;
  color: #aeaeae;
  border-bottom: 5px solid rgba(178, 178, 178, 0.19);
  width: 160px;
}

#process-bar .step .number {
  border-radius: 50%;
  background-color: #e5e5e5;
  width: 15px;
  color: #ffffff;
  font-size: 8px;
  font-weight: bold;
  height: 15px;
  text-align: center;
  margin-right: 5px;
}

#transaction-button-area {
  width: calc(100% - 330px); /* for IE */
  position: fixed;
  margin-top: 130px;
  z-index: 2;
  background-color: #f7f8fa;
  height: 30px;
  padding: 14px 35px;
}

#transaction-button-area .transaction-visible {
  float: left;
}

#transaction-list {
  margin-top: 155px;
}

#transaction-list .foreign-transaction-info-icon {
  width: 30px;
  height: 32px;
  border: none !important;
  vertical-align: middle;
}

.sub-title#card-transaction-title {
  position: fixed;
  z-index: 2;
  width: calc(100% - 260px); /* for IE */
  height: 100px;
  background: #fff;
  padding-top: 20px;
  font-size: 16px;
}

#transaction-filter {
  width: -webkit-calc(100% - 330px); /* for Chrome, Safari */
  width: -moz-calc(100% - 330px); /* for Firefox */
  width: calc(100% - 330px); /* for IE */
  margin-top: 110px;
  position: fixed;
  transition: none !important;
  height: 100px;
  padding: 0 35px;
  background-color: #fff;
  z-index: 2;
  float: left;
  vertical-align: middle;
  display: inline-block;
}

#transaction-filter #select-filters {
  display: inline-block;
  height: 40px;
}

#transaction-filter .apply button {
  margin-left: 10px;
}

#transaction-filter .scraped-at span {
  margin-right: 15px;
}

#transaction-filter .scraped-at {
  float: right;
  color: #657c97;
  line-height: 40px;
  font-size: 13px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/refresh-transaction.png');
  background-size: 10px 13px;
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
  margin-right: 3px;
}

#transaction-filter .period {
  float: left;
  display: inline-block;
  margin-right: 10px;
}

#transaction-filter .period p {
  float: left;
  margin-right: 10px;
  line-height: 30px;
  font-size: 10px;
  letter-spacing: -0.2px;
  color: #7d7d7d;
}

#transaction-filter .period ul {
  display: inline-block;
}

#transaction-filter .period ul li.period-selected,
#transaction-filter .period ul li:hover {
  background-color: #657c97;
  color: #ffffff;
}

#transaction-filter .period ul li {
  height: 28px;
  width: 55px;
  border-left: 1px solid #657c97;
  border-top: 1px solid #657c97;
  border-bottom: 1px solid #657c97;
  display: inline-block;
  line-height: 28px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: center;
  color: #657c97;
  cursor: pointer;
}

#transaction-filter .period ul li:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

#transaction-filter .period ul li:last-child {
  border-right: 1px solid #657c97;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

#transaction-filter .select-calendar .element {
  float: left;
  display: inline-block;
}

#transaction-filter .select-calendar .element.dash {
  line-height: 30px;
  font-size: 12px;
  color: #657c97;
  font-weight: bold;
  width: 20px;
  text-align: center;
}

#transaction-filter .picker-div {
  height: 28px;
  width: 180px;
  float: left;
  background-color: #657c97;
  border: 1px solid #657c97 !important;
}

#transaction-filter .picker-div .rdt {
  width: inherit !important;
  float: none !important;
  height: 28px;
}

#transaction-filter .picker-div input {
  position: absolute;
  top: 0;
  width: 100%;
  line-height: 28px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/cal-white.png');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 10px; /* for Chrome, Safari */
}

#transaction-filter .picker-div .rdt .rdtPicker {
  top: 40px;
  position: absolute !important;
}

#transaction-filter .picker-div .icon-calendar-active {
  display: none;
}

#transaction-filter .period-filter-custom {
  width: 100%;
  float: left;
  position: relative;
}

#transaction-filter .select-filters-custom {
  width: 100%;
  float: left;
  position: relative;
}

@media (max-width: 1200px) {
  #btn-add-to-expense .label,
  #btn-download-transactions .label {
    display: none;
  }

  #transaction-filter .picker-div input {
    color: transparent !important;
  }

  #transaction-filter .picker-div {
    width: 36px !important;
  }
}

@media (max-width: 999px) {
  #transaction-filter {
    width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
    width: -moz-calc(100% - 70px); /* for Firefox */
    width: calc(100% - 70px); /* for IE */
  }

  .sub-title#card-transaction-title {
    width: 100%;
  }

  #transaction-button-area {
    width: -moz-calc(100% - 70px); /* for IE */
    width: -webkit-calc(100% - 70px); /* for IE */
    width: calc(100% - 70px); /* for IE */
    position: fixed;
    margin-top: 170px;
    z-index: 2;
    background-color: #fff;
    height: 30px;
    padding: 14px 35px;
  }
}

@media (max-width: 1100px) {
  #transaction-filter #select-filters #transaction {
    display: none !important;
  }
}

/* transaction-filter-custom */
#transaction-filter-custom {
  width: -webkit-calc(100% - 330px); /* for Chrome, Safari */
  width: -moz-calc(100% - 330px); /* for Firefox */
  width: calc(100% - 330px); /* for IE */
  margin-top: 50px;
  position: fixed;
  transition: none !important;
  height: 80px;
  padding: 0 35px;
  background-color: #fff;
  z-index: 3;
  float: left;
  vertical-align: middle;
  display: inline-block;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

#transaction-filter-custom #select-filters {
  display: flex;
  height: 28px;
  align-items: center;
}

#transaction-filter-custom .apply {
  position: relative;
  float: left;
  width: 64px;
  height: 28px;
  border-radius: 15px;
  background-color: #e6eaee;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: -0.2px;
  color: #ffffff;
  cursor: pointer;
}

#transaction-filter-custom .apply-active {
  background-color: #047cde;
  position: relative;
  float: left;
  width: 64px;
  height: 28px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: -0.2px;
  color: #ffffff;
  cursor: pointer;
}

#transaction-filter-custom .scraped-at span {
  margin-left: 15px;
}

#transaction-filter-custom .scraped-at {
  float: right;
  color: #657c97;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/refresh-transaction.png');
  background-size: 10px 13px;
  background-repeat: no-repeat;
  background-position: left;
  cursor: pointer;
  margin-right: 3px;
}

#transaction-filter-custom .period {
  float: left;
  display: inline-block;
  margin-right: 10px;
}

#transaction-filter-custom .period p {
  float: left;
  margin-right: 10px;
  line-height: 30px;
  font-size: 10px;
  letter-spacing: -0.2px;
  color: #7d7d7d;
}

#transaction-filter-custom .period ul {
  display: inline-flex;
  align-items: center;
}

#transaction-filter-custom .period ul li.period-selected,
#transaction-filter-custom .period ul li:hover {
  font-weight: bold;
  color: #047cde;
}

#transaction-filter-custom .period ul li::after {
  content: attr(data-content);
  display: block;
  font-weight: bold;
  height: 0;
  visibility: hidden;
}

#transaction-filter-custom .period ul li:first-child {
  padding-left: 0;
}

#transaction-filter-custom .period ul li {
  padding: 0 5px;
  color: #002855;
  cursor: pointer;
  font-size: 14px;
  line-height: 28px;
}

#transaction-filter-custom .period .division {
  width: 1px;
  padding: 0 2px !important;
  opacity: 0.5;
  pointer-events: none;
}

#transaction-filter-custom .select-calendar .element {
  float: left;
  display: inline-block;
}

#transaction-filter-custom .select-calendar .element.dash {
  line-height: 28px;
  font-size: 12px;
  color: #657c97;
  font-weight: bold;
  width: 20px;
  text-align: center;
}

#transaction-filter-custom .picker-div {
  height: 28px;
  width: 70px;
  float: left;
  border: 1px solid #fff !important;
}

#transaction-filter-custom .picker-div .rdt {
  width: inherit !important;
  float: none !important;
  height: 28px;
}

#transaction-filter-custom .picker-div input {
  position: absolute;
  top: 0;
  width: 100%;
  line-height: 27px;
  font-size: 12px;
  color: #002855;
  text-align: center;
}

#transaction-filter-custom .picker-div .rdt .rdtPicker {
  top: 40px;
  position: absolute !important;
}

#transaction-filter-custom .picker-div .icon-calendar-active {
  display: none;
}

#transaction-filter-custom .picker-div-active {
  height: 28px;
  width: 70px;
  float: left;
  border: 1px solid #fff !important;
}

#transaction-filter-custom .picker-div-active .rdt {
  width: inherit !important;
  float: none !important;
  height: 28px;
}

#transaction-filter-custom .picker-div-active input {
  position: absolute;
  top: 0;
  width: 100%;
  line-height: 27px;
  font-size: 12px;
  font-weight: bold;
  color: #047cde;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#transaction-filter-custom .picker-div-active input[type='text']:focus {
  background-color: transparent !important;
  border: 0 solid !important;
}

#transaction-filter-custom .picker-div-active .rdt .rdtPicker {
  top: 40px;
  position: absolute !important;
}

#transaction-filter-custom .picker-div-active .icon-calendar-active {
  display: none;
}

#transaction-filter-custom .period-filter-custom {
  width: 100%;
  float: left;
  position: relative;
}

#transaction-filter-custom .select-filters-custom {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 5px;
}

@media (max-width: 1200px) {
  #btn-add-to-expense .label,
  #btn-download-transactions .label {
    display: none;
  }
}

@media (max-width: 999px) {
  #transaction-filter-custom {
    width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
    width: -moz-calc(100% - 70px); /* for Firefox */
    width: calc(100% - 70px); /* for IE */
  }

  .sub-title#card-transaction-title {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  #transaction-filter-custom #select-filters #transaction {
    display: none !important;
  }
}

/* intercom custom start */
#intercom-filter {
  border: 1px solid #bdbdbd3d;
  border-radius: 12px;
  position: relative;
  float: left;
  width: 40%;
  vertical-align: middle;
  display: inline-block;
  margin: 0 30%;
  background-color: #bdbdbd3d;
}

#intercom-filter #select-date {
  position: relative;
  float: left;
  height: 60px;
  margin: 5% 0 0 5%;
  width: 40%;
  line-height: 28px;
}

#intercom-filter #select-filters {
  display: inline-block;
  height: 40px;
}

#intercom-filter .picker-div .icon-calendar-active {
  display: none;
}

#intercom-filter .period {
  float: left;
  display: inline-block;
  margin-right: 10px;
}

#intercom-filter .period p {
  float: left;
  margin-right: 10px;
  line-height: 30px;
  font-size: 10px;
  letter-spacing: -0.2px;
  color: #7d7d7d;
}

#intercom-filter .period ul {
  display: inline-block;
}

#intercom-filter .period ul li.period-selected,
#intercom-filter .period ul li:hover {
  background-color: #657c97;
  color: #ffffff;
}

#intercom-filter .period.en ul li {
  width: 65px;
  font-size: 11px;
}

#intercom-filter .period ul li {
  height: 28px;
  width: 55px;
  border-left: 1px solid #657c97;
  border-top: 1px solid #657c97;
  border-bottom: 1px solid #657c97;
  display: inline-block;
  line-height: 28px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: center;
  color: #657c97;
  cursor: pointer;
}

#intercom-filter .period ul li:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

#intercom-filter .period ul li:last-child {
  border-right: 1px solid #657c97;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

#intercom-filter .select-calendar .element {
  margin: 5% 5% 0 0;
  float: right;
  display: inline-block;
}

#intercom-filter .select-calendar .element.dash {
  line-height: 30px;
  font-size: 12px;
  color: #657c97;
  font-weight: bold;
  width: 20px;
  text-align: center;
}

#intercom-filter .picker-div {
  height: 28px;
  width: 180px;
  float: left;
  background-color: #657c97;
  border: 1px solid #657c97 !important;
}

#intercom-filter .picker-div .rdt {
  width: inherit !important;
  float: none !important;
  height: 28px;
}

#intercom-filter .picker-div input {
  position: absolute;
  top: 0;
  width: 100%;
  line-height: 28px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/cal-white.png');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 10px; /* for Chrome, Safari */
}

#intercom-filter .picker-div .rdt .rdtPicker {
  top: 29px;
  position: absolute !important;
}

#intercom-filter .picker-div .icon-calendar-inactive {
  display: none;
}

#intercom-filter #setting {
  position: relative;
  float: right;
  width: 180px;
  height: 28px;
  vertical-align: middle;
  display: inline-block;
  margin: 10% 5% 0 0;
}

#intercom-filter #setting-alert {
  margin: 10% 0 0 5%;
  width: 40%;
  position: relative;
  float: left;
}

#intercom-filter #description {
  border: solid 1px #d1d1d1;
  border-radius: 10px;
  margin: 10% 0 5% 5%;
  padding: 1%;
  width: 90%;
  position: relative;
  float: left;
}

#intercom-filter #description button {
  position: relative;
  float: right;
}

#intercom-filter p {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: left;
  color: #000000;
  line-height: 28px;
}

#intercom-filter .period-filter {
  width: 180px;
  position: relative;
  float: right;
  margin: 1% 5% 0 0;
}

#intercom-filter .period-filter .period-selected {
  background-color: #657c97;
  color: #ffffff;
}
/* intercom custom end */
#card-foreign-transaction-modal {
  padding: 20px 50px 40px 50px;
}

#card-foreign-transaction-modal .div-table-header {
  position: fixed;
  font-size: 10px;
  z-index: 1;
  margin-right: 50px;
  background-color: #ffffff;
  border-bottom: 1px solid #687987;
}

#card-foreign-transaction-modal .transaction-row {
  width: 100%;
  height: 60px;
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  display: inline-block;
  font-size: 14px;
}

#card-foreign-transaction-modal .div-table-body {
  padding: 30px 0 0 0;
}

#card-foreign-transaction-modal span {
  color: #030303;
  font-size: 10px;
}

#card-foreign-transaction-modal p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#card-foreign-transaction-modal p.store {
  font-weight: 700;
  font-size: 13px;
  max-width: 300px;
}

#card-foreign-transaction-modal .card-transaction-td-1 {
  width: 20%;
  display: inline-block;
  margin-left: 10px;
}

#card-foreign-transaction-modal .card-transaction-td-2 {
  width: 15%;
  display: inline-block;
}

#card-foreign-transaction-modal .card-transaction-td-3 {
  width: 10%;
  display: inline-block;
}

#card-foreign-transaction-modal .card-transaction-td-4 {
  width: 10%;
  display: inline-block;
}

#card-foreign-transaction-modal .card-transaction-td-5 {
  width: 10%;
  display: inline-block;
}

#card-foreign-transaction-modal .card-transaction-td-6 {
  width: 12%;
  display: inline-block;
}

#card-foreign-transaction-modal .card-transaction-td-7 {
  width: 12%;
  display: inline-block;
}

#card-foreign-transaction-modal .card-transaction-td-8 {
  width: 10%;
  display: inline-block;
}
