html, body { -ms-overflow-style: none;  }

@media (min-width: 903px) {
    .background-text {
        position: absolute;
        font-size: 5vw;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
        font-weight: 800;
        opacity: 0.26;
    }
    .subtitle-div {
        margin-top: -15vw;
        margin-left: 10%;
    }
    /*subtitles*/
    h1 {
        font-size: 1.8vw;
        font-weight: 800;
    }

    /*sub-subtitles*/
    h2 {
        font-size: 1.1vw;
    }

    .our-product-subtitle {
        position: absolute;
        z-index: 2;
        margin-top: 20%;
        width: calc(100% - 20%);
        width: -webkit-calc(100% - 20px);
        width: -moz-calc(100% - 20px);
        text-align: center;
    }

    .hr {
        position: absolute;
        display: block;
        width: 75%;
        margin-top: 4.5%;
        margin-left: 14%;
        border-width: 1px;
        border-top: 1px solid #e8e8e8;
        opacity: 0.15;
    }

    .our-product-process {
        height: 54vw;
    }

    .our-product-process .images {
        display: inline-block;
        position: absolute;
        margin-top: 28%;
        height: auto;
        margin-left: 10%;
        width: 80%;
    }

    .our-product-process .video {
        position: absolute;
        margin-top: 17%;
        margin-left: 3.5%;
        width: 90%;
    }

    .our-product-process video {
        width: 100%;
        display: inline-block;
    }

    .our-product-process .images .process {
        display: inline-block;
        width: 18%;
        vertical-align: middle;
    }

    .our-product-process .images .bodymovin {
        display: inline-block;
        width: 9%;
        height: 250px;
        vertical-align: middle;
    }

    .our-product-process .images .process img {
        width: 100%;
    }

    .our-product-process .images .process div.process-desc {
        padding: 20px 0;
        font-weight: 600;
        text-align: center;
        height: 100px;
        line-height: 1.2vw;
        font-size: 1vw;
    }

    .our-product-process .images .fine-text {
        color: #9a9a9a;
        margin-top: 10px;
        font-size: 0.75vw;
    }

    .video-div {
        display: inline-block;
        width: 100%;
        min-height: 450px;
        margin-top: -5px;
    }

    .video-div .text {
        position: absolute;
        line-height: 5vw;
        font-size: 3vw;
        margin-top: 11%;
        margin-left: 10%;
        font-weight: 300;
        z-index: 3;

    }

    .video-div video {
        width: 100%;
        display: block;
        z-index: 2;
    }

    .auto-match-div {
        margin-top: 150px;
        display: inline-block;
        width: 100%;
        height: 92vw;
    }

    .auto-match-div .autoscan-image {
        position: absolute;
        display: inline-block;
        width: 45%;
    }

    .auto-match-div .autoscan-text {
        position: absolute;
        font-size: 12px;
        display: inline-block;
        margin-left: 50%;
        vertical-align: top;
        width: 50%;
    }

    .autoscan-small-blue-text {
        position: absolute;
        font-size: 1.2vw;
        margin-top: 9%;
        font-weight: 800;
        color: #047de0;
        z-index: 1;
    }

    .autoscan-subtitle {
        position: absolute;
        margin-top: 13%;
        font-weight: 800;
        z-index: 2;
    }

    .autoscan-background-text {
        position: absolute;
        margin-left: 30px;
        font-size: 5vw;
        margin-top: 30px;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
        font-weight: 800;
    }

    .autoscan-wall-of-text {
        position: absolute;
        margin-top: 20%;
        font-size: 1.1vw;
        line-height: 1.8vw;
    }

    .autoscan-wall-of-text .small {
        margin-top: 2%;
        position: absolute;
        font-size: 1vw;
        color: #9a9a9a;
    }

    .match-div {
        margin-left: 5%;
        margin-top: 40%;
    }

    .match-background-text {
        position: absolute;
        font-size: 5vw;
        font-weight: 800;
        color: rgba(240, 243, 246, 0.55);
        z-index: 2;
    }

    .match-small-blue-text {
        position: absolute;
        font-size: 1.2vw;
        font-weight: 800;
        margin-top: 2%;
        margin-left: 5%;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .match-subtitle {
        position: absolute;
        margin-left: 5%;
        z-index: 4;
        font-weight: 800;
        margin-top: 4%;
    }

    .match-sub-subtitle {
        position: absolute;
        margin-left: 5%;
        margin-top: 10%;
        font-weight: 800;
    }

    .match-summary {
        position: absolute;
        font-size: 1.1vw;
        margin-left: 5%;
        margin-top: 12%;
    }

    .match-fine-text {
        position: absolute;
        font-size: 0.9vw;
        margin-left: 5%;
        margin-top: 14%;
        color: #9a9a9a;
    }

    .match-app-sync {
        position: absolute;
        margin-left: 5%;
        margin-top: 20%;
        width: 25%;
    }

    .match-bank-logos {
        position: absolute;
        margin-left: 35%;
        margin-top: 25%;
        width: 35%;
    }

    .match-transactions {
        background-color: #213042;
        width: 100%;
        height: 40vw;
    }

    .match-transactions .textbox {
        width: 50%;
    }

    .match-transactions .sub-subtitle {
        color: #ffffff;
        font-weight: 800;
        text-align: right;
    }

    .match-transactions .summary {
        color: #ffffff;
        font-size: 1.1vw;
        text-align: right;
    }

    .match-transactions .fine-text {
        color: #b1b1b1;
        font-size: 0.9vw;
        text-align: right;
    }

    .match-transactions .image {
        position: absolute;
        margin-left: 40%;
        margin-top: -25%;
        width: 60%
    }

    .video-div-2 {
        display: inline-block;
        width: 100%;
    }

    .video-div-2 .text {
        position: absolute;
        font-size: 3vw;
        margin-top: 5%;
        margin-left: 10%;
        z-index: 3;
        line-height: 5vw;
    }

    .video-div-2 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-2 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .video-div-3 {
        display: inline-block;
        width: 100%;
    }

    .video-div-3 .text {
        position: absolute;
        font-size: 3vw;
        margin-top: 5%;
        margin-left: 10%;
        z-index: 3;
        line-height: 5vw;
    }

    .video-div-3 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-3 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }


    .report-div {
        width: 100%;
        height: 127vw;
        margin-top: -5px;
    }

    .report-div .slanted-bg-1 {
        background-image: -webkit-linear-gradient(37deg, rgb(225, 222, 220) 75%, #ffffff 25%);
        min-height: 65vw;
    }

    .report-div .slanted-bg-2 {
        background-image: -webkit-linear-gradient(37deg, #ffffff 15%, rgb(225, 222, 220) 15%);
        min-height: 62vw;
    }

    .report-background-text {
        margin-top: 5%;
        margin-left: 5%;
        position: absolute;
        font-weight: 800;
        font-size: 5vw;
        color: rgba(255, 255, 255, 0.3);
        z-index: 1;
    }

    .report-hr {
        margin-left: 15%;
        margin-top: 3%;
    }

    .report-vertical-text {
        margin-left: 14.6% !important;
        margin-top: 2.8%;
    }

    .report-small-blue-text {
        position: absolute;
        font-size: 1.2vw;
        font-weight: 800;
        margin-top: 7%;
        margin-left: 15%;
        color: #047de0;
        z-index: 3;
    }

    .report-subtitle {
        position: absolute;
        margin-left: 15%;
        z-index: 3;
        margin-top: 9%;
    }

    .report-div-image {
        max-width: 45%;
        margin-top: 25%;
        position: absolute;
    }

    .report-div-image-2 {
        max-width: 40%;
        position: absolute;
        margin-top: 40%;
        margin-left: 50%;
    }

    .report-subtitle-2 {
        position: absolute;
        margin-left: 52%;
        z-index: 3;
        margin-top: 30%;
    }

    .report-sub-subtitle {
        position: absolute;
        margin-left: 52%;
        z-index: 3;
        margin-top: 33%;
    }

    .report-div-image-3 {
        position: absolute;
        margin-top: 20%;
        margin-left: 30%;
    }

    .report-subtitle-3 {
        position: absolute;
        width: 30vw;
        margin-left: 13%;
        z-index: 3;
        margin-top: 22%;
        text-align: right;
    }

    .report-sub-subtitle-3 {
        position: absolute;
        margin-left: 3%;
        width: 40vw;
        z-index: 3;
        margin-top: 25%;
        text-align: right;
    }

    .report-features {
        background-color: #f7f6f5;
        min-height: 60vw;
        width: 100%;
    }

    .report-features-image-1 {
        position: absolute;
        margin-top: 280px;
        max-width: 25%;
    }

    .report-features-image-2 {
        position: absolute;
        margin-top: 280px;
        margin-left: 75%;
        max-width: 25%;
    }

    .report-features-subtitle {
        position: absolute;
        margin-left: 20%;
        z-index: 3;
        font-weight: 800;
        margin-top: 150px;
        width: 65vw;
        text-align: center;
        font-size: 1.5vw !important;
    }

    .report-features-comment {
        position: absolute;
        margin-left: 28%;
        margin-top: 20%;
    }

    .report-features-comment .text {
        color: #000000;
        font-size: 1vw;
        line-height: 1.6vw;
        margin-left: 2.2vw;
        width: 100%;
    }

    .report-features-comment .subtitle {
        font-size: 1.2vw;
        font-weight: 800;
        line-height: 3.5vw;
        color: #047de0;
    }

    .report-features-history {
        font-size: 1vw;
        line-height: 1.6vw;
        position: absolute;
        margin-left: 28%;
        margin-top: 30%;
    }

    .report-features-history .text {
        color: #000000;
        margin-left: 2.2vw;
        width: 100%;
    }

    .report-features-history .subtitle {
        font-size: 1.2vw;
        font-weight: 800;
        line-height: 3.5vw;
        color: #047de0;
    }

    .report-features-graph {
        font-size: 1vw;
        line-height: 1.6vw;
        position: absolute;
        margin-left: 53%;
        margin-top: 20%;
    }

    .report-features-graph .text {
        color: #000000;
        margin-left: 2.2vw;
        width: 100%;
    }

    .report-features-graph .subtitle {
        font-size: 1.2vw;
        font-weight: 800;
        line-height: 3.5vw;
        color: #047de0;
    }

    .report-features-map {
        font-size: 1vw;
        line-height: 1.6vw;
        position: absolute;
        margin-left: 53%;
        margin-top: 30%;
    }

    .report-features-map .text {
        color: #000000;
        width: 100%;
        margin-left: 2.2vw;
    }

    .report-features-map .subtitle {
        font-size: 1.2vw;
        font-weight: 800;
        line-height: 3.5vw;
        color: #047de0;
    }

    .compliance-div {
        background-color: #ffffff;
        width: 100%;
        min-height: 150vw;
    }

    .compliance-background-text {
        margin-top: 10%;
        margin-left: 10%;
        position: absolute;
        font-size: 5vw;
        font-weight: 800;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
    }

    .compliance-hr {
        margin-left: 15%;
        margin-top: 8%;
    }

    .compliance-vertical-text {
        margin-left: 14.6% !important;
        margin-top: 7%;
    }

    .compliance-small-blue-text {
        position: absolute;
        font-weight: 800;
        font-size: 1.2vw;
        margin-top: 12%;
        margin-left: 15%;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .compliance-subtitle {
        position: absolute;
        margin-left: 15%;
        z-index: 3;
        margin-top: 14%;
    }

    .compliance-features-div {
        z-index: 0;
        width: 100%;
        position: absolute;
        margin-top: 25%;
        background-color: #faf9f8;
    }

    .compliance-features-distance {
        font-size: 1vw;
        line-height: 1.6vw;
        position: absolute;
        margin-left: 15%;
        margin-top: 5%;
    }

    .compliance-features-distance .text {
        margin-left: 2.5vw;
        color: #000000;
        word-break: keep-all;

    }

    .compliance-features-distance .subtitle {
        font-weight: 800;
        font-size: 1.2vw;
        color: #047de0;
        line-height: 2.5vw;
    }

    .compliance-features-category {
        font-size: 1vw;
        line-height: 1.6vw;
        position: absolute;
        margin-left: 53%;
        margin-top: 5%;
    }

    .compliance-features-category .text {
        margin-left: 2.5vw;
        color: #000000;
        word-break: keep-all;
    }

    .compliance-features-category .subtitle {
        font-size: 1.2vw;
        font-weight: 800;
        color: #047de0;
        line-height: 2.5vw;
    }

    .compliance-features-roadview {
        font-size: 1vw;
        line-height: 1.6vw;
        position: absolute;
        margin-left: 15%;
        margin-top: 14%;
    }

    .compliance-features-roadview .text {
        margin-left: 2.5vw;
        color: #000000;
        word-break: keep-all;
    }

    .compliance-features-roadview .subtitle {
        font-size: 1.2vw;
        font-weight: 800;
        color: #047de0;
        line-height: 2.5vw;
    }

    .compliance-features-violation {
        font-size: 1vw;
        line-height: 1.6vw;
        position: absolute;
        margin-left: 53%;
        margin-top: 14%;
        word-break: keep-all;
        width: 35%;
    }

    .compliance-features-violation .text {
        margin-left: 2.5vw;
        color: #000000;
        word-break: keep-all;
    }

    .compliance-features-violation .subtitle {
        font-size: 1.2vw;
        font-weight: 800;
        color: #047de0;
        line-height: 2.5vw;
    }

    .compliance-features-example {
        margin-top: 25%;
        background-color: #ffffff;
        width: 100%;
        min-height: 500px;
    }

    .compliance-features-example-bg-circle-1 {
        margin-top: 35%;
        margin-left: 35%;
        position: absolute;
        width: 30vw;
        height: 30vw;
        -webkit-border-radius:900px;
        -moz-border-radius:900px;
        border-radius:900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-bg-circle-2 {
        margin-top: 27.5%;
        margin-left: 27.5%;
        position: absolute;
        width: 45vw;
        height: 45vw;
        -webkit-border-radius:900px;
        -moz-border-radius:900px;
        border-radius:900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-bg-circle-3 {
        margin-top: 12.5%;
        margin-left: 12.5%;
        position: absolute;
        width: 75vw;
        height: 75vw;
        -webkit-border-radius:900px;
        -moz-border-radius:900px;
        border-radius:900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-bg-circle-4 {
        margin-top: 5%;
        margin-left: 5%;
        position: absolute;
        width: 90vw;
        height: 90vw;
        -webkit-border-radius:900px;
        -moz-border-radius:900px;
        border-radius:900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-text {
        position: absolute;
        margin-top: 50%;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        line-height: 3.5vw;
        text-align: center;
    }

    .compliance-features-example-img-1 {
        position: absolute;
        margin-top: 15vw;
    }

    .compliance-features-example-img-2 {
        position: absolute;
        margin-top: 60vw;
    }

    .video-div-4 {
        display: inline-block;
        width: 100%;
        height: 50vw;
        line-height: 5vw;
    }

    .video-div-4 .text {
        position: absolute;
        font-size: 3vw;
        margin-top: 35%;
        margin-left: 15%;
        z-index: 3;
    }

    .video-div-4 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-4 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .attendee-div {
        width: 100%;
        min-height: 700px;
    }

    .attendee-background-text {
        margin-top: -10%;
        margin-left: 10%;
        position: absolute;
        font-size: 5vw;
        font-weight: 800;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
    }

    .attendee-hr {
        margin-left: 15%;
        margin-top: -12%;
    }

    .attendee-vertical-text {
        margin-left: 14.6% !important;
        margin-top: -13% !important;
    }

    .attendee-small-blue-text {
        position: absolute;
        font-size: 1.2vw;
        font-weight: 800;
        margin-top: -8%;
        margin-left: 15%;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .attendee-subtitle {
        position: absolute;
        margin-left: 15%;
        margin-top: -6%;
        z-index: 3;
        width: 80%;
    }

    .attendee-features {
        margin-top: 20%;
        margin-left: 10%;
        width: 90%;
        line-height: 1.3vw;
        min-height: 25vw;
    }

    .attendee-features-external {
        font-size: 1vw;
        line-height: 1.6vw;
        position: absolute;
        margin-left: 5%;
        margin-top: 5%;
        max-width: 35%;
        word-break: keep-all;
    }

    .attendee-features-external .text {
        margin-left: 2vw;
    }

    .attendee-features-external .subtitle {
        font-size: 1.2vw;
        font-weight: 800;
        color: #047de0;
    }

    .attendee-features-internal {
        font-size: 1vw;
        line-height: 1.6vw;
        position: absolute;
        margin-left: 45%;
        margin-top: 5%;
        max-width: 35%;
        word-break: keep-all;
    }

    .attendee-features-internal .text {
        margin-left: 2vw;
    }

    .attendee-features-internal .subtitle {
        font-size: 1.2vw;
        font-weight: 800;
        color: #047de0;
    }

    .attendee-example-div {
        width: 100%;
        min-height: 45vw;
        margin-top: 200px;
        background-color: #43526d;
    }

    .attendee-example-div .image-1 {
        position: absolute;
        max-width: 50%;
        margin-top: -5%;
        z-index: 1;
        margin-left: -10%;
    }

    .attendee-example-div .image-2 {
        position: absolute;
        margin-left: 20%;
        max-width: 80%;
        margin-top: -9%;
        z-index: 0;
    }

    .attendee-example-div .text {
        position: absolute;
        margin-left: 25%;
        margin-top: 5%;
        max-width: 29%;
        font-size: 1.1vw;
        word-break: keep-all;
        color: white;
        text-align: center;
        line-height: 1.8vw;
    }

    .video-div-5 {
        display: inline-block;
        width: 100%;
        height: 400px;
        margin-top: -10px;
    }

    .video-div-5 .text {
        position: absolute;
        font-size: 3vw;
        line-height: 2.2vw !important;
        margin-top: 10%;
        margin-left: 15%;
        z-index: 3;
    }

    .video-div-5 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-5 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .export-div {
        width: 100%;
        min-height: 80vw;
    }

    .export-background-text {
        margin-top: 5%;
        margin-left: 10%;
        position: absolute;
        font-size: 5vw;
        font-weight: 800;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
    }

    .export-hr {
        margin-left: 15%;
        margin-top: 8% !important;
    }

    .export-vertical-text {
        margin-left: 15% !important;
        margin-top: 7% !important;
    }

    .export-small-blue-text {
        position: absolute;
        font-weight: 800;
        font-size: 1.2vw;
        margin-top: 7%;
        margin-left: 15%;
        color: #047de0;
        z-index: 3;
    }

    .export-subtitle {
        position: absolute;
        line-height: 3vw;
        margin-left: 15%;
        z-index: 3;
        margin-top: 9%;
    }

    .export-summary {
        position: absolute;
        font-size: 1.1vw;
        margin-left: 15%;
        z-index: 3;
        margin-top: 17%;
        line-height: 1.8vw;
    }

    .export-div .green-div {
        position: absolute;
        margin-top: 36%;
        width: 100%;
        min-height: 50vw;
        background-color: #32463b;
    }

    .export-div .green-div .image-1 {
        position: absolute;
        margin-top: -30px;
    }

    .video-div-6 {
        display: inline-block;
        width: 100%;
        height: 400px;
    }

    .video-div-6 .text {
        position: absolute;
        font-size: 3vw;
        margin-top: 5%;
        margin-left: 15%;
        z-index: 3;
        line-height: 4vw !important;
    }

    .video-div-6 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-6 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .additional-feature-div {
        width: 100%;
        min-height: 100vw;
    }

    .additional-feature-div .left {
        background-image: linear-gradient(to bottom, #ffffff, #f5f5f5);
        width: 50%;
        min-height: 110vw;
        margin: 0;
        display: inline-block;
    }

    .additional-feature-div .left .background-text {
        margin-top: 5%;
        margin-left: 19%;
        position: absolute;
        font-size: 5vw;
        font-weight: 800;
        color: rgba(218, 220, 223, 0.55);
        z-index: 1;
    }

    .additional-hr {
        margin-left: 30%;
        margin-top: 2.5% !important;
    }

    .additional-vertical-text {
        margin-left: 29% !important;
        margin-top: 2% !important;
    }

    .additional-feature-div .left .small-blue-text {
        position: absolute;
        font-size: 1.2vw;
        font-weight: 800;
        margin-top: 7%;
        margin-left: 17%;
        color: #047de0;
        z-index: 3;
    }

    .additional-feature-div .left .subtitle {
        position: absolute;
        margin-left: 17%;
        width: 70%;
        z-index: 3;
        margin-top: 9%;
    }

    .additional-feature-div .left .summary {
        position: absolute;
        font-size: 1vw;
        line-height: 1.4vw;
        margin-left: 15%;
        margin-top: 50%;
        max-width: 30vw;
    }

    .additional-feature-div .summary .first {
        margin-top: 20vw;
        min-height: 7vw;
        max-width: 30vw;
        word-break: keep-all;
        font-size: 1vw;
        line-height: 1.6vw;
    }

    .additional-feature-div .summary .second {
        position: absolute;
        margin-top: 5vw;
        max-width: 30vw;
        word-break: keep-all;
        font-size: 1vw;
        line-height: 1.6vw;
    }

    .additional-feature-div .left .image {
        margin-top: 15%;
        position: absolute;
        width: 45%;
        margin-left: 5%
    }

    .additional-feature-div .right {
        background-image: linear-gradient(to top, #ffffff, #f5f5f5);
        width: 50%;
        min-height: 110vw;
        margin: 0;
        display: inline-block;
    }

    .additional-feature-div .right .background-text {
        margin-top: 5%;
        position: absolute;
        font-size: 5vw;
        font-weight: 800;
        color: #ffffff;
        z-index: 1;
    }

    .additional-feature-div .right .summary {
        position: absolute;
        font-size: 1vw;
        line-height: 1.4vw;
        margin-left: 8%;
        margin-top: 50%;
        max-width: 30vw;
    }

    .additional-feature-div .right .image {
        margin-top: 15%;
        position: absolute;
        width: 45%;
        margin-left: 5%
    }

    .video-div-7 {
        display: inline-block;
        width: 100%;
        height: 400px;
    }

    .video-div-7 .text {
        position: absolute;
        font-size: 3vw;
        margin-top: 18%;
        margin-left: 10%;
        z-index: 3;
        font-weight: 300;
        line-height: 4.5vw !important;
    }

    .video-div-7 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-7 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .report-div-image-2 .textbox-1 {
        position: absolute;
        margin-top: -1vw;
        margin-left: 5vw;
        font-size: 1vw;
        font-weight: 800;
        color: #047de0;
    }

    .report-div-image-2 .textbox-2 {
        position: absolute;
        margin-top: -1vw;
        margin-left: 22vw;
        font-size: 1vw;
        font-weight: 800;
        color: #047de0;
    }

    .report-div-image-2 .icon-1 {
        position: absolute;
        width: 1.7vw;
        height: 1.2vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-email.png");
        background-size: 1.7vw 1.2vw;
        margin-top: -1.1vw;
        margin-left: 2.5vw;
        display: inline-block;
    }

    .report-div-image-2 .icon-2 {
        position: absolute;
        width: 1.3vw;
        height: 1.8vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-document.png");
        background-size: 1.3vw 1.8vw;
        margin-top: -1.5vw;
        margin-left: 20vw;
        display: inline-block;
    }

    .report-features-comment .icon {
        position: absolute;
        width: 1.6vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-chat.png");
        background-size: 1.6vw 1.5vw;
        margin-top: 0.9vw;
        display: inline-block;
    }

    .report-features-history .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.4vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-history.png");
        background-size: 1.5vw 1.4vw;
        margin-top: 0.9vw;
        display: inline-block;
    }

    .report-features-graph .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-graph.png");
        background-size: 1.5vw 1.5vw;
        margin-top: 0.9vw;
        display: inline-block;
    }

    .report-features-map .icon {
        position: absolute;
        width: 1.1vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-marker-1.png");
        background-size: 1.1vw 1.5vw;
        margin-top: 0.9vw;
        display: inline-block;
    }

    .compliance-features-distance .icon {
        position: absolute;
        width: 1.3vw;
        height: 1.7vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-marker-2.png");
        background-size: 1.3vw 1.7vw;
        margin-top: 0.5vw;
        display: inline-block;
    }

    .compliance-features-category .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-tag.png");
        background-size: 1.5vw 1.5vw;
        margin-top: 0.5vw;
        display: inline-block;
    }

    .compliance-features-roadview .icon {
        position: absolute;
        width: 1.8vw;
        height: 1.3vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-eye.png");
        background-size: 1.8vw 1.3vw;
        margin-top: 0.5vw;
        margin-left: -6px;
        display: inline-block;
    }

    .compliance-features-violation .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-stop.png");
        background-size: 1.5vw 1.5vw;
        margin-top: 0.5vw;
        display: inline-block;
    }

    .attendee-features-external .icon {
        position: absolute;
        width: 1.2vw;
        height: 1.8vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-upload.png");
        background-size: 1.2vw 1.8vw;
        margin-top: -5px;
        display: inline-block;
    }

    .attendee-features-internal .icon {
        position: absolute;
        width: 1.2vw;
        height: 1.8vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-download.png");
        background-size: 1.2vw 1.8vw;
        margin-top: -5px;
        display: inline-block;
    }

    .additional-feature-div .left .summary .first .icon {
        position: absolute;
        width: 1.55vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-money.png");
        background-size: 1.5vw 1.5vw;
        display: inline-block;
    }

    .additional-feature-div .summary .textbox {
        margin-left: 2vw;
    }

    .additional-feature-div .left .summary .second .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-gas.png");
        background-size: 1.5vw 1.5vw;
        display: inline-block;
    }

    .additional-feature-div .right .summary .first .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-multiple.png");
        background-size: 1.5vw 1.5vw;
        display: inline-block;
    }

    .additional-feature-div .right .summary .second .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-crop.png");
        background-size: 1.5vw 1.5vw;
        display: inline-block;
    }

    .autoscan-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-top: 35%;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .report-submit-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 15%;
        margin-top: 13%;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .report-approve-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 12.5%;
        margin-top: 29%;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .compliance-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 15%;
        margin-top: 17%;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .attendee-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 15%;
        margin-top: -2.5%;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .export-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 15%;
        margin-top: 22%;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .vertical-text {
        margin-left: -1%;
        position: absolute;
        transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -web-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform-origin: left top 0;
        -o-transform-origin: left top 0;
        -moz-transform-origin: left top 0;
        -ms-transform-origin: left top 0;
        -webkit-transform-origin: left top 0;
        font-size: 12px;
    }

    .vertical-hr {
        position: absolute;
        width: 2px;
        border: 0px solid #00c5ff;
        color: #00c5ff;
        background-color: #00c5ff;
    }

    .goto-button {
        font-weight: 400;
        border: none;
        background-repeat: no-repeat;
        background-image: url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-goto.png);
        background-size: 30px;
        background-position: 133px center;
        padding-right: 50px;
    }

    .fadeInUp {
        -webkit-animation-name: fadeInUp;
        -ms-animation-name: fadeInUp;
        -moz-animation-name: fadeInUp;
        animation-name: fadeInUp;

        -ms-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;

        -ms-animation-fill-mode: both;
        -moz-animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: inline !important;
    }
    @-webkit-keyframes fadeInUp {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
    @-ms-keyframes fadeInUp {
        0% {
            opacity: 0;
            -ms-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -ms-transform: none;
            transform: none;
        }
    }
    @-moz-keyframes fadeInUp {
        0% {
            opacity: 0;
            -moz-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -moz-transform: none;
            transform: none;
        }
    }
    @keyframes fadeInUp {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
}

@media (min-width: 609px) and (max-width: 902px) {
    .background-text {
        position: absolute;
        font-size: 42px;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
    }

    .subtitle-div {
        margin-top: 25px;
        margin-left: 10%;
    }

    /*subtitles*/
    h1 {
        font-size: 14px;
    }

    /*sub-subtitles*/
    h2 {
        font-size: 12px;
    }

    .our-product-subtitle {
        position: absolute;
        z-index: 2;
        font-size: 20px;
        margin-top: 30px;
        width: calc(100% - 20%);
        width: -webkit-calc(100% - 20px);
        width: -moz-calc(100% - 20px);
        text-align: center;
    }

    .hr {
        position: absolute;
        display: block;
        width: 70%;
        margin-top: 43px;
        margin-left: 118px;
        border-style: inset;
        border-width: 1px;
        border-top: 1px solid rgba(197, 199, 202, 0.55);
    }

    .our-product-process {
        height: 400px;
    }

    .our-product-process .images {
        display: inline-block;
        position: absolute;
        margin-top: 100px;
        height: auto;
        margin-left: 2.5%;
        width: 95%;
    }

    .our-product-process .video {
        position: absolute;
        margin-top: 13%;
        margin-left: 3.5%;
        width: 100%;
    }

    .our-product-process video {
        width: 100%;
        display: inline-block;
    }
    .our-product-process .images .process {
        display: inline-block;
        width: 17%;
        vertical-align: middle;
    }
    .our-product-process .images .bodymovin {
        display: inline-block;
        width: 10%;
    }

    .our-product-process .images .process img {
        width: 100%;
        vertical-align: middle;
    }

    .our-product-process .images .process div.process-desc {
        padding: 20px 0;
        font-weight: 800;
        text-align: center;
        font-fize: 13px;
    }

    .our-product-process .images .fine-text {
        color: #9a9a9a;
        margin-top: 8px;
        font-size: 1vw;
    }

    .video-div {
        display: inline-block;
        width: 100%;
        height: 500px;
    }

    .video-div .text {
        position: absolute;
        font-size: 20px;
        line-height: 30px;
        margin-top: 50px;
        margin-left: 70px;
        z-index: 3;
    }

    .video-div .video {
        width: 100%;
        z-index: 1;
    }

    .video-div video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .auto-match-div {
        display: inline-block;
        width: 100%;
        height: 720px;
    }

    .auto-match-div .autoscan-image {
        position: absolute;
        display: inline-block;
        width: 50%;
        padding-left: 5%;
        z-index: 0;
    }

    .auto-match-div .autoscan-text {
        position: absolute;
        font-size: 12px;
        display: inline-block;
        margin-left: 50%;
        vertical-align: top;
        width: 50%;
        max-width: 400px;
    }

    .autoscan-small-blue-text {
        position: absolute;
        font-size: 12px;
        margin-top: 50px;
        color: #047de0;
        z-index: 1;
    }

    .autoscan-subtitle {
        position: absolute;
        font-size: 14px;
        margin-top: 65px;
        font-weight: 800;
        z-index: 2;
    }

    .autoscan-background-text {
        position: absolute;
        margin-left: 30px;
        font-size: 42px;
        margin-top: 30px;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
    }

    .autoscan-wall-of-text {
        position: absolute;
        line-height: 16px;
        margin-top: 90px;
        font-size: 12px;
        width: 300px;
    }

    .autoscan-wall-of-text .small {
        margin-top: 10px;
        position: absolute;
        font-size: 5px;
        width: 300px;
        color: #9a9a9a;
    }

    .match-div {
        position: absolute;
        margin-top: 350px;
    }

    .match-background-text {
        position: absolute;
        font-size: 42px;
        color: rgba(240, 243, 246, 0.55);
        z-index: 2;
    }

    .match-small-blue-text {
        position: absolute;
        font-size: 12px;
        margin-top: 15px;
        margin-left: 80px;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .match-subtitle {
        position: absolute;
        font-size: 14px;
        margin-left: 80px;
        z-index: 3;
        margin-top: 35px;
        width: 370px;
    }

    .match-sub-subtitle {
        position: absolute;
        margin-left: 80px;
        margin-top: 100px;
        width: 300px;
        font-weight: 800;
    }

    .match-summary {
        position: absolute;
        font-size: 10px;
        margin-left: 80px;
        margin-top: 130px;
        width: 300px;
    }

    .match-fine-text {

        position: absolute;
        font-size: 10px;
        margin-left: 80px;
        margin-top: 145px;
        width: 350px;
        color: #9a9a9a;
    }

    .match-app-sync {
        position: absolute;
        margin-left: 80px;
        margin-top: 180px;
        width: 150px;
    }

    .match-bank-logos {
        position: absolute;
        margin-left: 250px;
        margin-top: 220px;
        width: 250px;
    }

    .match-transactions {
        background-color: #213042;
        width: 100%;
        height: 25vw;
    }

    .match-transactions .textbox {
        width: 55%;
    }

    .match-transactions .sub-subtitle {
        color: #ffffff;
        font-weight: 800;
        text-align: right;
        font-size: 14px !important;
    }

    .match-transactions .summary {
        color: #ffffff;
        font-size: 12px;
        text-align: right;
    }

    .match-transactions .fine-text {
        color: #b1b1b1;
        font-size: 10px;
        text-align: right;
        line-height: 5px;
    }

    .match-transactions .image {
        position: absolute;
        margin-left: 60%;
        margin-top: -170px;
        max-width: 400px;
    }

    .video-div-2 {
        display: inline-block;
        width: 100%;
    }

    .video-div-2 .text {
        position: absolute;
        font-size: 20px;
        line-height: 30px;
        margin-top: 50px;
        margin-left: 70px;
        z-index: 3;

    }

    .video-div-2 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-2 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .video-div-3 {
        display: inline-block;
        width: 100%;
    }

    .video-div-3 .text {
        position: absolute;
        font-size: 20px;
        line-height: 30px;
        margin-top: 50px;
        margin-left: 70px;
        z-index: 3;
    }

    .video-div-3 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-3 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .report-div {
        width: 100%;
        height: 118vw;
        min-height: 975px;
    }

    .report-div .slanted-bg-1 {
        background-image: -webkit-linear-gradient(37deg, #f5f5f5 75%, #ffffff 50%);
        min-height: 400px;
    }

    .report-div .slanted-bg-2 {
        background-image: -webkit-linear-gradient(37deg, #ffffff 48%, #f5f5f5 20%);
        min-height: 800px;
    }

    .report-background-text {
        margin-top: 80px;
        margin-left: 30px;
        position: absolute;
        font-size: 42px;
        color: rgb(255, 255, 255);
        z-index: 1;
    }

    .report-small-blue-text {
        position: absolute;
        font-size: 12px;
        margin-top: 95px;
        margin-left: 80px;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .report-hr {
        margin-left: 80px;
        margin-top: 50px;
    }

    .report-vertical-text {
        margin-left: 75px !important;
        margin-top: 45px;
    }

    .report-subtitle {
        position: absolute;
        font-size: 14px;
        z-index: 3;
        margin-left: 80px;
        margin-top: 110px;
        width: 370px;
    }

    .report-div-image {
        width: 40%;
        position: absolute;
        margin-top: 150px;
    }

    .report-div-image-2 {
        max-width: 50%;
        position: absolute;
        margin-top: 285px;
        margin-left: 38%;
    }

    .report-subtitle-2 {
        position: absolute;
        margin-left: 200px;
        z-index: 3;
        margin-top: 150px;
        width: 370px;
    }

    .report-sub-subtitle {
        position: absolute;
        margin-left: 200px;
        z-index: 3;
        margin-top: 170px;
        width: 370px;
    }

    .report-div-image-3 {
        position: absolute;
        margin-top: 285px;
        margin-left: 30%;
    }

    .report-subtitle-3 {
        position: absolute;
        font-size: 14px;
        margin-left: 0;
        z-index: 3;
        margin-top: 250px;
        width: 45vw;
        text-align: right;
    }

    .report-sub-subtitle-3 {
        position: absolute;
        font-size: 12px;
        margin-left: 0;
        z-index: 3;
        margin-top: 270px;
        width: 45vw;
        text-align: right;
    }

    .report-features {
        background-color: #f5f5f5;
        min-height: 500px;
        width: 100%;
        line-height: 15px;
    }

    .report-features-image-1 {
        position: absolute;
        margin-top: 100px;
        max-width: 25%;
    }

    .report-features-image-2 {
        position: absolute;
        margin-top: 100px;
        margin-left: 75%;
        max-width: 25%;
    }

    .report-features-subtitle {
        position: absolute;
        font-size: 16px;
        line-height: 18px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        z-index: 3;
        margin-top: 10%;
        max-width: 550px;
        text-align: center;
    }

    .report-features-comment {
        font-size: 12px;
        position: absolute;
        margin-left: 30%;
        margin-top: 130px;
    }

    .report-features-comment .text {
        font-size: 14px;
        color: #000000;
        margin-left: 2vw;
        width: 100%;
    }

    .report-features-comment .subtitle {
        color: #047de0;
        line-height: 22px;
    }

    .report-features-history {
        font-size: 12px;
        position: absolute;
        margin-left: 30%;
        margin-top: 195px;
    }

    .report-features-history .text {
        font-size: 14px;
        color: #000000;
        margin-left: 2vw;
        width: 90%;
    }

    .report-features-history .subtitle {
        color: #047de0;
        line-height: 22px;
    }

    .report-features-graph {
        position: absolute;
        margin-left: 30%;
        margin-top: 275px;
    }

    .report-features-graph .text {
        font-size: 14px;
        color: #000000;
        margin-left: 2vw;
        width: 100%;
    }

    .report-features-graph .subtitle {
        color: #047de0;
        line-height: 22px;
    }

    .report-features-map {
        position: absolute;
        margin-left: 30%;
        margin-top: 350px;
    }

    .report-features-map .text {
        font-size: 14px;
        color: #000000;
        width: 100%;
        margin-left: 2vw;
    }

    .report-features-map .subtitle {
        color: #047de0;
        line-height: 22px;
    }

    .compliance-div {
        background-color: #ffffff;
        width: 100%;
        min-height: 1100px;
    }

    .compliance-background-text {
        margin-top: 80px;
        margin-left: 10%;
        position: absolute;
        font-size: 42px;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
    }

    .compliance-small-blue-text {
        position: absolute;
        font-size: 12px;
        margin-top: 95px;
        margin-left: 15%;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .compliance-hr {
        margin-left: 15%;
        margin-top: 50px;
    }

    .compliance-vertical-text {
        margin-left: 14.5% !important;
        margin-top: 45px;
    }

    .compliance-subtitle {
        position: absolute;
        margin-left: 15%;
        z-index: 3;
        margin-top: 110px;
        width: 370px;
    }

    .compliance-features-div {
        z-index: 0;
        width: 100%;
        position: absolute;
        margin-top: 200px;
        background-color: #f7f6f5;
        min-height: 250px;
        font-size: 14px;
        line-height: 18px;
    }

    .compliance-features-distance {
        position: absolute;
        margin-left: 5%;
        margin-top: 50px;
    }

    .compliance-features-distance .text {
        margin-left: 2vw;
        color: #000000;
        word-break: keep-all;
        width: 80%;
    }

    .compliance-features-distance .subtitle {
        color: #047de0;
        line-height: 26px;
    }

    .compliance-features-category {
        position: absolute;
        margin-left: 55%;
        margin-top: 50px;
        max-width: 220px;
    }

    .compliance-features-category .text {
        margin-left: 2vw;
        color: #000000;
        word-break: keep-all;
        width: 80%;
    }

    .compliance-features-category .subtitle {
        color: #047de0;
        line-height: 26px;
    }

    .compliance-features-roadview {
        position: absolute;
        margin-left: 5%;
        margin-top: 150px;
    }

    .compliance-features-roadview .text {
        margin-left: 2vw;
        color: #000000;
        word-break: keep-all;
        width: 80%;

    }

    .compliance-features-roadview .subtitle {
        color: #047de0;
        line-height: 26px;
    }

    .compliance-features-violation {
        position: absolute;
        margin-left: 55%;
        margin-top: 150px;
        max-width: 240px;
        word-break: keep-all;
    }

    .compliance-features-violation .text {
        margin-left: 2vw;
        color: #000000;
        word-break: keep-all;
    }

    .compliance-features-violation .subtitle {
        color: #047de0;
        line-height: 26px;
    }

    .compliance-features-example {
        margin-top: 300px;
        background-color: #ffffff;
        width: 100%;
        min-height: 500px;
    }

    .compliance-features-example-bg-circle-1 {
        margin-top: 35%;
        margin-left: 35%;
        position: absolute;
        width: 30vw;
        height: 30vw;
        -moz-border-radius: 900px;
        -webkit-border-radius: 900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-bg-circle-2 {
        margin-top: 27.5%;
        margin-left: 27.5%;
        position: absolute;
        width: 45vw;
        height: 45vw;
        -moz-border-radius: 900px;
        -webkit-border-radius: 900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-bg-circle-3 {
        margin-top: 12.5%;
        margin-left: 12.5%;
        position: absolute;
        width: 75vw;
        height: 75vw;
        -moz-border-radius: 900px;
        -webkit-border-radius: 900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-bg-circle-4 {
        margin-top: 5%;
        margin-left: 5%;
        position: absolute;
        width: 90vw;
        height: 90vw;
        -moz-border-radius: 900px;
        -webkit-border-radius: 900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-text {
        position: absolute;
        margin-top: 50%;
        margin-left: 50%;
        transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        text-align: center;
    }

    .compliance-features-example-img-1 {
        position: absolute;
        margin-top: 15vw;
    }

    .compliance-features-example-img-2 {
        position: absolute;
        margin-top: 60vw;
    }

    .video-div-4 {
        display: inline-block;
        width: 100%;
        height: 500px;
        margin-bottom: 50px;
        margin-top: 30%;
    }

    .video-div-4 .text {
        position: absolute;
        font-size: 20px;
        line-height: 30px !important;
        margin-top: 250px;
        margin-left: 70px;
        z-index: 3;
    }

    .video-div-4 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-4 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .attendee-div {
        width: 100%;
        min-height: 650px;
    }

    .attendee-background-text {
        margin-top: -120px;
        margin-left: 10%;
        position: absolute;
        font-size: 42px;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
    }

    .attendee-hr {
        margin-left: 15%;
        margin-top: -140px;
    }

    .attendee-vertical-text {
        margin-left: 14.5% !important;
        margin-top: -150px !important;
    }

    .attendee-small-blue-text {
        position: absolute;
        font-size: 12px;
        margin-top: -105px;
        margin-left: 15%;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .attendee-subtitle {
        position: absolute;
        margin-left: 15%;
        z-index: 3;
        margin-top: -90px;
        width: 85%;
    }

    .attendee-features {
        margin-top: 100px;
        margin-left: 5%;
        width: 95%;
        line-height: 15px;
        min-height: 300px;
    }

    .attendee-features-external {
        font-size: 14px;
        line-height: 18px;
        position: absolute;
        margin-left: 2.5%;
        margin-top: 10px;
        max-width: 45%;
        word-break: keep-all;
    }

    .attendee-features-external .text {
        margin-left: 2vw;
    }

    .attendee-features-external .subtitle {
        color: #047de0;
    }

    .attendee-features-internal {
        font-size: 14px;
        line-height: 18px;
        position: absolute;
        margin-left: 50%;
        margin-top: 10px;
        max-width: 45%;
        word-break: keep-all;
    }

    .attendee-features-internal .text {
        margin-left: 2vw;
    }

    .attendee-features-internal .subtitle {
        color: #047de0;
    }

    .attendee-example-div {
        width: 100%;
        min-height: 350px;
        background-color: #43526d;
    }

    .attendee-example-div .image-1 {
        position: absolute;
        max-width: 50%;
        margin-top: -5%;
        z-index: 1;
        margin-left: -10%;
    }

    .attendee-example-div .image-2 {
        position: absolute;
        margin-left: 20%;
        max-width: 80%;
        margin-top: -9%;
        z-index: 0;
    }

    .attendee-example-div .text {
        position: absolute;
        margin-left: 25%;
        margin-top: 5%;
        max-width: 30%;
        font-size: 12px;
        word-break: keep-all;
        color: white;
        text-align: right;
        line-height: 14px;
    }

    .video-div-5 {
        display: inline-block;
        width: 100%;
        height: 500px;
    }

    .video-div-5 .text {
        position: absolute;
        font-size: 20px;
        line-height: 18px !important;
        margin-top: 150px;
        margin-left: 70px;
        z-index: 3;
    }

    .video-div-5 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-5 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .export-div {
        width: 100%;
        min-height: 600px;
    }

    .export-background-text {
        margin-top: 70px;
        margin-left: 10%;
        position: absolute;
        font-size: 42px;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
    }

    .export-hr {
        margin-left: 15%;
        margin-top: 40px !important;
    }

    .export-vertical-text {
        margin-left: 15% !important;
        margin-top: 30px !important;
    }

    .export-small-blue-text {
        position: absolute;
        font-size: 12px;
        margin-top: 85px;
        margin-left: 15%;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .export-subtitle {
        position: absolute;
        margin-left: 15%;
        z-index: 3;
        margin-top: 105px;
        width: 85%;
        line-height: 16px;
    }

    .export-summary {
        position: absolute;
        font-size: 12px;
        margin-left: 15%;
        z-index: 3;
        margin-top: 150px;
        line-height: 14px;
    }

    .export-div .green-div {
        position: absolute;
        margin-top: 300px;
        width: 100%;
        min-height: 300px;
        background-color: #32463b;
    }

    .export-div .green-div .image-1 {
        position: absolute;
        margin-top: -30px;
    }

    .video-div-6 {
        display: inline-block;
        width: 100%;
    }

    .video-div-6 .text {
        position: absolute;
        font-size: 20px;
        line-height: 30px !important;
        margin-top: 50px;
        margin-left: 150px;
        z-index: 3;
    }

    .video-div-6 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-6 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .additional-feature-div {
        width: 100%;
        min-height: 1200px;
    }

    .additional-feature-div .left {
        background-image: linear-gradient(to bottom, #ffffff, #e6e6e6 75%, #dedede);
        width: 50%;
        min-height: 1200px;
        margin: 0;
        display: inline-block;
    }

    .additional-feature-div .left .background-text {
        margin-top: 70px;
        margin-left: 20%;
        position: absolute;
        font-size: 5vw;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
    }

    .additional-hr {
        margin-left: 30%;
        margin-top: 40px !important;
    }

    .additional-vertical-text {
        margin-left: 29% !important;
        margin-top: 30px !important;
    }

    .additional-feature-div .left .small-blue-text {
        position: absolute;
        font-size: 12px;
        margin-top: 85px;
        margin-left: 25%;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .additional-feature-div .left .subtitle {
        position: absolute;
        margin-left: 25%;
        z-index: 3;
        margin-top: 100px;
        width: 500px;
        line-height: 16px;
    }

    .additional-feature-div .left .summary {
        position: absolute;
        font-size: 14px;
        margin-left: 7%;
    }

    .additional-feature-div .right .summary {
        position: absolute;
        font-size: 14px;
        margin-left: 7%;
    }

    .additional-feature-div .summary .first {
        margin-top: 200px;
        width: 100%;
        min-height: 100px;
        word-break: keep-all;
        line-height: 16px;
    }

    .additional-feature-div .left .summary .first .textbox{
        width: 45%;
    }

    .additional-feature-div .summary .title {
        line-height: 22px;
    }

    .additional-feature-div .summary .second {
        margin-top: 100px;
        width: 250px;
        word-break: keep-all;
        line-height: 14px;
    }

    .additional-feature-div .left .image {
        margin-top: 500px;
        position: absolute;
        width: 45%;
        margin-left: 5%
    }

    .additional-feature-div .right {
        background-image: linear-gradient(to top, #ffffff, #dedede);
        width: 50%;
        min-height: 1200px;
        margin: 0;
        display: inline-block;
    }

    .additional-feature-div .right .background-text {
        margin-top: 70px;
        position: absolute;
        font-size: 5vw;
        color: #fffff;
        z-index: 1;
    }

    .additional-feature-div .right .summary {
        position: absolute;
        margin-left: 7%;
    }

    .additional-feature-div .right .image {
        margin-top: 500px;
        position: absolute;
        width: 45%;
        margin-left: 5%
    }

    .video-div-7 {
        display: inline-block;
        width: 100%;
        height: 400px;
    }

    .video-div-7 .text {
        position: absolute;
        font-size: 20px;
        margin-top: 80px;
        margin-left: 80px;
        z-index: 3;
        line-height: 32px !important;
    }

    .video-div-7 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-7 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .report-div-image-2 .textbox-1 {
        position: absolute;
        margin-top: -1vw;
        margin-left: 5vw;
        font-size: 1.2vw;
    }

    .report-div-image-2 .textbox-2 {
        position: absolute;
        margin-top: -1vw;
        margin-left: 25vw;
        font-size: 1.2vw;
    }

    .report-div-image-2 .icon-1 {
        position: absolute;
        width: 1.7vw;
        height: 1.2vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-email.png");
        background-size: 1.7vw 1.2vw;
        margin-top: -1vw;
        margin-left: 2.5vw;
        display: inline-block;
    }

    .report-div-image-2 .icon-2 {
        position: absolute;
        width: 1.3vw;
        height: 1.8vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-document.png");
        background-size: 1.3vw 1.8vw;
        margin-top: -1vw;
        margin-left: 22vw;
        display: inline-block;
    }


    .report-features-comment .icon {
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-chat.png");
        background-size: 20px 20px;
        margin-left: -10px;
        display: inline-block;
    }

    .report-features-history .icon {
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-history.png");
        background-size: 20px 20px;
        margin-left: -10px;
        display: inline-block;
    }

    .report-features-graph .icon {
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-graph.png");
        background-size: 20px 20px;
        margin-left: -10px;
        display: inline-block;
    }

    .report-features-map .icon {
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-marker-1.png");
        background-size: 20px 20px;
        margin-left: -10px;
        display: inline-block;
    }

    .compliance-features-distance .icon {
        position: absolute;
        width: 13px;
        height: 17px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-marker-2.png");
        background-size: 13px 17px;
        margin-left: -5px;
        margin-top: 5px;
        display: inline-block;
    }

    .compliance-features-category .icon {
        position: absolute;
        width: 15px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-tag.png");
        background-size: 15px 15px;
        margin-left: -5px;
        margin-top: 12px;
        display: inline-block;
    }

    .compliance-features-roadview .icon {
        position: absolute;
        width: 18px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-eye.png");
        background-size: 18px 15px;
        margin-left: -5px;
        margin-top: 5px;
        display: inline-block;
    }

    .compliance-features-violation .icon {
        position: absolute;
        width: 15px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-stop.png");
        background-size: 15px 15px;
        margin-left: -5px;
        margin-top: 5px;
        display: inline-block;
    }

    .attendee-features-external .icon {
        position: absolute;
        width: 12px;
        height: 18px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-upload.png");
        background-size: 12px 18px;
        margin-left: -5px;
        margin-top: 5px;
        display: inline-block;
    }

    .attendee-features-internal .icon {
        position: absolute;
        width: 12px;
        height: 18px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-download.png");
        background-size: 12px 18px;
        margin-left: -5px;
        display: inline-block;
    }

    .additional-feature-div .left .summary .first .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-money.png");
        background-size: 1.5vw 1.5vw;
        display: inline-block;
    }

    .additional-feature-div .summary .textbox {
        margin-left: 2.5vw;
    }

    .additional-feature-div .left .summary .second .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-gas.png");
        background-size: 1.5vw 1.5vw;
        display: inline-block;
    }

    .additional-feature-div .right .summary .first .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-multiple.png");
        background-size: 1.5vw 1.5vw;
        display: inline-block;
    }

    .additional-feature-div .right .summary .second .icon {
        position: absolute;
        width: 1.5vw;
        height: 1.5vw;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-crop.png");
        background-size: 1.5vw 1.5vw;
        display: inline-block;
    }

    .autoscan-button {
        position: absolute;
        width: 125px;
        height: 30px;
        margin-top: 70%;
        background-color: #047de0;
        color: #ffffff;
        font-size: 13px;
    }

    .report-submit-button {
        position: absolute;
        width: 125px;
        height: 30px;
        margin-left: 200px;
        margin-top: 200px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 13px;
    }

    .report-approve-button {
        position: absolute;
        width: 125px;
        height: 30px;
        margin-left: 25%;
        margin-top: 300px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 13px;
    }

    .compliance-button {
        position: absolute;
        width: 125px;
        height: 30px;
        margin-left: 15%;
        margin-top: 150px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 13px;
    }

    .attendee-button {
        position: absolute;
        width: 125px;
        height: 30px;
        margin-left: 15%;
        margin-top: -7%;
        background-color: #047de0;
        color: #ffffff;
        font-size: 13px;
    }

    .export-button {
        position: absolute;
        width: 125px;
        height: 30px;
        margin-left: 15%;
        margin-top: 220px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 13px;
    }

    .vertical-text {
        margin-left: -1%;
        position: absolute;
        transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        transform-origin: left top 0;
        -o-transform-origin: left top 0;
        -ms-transform-origin: left top 0;
        -webkit-transform-origin: left top 0;
        -moz-transform-origin: left top 0;
        font-size: 12px;
    }

    .vertical-hr {
        position: absolute;
        width: 2px;
        border: 0px solid #00c5ff;
        color: #00c5ff;
        background-color: #00c5ff;
    }

    .goto-button {
        font-weight: 400;
        border: none;
        background-repeat: no-repeat;
        background-image: url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-goto.png);
        background-size: 15px;
        background-position: 100px center;
        padding-right: 30px;
        font-size: 12px;
    }

    .fadeInUp {
        -webkit-animation-name: fadeInUp;
        -ms-animation-name: fadeInUp;
        -moz-animation-name: fadeInUp;
        animation-name: fadeInUp;

        -ms-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;

        -ms-animation-fill-mode: both;
        -moz-animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: inline !important;
    }
    @-webkit-keyframes fadeInUp {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
    @-ms-keyframes fadeInUp {
        0% {
            opacity: 0;
            -ms-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -ms-transform: none;
            transform: none;
        }
    }
    @-moz-keyframes fadeInUp {
        0% {
            opacity: 0;
            -moz-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -moz-transform: none;
            transform: none;
        }
    }
    @keyframes fadeInUp {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
}

@media (max-width: 608px) {
    .title-image {
        margin-top: 25%;
    }

    .subtitle-div {
        margin-top: 50px;
    }

    .background-text {
        position: absolute;
        font-size: 42px;
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        text-align: center;
    }

    .our-product-subtitle {
        position: absolute;
        font-size: 20px;
        z-index: 2;
        margin-top: 30px;
        margin-left: 50%;
        width: calc(100% - 20%);
        width: -webkit-calc(100% - 20px);
        width: -moz-calc(100% - 20px);
        text-align: center;
        transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        text-align: center;
    }

    .hr {
        position: absolute;
        display: block;
        width: 10%;
        margin-top: 60px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        border-style: inset;
        border-width: 1px;
        border-top: 1px solid rgba(197, 199, 202, 0.55);
    }

    .our-product-process {
        min-height: 500px;
    }

    .our-product-process .images {
        display: inline-block;
        position: absolute;
        margin-top: 100px;
        height: auto;
        margin-left: 1%;
        width: 99%;
    }

    .our-product-process .video {
        position: absolute;
        margin-left: 55%;
        width: 642px;
        transform: rotate(90deg);
        -ms-transform: rotate(90deg); /* IE 9 */
        -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
        -o-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        transform-origin: left top 0;
        -ms-transform-origin: left top 0;
        -moz-transform-origin: left top 0;
        -webkit-transform-origin: left top 0;
        -o-transform-origin: left top 0;
        z-index: 0;
    }

    .our-product-process video {
        width: 100%;
        display: inline-block;
    }

    .our-product-process .images .process {
        display: inline-block;
        vertical-align: middle;
        width: 17%;
    }
    .our-product-process .images .bodymovin {
        display: inline-block;
        vertical-align: middle;
        width: 10%;
    }
    .our-product-process .images .process img {
        width: 100%;
    }

    .our-product-process .images .process div.process-desc {
        padding: 20px 0;
        font-weight: 800;
        text-align: center;
        font-size: 10px;
    }

    .our-product-process .images .fine-text {
        color: #9a9a9a;
        margin-top: 5px;
        font-size: 6px;
    }

    .video-div {
        display: inline-block;
        width: 100%;
        height: 60vw;
    }

    .video-div .text {
        position: absolute;
        font-size: 15px;
        line-height: 20px;
        margin-top: 30px;
        margin-left: 30px;
        z-index: 3;
    }

    .video-div .video {
        width: 100%;
        max-width: 720px;
        z-index: 1;
    }

    .video-div video {
        width: 100%;
        display: inline-block;
        z-index: 2;
        background:transparent url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/movie-1-start.jpg) no-repeat 0 0;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        object-fit: cover;
    }

    .auto-match-div {
        display: inline-block;
        width: 100%;
        height: 950px;
    }

    .auto-match-div .autoscan-image {
        position: absolute;
        display: inline-block;
        max-width: 380px;
        width: 90%;
        margin-top: 450px !important;
        margin-left: 50% !important;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        z-index: 0;
    }

    .auto-match-div .autoscan-text {
        position: absolute;
        font-size: 12px;
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }

    .autoscan-small-blue-text {
        position: absolute;
        font-size: 14px;
        margin-top: 50px;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        text-align: center;
        color: #047de0;
        z-index: 1;
    }

    .autoscan-subtitle {
        position: absolute;
        font-size: 16px;
        margin-top: 70px;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        z-index: 2;
    }

    .autoscan-background-text {
        position: absolute;
        font-size: 42px;
        margin-top: 30px;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        color: rgba(240, 243, 246, 0.55);
        z-index: 1;
        width: 100%;
        text-align: center;
    }

    .autoscan-wall-of-text {
        position: absolute;
        line-height: 16px;
        margin-top: 110px;
        font-size: 14px;
        margin-left: 50%;
        width: 100%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .autoscan-wall-of-text .small {
        margin-top: 10px;
        position: absolute;
        font-size: 12px;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        width: 260px;
        color: #9a9a9a;
    }

    .match-div {
        position: absolute;
        width: 100%;
        margin-top: 350px;
    }

    .match-background-text {
        position: absolute;
        font-size: 42px;
        color: rgba(240, 243, 246, 0.55);
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        z-index: 2;
    }

    .match-small-blue-text {
        position: absolute;
        font-size: 14px;
        margin-top: 25px;
        color: #047de0;
        z-index: 3;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .match-subtitle {
        position: absolute;
        font-size: 16px;
        z-index: 3;
        margin-top: 45px;
        width: 90%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .match-sub-subtitle {
        position: absolute;
        margin-top: 400px;
        font-weight: 800;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .match-summary {
        position: absolute;
        font-size: 12px;
        margin-top: 430px;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .match-fine-text {
        position: absolute;
        font-size: 10px;
        margin-top: 445px;
        color: #9a9a9a;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .match-app-sync {
        display: none;
    }

    .match-bank-logos {
        position: absolute;
        margin-top: 500px;
        width: 80%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
    }

    .match-transactions {
        width: 100%;
        height: 450px;
    }

    .match-transactions .textbox {
        width: 100%;
    }

    .match-transactions .sub-subtitle {
        font-weight: 800;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .match-transactions .summary {
        font-size: 14px;
        width: 65%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .match-transactions .fine-text {
        color: #b1b1b1;
        font-size: 10px;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        line-height: 15px;
    }

    .match-transactions .image {
        position: absolute;
        width: 90%;
        max-width: 500px;
        margin-left: 50%;
        margin-top: 50px;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .video-div-2 {
        display: inline-block;
        width: 100%;
    }

    .video-div-2 .text {
        position: absolute;
        font-size: 13px;
        line-height: 20px;
        margin-top: 30px;
        margin-left: 30px;
        z-index: 3;
    }

    .video-div-2 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-2 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
        background:transparent url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/movie-2-start.jpg) no-repeat 0 0;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        object-fit: cover;
    }

    .video-div-3 {
        display: inline-block;
        width: 100%;
    }

    .video-div-3 .text {
        position: absolute;
        font-size: 13px;
        line-height: 20px;
        margin-top: 30px;
        margin-left: 30px;
        z-index: 3;
    }

    .video-div-3 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-3 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
        background:transparent url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/movie-3-start.jpg) no-repeat 0 0;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        object-fit: cover;
    }

    .report-div {
        width: 100%;
        height: 200vw;
        min-height: 1300px;
    }

    .report-div .slanted-bg-1 {
        /*background-image: -webkit-linear-gradient(37deg, rgb(225, 222, 220) 75%, #ffffff 50%);*/
        min-height: 400px;
    }

    .report-div .slanted-bg-2 {
        /*background-image: -webkit-linear-gradient(37deg, #ffffff 48%, rgb(225, 222, 220) 20%);*/
        min-height: 800px;
    }

    .report-background-text {
        margin-top: 80px;
        position: absolute;
        font-size: 42px;
        color: #F2F2F2;
        z-index: 1;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .report-small-blue-text {
        position: absolute;
        font-size: 14px;
        margin-top: 105px;
        color: #047de0;
        z-index: 3;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .report-hr {
        margin-left: 80px;
        margin-top: 50px;
    }

    .mobile-report-2 {
        max-width: 200px;
    }

    .report-vertical-text {
        margin-top: 35px;
    }

    .report-subtitle {
        position: absolute;
        font-size: 16px;
        z-index: 3;
        margin-top: 125px;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .report-div-image {
        position: absolute;
        margin-top: 380px;
        max-width: 350px;
        width: 80%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .report-div-image-2 {
        position: absolute;
        margin-top: 600px;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .report-subtitle-2 {
        position: absolute;
        font-size: 16px;
        z-index: 3;
        margin-top: 200px;
        width: 100%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .report-sub-subtitle {
        position: absolute;
        z-index: 3;
        margin-top: 220px;
        font-size: 14px;
        width: 80%;
        margin-left: 50%;
        transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
    }

    .report-div-image-3 {
        position: absolute;
        margin-top: 750px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        width: 80%;
        max-width: 490px;
    }

    .report-subtitle-3 {
        position: absolute;
        font-size: 16px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        z-index: 3;
        margin-top: 555px;
        width: 80%;
    }

    .report-sub-subtitle-3 {
        position: absolute;
        font-size: 14px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        z-index: 3;
        margin-top: 580px;
        width: 70%;
    }

    .report-features {
        min-height: 1500px;
        width: 100%;
        line-height: 15px;
    }

    .report-features-image-1 {
        position: absolute;
        margin-top: 600px;
        margin-left: 50%;
        transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        width: 90%;
        max-width: 380px;
    }

    .report-features-image-2 {
        position: absolute;
        margin-top: 1250px;
        margin-left: 50%;
        transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        width: 90%;
        max-width: 380px;
    }

    .report-features-subtitle {
        position: absolute;
        font-size: 18px;

        line-height: 18px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        z-index: 3;
        margin-top: 100px;
        width: 80%;
        text-align: center;
        word-break: keep-all !important;
        -moz-hyphens:none;
        -ms-hyphens:none;
        -webkit-hyphens: none;
        hyphens:none;
    }

    .report-features-comment {
        font-size: 12px;
        position: absolute;
        margin-left: 15%;
        margin-top: 200px;
    }

    .report-features-comment .text {
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        margin-left: 10px;
        width: 100%;
    }

    .report-features-comment .subtitle {
        color: #047de0;
        font-size: 18px;

    }

    .report-features-history {
        font-size: 12px;
        position: absolute;
        margin-left: 15%;
        margin-top: 270px;
    }

    .report-features-history .text {
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        margin-left: 10px;
        width: 100%;
    }

    .report-features-history .subtitle {
        color: #047de0;
        font-size: 18px;
    }

    .report-features-graph {
        font-size: 16px;
        line-height: 18px;
        position: absolute;
        margin-left: 15%;
        margin-top: 850px;
    }

    .report-features-graph .text {
        font-size: 16px;
        line-height: 18px;
        margin-left: 10px;
        color: #000000;
        width: 100%;
    }

    .report-features-graph .subtitle {
        color: #047de0;
        font-size: 18px;
    }

    .report-features-map {
        font-size: 12px;
        position: absolute;
        margin-left: 15%;
        margin-top: 925px;
    }

    .report-features-map .text {
        font-size: 16px;
        line-height: 18px;
        margin-left: 10px;
        color: #000000;
        width: 100%;
        min-width: 270px;
    }

    .report-features-map .subtitle {
        color: #047de0;
        font-size: 18px;
    }

    .compliance-div {
        width: 100%;
        height: 225vw;
        min-height: 990px;
    }

    .compliance-background-text {
        margin-top: 80px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        position: absolute;
        font-size: 42px;
        color: rgba(218, 220, 223, 0.55);
        z-index: 1;
    }

    .compliance-small-blue-text {
        position: absolute;
        font-size: 14px;
        margin-top: 100px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        color: #047de0;
        width: 300px;
        z-index: 3;
        text-align: center;
    }

    .compliance-hr {
        margin-left: 15%;
        margin-top: 50px;
    }

    .compliance-vertical-text {
        margin-top: 35px;
    }

    .compliance-subtitle {
        position: absolute;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        z-index: 3;
        margin-top: 120px;
        font-size: 18px;
        width: 250px;
    }

    .compliance-features-div {
        width: 100%;
        position: absolute;
        margin-top: 200px;
        min-height: 1000px;
    }

    .compliance-features-distance {
        position: absolute;
        margin-left: 15%;
        margin-top: 50px;
        width: 70%;
    }

    .compliance-features-distance .text {
        font-size: 14px;
        margin-left: 2vw;
        color: #000000;
        word-break: keep-all;
    }

    .compliance-features-distance .subtitle {
        color: #047de0;
        line-height: 18px;
    }

    .compliance-features-category {
        font-size: 14px;
        position: absolute;
        margin-left: 15%;
        margin-top: 125px;
        width: 70%;
    }

    .compliance-features-category .text {
        margin-left: 2.5vw;
        color: #000000;
        word-break: keep-all;
    }

    .compliance-features-category .subtitle {
        color: #047de0;
        line-height: 18px;
    }

    .compliance-features-roadview {
        position: absolute;
        margin-left: 15%;
        margin-top: 200px;
        width: 70%;
    }

    .compliance-features-roadview .text {
        font-size: 14px;
        margin-left: 2vw;
        color: #000000;
        word-break: keep-all;
    }

    .compliance-features-roadview .subtitle {
        color: #047de0;
        line-height: 18px;
    }

    .compliance-features-violation {
        font-size: 14px;
        position: absolute;
        margin-left: 15%;
        margin-top: 275px;
        word-break: keep-all;
        width: 70%;
    }

    .compliance-features-violation .text {
        margin-left: 2vw;
        color: #000000;
        word-break: keep-all;
    }

    .compliance-features-violation .subtitle {
        color: #047de0;
        line-height: 18px;
    }

    .compliance-features-example {
        margin-top: 400px;
        width: 100%;
        min-height: 400px;
    }

    .compliance-features-example-bg-circle-1 {
        margin-top: 35%;
        margin-left: 35%;
        position: absolute;
        width: 30vw;
        height: 30vw;
        border-radius: 900px;
        -moz-border-radius: 900px;
        -webkit-border-radius: 900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-bg-circle-2 {
        margin-top: 27.5%;
        margin-left: 27.5%;
        position: absolute;
        width: 45vw;
        height: 45vw;
        border-radius: 900px;
        -moz-border-radius: 900px;
        -webkit-border-radius: 900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-bg-circle-3 {
        margin-top: 12.5%;
        margin-left: 12.5%;
        position: absolute;
        width: 75vw;
        height: 75vw;
        border-radius: 900px;
        -moz-border-radius: 900px;
        -webkit-border-radius: 900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-bg-circle-4 {
        margin-top: 5%;
        margin-left: 5%;
        position: absolute;
        width: 90vw;
        height: 90vw;
        border-radius: 900px;
        -moz-border-radius: 900px;
        -webkit-border-radius: 900px;
        opacity: 0.1;
        border: 1px solid #047de0;
    }

    .compliance-features-example-text {
        position: absolute;
        margin-top: 50%;
        margin-left: 50%;
        transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        font-size: 16px;
        text-align: center;
        width: 70%;
    }

    .compliance-features-example-text-samsung {
        position: absolute;
        margin-top: 175px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        font-size: 16px;
        text-align: center;
        width: 70%;
    }

    .compliance-features-example-img-1 {
        position: absolute;
        margin-top: 15vw;
    }

    .compliance-features-example-img-2 {
        position: absolute;
        margin-top: 60vw;
    }

    .compliance-features-example-img-2-samsung {
        position: absolute;
        margin-top: 250px;
    }

    .video-div-4 {
        display: inline-block;
        width: 100%;
        height: 300px;
    }

    .video-div-4 .text {
        position: absolute;
        font-size: 15px;
        line-height: 20px;
        margin-top: 30px;
        margin-left: 30px;
        z-index: 3;
        text-shadow: 0.5px 0.5px #c8c8c8;
    }

    .video-div-4 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-4 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
        background:transparent url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/movie-4-start.jpg) no-repeat 0 0;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        object-fit: cover;
    }

    .attendee-div {
        width: 100%;
        min-height: 650px;
    }

    .attendee-background-text {
        margin-top: -70px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        position: absolute;
        font-size: 42px;
        color: rgba(218, 220, 223, 0.55);
        z-index: 1;
    }

    .attendee-hr {
        margin-left: 15%;
        margin-top: -90px;
    }

    .attendee-vertical-text {
        margin-top: -100px !important;
    }

    .attendee-small-blue-text {
        position: absolute;
        font-size: 14px;
        margin-top: -45px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .attendee-subtitle {
        position: absolute;
        font-size: 16px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        z-index: 3;
        margin-top: -20px;
        width: 300px;
    }

    .attendee-features {
        margin-top: 100px;
        margin-left: 10%;
        width: 90%;
        line-height: 15px;
        min-height: 550px;
    }

    .attendee-features-external {
        font-size: 14px;
        line-height: 15px;
        position: absolute;
        margin-left: 5%;
        margin-top: 80px;
        max-width: 70%;
        word-break: keep-all;
    }

    .attendee-features-external .text {
        margin-left: 2vw;
    }

    .attendee-features-external .subtitle {
        color: #047de0;
        font-size: 16px;
        line-height: 18px;
    }

    .attendee-features-internal {
        font-size: 14px;
        line-height: 15px;
        position: absolute;
        margin-left: 5%;
        margin-top: 305px;
        max-width: 70%;
        word-break: keep-all;
    }

    .attendee-features-internal .text {
        margin-left: 2vw;
    }

    .attendee-features-internal .subtitle {
        color: #047de0;
        font-size: 16px;
        line-height: 18px;
    }

    .attendee-example-div {
        width: 100%;
        min-height: 350px;
        background-color: #103763;
    }

    .attendee-example-div .image-1 {
        position: absolute;
        width: 80%;
        max-width: 330px;
        z-index: 1;
        /*margin-left: -10%;*/
        margin-top: -55px;
    }

    .attendee-example-div .image-2 {
        position: absolute;
        padding-left: 30%;
        width: 70%;
        margin-top: -20%;
        z-index: 0;
    }

    .attendee-example-div .text {
        position: absolute;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        margin-top: 230px;
        width: 80%;
        font-size: 16px;
        word-break: keep-all;
        color: white;
        text-align: center;
        line-height: 18px;
    }

    .video-div-5 {
        display: inline-block;
        width: 100%;
        height: 200px;
    }

    .video-div-5 .text {
        position: absolute;
        font-size: 15px;
        line-height: 12px !important;
        margin-top: 30px;
        margin-left: 30px;
        z-index: 3;
    }

    .video-div-5 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-5 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
        background:transparent url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/movie-5-start.jpg) no-repeat 0 0;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        object-fit: cover;
    }

    .export-div {
        width: 100%;
        min-height: 600px;
    }

    .export-background-text {
        margin-top: 70px;
        margin-left: 50%;
        transform: translate(-50%,0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        position: absolute;
        font-size: 42px;
        color: rgba(218, 220, 223, 0.55);
        z-index: 1;
    }

    .export-hr {
        margin-left: 15%;
        margin-top: 40px !important;
    }

    .export-vertical-text {
        margin-top: 25px !important;
    }

    .export-small-blue-text {
        position: absolute;
        font-size: 14px;
        margin-top: 95px;
        margin-left: 50%;
        transform: translate(-50%,0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        color: #047de0;
        width: 300px;
        z-index: 3;
    }

    .export-subtitle {
        position: absolute;
        margin-left: 50%;
        transform: translate(-50%,0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        font-size: 16px;
        text-align: center;
        z-index: 3;
        margin-top: 110px;
        width: 300px;
        line-height: 16px;
    }

    .export-summary {
        position: absolute;
        text-align: left !important;
        font-size: 12px;
        margin-left: 15%;
        z-index: 3;
        margin-top: 250px;
        line-height: 14px;
        width: 75%;
    }

    .export-div .green-div {
        position: absolute;
        margin-top: 400px;
        width: 100%;
        min-height: 200px;
    }

    .export-div .green-div .image-1 {
        position: absolute;
        margin-top: -30px;
    }

    .video-div-6 {
        display: inline-block;
        width: 100%;
        height: 180px;
    }

    .video-div-6 .text {
        position: absolute;
        font-size: 15px;
        line-height: 18px !important;
        margin-top: 20px;
        margin-left: 30px;
        z-index: 3;
    }

    .video-div-6 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-6 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
        background:transparent url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/movie-6-start.jpg) no-repeat 0 0;
        -webkit-background-size:cover;
        -moz-background-size:cover;
        -o-background-size:cover;
        background-size:cover;
        object-fit: cover;
    }

    .additional-feature-div {
        width: 100%;
        min-height: 1700px;
    }

    .additional-feature-div .left {
        width: 100%;
        min-height: 690px;
        margin: 0;
        display: inline-block;
    }

    .additional-feature-div .left .background-text {
        margin-top: 70px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        position: absolute;
        font-size: 42px;
        color: rgba(218, 220, 223, 0.55);
        z-index: 1;
    }

    .additional-hr {
        margin-left: 30%;
        margin-top: 40px !important;
    }

    .additional-vertical-text {
        margin-top: 25px !important;
    }

    .additional-feature-div .left .small-blue-text {
        position: absolute;
        font-size: 12px;
        margin-top: 95px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        color: #047de0;
        width: 250px;
        z-index: 3;
    }

    .additional-feature-div .left .subtitle {
        position: absolute;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        text-align: center;
        z-index: 3;
        margin-top: 115px;
        width: 250px;
        font-size: 16px;
        line-height: 18px;
    }

    .additional-feature-div .left .summary {
        position: absolute;
        font-size: 14px;
        margin-left: 20%;
        margin-top: 20px;
    }

    .additional-feature-div .summary .first {
        margin-top: 200px;
        width: 80%;
        min-height: 120px;
        word-break: keep-all;
        line-height: 16px;
    }

    .additional-feature-div .left .summary .first .icon {
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-money.png");
        background-size: 20px 20px;
        margin-left: -30px;
        margin-top: -2px;
        display: inline-block;
    }

    .additional-feature-div .summary .second {
        margin-top: 20px;
        width: 80%;
        word-break: keep-all;
        line-height: 16px;
        font-size: 14px;
    }

    .additional-feature-div .left .summary .second .icon {
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-gas.png");
        background-size: 20px 20px;
        margin-left: -30px;
        margin-top: -2px;
        display: inline-block;
    }

    .additional-feature-div .left .image {
        margin-top: 450px;
        position: absolute;
        width: 80%;
        max-width: 360px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
    }

    .additional-feature-div .right {
        width: 100%;
        min-height: 800px;
        margin: 0;
        display: inline-block;
    }

    .additional-feature-div .right .background-text {
        display: none;
    }

    .additional-feature-div .right .summary {
        position: absolute;
        font-size: 14px;
        margin-left: 20%;
    }

    .additional-feature-div .right .summary .first .icon {
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-multiple.png");
        background-size: 20px 20px;
        margin-left: -30px;
        margin-top: -2px;
        display: inline-block;
    }

    .additional-feature-div .right .summary .second .icon {
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-crop.png");
        background-size: 20px 20px;
        margin-left: -30px;
        margin-top: -2px;
        display: inline-block;
    }

    .additional-feature-div .right .image {
        margin-top: 450px;
        position: absolute;
        max-width: 360px;
        width: 80%;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
    }

    .video-div-7 {
        display: inline-block;
        width: 100%;
        height: 200px;
    }

    .video-div-7 .text {
        position: absolute;
        font-size: 12px;
        margin-top: 50px;
        margin-left: 25px;
        z-index: 3;
        line-height: 20px !important;
    }

    .video-div-7 .video {
        width: 100%;
        z-index: 1;
    }

    .video-div-7 video {
        width: 100%;
        display: inline-block;
        z-index: 2;
    }

    .report-div-image-2 .textbox-1 {
        position: absolute;
        margin-top: -50px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        font-size: 14px;
        color: #047de0;
    }

    .report-div-image-2 .textbox-2 {
        position: absolute;
        margin-top: -30px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        font-size: 14px;
        color: #047de0;
    }

    .report-div-image-2 .icon-1 {
        display: none;
    }

    .report-div-image-2 .icon-2 {
        display: none;
    }

    .report-features-comment .icon {
        position: absolute;
        width: 16px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-chat.png");
        background-size: 16px 15px;
        margin-left: -15px;
        display: inline-block;
    }

    .report-features-history .icon {
        position: absolute;
        width: 15px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-history.png");
        background-size: 15px 15px;
        margin-left: -15px;
        display: inline-block;
    }

    .report-features-graph .icon {
        position: absolute;
        width: 15px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-graph.png");
        background-size: 15px 15px;
        margin-left: -15px;
        display: inline-block;
    }

    .report-features-map .icon {
        position: absolute;
        width: 11px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-marker-1.png");
        background-size: 11px 15px;
        margin-left: -15px;
        display: inline-block;
    }

    .compliance-features-distance .icon {
        position: absolute;
        width: 12px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-marker-2.png");
        background-size: 12px 15px;
        margin-left: -10px;
        display: inline-block;
    }

    .compliance-features-category .icon {
        position: absolute;
        width: 15px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-tag.png");
        background-size: 15px 15px;
        margin-left: -12px;
        margin-top: 2px;
        display: inline-block;
    }

    .compliance-features-roadview .icon {
        position: absolute;
        width: 15px;
        height: 12px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-eye.png");
        background-size: 15px 12px;
        margin-left: -10px;
        margin-top: 2px;
        display: inline-block;
    }

    .compliance-features-violation .icon {
        position: absolute;
        width: 15px;
        height: 15px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-stop.png");
        background-size: 15px 15px;
        margin-left: -10px;
        margin-top: 2px;
        display: inline-block;
    }

    .attendee-features-external .icon {
        position: absolute;
        width: 12px;
        height: 18px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-upload.png");
        background-size: 12px 18px;
        margin-left: -10px;
        margin-top: -1px;
        display: inline-block;
    }

    .attendee-features-internal .icon {
        position: absolute;
        width: 12px;
        height: 18px;
        background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-download.png");
        background-size: 12px 18px;
        margin-left: -10px;
        margin-top: -1px;
        display: inline-block;
    }

    .autoscan-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        margin-top: 250px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .report-submit-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        margin-top: 300px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .report-approve-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        margin-top: 650px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .compliance-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        margin-top: 175px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .attendee-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        margin-top: 25px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .export-button {
        position: absolute;
        width: 180px;
        height: 37px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        margin-top: 180px;
        background-color: #047de0;
        color: #ffffff;
        font-size: 15px;
    }

    .vertical-text {
        margin-left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        font-size: 12px;
    }

    .vertical-hr {
        position: absolute;
        width: 2px;
        border: 0px solid #00c5ff;
        color: #00c5ff;
        margin-left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        background-color: #00c5ff;
    }

    .goto-button {
        font-weight: 400;
        border: none;
        background-repeat: no-repeat;
        background-image: url(https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/our-product/icon-goto.png);
        background-size: 25px;
        background-position: 140px center;
        padding-right: 50px;
    }

    .mobile-margin {
        margin-top: 40px;
    }
}

.goto-button:hover {
    background-color: #047de0 !important;
}

.our-product {
    word-break: keep-all !important;
    font-family: 'NanumSquare', "Nanum Gothic" !important;
}

.white {
    display: inline;
    color: #ffffff;
}

.blue {
    display: inline;
    color: #0d65d9;
}

.additional-feature-div .summary .title {
    color: #047de0;
    font-weight: 800;
}

.margin-60-top {
    padding-top: 60px;
}

.our-product-intro-text {
    margin-top: 50px !important;
    margin-left: 50% !important;
    transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    text-align: center;
    width: 85%;
    font-size: 14px;
}

.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
    opacity: 0;
    pointer-events: none;
    width: 5px;
}

.extra-thicc {
    font-weight: 800;

}

@media (min-width: 610px) {
    .no-display {
        display: none;
    }
}

.but-inline {
    display: inline-block !important;
}