/* 스크롤바 적용*/
#transaction-upload-modal .invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

/* 스크롤바 스타일 */
#transaction-upload-modal ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 0px;
}

#transaction-upload-modal ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
}

#transaction-upload-modal ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

#transaction-upload-modal .div-table-header ::-webkit-scrollbar {
  display: none !important;
}

#transaction-upload-modal .modal-content .modal-desc {
  margin: 0 25px 25px 25px;
  padding: 15px 0 0 0;
  height: 370px;
}

#transaction-upload-modal .modal-content {
  width: 700px;
}

#transaction-upload-modal .modal-content .modal-desc .row {
  height: 350px;
}

#transaction-upload-modal .modal-content .modal-desc .row .text-row {
  font-size: 14px;
  color: #545454;
  padding-bottom: 10px;
}

#transaction-upload-modal .modal-content .modal-desc .row-save .text-row p {
  padding: 2px;
  font-size: 12px;
}

#transaction-upload-modal .modal-content .modal-desc .row-desc {
  overflow-x: scroll;
  margin-top: 10px;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin: 0 auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .result-table {
  border-radius: 2px;
  border: solid 1px #d5d5d5;
  margin-top: 5px;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .complete-text {
  width: 100%;
  height: 17px;
  line-height: 17px;
  font-size: 11px;
  color: #333333;
  margin: 5px 0;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .complete-text p {
  float: left;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .complete-text .total {
  margin-right: 10px;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .complete-text .success {
  font-weight: 700;
  color: #047de0;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .complete-text .fail {
  font-weight: 700;
  color: #333333;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .result-table .result-header {
  border-radius: 2px;
  height: 15px;
  line-height: 15px;
  padding: 5px;
  font-size: 11px;
  background-color: #f0f2f4;
  color: #7d7d7d;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .result-table .result-list {
  overflow-y: scroll;
  height: 300px;
  font-size: 12px;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .result-table .result-row {
  border-bottom: solid 1px #efefef;
  float: left;
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #000000;
}

#transaction-upload-modal .modal-content .modal-desc .row .complete-result .result-table .result-row span {
  float: left;
  width: 130px;
  text-align: center;
  line-height: 20px;
  height: 20px;
  overflow: hidden;
  padding: 5px;
}

#transaction-upload-modal .modal-content .modal-desc .upload-field .text-area {
  float: left;
  font-size: 12px;
  color: #000000;
}

#transaction-upload-modal .modal-content .modal-desc .upload-field .text-area .text-row {
  float: left;
  width: 100%;
  padding: 5px 0;
}

#transaction-upload-modal .modal-content .modal-desc .upload-field .text-area .check-field {
  color: #545454;
  margin: 25px 0 43px 0;
  float: left;
  width: 100%;
}

#transaction-upload-modal .modal-content .modal-desc .upload-field .text-area .check-field .check-header {
  float: left;
}

#transaction-upload-modal .modal-content .modal-desc .upload-field .text-area .check-field .text-row {
  float: left;
  width: 80%;
  padding: 1px 0 1px 9px;
}

#transaction-upload-modal .modal-content .modal-desc .upload-field .upload-area .upload-button {
  margin: 0 225px;
  width: 200px;
  height: 100px;
  border-radius: 2px;
  border: solid 1px #047cde;
  background-position: 70px 16px;
  background-size: 60px 40px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/transaction-upload.png');
}

#transaction-upload-modal .modal-content .modal-desc .upload-field .upload-area .upload-button:hover {
  background-color: rgba(4, 124, 222, 0.1);
}

#transaction-upload-modal .modal-content .modal-desc .upload-field .upload-area .upload-button .upload-text {
  margin-top: 50px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #047cde;
}

#transaction-upload-modal .modal-content .modal-desc .upload-field .upload-area #file-upload {
  display: none;
}

#transaction-upload-modal .modal-desc .type-wrapper {
  text-align: center;
}

#transaction-upload-modal .card-companies {
  display: inline-block;
  text-align: center;
}

#transaction-upload-modal .transaction-update-td {
  font-size: 14px;
  width: 82px;
  overflow-wrap: break-word;
  margin-right: 10px;
}

#transaction-upload-modal .div-table-header {
  display: flex;
  height: 39px;
  background-color: #f0f2f4;
  padding-left: 10px;
}

#transaction-upload-modal .div-table-body {
  padding-left: 10px;
}

#transaction-upload-modal .div-table-body-wrapper {
  line-height: 12px;
  overflow: hidden;
}

#transaction-upload-modal .modal-content .button-bottom {
  margin-top: 0;
  padding: 9px 20px 30px 20px;
  text-align: right;
}

#transaction-upload-modal .modal-content button.submit {
  margin-right: 18px;
  position: relative;
  display: inline-block;
  background-color: #047de0;
  height: 30px;
  font-size: 12px;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #047de0;
}
