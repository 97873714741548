#feed .main .group {
  object-fit: contain;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

#feed .main .group-title-div {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #333333;
  display: flex;
  align-items: center;
  height: 24px;
}

#feed .main .group-title {
  float: left;
  font-size: 16px;
  margin-right: 8px;
}

#feed .main .feed {
  border-radius: 10px;
  border: solid 1px #dbe3ec;
  display: inline-block;
  width: 100%;
}

#feed .main .feed .feed-title {
  height: 45px;
  text-align: left;
  font-size: 14px;
  color: #093261;
  border-radius: 10px;
  background-color: #f2f6fa;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:8px;
  position: relative;
  width: 100%;
}

#feed .main .feed .feed-title.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#feed .main .feed .feed-title .real-title {
  margin-left: 36px;
}

#feed .main .feed .center {
  margin: 0 auto;
}

#feed .desc .with-title p {
  max-width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#feed .desc .image {
  width: 50%;
  margin: 0 auto;
}

#feed .desc .image img {
  width: 100%;
}

#feed .desc .feed-desc-title {
  color: #7a7a7a;
  width: 100px;
  float: left;
}

#feed .main ul#feedlist li {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 18px 25px;
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

#feed .main ul#feedlist li .feed-info p.feed-date {
  width: 15%;
}

#feed .main ul#feedlist li .feed-info p.desc.image img {
  width: 25%;
  float: left;
  padding-right: 20px;
}

#feed .main ul#feedlist li .feed-info .desc {
  color: #333;
  line-height: 24px;
  font-size: 12px;
  padding: 15px 36px;
  float: left;
  width: -webkit-calc(100% - 72px); /* for Chrome, Safari */
  width: -moz-calc(100% - 72px); /* for Firefox */
  width: calc(100% - 72px); /* for IE */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#feed .main ul#feedlist li .feed-info .desc-custom {
  color: #777;
  line-height: 24px;
  font-size: 12px;
  margin: 0 36px;
  padding: 10px 0;
  float: left;
  width: -webkit-calc(100% - 72px); /* for Chrome, Safari */
  width: -moz-calc(100% - 72px); /* for Firefox */
  width: calc(100% - 72px); /* for IE */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#feed .main ul#feedlist li .feed-info #feed-border {
  border-bottom: solid 1px #e8e8e8;
  margin: 0 16px;
  padding: 15px 20px;
}

#feed .main ul#feedlist li.show-hidden {
  color: #909caa;
  font-size: 14px;
  text-align: center;
  border: none !important;
}

#feed .main ul#feedlist li.show-hidden .show-hidden-wrapper {
  display: inline-block;
}

#feed .feed-info .link button {
  height: 40px;
  font-size: 12px;
  padding: 0 26px !important;
}

#feed .feed-info .link button:hover {
  background-color: rgba(77, 161, 233, 0.1);
}

#feed .feed-info .user-card-name {
  float: left;
}

#feed .feed-info .user-card-icon {
  background-size: 15px 15px;
  width: 15px;
  height: 15px;
  float: left;
  margin: 5px 2px 0 5px;
  background-repeat: no-repeat;
}

#feed .feed-info .sub-group .feed-budget .bar .budget-info {
  font-size: 12px;
}

#feed .feed-info .sub-group .feed-budget .bar .ratio {
  float: right;
  text-align: right;
}

#feed .feed-info .sub-group .feed-budget .bar .limit-status {
  height: 6px;
  color: #373737;
  background-color: #f2f6fa;
  border-radius: 4px;
  display: flex;
  width: 100%;
  float: left;
}

#feed .feed-info .sub-group .feed-budget .bar {
  float: right;
  position: relative;
  height: 5px;
  width: calc(100% - 100px);
  display: block;
  line-height: 25px;
}

#feed .feed-info .sub-group .feed-budget .label {
  color: #9f9f9f;
  width: 100px;
}

#feed .feed-info .sub-group .budget-desc {
  height: 40px;
  color: #373737;
}

#feed .feed-info .sub-group .feed-budget {
  margin: 5px 0;
  font-size: 12px;
  padding: 10px 35px;
}

@media (max-width: 999px) {
  #feed .main ul#feedlist {
    margin: 2% auto;
    width: 96%;
    padding: 50px 0;
  }

  #feed .main ul#feedlist li {
    padding: 10px 30px;
    margin: 20px;
    font-size: 14px;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 95% 30px;
    cursor: pointer;
  }

  #feed .main ul#feedlist li .feed-info p.feed-date {
    width: 30%;
  }
}
