#project-tags #split-view #tag-list {
  display: block;
  width: 239px;
  height: 100%;
  position: fixed;
  padding: 0 0 20px 0;
  background-color: #fff;
  border-right: 1px solid #ddd;
  z-index: 1;
  overflow-y: auto;
}

#project-tags #split-view #tag-list .tag-title span {
  border-bottom: 3px solid #002855;
}

#project-tags #split-view #tag-list .tag-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #002855;
  width: 230px;
  margin: 0 5px;
  background: #ffffff;
  height: 75px;
  line-height: 75px;
  position: fixed;
  border-bottom: 2px solid #b6b6b6;
}

#project-tags #split-view #tag-list .list-ul {
  overflow-y: auto;
  margin: 75px 5px 0 5px;
  height: calc(100% - 229px);
}

#project-tags #split-view #tag-list .list-ul .list-li {
  border-bottom: 1px solid #e8e8e8;
  padding: 23px 15px;
  font-size: 14px;
  cursor: pointer;
}

#project-tags #split-view #tag-list .list-ul .list-li:hover {
  background-color: rgba(190, 201, 216, 0.1);
}

#project-tags #split-view #tag-list .list-ul .selected {
  background-color: rgba(190, 201, 216, 0.1);
  font-weight: bold;
  font-size: 14px;
}

#project-tags #split-view #tag-list .list-ul .list-li:last-child {
  border-bottom: 2px solid #b6b6b6;
}

#project-tags #split-view #tag-list .list-ul li.no-tags {
  font-size: 12px;
  color: #333333;
  padding-top: 20px;
  text-align: center;
}

#project-tags #split-view #tags-view {
  width: -webkit-calc(100% - 240px); /* for Chrome, Safari */
  width: -moz-calc(100% - 240px); /* for Firefox */
  width: calc(100% - 240px); /* for IE */
  left: 240px;
  position: relative;
}

#project-tags #split-view #tags-view .content .dot {
  width: 6px;
  height: 6px;
  margin: 4px 20px 0 0;
  border-radius: 100%;
  background-color: #002855;
  float: left;
}

#project-tags #split-view #tags-view .content .tags-body .no-list-text {
  margin-top: 50px;
}

#project-tags #split-view #tags-view .content .parent-name {
  color: #002855;
  z-index: 10;
  font-weight: bold;
  position: fixed;
  padding-left: 20px;
  height: 30px;
  background-color: #fff;
  width: 100%;
}

#project-tags #split-view #tags-view .content {
  padding: 135px 35px 0 35px;
}

#project-tags .top-area {
  position: fixed;
  float: left;
  height: 135px;
  z-index: 50;
  width: -webkit-calc(100% - 242px); /* for Chrome, Safari */
  width: -moz-calc(100% - 242px); /* for Firefox */
  width: calc(100% - 242px); /* for IE */
  margin: 0 auto;
  right: 1px;
}

#project-tags .button-search-area {
  width: 100%;
  height: 75px;
  float: left;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

#project-tags .button-area {
  position: relative;
  float: left;
  width: -webkit-calc(100% - 70px);
  width: -moz-calc(100% - 70px);
  width: calc(100% - 70px);
  height: 30px;
  background-color: #f7f8fa;
  border-bottom: 1px solid #efefef;
  padding: 15px 35px;
}

#project-tags .button-area p {
  margin: 20px auto 0 auto;
  font-size: 12px;
  line-height: 15px;
  color: #333;
  background: rgba(250, 250, 250, 0.8);
}

#project-tags .search-area {
  position: relative;
  float: left;
  width: 100%;
  height: 30px;
  margin-top: 35px;
}

#project-tags .search-area input[type='text']:focus {
  background-color: transparent !important;
  border: 0 solid !important;
  color: #000000 !important;
}

#project-tags .search-area input[type='text']:focus,
#project-tags .search-area input[type='text'] {
  position: relative;
  float: left;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #000000;
  left: calc(50% - 15%);
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 90%;
  width: 30%;
  height: 40px;
  border-bottom: solid 2px #002855 !important;
}

/* tag list */
#tags .tag-td-check {
  width: 80px;
  padding: 0 15px;
}

#tags .tag-td-enable {
  width: 5%;
  margin-right: 5px;
}

#tags .tag-td-1 {
  width: 20%;
  text-align: left;
  margin-right: 5px;
}

#tags .div-table-body-wrapper {
  font-size: 13px;
}

#tags .tag-td-2 {
  width: 20%;
  text-align: left;
  margin-right: 5px;
}
