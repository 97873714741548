/* report */
#report .div-table-body {
  padding: 35px 0 0 0;
  font-size: 13px;
}

#report .no-list {
  padding-top: calc(20% - 50px);
}

#report #split-view #report-list .top-title span {
  margin-left: 10px;
}
#report #split-view #report-list .top-title {
  height: 40px;
  background-color: #fff;
  line-height: 40px;
  font-weight: bold;
  font-size: 14px;
}

#report #split-view #report-list .list-ul .list-li.none {
  border-left: 6px solid transparent;
}

#report #split-view #report-list .list-ul .list-li.ready {
  border-left: 6px solid #1b64b9;
}

.report-wrap .content {
  height: 100%;
}

#report .content .report-td-0.ready {
  border-left: 7.5px solid #1b64b9;
}

#report #split-view #report-list .list-ul .list-li.sent {
  border-left: 6px solid #95b941;
}

#report .content .report-td-0.sent {
  border-left: 7.5px solid #95b941;
}

#report #split-view #report-list .list-ul .list-li.refund_ready {
  border-left: 6px solid #0b8672;
}

#report .content .report-td-0.refund_ready {
  border-left: 7.5px solid #0b8672;
}

#report #split-view #report-list .list-ul .list-li.refunded {
  border-left: 6px solid #9c9c9c;
}

#report .content .report-td-0.refunded {
  border-left: 7.5px solid #9c9c9c;
}

#report .content .div-table-header .report-td-1 {
  padding-left: 17.5px;
}

#report .content .report-td-0.blank {
  border-left: 7.5px solid transparent;
}

#report .content .report-td-0 {
  width: 5px;
}

#report .content .report-td-1 {
  width: 15px;
}

#report .content .report-td-3 p.price {
  color: #047de0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

#report .content .report-td-4 .policy {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#report .content .price {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#report .content .policy-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  line-height: 15px;
}

#report .content .date,
#report .content .policy-name {
  font-size: 11px;
  padding: 2px 0;
}

#report .content .report-td-9 .date {
  display: flex;
  align-items: center;
}

#report .content .report-td-7 p.date,
#report .content .report-td-10 p.date {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#report .content .report-td-8 .status {
  border-radius: 20px;
  text-align: center;
  font-size: 11px;
  color: #fff;
  padding: 3px 8px;
  text-overflow: ellipsis;
  overflow: hidden;
}

#report .content .report-td-9 .icon-erp-exported,
#report .content .report-td-9 .icon-violation,
#report .content .report-td-9 .icon-csv {
  margin-right: 5px;
  float: right;
}

#report .content .email {
  color: #7d7d7d;
  font-size: 12px;
  line-height: 15px;
}

#report #report-view #report-table thead {
  border-bottom: none !important;
}

#report #report-view #report-table th.td-report-list-select {
  line-height: 20px;
  overflow: visible !important;
  vertical-align: bottom;
}

#report #report-view #report-table th {
  background-color: #f8f8f8;
  line-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

#report #report-view #report-table tbody tr:last-child {
  border-bottom: 1px solid #d1d1d1;
}

#report #report-view .report-attendees-title {
  padding: 10px 0;
  font-weight: bold;
  font-size: 13px;
}

#report #report-custom-fields {
  display: inline-block;
  width: calc(100% - 10px);
  padding-left: 10px;
}

#report #report-custom-fields .custom-field {
  padding: 2px 10px 2px 10px;
  width: 40%;
  display: inline-block;
}

#report #report-custom-fields .custom-field.right {
  float: right;
}

#report #report-custom-fields div.field-title {
  float: left;
  line-height: 15px;
  height: 15px;
  font-size: 12px;
  color: #7d7d7d;
  width: 80px;
  padding: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#report #report-custom-fields div.field-title-custom {
  margin-top: 2px;
  float: left;
  width: 80px;
  line-height: 35px;
  font-size: 12px;
  color: #7d7d7d;
  padding: 10px;
  height: 15px;
  line-height: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#report #report-custom-fields .field-title .edit-title {
  width: 75%;
  font-size: 12px;
  border: 1px solid #b4c0cc;
  border-radius: 2px;
  padding: 7px 10px;
  line-height: 20px;
}

#report #report-custom-fields div.field-content {
  float: right;
  width: 70%;
}

#report #report-custom-fields .date-picker-div .rdt .rdtPicker {
  top: 40px;
  position: absolute !important;
  z-index: 10 !important;
}

#report #report-custom-fields .date-picker-div .rdt .form-control {
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down-gray.png');
}

#report #report-exchange-rates .content .desc {
  font-size: 12px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #7f7f7f;
  padding-bottom: 10px;
}

#report #split-view #report-list {
  /* display: block;
  width: 239px;
  height: 100%;
  position: fixed;
  padding: 0 0 20px 0;
  border-right: 1px solid #ddd;
  z-index: 300;
  overflow-y: auto;
  background-color: #fafafa; */
}

#report #split-view #report-list .list-ul {
  flex: 1;
}

#report #split-view #report-list .list-ul .list-li {
  border-bottom: 1px solid #e8e8e8;
  padding: 13px 15px;
  font-size: 12px;
  cursor: pointer;
}

#report #split-view #report-list .list-ul .list-li:hover {
  background-color: #e9f0f5;
}

#report #split-view #report-list .list-ul .selected {
  background-color: #e9f0f5;
}

#report #split-view #report-list .list-ul li.no-reports {
  font-size: 12px;
  color: #333333;
  padding-top: 20px;
  text-align: center;
}

#report #split-view #report-list .list-ul .list-li .info p {
  display: inline-block;
}

#report #split-view #report-list .list-ul .list-li .info p.status {
  border-radius: 100px;
  font-size: 10px;
  color: #fff;
  padding: 4px 5px 3px;
}

#report #split-view #report-list .list-ul .list-li .info p.date {
  font-size: 11px;
  color: #777;
  width: 140px;
  letter-spacing: -0.3px;
}

#report #split-view #report-list .list-ul .list-li .report {
  font-weight: 700;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#report #split-view #report-list .list-ul .list-li .report .report-title {
  float: left;
  margin-right: 5px;
}

#report #split-view #report-list .list-ul .list-li .price {
  color: #777;
}

#report #split-view #report-view {
  /* width: calc(100% - 240px);
  left: 240px;
  position: relative; */
}

#report #split-view #report-view #report-view-container {
  flex: 1;
  overflow: auto;
  padding: 35px;
  background-color: #fcfcfc;
}

#report #report-document .status span {
  top: 25px;
  left: 25px;
  font-size: 12px;
  padding: 5px 0;
  text-align: center;
  width: 100px;
  border-radius: 100px;
  display: inline-block;
  color: #fff;
}

#report #report-document .report-title {
  font-size: 19px;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 130px);
  height: 32px;
}

#report #report-document .report-title .title-visible p,
#report #report-document .report-title .div-title .text-inner .title-visible p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#report #report-document .report-title .div-title .text-inner {
  float: left;
  margin-left: 10px;
  max-width: calc(100% - 40px);
  max-width: -webkit-calc(100% - 40px);
  max-width: -moz-calc(100% - 40px);
}

#report #report-document .report-title .div-title {
  float: left;
  width: 100%;
  margin: 3px 0;
}

#report #report-document .report-title input {
  border: 1px solid #ddd;
  border-radius: 2px;
  width: 90%;
  height: 22px;
  padding-left: 10px;
  font-size: 19px;
  color: #000000;
}

#report #report-document .report-title .title-visible p {
  height: 22px;
}

#report .title-report {
  background-color: #022855;
}

#report .title-plan {
  background-color: #b2b2b2;
}

#report .title-report,
#report .title-plan {
  float: left;
  font-weight: 400;
  color: #ffffff;
  border-radius: 2px;
  font-size: 11px;
  padding: 1px 4px;
  height: 12px;
  line-height: 12px;
  margin: 3px 4px 3px 0;
}

#report #report-document #top-info p.status {
  border-radius: 100px;
  font-size: 10px;
  color: #fff;
  padding: 5px 15px;
  text-align: center;
  margin: 2px 5px 2px 0;
}

#report #report-document #top-info {
  display: inline-block;
  width: 100%;
}

#report #report-document #top-info ul.left {
  width: calc(100% - 480px);
  float: left;
}

#report #report-document #top-info ul.right {
  width: 470px;
  float: right;
}

#report #report-document #top-info ul.info.left > li {
  float: left;
  margin: 0 10px;
}

#report #report-document #top-info ul.info.right > li {
  float: right;
  margin-left: 10px;
}

#report #report-document #top-info .policy-text {
  top: 25px;
  right: 235px;
  line-height: 28px;
  text-align: right;
  font-size: 12px;
  color: #7d7d7d;
}

#report #report-document #top-info .policy {
  top: 25px;
  right: 25px;
  display: inline-block;
}

#report #report-document #report-info {
  font-size: 13px;
  margin-top: 30px;
  margin-bottom: 50px;
  min-height: 50px;
}

#report #report-document #report-expenses .expense-add .icon-expense-add .icon-expense {
  position: relative;
  width: 110px;
  margin: 5px auto;
  text-align: center;
  cursor: pointer;
  background-position: 10px 7px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-add-expense.png');
}

#report #report-document #report-expenses .expense-add .icon-expense-add .icon-plan {
  position: relative;
  width: 110px;
  margin: 5px auto;
  text-align: center;
  cursor: pointer;
  background-position: 10px 7px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/plan/icon-budget.png');
}

#report #report-document #report-expenses .expense-add .icon-expense-add .text {
  margin: 0 auto;
  line-height: 32px;
  padding-left: 25px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.3px;
  color: #67abf0;
}

#report #report-document #report-expenses .expense-add .icon-expense-add .icon-plan .text {
  margin: 0 auto;
  line-height: 32px;
  padding-left: 25px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #67abf0;
}

#report #report-document #report-expenses .expense-add .icon-expense-add {
  margin: 0 auto;
  color: #67abf0;
  height: 40px;
  position: relative;
}

#report #report-document #report-expenses #add-expense-line {
  opacity: 0.1;
  position: relative;
  bottom: 19px;
  height: 12px;
  background-color: #67abf0;
}

#report #report-document #report-expenses .expense-add {
  width: 100%;
  padding: 20px 0;
}

#report #report-document .button#category-integrate {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/up.png');
  background-size: 7px;
  padding-right: 30px;
  background-repeat: no-repeat;
  background-position: 105px center;
  cursor: pointer;
}

#report #report-document .policy #report-policy-select,
#report #report-document #report-divide-select {
  display: inline-block;
}

#report #report-document .policy #report-policy-select,
#report #report-document #report-divide-select .list-body {
  border-radius: 2px;
  /*border: 1px solid #ddd;*/
}

#report #report-document .policy #report-policy-select {
  text-align: left !important;
  width: 150px;
}

#report #report-document #report-divide-select .list-body .list-selected {
  text-align: left !important;
  padding-right: 26px;
}

#report #report-document .policy #report-policy-select .list-body .list,
#report #report-document #report-divide-select .list-body .list {
  border: 1px solid #ddd;
}

#report #report-document .approver-info p {
  float: left;
  margin-right: 10px;
}

#report #report-document #report-info #document {
  display: inline-block;
  width: calc(100% - 400px);
}

#report #report-document #report-info .exchange-info {
  width: calc(100% - 5px);
  float: right;
  text-align: right;
  color: #7d7d7d;
  padding: 5px 12px 5px 18px;
  letter-spacing: -0.2px;
}

#report #report-document #report-info .exchange-info dd {
  float: right;
}

#report #report-document #report-info .exchange-info dt {
  float: right;
  margin-right: 5px;
}

#report #report-document #report-info #price {
  display: inline-block;
  width: 355px;
  background-color: #f8f8f8;
  padding: 12px 15px;
  float: right;
  border-radius: 2px;
}

#report #report-document #report-info #price,
#report #report-document #report-info #document {
  vertical-align: middle;
}

#report #report-document #report-info #document dt {
  display: inline-block;
  color: #a4a4a4;
  line-height: 24px;
  width: 20%;
  margin-left: 20px;
}

#report #report-document #report-info #document dd {
  display: inline-block;
  padding-left: 20px;
  width: calc(70% - 25px);
}

#report #report-document #report-info #price dt {
  display: inline-block;
  color: #a4a4a4;
  line-height: 28px;
  width: 40%;
}

#report #report-document #report-info #price dd.selected {
  font-size: 16px;
  height: 24px;
  line-height: 23px;
  float: right;
}

#report #report-document #report-info #price dt.selected {
  display: inline-block;
  color: #a4a4a4;
  line-height: 24px;
  height: 24px;
  width: 40%;
}

#report #report-document #report-info #price dd.all {
  font-size: 20px;
  line-height: 28px;
  float: right;
}

#report #report-document #report-info #price dd {
  display: inline-block;
  width: 58%;
  text-align: right;
}

#report #report-document .clause .caption {
  cursor: pointer;
  font-size: 14px;
  color: #bdbdbd;
  padding-bottom: 25px;
  font-weight: 700;
}

#report #report-document .clause .caption span {
  vertical-align: middle;
}

#report #report-document .clause .caption .caption-title {
  float: left;
  position: absolute;
  background-color: white;
  z-index: 10;
  padding-right: 20px;
}

#report #report-document .clause .caption .caption-bar {
  border-bottom: 1px solid #d1d1d1;
  width: calc(100% - 50px);
  width: -webkit-calc(100% - 50px); /* for Chrome, Safari */
  width: -moz-calc(100% - 50px); /* for Firefox */
  height: 10px;
  position: absolute;
}

#report #report-document .clause .caption .caption-menu {
  position: absolute;
  /*z-index: 10;*/
  right: 25px;
  /* width: 100px; */
  background-color: white;
  padding-left: 20px;
}

#report #report-document .clause .content {
  font-size: 13px;
  color: #333;
  line-height: 20px;
}

#searchFile {
  display: none;
}

#report #report-document .edit-custom-field {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/cog-gray.png');
  background-size: 13px;
  background-position: left;
  background-repeat: no-repeat;
  width: 80px;
  font-weight: 400;
  text-align: right;
}

#report .report-visible-images,
#report .report-visible {
  cursor: pointer;
}

#report .report-visible-images .overlay {
  display: none;
  border-radius: 1px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border: solid 1px #d9dfe6;
  z-index: 200;
  font-size: 12px;
  width: 180px;
  margin-top: 7px;
  position: absolute;
}

/* 도움말 풍선의 세모 모양 */
#report .report-visible-images .overlay:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #d9dfe6;
  right: 50%;
  top: -12px;
}

/* 도움말 풍선이 가려지는 것을 방지?
 잘은 모르겠지만 이것으로 인해 ?표시 있는 곳 까지
 <p> 영역을 확장하게 된다. 아래 그림 참조
 */
#report .report-visible-images .overlay:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#report .report-visible-images .overlay .visibility-list {
  color: #000000;
  padding: 15px;
  line-height: 20px;
}

#report .report-visible-images .overlay .visibility-list li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#report .report-visible:hover #btn-report-see {
  border: 1px solid #81beef !important;
  color: #037de0 !important;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/see-hover.png') !important;
}

#report .report-visible-images:hover .overlay,
#report .report-visible:hover .overlay {
  display: inline-block !important;
}

#report-document #report-table {
  font-size: 12px;
  margin-top: 20px;
}

#report-document #report-table tr:hover {
  background: #fbfbfb !important;
}

#report-document #report-table #report-no-expense {
  text-align: center;
  color: #3d8de1;
  font-weight: bold;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add-image.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: 50%;
  line-height: 300px;
  height: 200px;
  vertical-align: bottom;
  cursor: pointer;
}

#report .report-next-deny-button:hover {
  background-color: #f4f9fd;
}

#report .report-next-deny-button {
  border-color: #047de0;
  font-size: 12px;
  letter-spacing: -0.2px;
  padding: 0 50px;
  color: #047de0;
}

#report .report-next-button {
  background-color: #047de0;
  border-color: #047de0;
  font-size: 12px;
  letter-spacing: -0.2px;
  padding: 0 50px;
  color: #ffffff;
}

#report .report-next-button:hover {
  background-color: #0f58ae;
  border-color: #0f58ae;
}

#report .report-next-button-gray {
  background-color: #c6d0dd;
  border-color: #c6d0dd;
  font-size: 12px;
  letter-spacing: -0.2px;
  padding: 0 50px;
  color: #ffffff;
}

#report .report-next-button-gray:hover {
  background-color: #aab8c8;
  border-color: #aab8c8;
}

#report-note .content li {
  line-height: 15px;
  margin: 6px 0;
  display: flex;
  align-items: center;
}

/* overlay-custom start */
#report .report-visible-images .overlay-custom {
  display: none;
  border-radius: 1px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border: solid 1px #d9dfe6;
  z-index: 100;
  font-size: 12px;
  width: 180px;
  margin-top: 7px;
  position: absolute;
}

#report .report-visible-images .overlay-custom:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #022855;
  right: 71%;
  top: -12px;
}

#report .report-visible-images .overlay-custom:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#report .report-visible-images .overlay-custom .visibility-list {
  color: #000000;
  padding: 15px;
  line-height: 20px;
}

#report .report-visible-images .overlay-custom .visibility-list li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#report .report-visible-images:hover .overlay-custom,
#report .report-visible:hover .overlay-custom {
  display: inline-block !important;
}

#report .report-visible-images .overlay-custom p {
  line-height: 26px;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
/* overlay-custom end */

/* overlay-reports-custom start */
#report .report-visible-images .overlay-reports-custom {
  display: none;
  border-radius: 1px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  background-color: #bec8d3;
  border: solid 1px #bec8d3;
  z-index: 300;
  font-size: 12px;
  width: 180px;
  margin-top: 7px;
  position: absolute;
}

#report .report-visible .overlay-reports-custom {
  display: none;
  height: 35px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #bec8d3;
  border: solid 1px #bec8d3;
  z-index: 300;
  margin-top: -45px;
  font-size: 12px;
  position: absolute;
}

.report-visible .etc {
  font-size: 11px;
  margin-left: 5px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #7d7d7d;
}
#report .report-visible .overlay-reports-custom:before {
  top: 36px;
}

#report .report-visible-images .overlay-reports-custom:before {
  top: 31px;
}

#report .report-visible-images .overlay-reports-custom:before,
#report .report-visible .overlay-reports-custom:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #bec8d3;
  right: 20%;
  z-index: 310;
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}

#report .report-visible-images .overlay-reports-custom:after,
#report .report-visible .overlay-reports-custom:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#report .report-visible .overlay-reports-custom .visibility-title {
  font-weight: bold;
  color: #bec8d3;
  padding: 15px;
  border-bottom: solid 1px #bec8d3;
}

#report .report-visible-images .overlay-reports-custom .visibility-list,
#report .report-visible .overlay-reports-custom .visibility-list {
  color: #000000;
  padding: 15px;
  line-height: 20px;
}

#report .report-visible-images .overlay-reports-custom .visibility-list li,
#report .report-visible .overlay-reports-custom .visibility-list li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#report .report-visible-images:hover .overlay-reports-custom,
#report .report-visible:hover .overlay-reports-custom {
  display: inline-block !important;
}

/* overlay-custom start */
#report .report-row .title-visible .overlay-title {
  margin-top: 30px;
  margin-left: -20%;
}

#report .title-visible .overlay-title {
  display: none;
  min-height: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.75);
  background-color: #efefef;
  border: solid 1px #efefef;
  z-index: 100;
  padding: 0 10px;
  font-size: 12px;
  position: absolute;
}

#report .title-visible .overlay-title .name {
  color: #ffffff !important;
  font-size: 12px !important;
}

#report .title-visible .overlay-title:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #efefef;
  right: 85%;
  top: -12px;
}

#report .title-visible .overlay-title:after {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
}

#report .title-visible .overlay-title .visibility-title {
  padding: 15px;
  border-bottom: solid 1px #022855;
  color: #ffffff !important;
}

#report .title-visible .overlay-title .visibility-list {
  color: #ffffff !important;
  padding: 15px;
  line-height: 20px;
}

#report .title-visible .overlay-title .visibility-list li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#report .title-visible:hover .overlay-title {
  display: inline-block !important;
}

.overlay-title div {
  line-height: 26px;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
}
/* overlay-custom end */

#report-document li#navigation {
  text-align: right;
  position: absolute;
  right: 15px;
  height: 30px;
  top: 10px;
  background-color: transparent;
}

#report-document li#navigation .pages {
  height: 30px;
  line-height: 30px;
  float: left;
  font-size: 15px;
  vertical-align: middle;
  padding: 0 10px;
  color: #022855;
}

#report-document #report-comments .page-navigation {
  float: right;
  padding: 10px 0;
}

#report-document #report-comments ul .div-pagination ul li {
  padding: 0 !important;
  min-height: 0px !important;
  margin-bottom: 0px !important;
}

#report-document #report-comments .page-navigation .page-info-select {
  float: right !important;
}

#report #report-document #top-info .plan-date-right {
  width: 470px;
  float: right;
  margin-top: 31px;
}

#report #report-document #top-info .plan-date-right .period-area {
  float: right;
  width: 277px;
  height: 32px;
}

#report #report-document #top-info .plan-date-right li {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  float: left;
  width: 130px;
}

#report #report-document #top-info .plan-date-right .dash {
  float: left;
  height: 32px;
  line-height: 30px;
  margin: 0 5px;
  color: #a3a3a3;
}

#report #report-document #top-info .plan-date-right .policy-text {
  top: 25px;
  right: 235px;
  line-height: 32px;
  height: 32px;
  text-align: right;
  float: right;
  font-size: 12px;
  color: #7d7d7d;
}

#report #report-document #top-info .plan-date-right .days-button.active {
  color: #ffff;
  font-weight: bold;
  background-color: #5b6d7f;
}

#report #report-document #top-info .plan-date-right .days-button {
  width: 42px;
  height: 28px;
  margin: 1px;
  line-height: 28px;
  text-align: center;
  border-radius: 2px;
  background-color: #ffffff;
  float: left;
  font-size: 13px;
  color: #5b6d7f;
}

#report #report-document #top-info .plan-date-right .plan-date-button {
  float: right;
  width: 88px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 2px;
  border: solid 1px #b4c0cc;
}

#report #report-document #top-info .plan-date-left {
  width: calc(100% - 480px);
  height: 30px;
  float: left;
}

#report #report-document .clause .report-expenses-area {
  width: 100%;
  margin: 20px 0;
}

#report #report-document .clause .report-expenses-area .report-expense-area.plan {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #dbdbdb;
}

#report #report-document .clause .report-expenses-area .report-expense-area {
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 20px;
  display: inline-table;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-left-info {
  width: 92%;
  height: 60px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-left-info.normal {
  width: 60%;
  height: 60px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-left-info.normal p {
  float: left;
  margin: 10px 0;
  overflow: hidden;
  max-width: 470px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;

  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.33px;
  color: #000000;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-left-info.normal p .total-amount {
  font-weight: normal;
}

#report
  #report-document
  .clause
  .report-expenses-area
  .report-expense-area
  .expense-left-info
  #row-overlay
  .row-visible {
  float: none !important;
  width: none !important;
}

#report
  #report-document
  .clause
  .report-expenses-area
  .report-expense-area
  .expense-left-info
  #row-overlay
  .row-visible
  .overlay-custom {
  margin-top: 60px;
  margin-left: -260px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-right-info.normal {
  width: 40%;
  height: 60px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-right-info.normal dl {
  float: left;
  width: 100%;
  margin: 10px 0;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-right-info.normal dd {
  float: right;
  font-size: 14px;
  color: #000000;
  margin-right: 15px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-right-info.normal dt {
  float: left;
  font-size: 12px;
  color: #7f7f7f;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-left-info .expense-info {
  float: left;
  width: 60%;
  margin-top: 5px;
}

#report #report-document .report-expenses-area .report-expense-area .expense-left-info .expense-info .check-type.daily,
#report #report-document .report-expenses-area .report-expense-area .expense-left-info .expense-info .check-type.night {
  background-color: #b2b2b2;
  float: left;
}

#report
  #report-document
  .clause
  .report-expenses-area
  .report-expense-area
  .expense-left-info
  .expense-info
  .category-name {
  float: left;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  margin: 10px 0;
}

#report #report-document .report-expenses-area .report-expense-area .expense-left-info .plan-graph-info {
  width: 40%;
  float: right;
  margin-top: 6px;
}

#report #report-document .report-expenses-area .report-expense-area .expense-left-info .plan-graph-info .limitation {
  text-align: right;
}

#report
  #report-document
  .report-expenses-area
  .report-expense-area
  .expense-left-info
  .plan-graph-info
  .limitation
  .limitation-title {
  color: #7f7f7f;
  font-size: 12px;
  margin: 2px 0 7px 0;
}

#report
  #report-document
  .report-expenses-area
  .report-expense-area
  .expense-left-info
  .plan-graph-info
  .limitation
  .limitation-price {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #000000;
}

#report
  #report-document
  .report-expenses-area
  .report-expense-area
  .expense-left-info
  .expense-info
  .check-type.subject {
  background-color: #6f8aa7;
  float: left;
}

#report #report-document .report-expenses-area .report-expense-area .expense-left-info .expense-info .check-type {
  float: left;
  height: 13px;
  line-height: 13px;
  border-radius: 2px;
  padding: 2px 5px;
  color: #ffffff;
  font-size: 11px;
}

#report
  #report-document
  .clause
  .report-expenses-area
  .report-expense-area
  .expense-left-info
  .expense-info
  .limitation {
  float: right;
  font-size: 12px;
  color: #7f7f7f;
  height: 18px;
  text-align: right;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-left-info .bottom-info {
  float: left;
  width: 100%;
  height: 40px;
}

#report
  #report-document
  .clause
  .report-expenses-area
  .report-expense-area
  .expense-left-info
  .bottom-info
  .category-name {
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  float: left;
}

#report
  #report-document
  .clause
  .report-expenses-area
  .report-expense-area
  .expense-left-info
  .bottom-info
  .limitation-price {
  float: right;
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #000000;
  line-height: 24px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-top-info {
  padding: 10px 10px 10px 20px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-top-info.normal {
  padding: 10px 10px 10px 20px;
  margin-bottom: 30px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-right-info {
  float: right;
  width: 8%;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-right-info .pencil-icon {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/plan/plan-edit.png');
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  float: right;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 10px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-right-info .x-icon {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/plan/plan-delete.png');
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  float: right;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 6px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-right-info .pencil-icon:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/plan/plan-edit-hover.png');
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  float: right;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 10px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .expense-right-info .x-icon:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/plan/plan-delete-hover.png');
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  float: right;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 6px;
}

#report #report-document .clause .report-expenses-area .report-expense-area .plan-bottom-info {
  width: 100%;
  float: left;
}

#report #report-document .clause .report-expenses-area .report-expense-area .plan-bottom-info .bottom-title {
  float: left;
  font-size: 14px;
  padding: 10px 0 17px 0;
  letter-spacing: -0.2px;
  color: #7d7d7d;
  width: 5%;
}

#report #report-document .clause .report-expenses-area .report-expense-area .plan-bottom-info .bottom-desc {
  float: left;
  color: #000000;
  font-size: 14px;
  width: 85%;
  padding: 10px 10px 17px 10px;
}

#report-document #report-table .top-info {
  background-color: #f2f4f6;
  height: 38px;
  border-radius: 8px 8px 0 0 !important;
}

#report-document #report-table .top-info .add-expense {
  width: 45%;
  float: left;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: left;
  color: #047de0;
  line-height: 18px;
  margin: 10px 0 10px 10px;
}

#report-document #report-table .top-info .add-expense span {
  cursor: pointer;
}

#report-document #report-table .top-info .expense-count {
  width: 100%;
  float: left;
  line-height: 18px;
  margin: 10px 0;
  color: #7d7d7d;
}

#report-document #report-table .top-info .expense-count .open-icon {
  float: left;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

#report-document .plan-budget {
  width: 100%;
  float: right;
}

#report-document .plan-budget .bar .budget-info {
  font-size: 12px;
  float: right;
  width: 100%;
  margin-top: 10px;
}

#report-document .plan-budget .bar .budget-info .budget-title {
  float: left;
  margin-right: 10px;
  font-size: 12px;
  color: #7f7f7f;
  width: 40%;
}

#report-document .plan-budget .bar .budget-info .budget-desc {
  float: left;
  font-size: 14px;
  color: #000000;
  width: 50%;
}

#report-document .plan-budget .bar .ratio {
  float: right;
  text-align: right;
}

#report-document .plan-budget .bar .limit-status {
  height: 6px;
  color: #373737;
  background-color: #f2f6fa;
  border-radius: 4px;
  display: flex;
  width: 100%;
  float: left;
}

#report-document .plan-budget .bar .budget-balance {
  font-size: 12px;
  float: right;
  width: 30%;
  margin-top: 10px;
}

#report-document .plan-budget .bar .budget-balance .budget-balance-title {
  font-size: 12px;
  letter-spacing: -0.2px;
  color: #7f7f7f;
  float: right;
  width: 100%;
  text-align: right;
}

#report-document .plan-budget .bar .budget-balance .budget-balance-desc {
  font-size: 16px;
  font-weight: bold;
  float: right;
  width: 100%;
  text-align: right;
}

#report-document .plan-budget .bar {
  float: right;
  position: relative;
  height: 5px;
  width: calc(100% - 100px);
  display: block;
  line-height: 25px;
}

#report-document .plan-budget .label {
  color: #9f9f9f;
  width: 100px;
}

#report-document .plan-budget .budget-desc {
  color: #373737;
}

#report-document .plan-budget {
  margin: 5px 0;
  font-size: 12px;
  padding: 10px 35px;
}

#report #report-view #plan-table thead {
  border-bottom: 1px solid #efefef;
}

#report #report-view #plan-table th.td-report-list-select {
  line-height: 20px;
  overflow: visible !important;
  vertical-align: bottom;
  padding-left: 15px;
}

#report #report-view #plan-table th {
  background-color: #f8f8f8;
  line-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

#report #report-view #plan-table .td-report-list-attendee {
  width: 14%;
  text-align: left;
  padding-left: 10px;
}

#report #report-view #plan-table .td-report-list-10 {
  width: 6%;
  text-align: center;
}

#report #report-view #plan-table tbody tr:last-child {
  border-bottom: 1px solid #d1d1d1;
}

#report-document #plan-table {
  font-size: 12px;
  margin-top: 30px;
}

#report-document #plan-table tr:hover {
  background: #fbfbfb !important;
}

#report-document #plan-table #report-no-expense {
  text-align: center;
  color: #3d8de1;
  font-weight: bold;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add-image.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: 50%;
  line-height: 300px;
  height: 200px;
  vertical-align: bottom;
  cursor: pointer;
}

#report-document #plan-table .top-info {
  background-color: #f2f4f6;
  display: inline-table;
  width: 100%;
}

#report-document #plan-table .top-info.normal {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

#report-document #plan-table .top-info.close {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

#report-document #plan-table .top-info .add-expense {
  width: 45%;
  float: left;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: left;
  color: #047de0;
  line-height: 18px;
  margin: 10px 0 10px 10px;
}

#report-document #plan-table .top-info .add-expense span {
  cursor: pointer;
}

#report-document #plan-table .top-info .expense-list {
  line-height: 18px;
  padding: 10px 10px 10px 20px;
  cursor: pointer;
  color: #7d7d7d;
  background-color: #f2f4f6;
}

#report-document #plan-table .top-info .expense-list.close {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

#report-document #plan-table .top-info .expense-list.common {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

#report-document #plan-table .top-info .expense-list .open-icon {
  float: left;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

#report-document #plan-table .select-button {
  width: calc(100% - 20px);
  line-height: 50px;
  background-color: #f2f4f6;
  margin-left: 20px;
}

#report .icon-edit {
  margin-left: 5px;
}

#report #plan-table tr.disabled,
#report #report-table tr.disabled {
  background-color: #f8f8f8 !important;
}

#report #report-table table {
  table-layout: fixed;
}
