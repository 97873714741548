#policy-refund .content#single-view {
  flex: 1;
  overflow: auto;
}

#policy-refund .policy-refund-title {
  line-height: 25px;
  padding: 20px 0;
  text-align: center;
}

#policy-refund .policy-refund-title .set-refund-complete {
  padding-left: 17px;
  border-radius: 2px;
  margin-top: 31px;
}

#policy-refund .policy-refund-title .set-refund-complete .half-area {
  width: 75%;
}

#policy-refund .policy-refund-title .set-refund-complete dl {
  line-height: 40px;
  color: #333;
  text-align: left;
  font-size: 13px;
}

#policy-refund .policy-refund-title .set-refund-complete dl dt {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: 18px;
  color: #000000;
  white-space: nowrap;
}

#policy-refund .policy-refund-title .set-refund-complete dl dt i {
  position: relative;
  margin-left: 6px;
  cursor: pointer;
}

#policy-refund .policy-refund-title .set-refund-complete dl dt i:hover .overlay {
  display: block;
}

#policy-refund .policy-refund-title .set-refund-complete dl dd {
  display: inline-block;
  vertical-align: top;
  color: #7d7d7d;
}

#policy-refund .policy-refund-title .set-refund-complete dl dd .switch {
  float: left;
  margin-top: 10px;
  margin-right: 10px;
}

#policy-refund .policy-refund-td-1 {
  width: 30%;
  padding-left: 10px;
}

#policy-refund .policy-refund-td-2 {
  width: 30%;
}

#policy-refund .policy-refund-td-3 {
  width: 15%;
  text-align: right;
}

#policy-refund .policy-refund-td-4 {
  width: 15%;
  float: right !important;
  text-align: right;
  padding-right: 10px;
}

#policy-refund .policy-refund-td-4 p {
  color: #de3c3c;
}

#policy-info #card-info .sub-title {
  height: 74px;
  padding-top: 20px;
  padding-bottom: 40px;
  box-sizing: border-box;
}

#policy-info .policy-refund #spent-info-container .content {
  margin-top: 100px;
}

#policy-refund .policy-refund {
  display: flex;
  flex-direction: column;
}

.policy-refund .content {
  font-size: 13px;
}

#policy-refund .policy-refund .content {
  flex: 1;
  overflow: auto;
}

#policy-refund .buttons {
  height: 40px;
  background-color: #f7f8fa;
}

#policy-refund .buttons #btn-remove {
  margin-left: 8px;
}
