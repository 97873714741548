#policy-erp .content .desc .erp-description {
  margin: 20px 0;
  height: 20px;
}

#policy-erp .erp-description.link-back {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/link-back.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 14px 10px;
  padding: 0 35px;
  line-height: 20px;
  color: #7d7d7d;
  margin: 0 0 30px 27px;
  height: 20px;
}

/* erp overview in report */
#erp-overview table th {
  background-color: #ffffff !important;
  border-bottom: solid 1px #979797;
  font-size: 10px;
  color: #7d7d7d;
}

#erp-overview table td {
  font-size: 13px;
}

#erp-overview table .erp-overview-modal-td-1 {
  padding-left: 10px;
}

#erp-overview table td.erp-overview-modal-td-2 .bold {
  color: #000000;
}

#erp-overview table td.erp-overview-modal-td-2 .report-title-disabled {
  font-size: 11px !important;
  letter-spacing: 0.1px !important;
  color: #ea433e !important;
}

#erp-overview table td.erp-overview-modal-td-2 .report-title {
  font-size: 11px !important;
  letter-spacing: 0.1px !important;
  color: #7d7d7d !important;
}

#erp-overview table .erp-overview-modal-td-4 {
  width: 100px;
  max-width: 100px;
}

#erp-overview table .erp-overview-modal-td-5 {
  width: 40px;
  padding-left: 20px;
}

#erp-overview table tr.erp-overview-detail div.detail-body {
  background: #f3f5f7;
  height: 65px;
}

#erp-overview table tr.erp-overview-detail span.erp-missing {
  color: #ea433e;
  font-size: 12px;
  font-weight: bold;
}

#erp-overview table tr.erp-overview-detail div.detail-header {
  color: #7d7d7d;
  height: 20px;
  line-height: 20px;
  background: #e7ecef;
  font-size: 10px;
}

#erp-overview table tr.erp-overview-detail ul {
  display: inline-block;
  width: 100%;
}

#erp-overview table tr.erp-overview-detail .detail-body li {
  line-height: 25px;
  font-size: 12px;
}

#erp-overview table tr.erp-overview-detail li.type {
  width: 30px;
}

#erp-overview table tr.erp-overview-detail li.erp-code {
  width: 30%;
}

#erp-overview table tr.erp-overview-detail li.red {
  color: #ea433e;
}

#erp-overview table tr.erp-overview-detail li.category-name {
  width: 25%;
}

#erp-overview table tr.erp-overview-detail li.price {
  text-align: right;
  width: calc(35% - 50px);
  width: -webkit-calc(35% - 50px);
  width: -moz-calc(35% - 50px);
}

#erp-overview table tr.erp-overview-detail li,
#erp-overview .overview-summary li {
  float: left;
  padding-left: 10px;
}

#erp-overview .overview-summary ul.left {
  margin-right: 10px;
}

#erp-overview .overview-summary ul {
  display: inline-block;
  margin: 10px 0;
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
  width: -moz-calc(50% - 5px);
}

#erp-overview .overview-summary .count {
  font-size: 11px;
  letter-spacing: -0.2px;
  color: #000000;
  line-height: 20px;
  padding-left: 20px;
}

#erp-overview .overview-summary li {
  height: 40px;
  border-radius: 2px;
  background-color: #f3f5f7;
  line-height: 40px;
  width: calc(100% - 10px);
  width: -webkit-calc(100% - 10px);
  width: -moz-calc(100% - 10px);
  font-size: 13px;
}

#erp-overview .overview-summary li .total-title {
  float: left;
  padding-left: 10px;
}

#erp-overview .overview-summary li .total-price {
  float: right;
  padding-right: 20px;
  font-weight: bold;
}

#erp-overview table tr.erp-overview-detail div.left {
  margin-right: 10px;
}

#erp-overview table tr.erp-overview-detail div.detail {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
  width: -moz-calc(50% - 5px);
  float: left;
  margin-top: 5px;
  margin-bottom: 5px;
}
