#web-view {
    float: left;
    width: calc(100% - 20px);
    padding: 20px 10px;
}

#password-change {
    width: 100%;
}

#password-change #pwd-tooltip {
    display: unset;
    background-color: #FFFFFF;
}

#password-change .modal-title {
    background-color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: #022855;
    text-align: center;
}

#password-change .modal-desc {
    float: left;
    padding: 20px 40px;
}


#password-change .middle-area input::placeholder {
    color: #c3cdd6;
    letter-spacing: -0.3px;
}


#password-change .exit {
    margin: 7px 0;
    background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/x.png');
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20px;
    top: 13px;
    z-index: 10;
    background-size: 100%;
    cursor: pointer;
}

#password-change .top-area {
    float: left;
    width: 100%;
}

#password-change .top-area .password-reset-icon {
    float: left;
    width: 100%;
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/password-reset-icon.svg");
    height: 100px;
    background-size: 88px 100px;
    background-repeat: no-repeat;
    background-position: center;
}

#password-change .top-area .password-reset-desc {
    float: left;
    margin-top: 10px;
    width: 100%;
}

#password-change .top-area .password-reset-desc p {
    text-align: center;
    float: left;
    width: 100%;
    font-size: 12px;
    letter-spacing: -0.4px;
    color: #555555;
    line-height: 20px;
}

#password-change .top-area .password-reset-desc p.date-desc {
    color: #c93939;
    font-weight: bold;
}

#password-change .middle-area {
    float: left;
    width: 100%;
    margin-top: 28px;
}

#password-change .middle-area .find-password-desc {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #9f9f9f;
    cursor: pointer;
    float: left;
    width: 100%;
    text-align: right;
}

#password-change .middle-area .find-password-desc span {
    border-bottom: solid 1px #9f9f9f;
    color: #9f9f9f;
    line-height: 20px;
}

#password-change .middle-area input[type="password"] {
    height: 50px;
    border-radius: 6px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #d4d4d4;
    padding: 0 20px;
    width: calc(100% - 40px);
    margin-bottom: 13px;
    font-size: 16px;
}

#password-change .button-area {
    float: left;
    width: 100%;
    margin-top: 24px;
}

#password-change .button-area button.password-change {
    float: left;
    width: 100%;
    height: 50px;
    border-radius: 6px;
    background-color: #047de0;
    color: #FFFFFF;
    font-size: 16px;
}

#password-change .bottom-desc {
    float: left;
    width: 100%;
    margin-top: 25px;
}

#password-change .bottom-desc .check-area {
    float: left;
    width: 100%;
}

#password-change .bottom-desc .check-area span {
    font-size: 12px;
    letter-spacing: normal;
    color: #555555;
    float: left;
    width: calc(100% - 30px);
    line-height: 15px;
}

#password-change .bottom-desc .check-area input[type="checkbox"] {
    margin: 0 9px 0 0;
    float: left;
}

#password-change .bottom-desc .offer-change {
    float: left;
    width: calc(100% - 23px);
    font-size: 12px;
    color: #555555;
    margin-left: 23px;
}

#password-change .bottom-desc .offer-change span {
    margin-top: 2px;
    float: left;
    width: 100%;
}