#policy-plan .button-area {
  padding: 20px 20px 40px 20px;
  width: calc(100% - 40px);
}

#policy-plan .plan-td-2 .name {
  float: left;
}

#policy-plan .icon-plan-open {
  float: left;
  width: 14px;
  position: relative;
  height: 16px;
  margin-left: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  cursor: pointer;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/category-close.png');
}

#policy-plan .icon-plan-close {
  float: left;
  width: 14px;
  height: 12px;
  background-size: 14px 12px;
  height: 16px;
  margin-left: 10px;
  background-position: center;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/category-close.png');
}

#policy-plan .icon-trash {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/delete-red.png');
  background-size: 20px 20px;
  cursor: pointer;
  margin: 0 auto;
}

#policy-plan .icon-cog-gray {
  width: 16px;
  height: 16px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/cog-gray.png');
  margin: 0 auto;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
}

#policy-plan .sub-plan-2 .switch {
  float: left;
  margin-right: 10px;
}

#policy-plan .sub-plan-2 .sub-plan-name {
  z-index: 0 !important;
  font-size: 12px;
  border: 1px solid #b4c0cc;
  border-radius: 2px;
  padding: 7px 10px;
  line-height: 20px;
  width: 50% !important;
}

#policy-plan .sub-plan-2 .plan-toggle {
  float: left;
  margin: 7px 0px;
}

#policy-plan .sub-plan-4 .button-section {
  width: 56px;
  height: 36px;
  margin: 0 auto;
}

#policy-plan .sub-plan-4 .button-section .button {
  height: 36px;
  width: 60px;
  border: solid 1px #7fafd6;
}

#policy-plan .sub-plan-4 .button-section .button:hover {
  border: solid 1px #7fafd6;
  background-color: #7fafd6;
  color: #ffffff;
}

#policy-plan .sub-plan-2 .name {
  padding: 3px;
}

#policy-plan .desc .sub-plan-3 .group-list {
  float: left;
  padding: 3px;
}

#policy-plan .desc tbody tr{
  height: 64px;
}

#plan-modal .add-plan {
  width: 100%;
  float: left;
}

#policy-plan .add-plan .option {
  float: left;
  width: 100%;
}

#policy-plan .add-plan .option .sub-plan-name {
  width: 70% !important;
  float: left;
  margin: 3px 0;
}

#policy-plan .add-plan .option .icon-add-plan {
  float: left;
  margin-right: 10px;
  width: 10%;
  height: 42px;
  float: left;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/icon-category-add.png');
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

#policy-plan .add-plan .sub-plan-name {
  z-index: 0 !important;
  font-size: 12px;
  border: 1px solid #b4c0cc;
  border-radius: 2px;
  padding: 7px 10px;
  line-height: 20px;
  width: 50% !important;
}

#policy-plan .desc .plan-td-1 {
  width: 10%;
  padding: 10px;
}

#policy-plan .desc .plan-td-2 {
  width: 20%;
}

#policy-plan .desc .plan-td-3 {
  width: 20%;
}

#policy-plan .desc .plan-td-3 .group-list {
  float: left;
  padding: 3px;
}

#policy-plan .desc .plan-td-4 {
  width: 20%;
  text-align: center;
}

#policy-plan .desc .plan-td-5 {
  width: 20%;
  text-align: center;
}

#policy-plan .report_complete_in {
  float: left;
  margin: 0 17px 0 20px;
}

#policy-plan .auto_submit .switch.on {
  float: left;
  margin-right: 20px;
}