.button-list button {
  background-color: #ffffff;
}

.button-list button:hover {
  background-color: #f6f7f8;
}

button#btn-save:disabled:hover {
  background-color: rgba(2, 40, 85, 0.08);

  border: 1px solid rgba(2, 40, 85, 0.08);
}

button#btn-save:disabled {
  background-color: #e6eaee;
  border: 1px solid #e6eaee;
}

button.btn-red:hover {
  border: 1px solid #de3c3c;
  color: #de3c3c;
}

.quit button.btn-red {
  width: 162px;
}

button.btn-red {
  padding-right: 30px;
  padding-left: 30px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e65858;
  color: #e65858;
  font-size: 12px;
}

button.btn-icon-red {
  float: left;
  padding-right: 12px;
  padding-left: 30px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e65858;
  color: #e65858;
  font-size: 12px;
}

@media (max-width: 1600px) {
  button.btn-report-icon {
    width: 30px;
    padding-left: 30px !important;
  }

  button.btn-report-icon span {
    display: none;
  }
}

@media (max-width: 1400px) {
  #company-users button#upload-member,
  #company-users button#invite-member,
  #expense button#btn-attach,
  #attendee button#btn-add,
  #expense button#btn-add,
  #expense button#btn-scan,
  #report button#btn-add,
  #attendee button#btn-remove,
  #expense .button-list button#btn-remove {
    padding-left: 26px !important;
    background-position: center !important;
  }

  #company-users button#upload-member span,
  #company-users button#invite-member span,
  #expense button#btn-attach span,
  #attendee button#btn-add span,
  #report button#btn-add span,
  #expense button#btn-scan span,
  #expense button#btn-add span,
  #attendee button#btn-remove span,
  #expense .button-list button#btn-remove span,
  #report button#btn-remove span {
    display: none;
  }

  #expense #bulk-selected .list-selected span,
  #expense #download .list-selected span,
  #report #export-options .list-selected span {
    display: none;
  }

  #expense #bulk-selected .list-selected {
    width: 40px;
    padding: 0;
    background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expenses/check-all.png');
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
  }

  #report #export-options #list,
  #expense #download #list,
  #expense #bulk-selected #list {
    border-top: 1px solid #7e91a8;
    margin-top: 2px;
  }

  #expense #download .list-selected {
    width: 40px;
    padding: 0;
    background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down.png');
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
  }

  #report #export-options .list-selected {
    width: 40px;
    padding: 0;
    background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/export/btn-export.png');
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

button.btn-report-icon {
  padding-right: 12px;
  padding-left: 35px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #97a9c0;
  color: #657c97;
  font-size: 12px;
  cursor: pointer;
  background-size: 20px;
  background-position: 10px;
  background-repeat: no-repeat;
}

button.btn-icon {
  padding-right: 12px;
  padding-left: 30px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #97a9c0;
  color: #657c97;
  font-size: 12px;
  cursor: pointer;
}

button.btn-icon-gray {
  padding-right: 21px;
  padding-left: 21px;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #c4c4c4;
  font-size: 12px;
  color: #c4c4c4;
  margin-right: 10px;
}

button.btn-icon-blue {
  padding-right: 21px;
  padding-left: 21px;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #047de0;
  font-size: 12px;
  color: #047de0;
  margin-right: 10px;
}

button.btn-icon-blue-clip {
  padding-right: 15px;
  padding-left: 25px;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #047de0;
  font-size: 12px;
  color: #047de0;
  margin-right: 6px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/plan/clip.png');
  background-repeat: no-repeat;
  background-position: 7px;
  background-size: 12px 13px;
}

button.btn-icon:hover {
  background-color: #ffffff;
}

#btn-add {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add.png');
  background-size: 16px 16px;
  background-position: 12px;
  background-repeat: no-repeat;
}

#btn-ok {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add.png');
  background-size: 16px 16px;
  background-position: 12px;
  background-repeat: no-repeat;
}

#btn-trash {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/trash.png');
  background-size: 14px 14px;
  background-position: 12px;
  background-repeat: no-repeat;
}

#btn-expense {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/white_receipt.png');
  background-size: 16px 17px;
  background-position: 12px;
  background-repeat: no-repeat;
}

.btn-report-download:hover {
  border: 1px solid #81beef;
  color: #037de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/down-hover.png');
}

.btn-report-download {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/down.png');
}

#btn-report-del:hover {
  border: 1px solid #e65858;
  color: #e65858;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/del-hover.png');
}

#btn-report-del {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/del.png');
}

#btn-report-export:hover {
  border: 1px solid #81beef;
  color: #037de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/export-hover.png');
}

#btn-report-export {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/export.png');
}

#btn-report-see {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/see.png');
}

#btn-report-image:hover {
  border: 1px solid #81beef;
  color: #037de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/image-hover.png');
}

#btn-report-image {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/image.png');
}

#btn-report-pdf:hover {
  border: 1px solid #81beef;
  color: #037de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/pdf-hover.png');
}

#btn-report-pdf {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/pdf.png');
}

#btn-report-mail:hover {
  border: 1px solid #81beef;
  color: #037de0;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/mail-hover.png');
}

#btn-report-mail {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/mail.png');
}

#btn-download {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/down.png');
  background-size: 16px 16px;
  background-position: 12px;
  background-repeat: no-repeat;
}

#btn-upload {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/btn-transaction-upload.png');
  background-size: 18px 12px;
  background-position: 8px;
  background-repeat: no-repeat;
  margin-left: 10px;
}

#btn-export {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/btn-transaction-upload.png');
  background-size: 16px 16px;
  background-position: 12px;
  background-repeat: no-repeat;
}

#btn-send-to-expense {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/send_to_expense.png');
  background-size: 14px 13px;
  background-position: 12px;
  background-repeat: no-repeat;
  color: #047de0;
  border: 1px solid #047de0;
}

#btn-card {
  color: #047de0;
  border: 1px solid #047de0;
  margin: 20px;
  background-color: transparent;
}

#btn-card-custom {
  background-color: transparent;
  float: right;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #333333;
}

#btn-share {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/email-gray.png');
  background-size: 14px 10px;
  background-position: 12px;
  background-repeat: no-repeat;
}

#btn-remove {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/delete-red.png');
  background-size: 15px 15px;
  background-position: 12px;
  background-repeat: no-repeat;
  border: 1px solid #e65858;
  color: #e65858;
}

#btn-remove-group {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/delete-red.png');
  background-size: 15px 15px;
  background-position: 12px;
  background-repeat: no-repeat;
  border: 1px solid #657c97;
  color: #657c97;
}

#btn-import-erp {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/import_erp.png');
  background-size: 15px 15px;
  background-position: 10px;
  background-repeat: no-repeat;
}

#btn-export-csv {
  /*background-image: url("https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/import_erp.png");*/
  background-size: 15px 15px;
  background-position: 12px;
  background-repeat: no-repeat;
}

#btn-export-csv:hover {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

#btn-export-download {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/export/download-icon.svg');
  background-size: 10px 12px;
  background-position: 12px 50%;
  background-repeat: no-repeat;
}

#btn-export-download:hover {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

#btn-export-transaction {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/export_transaction.svg');
  background-size: 12px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
}

#btn-export-transaction:hover {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

#btn-attach {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/attach-report.png');
  background-size: 16px 16px;
  background-position: 12px;
  background-repeat: no-repeat;
}

#btn-convert {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/icon-expense.png');
  background-size: 13px 15px;
  background-position: 12px;
  background-repeat: no-repeat;
  margin-left: 10px;
}

#btn-scan {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scan.png');
  background-size: 12px 12px;
  background-position: 12px;
  background-repeat: no-repeat;
}

#btn-search {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/search.png');
  background-size: 12px 12px;
  background-position: 9px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.btn-selected {
  background-color: #047cde;
  color: #fff;
  font-size: 12px;
  border: none;
}

.btn-selected:hover {
  background-color: #047cde;
  color: #fff;
  font-size: 12px;
  border: none;
}

/* i for icon */

i {
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  background-position: center center;
}

.expense-image {
  width: 16px;
  height: 17px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-image.png');
}

.expense-normal-cash {
  width: 18px;
  height: 12px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense_normal_cash.png');
  background-position: center center;
  margin-right: 10px;
}

.expense-normal-money {
  width: 18px;
  height: 12px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense_normal_money.png');
  background-position: center center;
  margin-right: 10px;
}

.expense-normal-card {
  width: 18px;
  height: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-card.png');
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.expense-normal-corporation {
  width: 18px;
  height: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-corporation.png');
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.expense-normal-personal {
  width: 18px;
  height: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-personal.png');
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.expense-policy {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-policy.png');
}

.admin-icon-card {
  background-size: 25px 15px;
  background-repeat: no-repeat;
  width: 25px;
  height: 15px;
}

.icon-card {
  background-size: 30px 18px;
  background-repeat: no-repeat;
  width: 30px;
  height: 18px;
}

/* for MS계열 브라우저 */
@keyframes bank-blink {
  0% {
    background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/bank_logged_out_alert.png');
  }
  50% {
    background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/bank_logged_out.png');
  }
}

/* for Chrome, Safari */
@-webkit-keyframes bank-blink {
  0% {
    background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/bank_logged_out_alert.png');
  }
  50% {
    background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/bank_logged_out.png');
  }
}

.icon-bank-log-out {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping/bank_logged_out.png');
  animation: bank-blink 0.65s step-end infinite;
  -webkit-animation: bank-blink 0.65s step-end infinite;
  background-size: 36px 40px;
  background-repeat: no-repeat;
  width: 36px;
  height: 40px;
}

.card-personal {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-personal.png');
  background-size: 25px 15px;
  background-repeat: no-repeat;
  width: 25px;
  height: 15px;
}

.card-public {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/public_card.png');
  background-size: 25px 15px;
  background-repeat: no-repeat;
  width: 25px;
  height: 15px;
}

.card-normal {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-card.png');
  background-size: 20px 15px;
  background-repeat: no-repeat;
  width: 25px;
  height: 15px;
}

.card-corporation {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-corporation.png');
  background-size: 25px 15px;
  background-repeat: no-repeat;
  width: 25px;
  height: 15px;
}

.icon-landing-logo {
  width: 80px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/landing/landing-logo.png');
  background-size: 80px 20px;
  display: inline-block;
  margin: 0 auto;
}

.icon-landing-logo-blur {
  width: 80px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/logo_blur.png');
  background-size: 80px 20px;
  display: inline-block;
  margin: 0 auto;
}

.icon-exclude {
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/delete-gray.png');
  background-size: 15px;
  cursor: pointer;
  display: inline-block;
  margin: 0 auto;
}

.icon-attendee {
  width: 22px;
  height: 22px;
  background-size: 22px;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.icon-attendee-both {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/attendee-both.png');
}

.icon-internal-attendee {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/internal-attendee.png');
}

.icon-external-attendee {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/external-attendee.png');
}

.icon-delete {
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/delete-gray.png');
  background-size: 14px 14px;
  cursor: pointer;
  margin: 0 auto;
}

.icon-minus {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/minus.png');
}

.icon-trash {
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/x.png');
  background-size: 14px 14px;
  cursor: pointer;
  margin: 0 auto;
}

.icon-trash-gray {
  cursor: pointer;
  width: 34px;
  float: right;
  height: 34px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-tax/delete.png');
  background-size: 34px 34px;
}

.icon-trash-gray:hover {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-tax/delete-hover.png');
  background-size: 34px 34px;
  border-radius: 20px;
}

.icon-delete-feed {
  margin-right: 24px;
  margin-left: auto;
  cursor: pointer;
  width: 15px;
  float: right;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/x.png');
  background-size: 15px;
}

.icon-delete-feed-custom {
  margin: 15.5px 20px 15.5px 0px;
  cursor: pointer;
  width: 11px;
  float: right;
  height: 11px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/x.png');
  background-size: 10px;
}

.icon-report-delete-expense:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-delete-expense-hover.png');
}

.icon-report-delete-expense {
  cursor: pointer;
  width: 20px;
  margin: 0 auto;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-delete-expense.png');
  background-size: 20px;
}

.icon-delete-small {
  cursor: pointer;
  width: 9px;
  margin: 0 auto;
  height: 9px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/delete-gray.png');
  background-size: 9px 9px;
}

.icon-duplicate {
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/trash.png');
  background-size: 14px 14px;
  cursor: pointer;
  margin: 0 auto;
}

.icon-tri-gray {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/tri-gray.png');
  background-repeat: no-repeat;
  width: 10px;
  height: 6px;
  margin: 6px 10px 0 0;
  background-size: 10px 6px;
  float: left;
}

.icon-tri-gray-closed {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/tri-gray.png');
  background-repeat: no-repeat;
  width: 10px;
  height: 12px;
  margin-right: 10px;
  background-size: 10px 6px;
  float: left;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
/*todo */
.icon-tri-blue {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-menu/report-folder-open.png');
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  background-position: center;
  margin: 4px 8px 0 0;
  background-size: 9px 10px;
  float: left;
}

.icon-add-category {
  width: 37px;
  height: 35px;
  margin-right: 10px;
  float: left;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/icon-category-add.png');
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.icon-member {
  width: 34px;
  height: 18px;
  margin: 0 auto;
  background-size: 34px 18px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.icon-final-approver {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-final-approver.png');
}

.icon-ca-fa {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/ca-fa.png');
}

.icon-company-admin {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-admin.png');
}

.icon-white-receipt {
  width: 16px;
  height: 17px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/white_receipt.png');
  background-size: 16px 17px;
  cursor: pointer;
  background-repeat: no-repeat;
}

.icon-preview {
  width: 22px;
  height: 19px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/preview.png');
  background-size: 22px 19px;
  cursor: pointer;
}

.icon-preview:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/preview-hover.png');
}

.icon-violation {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-policy.png');
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: 15px;
  cursor: pointer;
}

.icon-scraping-alert {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-policy.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 14px 14px;
  cursor: pointer;
}

.icon-config-info {
  width: 12px;
  height: 12px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/config-info.png');
  background-size: 12px 12px;
}

.icon-blue-checked {
  margin: 0 auto;
  width: 16px;
  height: 11px;
  background-size: 16px 11px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/blue-checked.png');
}

.icon-cog-gray {
  width: 34px;
  height: 34px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-tax/setting.png');
  background-size: 34px 34px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-cog-gray:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-tax/setting-hover.png');
  background-size: 34px 34px;
  background-repeat: no-repeat;
  background-color: #edeff1;
  border-radius: 20px;
}

.icon-pencil-left {
  width: 14px;
  height: 14px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/pencil.png');
  background-size: 14px 14px;
  cursor: pointer;
  background-repeat: no-repeat;
  float: left;
}

.icon-pencil-left-custom {
  width: 14px;
  height: 14px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/pencil.png');
  background-size: 11px 11px;
  background-position: 1px;
  background-repeat: no-repeat;
  float: left;
  cursor: pointer;
  margin-left: 10px;
}

.icon-pencil {
  width: 14px;
  height: 14px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/pencil.png');
  background-size: 14px 14px;
  background-repeat: no-repeat;
  float: right;
  cursor: pointer;
}

.icon-add-person {
  width: 26px;
  height: 17px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add-person.png');
  background-size: 26px 17px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-add {
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add.png');
  background-size: 12px 12px;
}

.icon-policy-pro {
  width: 320px;
  height: 450px;
  background-repeat: no-repeat;
  background-size: 320px 450px;
  cursor: pointer;
  margin-right: 20px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-pro.png');
}

.icon-policy-pro:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-pro-hover.png');
}

.icon-policy-corp {
  width: 320px;
  height: 450px;
  background-size: 320px 450px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-corp.png');
}

.icon-policy-corp:hover {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/policy-corp-hover.png');
}

.icon-road-view {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/road-view.png');
  width: 20px;
  height: 30px;
  background-size: 20px 30px;
  cursor: pointer;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.icon-arrow-down {
  margin: 0 auto;
  width: 15px;
  height: 8px;
  background-size: 15px 8px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/arrow-down.png');
}

.icon-bill-payment:before {
  content: '결제';
}

.icon-bill-payment {
  width: 30px;
}

.icon-expense-daily {
  width: 15px;
  height: 19px;
  background-position: center;
  float: left;
  margin-right: 5px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-modal/expense-daily.png');
  background-size: 15px 14px;
  background-repeat: no-repeat;
}

.icon-expense-distance {
  width: 13px;
  height: 19px;
  background-position: center;
  float: left;
  margin-right: 5px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-modal/expense-distance.png');
  background-size: 13px 16px;
  background-repeat: no-repeat;
}

.icon-expense-time {
  width: 15px;
  height: 19px;
  background-position: center;
  float: left;
  margin-right: 5px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-modal/expense-time.png');
  background-size: 15px;
  background-repeat: no-repeat;
}

.icon-expense-normal-cash {
  width: 20px;
  height: 20px;
  background-position: center;
  margin-right: 5px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-cash.png');
  background-size: 20px 15px;
  background-repeat: no-repeat;
}

.icon-checkbox {
  width: 22px;
  height: 22px;
  line-height: 35px;
  background-size: 22px 22px;
  background-repeat: no-repeat;
  float: right;
}

.icon-checkbox.checked {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/checked.png');
}

.icon-checkbox.unchecked {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/unchecked.png');
}

.icon-x {
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/x.png');
  background-size: 10px 10px;
}

.icon-add-blue {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add-blue.png');
}

.icon-help {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/help-blue.png');
  width: 14px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 14px 18px;
  cursor: pointer;
}

.icon-expense-image {
  width: 16px;
  height: 17px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-image.png');
  background-size: 16px 17px;
}

.icon-expense-image-edited {
  width: 17px;
  height: 18px;
  margin: 0 auto;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-note-image-e.png');
  background-size: 17px 18px;
  background-repeat: no-repeat;
}

.icon-category-open {
  width: 14px;
  position: relative;
  height: 12px;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  cursor: pointer;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/category-close.png');
}

.icon-category-close {
  width: 14px;
  height: 12px;
  background-size: 14px 12px;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/category-close.png');
}

.icon-report-note {
  line-height: 1;
  width: 12px;
  height: 12px;
  font-weight: bold;
  float: left;
  margin-right: 3px;
  text-align: center;
  cursor: pointer;
}

.icon-report-note-url {
  width: 12px;
  height: 12px;
  background-size: 12px 12px;
  margin-right: 3px;
  cursor: pointer;
  background-repeat: no-repeat;
  float: left;
}

.icon-feed {
  float: left;
  position: absolute;
  width: 36px;
  height: 45px;
  background-size: 12px 8px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-feed-title {
  width: 17px;
  height: 14px;
  background-size: 17px 14px;
  cursor: pointer;
  margin-right: 5px;
  background-repeat: no-repeat;
  float: left;
}

.icon-feed-open {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png');
}

.icon-feed-close {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/select-down-dark.png');
}

.icon-saving {
  width: 35px;
  height: 35px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/saving.png');
  background-size: 35px;
  background-repeat: no-repeat;
}

.icon-withdrawal {
  width: 35px;
  height: 35px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/withdrawal.png');
  background-size: 35px;
  background-repeat: no-repeat;
}

.icon-expense-card {
  background-size: 20px 13px;
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-expense-normal-corporation {
  width: 20px;
  height: 13px;
  margin-right: 5px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-corporation.png');
  background-size: 20px 13px;
  background-repeat: no-repeat;
}

.icon-expense-normal-personal {
  width: 20px;
  height: 13px;
  margin-right: 5px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-personal.png');
  background-size: 20px 13px;
  background-repeat: no-repeat;
}

.icon-expense-normal-card {
  width: 18px;
  height: 13px;
  margin-right: 7px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-normal-card.png');
  background-size: 18px 13px;
  background-repeat: no-repeat;
  margin-top: 2px;
}

.icon-asterisk {
  margin-right: 3px;
  font-weight: bold;
  vertical-align: middle;
  font-size: 17px;
  color: #e65858;
}

.icon-scraping-mobile {
  width: 12px;
  height: 15px;
  float: left;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/scraping-mobile.png');
  background-size: 12px 15px;
}

.icon-csv {
  width: 15px;
  height: 15px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/csv.png');
  background-size: 15px;
}

.icon-erp-exported {
  width: 15px;
  height: 15px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/erp/erp-connected-active.png');
  background-size: 15px;
}

.icon-self {
  width: 26px;
  height: 19px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/self.png');
  background-size: 26px 19px;
}

.icon-notify-policy {
  width: 12px;
  height: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/notify-policy.png');
  background-repeat: no-repeat;
  background-size: 12px;
  margin-right: 5px;
  margin-top: 2px;
  float: left;
}

.icon-bill {
  width: 18px;
  height: 20px;
  margin: 0 auto;
  background-size: 18px, 20px;
  background-repeat: no-repeat;
}

.icon-bill-preview {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  background-size: 20px, 20px;
  background-repeat: no-repeat;
}

.bill-preview-active {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/preview-blue.png');
}

.bill-preview-inactive {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/preview-gray.png');
}

.bill-active {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/bill-active.png');
}

.bill-inactive {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/bill-inactive.png');
}

.float-left {
  float: left;
}

.inline-block {
  display: inline-block;
}

.icon-next {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-prev.png');
  background-repeat: no-repeat;
  width: 10px;
  height: 12px;
  cursor: pointer;
  float: right;
  background-size: 10px 12px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-prev {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-prev.png');
  background-repeat: no-repeat;
  width: 10px;
  height: 12px;
  background-size: 10px 12px;
  float: left;
  cursor: pointer;
}

/*navigation for expense */

.icon-expense-navigation.inactive {
  border: solid 1px #b9c4cf !important;
  background-color: transparent;
}

.icon-expense-navigation {
  background-color: #ffffff;
  border: solid 1px #b9c4cf;
  display: inline-block;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-expense-prev.active:hover {
  background-color: #f6f7f8;
}
.icon-expense-prev.active {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-prev-active.png');
  border-right: none !important;
  background-repeat: no-repeat;
}

.icon-expense-prev.inactive {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-prev-inactive.png');
  border-right: none !important;
  background-repeat: no-repeat;
}

.icon-expense-next.active:hover {
  background-color: #f6f7f8;
}
.icon-expense-next.active {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-next-active.png');
}

.icon-expense-next.inactive {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/expense-next-inactive.png');
}

/* navigation for report */

.icon-report-navigation.inactive {
  border: solid 1px #b9c4cf !important;
  background-color: transparent;
}

.icon-report-navigation {
  background-color: #ffffff;
  border: solid 1px #b9c4cf;
  display: inline-block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-size: 8px 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-report-prev.active:hover {
  background-color: #f6f7f8;
}
.icon-report-prev.active {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-prev-active.png');
  border-right: none !important;
}

.icon-report-prev.inactive {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-prev-inactive.png');
  border-right: none !important;
}

.icon-report-next.active:hover {
  background-color: #f6f7f8;
}

.icon-report-next.active {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-active.png');
}

.icon-report-next.inactive {
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/report-next-inactive.png');
}

.icon-new-attendee {
  width: 12px;
  height: 12px;
  float: left;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 5px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/attendee-new.png');
  background-size: 12px;
}

#btn-download-transactions {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/download-transaction.png');
  background-size: 20px 20px;
  background-position: 10px;
  background-repeat: no-repeat;
  height: 30px;
  float: left;
  color: #047de0;
  border: 1px solid #047de0;
  margin-bottom: 5px;
  margin-right: 10px;
}

#btn-add-to-expense {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/add-to-expense.png');
  background-size: 20px 20px;
  background-position: 10px;
  background-repeat: no-repeat;
  height: 30px;
  color: #047de0;
  float: left;
  border: 1px solid #047de0;
}

.icon-expense-filter {
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #40464d;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/filter-white.png');
  background-size: 12px;
}
.icon-report-filter {
  width: 40px;
  height: 40px;
  float: left;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/filter-black.png');
  background-size: 12px;
}

.icon-feed-alert {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/feed-alert.png');
  width: 22px;
  height: 22px;
  background-size: 19px;
  float: left;
  margin-right: 8px;
  cursor: pointer;
  background-repeat: no-repeat;
}

.icon-show-hidden-feeds {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/show-hidden-feeds.png');
  width: 25px;
  height: 15px;
  background-size: 15px 14px;
  float: left;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-feed-subgroup-fold {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/feed-subgroup-fold.png');
  width: 14px;
  height: 8px;
  margin-right: 13px;
  background-size: 14px 8px;
  float: left;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-feed-subgroup-fold.closed {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

.icon-report-copy {
  cursor: pointer;
  width: 15px;
  float: right;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-tax/show-report.png');
  background-size: 34px 34px;
}

.icon-report-copy:hover {
  cursor: pointer;
  width: 15px;
  float: right;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-tax/show-report-hover.png');
  background-size: 34px 34px;
  background-color: #edeff1;
  border-radius: 20px;
}

.icon-policy-copy {
  cursor: pointer;
  width: 15px;
  float: right;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-tax/duplicate-policy.png');
  background-size: 34px 34px;
}

.icon-policy-copy:hover {
  cursor: pointer;
  width: 15px;
  float: right;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-tax/duplicate-policy-hover.png');
  background-size: 34px 34px;
  background-color: #edeff1;
  border-radius: 20px;
}

.icon-filter-reset {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-resource-staging/icons/svg/filter-reset-icon.svg');
  background-repeat: no-repeat;
  width: 9px;
  height: 12px;
  background-size: 9px 12px;
}

#expense .report-info .report-refunded,
#expense .report-info .report-approved,
#expense .report-info .report-approved-to-refund,
#expense .report-info .report-refund_completed,
#expense .report-info .report-refunded,
#expense .report-info .report-sent,
#expense .report-info .report-ready {
  margin-right: 6px;
  height: 16px;
  border-radius: 16px;
  background-color: #9c9c9c;
  float: left;
  font-size: 9px;
  font-weight: normal;
  line-height: 17px;
  text-align: center;
  letter-spacing: normal;
  padding: 0 5px;
  white-space: nowrap;
}

#expense .report-info .report-sent {
  background-color: #95b941;
  color: #ffffff;
}

#expense .report-info .report-ready {
  background-color: #1b64b9;
  color: #ffffff;
}

#expense .report-info .report-refunded {
  background-color: #7b7b7b;
  color: #ffffff;
}

#expense .report-info .report-refund_completed {
  background-color: #ffffff;
  color: #7b7b7b;
  border: 1px solid #9c9c9c;
  height: 14px;
  line-height: 14px;
}

#expense .report-info .report-approved-to-refund {
  background-color: #0b8672;
  color: #ffffff;
}

#expense .report-info .report-approved {
  background-color: #ffffff;
  border: 1px solid #0b8672;
  color: #0b8672;
  height: 14px;
  line-height: 14px;
}

#expense .report-info .report-approved.company_tax {
  background-color: #0b8672;
  color: #ffffff;
}

#expense .report-info .report-refunded {
  background-color: #9c9c9c;
  color: #ffffff;
}
