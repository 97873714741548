#policy-approval-flow .desc dl {
  margin: 15px 0;
  height: 30px;
}

#policy-approval-flow .desc dl dt {
  height: 30px;
  line-height: 30px;
  width: 25%;
}

#policy-approval-flow .desc dl dd {
  line-height: 29px;
  vertical-align: top;
}

#policy-approval-flow #btn-link-member-code {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/link_member_code.png');
  background-size: 25px 10px;
  background-position: 12px;
  padding-left: 45px;
  background-repeat: no-repeat;
}

#policy-approval-flow > .settings > .content > .title {
  background-color: #ffffff;
  border-radius: 6px;
}

#policy-approval-flow .btn-add-member {
  color: #ffffff;
  width: 80px;
  background-color: #97a9c0;
  border: 1px solid #97a9c0;
  float: right;
}

#policy-approval-flow .select-member .Select {
  height: 30px;
}

#policy-approval-flow .select-member .Select .Select-control input {
  height: 24px !important;
}

#policy-approval-flow .select-member {
  width: calc(100% - 100px);
  float: left;
}

.swal-sub-title {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #212121;
  line-height: 1.5;
  margin-bottom: 16px;
}

.swal-policy-erp img {
  height: 14px;
  margin-right: 15px;
}

.swal-policy-erp {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: rgba(191, 201, 212, 0.14);
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #7d7d7d;
  padding: 12px;
  line-height: 1.5;
  text-align: left;
}

#policy-info #policy-approval-flow .display-policy-members .search-field {
  height: 30px;
  padding: 40px 25px;
}

#policy-info #policy-approval-floww .display-policy-members .search-field input::placeholder {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #9f9f9f;
}

#policy-info #policy-approval-flow .display-policy-members .search-field .button-area {
  margin: 15px 0 0 20px;
  width: 50%;
  float: left;
}

#policy-info #policy-approval-flow .desc .policy-member-table {
  float: left;
  margin-left: 20px;
  width: calc(100% - 10px);
}

/*#policy-approval-flow .search-area {*/
/*  float: right;*/
/*  width: 200px;*/
/*  height: 30px;*/
/*  border: 1px solid #97a9c0;*/
/*  background-color: #FFFFFF;*/
/*  margin: 20px 20px 0 20px;*/
/*}*/

#policy-info #policy-approval-flow .search-area .search-icon {
  cursor: pointer;
  float: right;
  background-position: center;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-size: 15px;
  background-repeat: no-repeat;
  background-color: #ffffff;
  padding: 0 10px 0 5px;
  width: 10px;
  height: 30px;
}

#policy-info #policy-approval-flow .search-area input[type='text'] {
  background-color: #ffffff;
  padding: 2px 0 2px 10px;
  font-size: 13px;
  float: left;
  width: 165px;
  height: 26px;
  border: 0 solid #97a9c0 !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.3px;
}

#policy-info #policy-approval-flow .search-area input[type='text']:focus {
  border: 0 solid #97a9c0 !important;
}
/*
#policy-approval-flow .content .search-field button {
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 12px;
  color: #637c96;
  border: solid 1px #97a9c0;
  margin-left: 5px;
}

#policy-approval-flow .content .search-field button:hover {
  border-radius: 2px;
  background-color: #f6f7f8;
  font-size: 12px;
  transition: 0s;
} */

#policy-approval-flow .initial-field {
  position: relative;
  float: left;
  -ms-overflow-style: none;
  width: 50px;
  z-index: 30;
  margin: 48px 0 10px 0;
  border-radius: 3px;
  background-color: #cad0d5;
  overflow: scroll;
  left: 20px;
}

#policy-approval-flow .initial-field #ko {
  height: 500px;
}

#policy-approval-flow .initial-field #en {
  height: 500px;
}

#policy-approval-flow .initial-field .dot {
  margin: 15px 22px;
  width: 4px;
  height: 4px;
  opacity: 0.4;
  background-color: #ffffff;
  border-radius: 10px;
}

#policy-approval-flow .initial-field .ko-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#policy-approval-flow .initial-field .en-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#policy-approval-flow .initial-field .all-field {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
}

#policy-approval-flow .initial-field .all-field-active,
#policy-approval-flow .initial-field .en-field-active,
#policy-approval-flow .initial-field .ko-field-active,
#policy-approval-flow .initial-field .all-field:hover,
#policy-approval-flow .initial-field .ko-field:hover,
#policy-approval-flow .initial-field .en-field:hover {
  padding: 5px 5px;
  margin: 10px 5px;
  height: 17px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  border-radius: 2px;
  cursor: pointer;
  color: #cad0d5;
  background-color: #ffffff;
  font-weight: bold;
}

#policy-approval-flow .desc .button-area {
  display: inline-flex;
  padding: 0 0 30px 0;
}

#policy-approval-flow .desc .approver-info {
  border-radius: 8px;
  background-color: var(--white);
}

#policy-approval-flow .desc .approver-info .approval-line-detail {
}

#policy-approval-flow .desc .approver-info .approver-info-detail {
  width: 100%;
  padding: 27px 0;
  height: 27px;
}

#policy-approval-flow .desc .approver-info .approval-line-detail .display-draggable-approver {
  width: 100%;
  border-top: 1px solid #bac4d0;
  border-bottom: 1px solid #bac4d0;
  display: grid;
}

#policy-approval-flow .desc .approver-info .approval-line-detail .display-draggable-approver .approval-dnd-desc {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 31px;
  letter-spacing: -0.32px;
  color: #7d7d7d;
}

#policy-approval-flow .desc .approver-info .approval-line-detail .display-draggable-approver .button-area {
  padding: 20px 0 10px 21px;
  width: calc(100% - 21px);
}

#policy-approval-flow .approver-section {
  float: left;
  width: calc(100% - 50px);
  margin: 0 25px;
  height: 240px;
  border-radius: 2px;
  background-color: #f3f5f7;
  display: inline-block;
}

#policy-approval-flow .approver-section .inner {
  display: inline-block;
  float: left;
  height: 240px;
  padding: 0 10px;
}

#policy-approval-flow .List {
  float: left;
}

#policy-info #policy-approval-flow .policy-member-td-icon {
  width: 5%;
}

#policy-info #policy-approval-flow .policy-member-td-check {
  width: 3%;
  padding-left: 10px;
}

#policy-info #policy-approval-flow .policy-member-td-1 {
  width: 30%;
  padding-left: 10px;
}

#policy-info #policy-approval-flow .policy-member-td-2 {
  width: 30%;
  padding-left: 10px;
}

#policy-info #policy-approval-flow .policy-member-td-3 {
  width: 20%;
  padding-left: 10px;
}

#policy-info #policy-approval-flow .policy-member-td-4 {
  width: 10%;
  padding-left: 10px;
  text-align: right;
}

#policy-info #policy-approval-flow .policy-member-td-5 {
  width: 10%;
  padding-left: 10px;
}

#policy-info #policy-approval-floww .policy-member-td-6 {
  width: 15%;
  padding-left: 10px;
}

#policy-approval-flow #add-group-modal .div-table-body .policy-member-td-3 {
  margin-left: 3px;
}

#policy-info #policy-approval-flow .div-table-header {
  position: absolute !important;
  border-bottom: 1px solid #687987;
}

#policy-info #policy-approval-flow .div-table-body-wrapper p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#policy-info #policy-approval-flow .div-table-body-wrapper .delete-icon-gray {
  background-image: url(https://spendit-resource-staging.s3.ap-northeast-2.amazonaws.com/icons/svg/delete-icon-grey.svg);
  background-size: 16px 16px;
  width: 100%;
  height: 16px;
  background-position: right;
  background-repeat: no-repeat;
}

#policy-info #policy-approval-flow .display-policy-members .add-policy-member-table {
  position: relative;
  border-radius: 2px;
  padding: 0 20px;
}


#policy-info #policy-approval-flow .select-box ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
}

#policy-info #policy-approval-flow ::-webkit-scrollbar-thumb {
  border-radius: 3px !important;
  background-color: #d5d5d5 !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}
