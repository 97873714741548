#mypage #split-view #spent-info .content .billing-transaction-td-1 {
  width: 15px;
  padding: 0 10px;
}

#mypage #split-view #spent-info .content .billing-transaction-td-2 {
  width: 35%;
}

#mypage #split-view #spent-info .content .billing-transaction-td-2 .canceled {
  border: solid 1px #e65858;
  border-radius: 100px;
  color: #e65858;
  line-height: 18px;
  font-size: 9px;
  float: left;
  padding: 0 3px;
  margin-right: 5px;
}

#mypage #split-view #spent-info .content .transaction-row .billing-transaction-td-2 p.report-title {
  font-size: 12px;
  letter-spacing: -0.2px;
  text-align: left;
  line-height: 20px;
  color: #9d9d9d;
}

#mypage #split-view #spent-info .content .billing-transaction-td-2 p.store {
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}

#mypage #split-view #spent-info .content .billing-transaction-td-3 {
  width: 10%;
  text-align: right;
}

#mypage #split-view #spent-info .content .billing-transaction-td-3 p.address {
  font-size: 12px;
  color: #7d7d7d;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
}

#mypage #split-view #spent-info .content .billing-transaction-td-4 {
  width: 10%;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .transaction-row .billing-transaction-td-4 {
  font-size: 14px;
}

#mypage #split-view #spent-info .content .billing-transaction-td-5 {
  width: 10%;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .transaction-row .billing-transaction-td-5 {
  font-size: 14px;
}

#mypage #split-view #spent-info .content .billing-transaction-td-6 {
  width: 10%;
  text-align: right;
  padding-right: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .transaction-row .billing-transaction-td-6 {
  font-size: 14px;
}

#mypage #split-view #spent-info .content .billing-transaction-td-6 p.subtotal {
  font-weight: 700;
}

#mypage #split-view #spent-info .content .billing-transaction-td-7 {
  width: 13%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .billing-transaction-td-7 p.time {
  color: #7d7d7d;
  font-size: 12px;
  line-height: 60px;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#mypage #split-view #spent-info .content .billing-transaction-td-number {
  width: 5%;
  font-size: 11px;
  text-align: right;
  padding-right: 10px;
  color: #619cd8;
}

.billing-desc {
  font-size: 12px;
  color: #b0b0b0;
  float: left;
  line-height: 25px;
}

.transaction-title {
  position: fixed;
  z-index: 2;
  width: -webkit-calc(100% - 260px);
  width: -moz-calc(100% - 260px);
  width: calc(100% - 260px);
  height: 130px;
  background: #fff;
  color: #002855;
  font-size: 16px;
  font-weight: 300;
  padding-top: 21px;
  text-align: center;
  cursor: pointer;
}

#billing-transaction-title span {
  float: left;
  border-bottom: none !important;
}

#billing-transaction-title .divide {
  font-size: 14px;
  color: #b2becc;
  text-align: center;
  padding: 0;
}

/* billing-transaction-filter */
#billing-transaction-filter {
  width: -webkit-calc(100% - 330px);
  width: -moz-calc(100% - 330px);
  width: calc(100% - 330px);
  margin-top: 90px;
  position: fixed;
  transition: none !important;
  height: 35px;
  border-bottom: 1px solid #efefef;
  padding: 0 35px;
  background-color: #fff;
  z-index: 3;
  float: left;
  vertical-align: middle;
  display: inline-block;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

#billing-transaction-filter #select-filters {
  display: inline-block;
  height: 28px;
}

#billing-transaction-filter .apply {
  position: relative;
  float: left;
  width: 64px;
  height: 28px;
  border-radius: 15px;
  background-color: #e6eaee;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: -0.2px;
  color: #ffffff;
  cursor: pointer;
}

#billing-transaction-filter .apply-active {
  background-color: #047cde;
  position: relative;
  float: left;
  width: 64px;
  height: 28px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: -0.2px;
  color: #ffffff;
  cursor: pointer;
}

#billing-transaction-filter .scraped-at span {
  margin-left: 15px;
}

#billing-transaction-filter .scraped-at {
  float: right;
  color: #657c97;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/refresh-transaction.png');
  background-size: 10px 13px;
  background-repeat: no-repeat;
  background-position: left;
  cursor: pointer;
  margin-right: 3px;
}

#billing-transaction-filter .period {
  float: left;
  display: inline-block;
  margin-right: 10px;
}

#billing-transaction-filter .period p {
  float: left;
  margin-right: 10px;
  line-height: 30px;
  font-size: 10px;
  letter-spacing: -0.2px;
  color: #7d7d7d;
}

#billing-transaction-filter .period ul {
  display: inline-block;
}

#billing-transaction-filter .period ul li.period-selected,
#billing-transaction-filter .period ul li:hover {
  font-weight: bold;
  color: #047cde;
}

#billing-transaction-filter .period ul li {
  height: 28px;
  width: 45px;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: -0.2px;
  color: #002855;
  cursor: pointer;
  text-align: center;
}

#billing-transaction-filter .period .division {
  width: 1px;
  padding: 0 2px !important;
  opacity: 0.5;
}

#billing-transaction-filter .period.en ul li.period-selected,
#billing-transaction-filter .period en ul li:hover {
  font-weight: bold;
  color: #047cde;
  width: 70px;
}

#billing-transaction-filter .period.en ul li {
  width: 65px;
  font-size: 11px;
}

#billing-transaction-filter .period.en ul li {
  height: 28px;
  width: 70px;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: -0.2px;
  color: #002855;
  cursor: pointer;
  text-align: center;
}

#billing-transaction-filter .period.en .division {
  width: 1px;
  padding: 0 !important;
  opacity: 0.5;
}

#billing-transaction-filter .period ul li:first-child {
}

#billing-transaction-filter .period ul li:last-child {
  padding-left: 8px;
}

#billing-transaction-filter .select-calendar .element {
  float: left;
  display: inline-block;
}

#billing-transaction-filter .select-calendar .element.dash {
  line-height: 28px;
  font-size: 12px;
  color: #657c97;
  font-weight: bold;
  width: 20px;
  text-align: center;
}

#billing-transaction-filter .picker-div {
  height: 28px;
  width: 70px;
  float: left;
  border: 1px solid #fff !important;
}

#billing-transaction-filter .picker-div .rdt {
  width: inherit !important;
  float: none !important;
  height: 28px;
}

#billing-transaction-filter .picker-div input {
  position: absolute;
  top: 0;
  width: 100%;
  line-height: 27px;
  font-size: 12px;
  color: #002855;
  text-align: center;
}

#billing-transaction-filter .picker-div .rdt .rdtPicker {
  top: 40px;
  position: absolute !important;
}

#billing-transaction-filter .picker-div .icon-calendar-active {
  display: none;
}

#billing-transaction-filter .picker-div-active {
  height: 28px;
  width: 70px;
  float: left;
  border: 1px solid #fff !important;
}

#billing-transaction-filter .picker-div-active .rdt {
  width: inherit !important;
  float: none !important;
  height: 28px;
}

#billing-transaction-filter .picker-div-active input {
  position: absolute;
  top: 0;
  width: 100%;
  line-height: 27px;
  font-size: 12px;
  font-weight: bold;
  color: #047cde;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#billing-transaction-filter .picker-div-active input[type='text']:focus {
  background-color: transparent !important;
  border: 0 solid !important;
}

#billing-transaction-filter .picker-div-active .rdt .rdtPicker {
  top: 40px;
  position: absolute !important;
}

#billing-transaction-filter .picker-div-active .icon-calendar-active {
  display: none;
}

#billing-transaction-filter .period-filter-custom {
  width: 100%;
  float: left;
  position: relative;
}

#billing-transaction-filter .select-filters-custom {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 10px;
}

@media (max-width: 1200px) {
  #btn-add-to-expense .label,
  #btn-download-transactions .label {
    display: none;
  }
}

@media (max-width: 999px) {
  #billing-transaction-filter {
    width: -webkit-calc(100% - 70px); /* for Chrome, Safari */
    width: -moz-calc(100% - 70px); /* for Firefox */
    width: calc(100% - 70px); /* for IE */
  }

  .sub-title#card-transaction-title {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  #billing-transaction-filter #select-filters #transaction {
    display: none !important;
  }
}
