.spendit-modal #add-group-modal .div-table-header {
  width: calc(100% - 52px) !important;
  background-color: #fafafa;
  border-bottom: 1px solid rgb(213, 213, 213) !important;
}

.spendit-modal #add-group-modal .user-row {
  height: 40px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.32px;
  color: #101010;
}

.spendit-modal #add-group-modal .policy-member-td-1 {
  width: 30%;
}

.spendit-modal #add-group-modal .div-table-body {
  padding: 27px 0 0 0;
}

.spendit-modal #add-group-modal .policy-member-td-2 {
  width: 40%;
}

.spendit-modal #add-group-modal .policy-member-td-3 {
  width: 20%;
}

.spendit-modal #add-group-modal .search-area {
  width: 100%;
  height: 90px;
}

.spendit-modal #add-group-modal p.member-name {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.spendit-modal #add-group-modal p.member-email {
  font-size: 11px;
  font-weight: normal;
  color: #7d7d7d;
  line-height: 11px;
  height: 14px;
}

.spendit-modal #add-group-modal .search-area .search-desc {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.23px;
  color: #555555;
  float: left;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
}

.spendit-modal #add-group-modal .search-area .search-field {
  width: 100%;
  float: left;
}

.spendit-modal #add-group-modal .search-area .search-field .page-count {
  float: left;
  width: 100%;
  height: 18px;
  text-align: right;
  color: #047de0;
  font-size: 12px;
  margin: 10px 0;
  line-height: 18px;
}

.spendit-modal #add-group-modal .search-area .group-search-field .page-count {
  float: left;
  width: 100%;
  height: 18px;
  text-align: right;
  color: #047de0;
  font-size: 12px;
  margin: 10px 0;
  line-height: 18px;
}

.spendit-modal #add-group-modal .search-area input[type='text']:focus {
  border: none !important;
  border-bottom: solid 2px #002855 !important;
}

.spendit-modal #add-group-modal .search-area input[type='text'] {
  font-weight: normal;
  font-style: normal;
  font-size: 13px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: left;
  color: #000000;
  float: left;
  border-bottom: solid 2px #002855 !important;
  height: 28px;
  padding-right: 35px;
  padding-left: 15px;
  width: calc(100% - 50px);
  left: unset;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-color: transparent;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98%;
}

.spendit-modal #add-group-modal .search-area input[type='text']::placeholder {
  font-size: 16px;
  letter-spacing: -0.27px;
  color: #b7b7b7;
}

.spendit-modal #add-group-modal .search-area ul {
  margin-top: 10px;
}

.spendit-modal #add-group-modal .search-area ul #list-clicked {
  z-index: 300;
}

.spendit-modal #add-group-modal .member-icon img {
  margin: 4px 0;
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 15px;
}

.spendit-modal #add-group-modal .member-info {
  margin: 0 0 0 5px;
  float: left;
}

.spendit-modal #add-group-modal .button-field {
  margin-top: 0px;
  float: right;
  width: 100%;
  padding-bottom: 10px;
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

.spendit-modal #add-group-modal .button-field button.submit {
  font-weight: normal;
  height: 32px;
  width: 68px;
  float: right;
  font-size: 14px;
  padding: 0 5px;
}

.spendit-modal #add-group-modal .button-field button.submit:disabled {
  background-color: #f9f9f9 !important;
  border: 0;
  color: #aaa;
}

.spendit-modal #add-group-modal .button-field button.cancel {
  height: 32px;
  float: right;
  width: 68px;
  font-size: 14px;
  padding: 0 5px;
}

.spendit-modal #add-group-modal .add-policy-member-table {
  overflow: scroll;
  border-radius: 2px;
  border: solid 1px #d5d5d5 !important;
}

.spendit-modal #add-group-modal ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 4px;
}

.spendit-modal #add-group-modal#add-policy-member-table ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
}

.spendit-modal #add-group-modal ::-webkit-scrollbar-thumb {
  border-radius: 3px !important;
  background-color: #d5d5d5 !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

/* policy-approval-flow */
#policy-info #policy-approval-flow .spendit-modal #add-group-modal .div-table-header {
  background-color: #fafafa;
  border-bottom: 1px solid rgb(213, 213, 213) !important;
  color: #7d7d7d;
  font-size: 12px;
  position: fixed !important;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .search-area {
  width: 100%;
  height: 90px;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .search-area .search-desc {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.23px;
  color: #555555;
  float: left;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .search-area .group-search-field {
  width: 100%;
  float: left;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .search-area .group-search-field .page-count {
  float: left;
  width: 100%;
  height: 18px;
  text-align: right;
  color: #047de0;
  font-size: 12px;
  margin: 10px 0;
  line-height: 18px;
}

#policy-info #policy-approval-flow #add-group-modal .search-area input[type='text']:focus {
  border: none !important;
  border-bottom: solid 2px #002855 !important;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .search-area input[type='text'] {
  font-weight: normal;
  font-style: normal;
  font-size: 13px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: left;
  color: #000000;
  float: left;
  border-bottom: solid 2px #002855 !important;
  height: 28px;
  padding-right: 35px;
  padding-left: 15px;
  width: calc(100% - 50px);
  left: unset;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/spendit-bucket-staging/resource/icons/company-user-search.png');
  background-color: transparent;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98%;
}

#policy-info
  #policy-approval-flow
  .spendit-modal
  #add-group-modal
  .search-area
  input[type='text']::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 17px;
  color: #b7b7b7;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .search-area ul {
  margin-top: 10px;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .search-area ul #list-clicked {
  z-index: 300;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .user-row {
  height: 40px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.32px;
  color: #101010;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .member-icon img {
  margin: 4px 0;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .member-info {
  margin: 4px 0 4px 5px;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .button-field {
  margin-top: 0px;
  float: right;
  width: 100%;
  padding-bottom: 10px;
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .button-field button.submit {
  font-weight: normal;
  height: 32px;
  width: 68px;
  float: right;
  font-size: 14px;
  padding: 0 5px;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .button-field button.cancel {
  height: 32px;
  float: right;
  width: 68px;
  font-size: 14px;
  padding: 0 5px;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal .add-policy-member-table {
  overflow: scroll;
  border-radius: 2px;
  border: solid 1px #d5d5d5 !important;
  padding: 0px;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
  width: 4px;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal#add-policy-member-table ::-webkit-scrollbar {
  display: inline !important;
  -webkit-appearance: none !important;
  height: 5px;
}

#policy-info #policy-approval-flow .spendit-modal #add-group-modal ::-webkit-scrollbar-thumb {
  border-radius: 3px !important;
  background-color: #adadad !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}
